import PropTypes from 'prop-types';
import clsx from 'clsx';

import './ModalBody.scss';

export const SurveyModalsModalBody = ({ className, ...props }) => (
	<div className={clsx('SurveyModalsModalBody', className)} {...props} />
);

SurveyModalsModalBody.propTypes = {
	className: PropTypes.string,
};

SurveyModalsModalBody.defaultProps = {
	className: undefined,
};
