// @ts-check

import { api } from '../api';

const API_MODERATION_REPORT_PATH = '/moderation/report';

export const reportVideo = async (
	id,
	category,
	reason,
) => api.post(
	`${API_MODERATION_REPORT_PATH}/video/${id}`,
	{ reason, category },
);

export const reportChannel = async (
	id,
	category,
	reason,
	studio,
) => api.post(
	`${API_MODERATION_REPORT_PATH}/channel/${id}`,
	{ reason, category, studio },
);

/**
 * @param {string} id
 * @param {string} category
 * @param {string} reason
 * @param {string} studio
 * @returns {Promise<import('axios').AxiosResponse<void>>}
 */
export const reportGuest = async (
	id,
	category,
	reason,
	studio,
) => api.post(
	`${API_MODERATION_REPORT_PATH}/guest/${id}`,
	{ reason, category, studio },
);
