export const properties = {
	name: {
		type: 'string',
	},
	tel: {
		type: 'string',
	},
	address: {
		type: 'string',
	},
	email: {
		type: 'string',
	},
};

export const schema = {
	type: 'object',
	additionalProperties: false,
	properties,
	required: [
		'name',
		'tel',
	],
};
