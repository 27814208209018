import { useEffect, useState } from 'react';
import { Badge } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { useCurrentStudio } from '../useCurrentStudio';

export const StudioGoLiveCountdown = () => {
	const { t } = useTranslation();
	const [countdown, setCountdown] = useState(null);

	const {
		currentStudio: { liveStartedAt },
	} = useCurrentStudio();

	useEffect(() => {
		if (liveStartedAt) {
			const intervalId = setInterval(() => {
				const timeDiff = new Date(liveStartedAt) - new Date();
				if (timeDiff > 0) {
					const secondsLeft = Math.ceil(timeDiff / 1000);
					setCountdown(secondsLeft);
				} else {
					setCountdown(-1);
					clearInterval(intervalId);
				}
			}, 1000);

			return () => clearInterval(intervalId);
		}
		return undefined;
	}, [liveStartedAt]);

	let text = t('GoLive.Countdown.initializingLive');
	if (countdown) {
		if (countdown > 0) {
			text = t('GoLive.Countdown.countdown', { value: countdown });
		} else {
			text = t('GoLive.Countdown.onAirImminent');
		}
	}

	return (
		<Badge
			color={(countdown && countdown <= 5) ? 'warning' : 'info'}
			title={text}
		>
			{text}
		</Badge>
	);
};
