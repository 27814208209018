import { createContext, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

const ListSortingContext = createContext({});

export const useListSorting = () => useContext(ListSortingContext);

export const ListSortingType = {
	NEWEST_TO_OLDEST: 'NEWEST_TO_OLDEST',
	OLDEST_TO_NEWEST: 'OLDEST_TO_NEWEST',
};

export const ListSortingProvider = ({ children, defaultSort }) => {
	const [currentSort, setCurrentSort] = useState(defaultSort);

	const value = useMemo(() => ({
		currentSort,
		setCurrentSort,
	}), [
		currentSort,
		setCurrentSort,
	]);

	return (
		<ListSortingContext.Provider value={value}>
			{children}
		</ListSortingContext.Provider>
	);
};

ListSortingProvider.propTypes = {
	children: PropTypes.node.isRequired,
	defaultSort: PropTypes.oneOf(Object.values(ListSortingType)),
};

ListSortingProvider.defaultProps = {
	defaultSort: ListSortingType.NEWEST_TO_OLDEST,
};
