import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Field, FieldError, Form as SchemaForm } from 'react-jsonschema-form-validation';
import { Alert, Button, Container, FormGroup, Input, Label, Popover, PopoverBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { FaInfoCircle } from 'react-icons/fa';
import { FormLabel } from '../../Form/Label';
import FormSubmit from '../../Form/Submit';
import { useCreateOrganizationRole, useFetchOrganizationDetails, useUpdateOrganizationRole } from '../../../api-hooks/organization/organization';
import { OrganizationVisibility } from '../organizationSettings.schema';

export const RolePaymentType = {
	FREE: 'FREE',
	PAID: 'PAID',
};

const roleSettingsScheme = {
	type: 'object',
	additionalProperties: false,
	properties: {
		name: {
			type: 'string',
			minLength: 1,
			maxLength: 30,
		},
		description: {
			tpe: 'string',
			maxLength: 150,
		},
		allowMemberJoinRequests: {
			type: 'boolean',
		},
		memberJoinRequestPrice: {
			type: 'number',
		},
		canManageOrganization: {
			type: 'boolean',
		},
		canStartStudio: {
			type: 'boolean',
		},
		canUploadVod: {
			type: 'boolean',
		},
		canSeeOrganizationBalance: {
			type: 'boolean',
		},
		paymentType: {
			type: 'string',
			enum: Object.values(RolePaymentType),
		},
		featured: {
			type: 'boolean',
		},
		featureOwner: {
			type: 'boolean',
		},
	},
};

const getInitialData = (role) => ({
	name: role?.name ?? '',
	description: role?.description ?? '',
	allowMemberJoinRequests: role?.allowMemberJoinRequests ?? false,
	memberJoinRequestPrice: role?.memberJoinRequestPrice ?? 0,
	canManageOrganization: role?.canManageOrganization ?? false,
	canStartStudio: role?.canStartStudio ?? false,
	canUploadVod: role?.canUploadVod ?? false,
	featured: role?.featured ?? false,
	featureOwner: role?.featureOwner ?? false,
	canSeeOrganizationBalance: role?.canSeeOrganizationBalance ?? false,
	paymentType: role?.paymentType ?? RolePaymentType.FREE,
});

export const RoleForm = ({ role, onCancel, onCreate }) => {
	const { t } = useTranslation();
	const [data, setData] = useState(getInitialData(role));
	const clear = useCallback(() => setData(getInitialData(role)), [role]);

	const { data: organization } = useFetchOrganizationDetails();

	const [showPopover, setShowPopover] = useState([false, false]);

	const isPrivateOrganization = useMemo(
		() => organization?.visibility === OrganizationVisibility.PRIVATE,
		[organization],
	);

	const {
		mutate: createRole,
		isLoading: isCreating,
		error: createError,
		isSuccess: hasCreated,
		reset: resetCreate,
	} = useCreateOrganizationRole();

	const {
		mutate: updateRole,
		isLoading: isUpdating,
		error: updateError,
		isSuccess: hasUpdated,
		reset: resetUpdate,
	} = useUpdateOrganizationRole();

	const handleChange = useCallback((values) => {
		setData((prevData) => ({
			...prevData,
			...values,
		}));
	}, []);

	const handleToggle = useCallback((key) => {
		setData((prevData) => ({
			...prevData,
			[key]: !prevData[key],
		}));
	}, []);

	const handleSubmit = useCallback(() => {
		if (role) {
			updateRole({ roleId: role._id, role: data }, {
				onSuccess: () => {
					setTimeout(() => {
						resetUpdate();
					}, 2000);
				},
			});
		} else {
			createRole(data, {
				onSuccess: (createdRole) => {
					onCreate(createdRole);
					setTimeout(() => {
						resetCreate();
					}, 2000);
				},
			});
		}

		clear();
	}, [clear, createRole, data, onCreate, resetCreate, resetUpdate, role, updateRole]);

	const isEmpty = !data.name;
	const isActionDisabled = isEmpty || isCreating || isUpdating;

	useEffect(() => {
		if (role) {
			setData(getInitialData(role));
		}
	}, [role]);

	return (
		<Container fluid>
			<SchemaForm
				className="h-100 d-flex flex-column my-4 mx-0 form-light"
				schema={roleSettingsScheme}
				data={data}
				onSubmit={handleSubmit}
			>
				<FormGroup>
					<FormLabel htmlFor="email">{t('MyOrganizationModal.OrganizationDetails.RoleForm.roleName')}</FormLabel>
					<Field
						component={Input}
						name="name"
						placeholder={t('MyOrganizationModal.OrganizationDetails.RoleForm.roleName')}
						value={data.name}
						onChange={(e) => handleChange({ name: e.target.value })}
					/>
					<FieldError name="name" />
				</FormGroup>
				<FormGroup>
					<FormLabel>{t('MyOrganizationModal.OrganizationDetails.RoleForm.description')}</FormLabel>
					<Field
						component={Input}
						maxLength={250}
						name="description"
						placeholder={t('MyOrganizationModal.OrganizationDetails.RoleForm.descriptionPlaceholder')}
						type="textarea"
						value={data.description}
						onChange={(e) => handleChange({ description: e.target.value })}
					/>
					<FieldError name="description" />
				</FormGroup>
				<FormGroup tag="fieldset">
					<FormLabel>{t('MyOrganizationModal.OrganizationDetails.RoleForm.actions')}</FormLabel>
					<div className="d-flex flex-column ml-3">
						<FormGroup check className="mr-3">
							<Input
								id="RoleAction-canManageOrganization"
								type="checkbox"
								name="canManageOrganization"
								onChange={() => { handleToggle('canManageOrganization'); }}
								checked={data.canManageOrganization}
							/>
							<Label check for="RoleAction-canManageOrganization">
								{t('MyOrganizationModal.OrganizationDetails.RoleForm.manageOrganization')}
							</Label>
						</FormGroup>
						<FormGroup check className="mr-3">
							<Input
								id="RoleAction-canSeeOrganizationBalance"
								type="checkbox"
								name="canSeeOrganizationBalance"
								onChange={() => { handleToggle('canSeeOrganizationBalance'); }}
								checked={data.canSeeOrganizationBalance}
							/>
							<Label check for="RoleAction-canSeeOrganizationBalance">
								{t('MyOrganizationModal.OrganizationDetails.RoleForm.seeOrganizationBalance')}
							</Label>
						</FormGroup>
						<FormGroup check className="mr-3">
							<Input
								id="RoleAction-canStartStudio"
								type="checkbox"
								name="canStartStudio"
								onChange={() => { handleToggle('canStartStudio'); }}
								checked={data.canStartStudio}
							/>
							<Label check for="RoleAction-canStartStudio">
								{t('MyOrganizationModal.OrganizationDetails.RoleForm.startStudio')}
							</Label>
						</FormGroup>
						<FormGroup check className="mr-3">
							<Input
								id="RoleAction-canUploadVod"
								type="checkbox"
								name="canUploadVod"
								onChange={() => { handleToggle('canUploadVod'); }}
								checked={data.canUploadVod}
							/>
							<Label check for="RoleAction-canUploadVod">
								{t('MyOrganizationModal.OrganizationDetails.RoleForm.uploadVOD')}
							</Label>
						</FormGroup>
						<FormGroup check className="mr-3">
							<Input
								id="RoleAction-featured"
								type="checkbox"
								name="featured"
								onChange={() => { handleToggle('featured'); }}
								checked={data.featured}
							/>
							<Label check for="RoleAction-featured">
								{t('MyOrganizationModal.OrganizationDetails.RoleForm.featureOnHomepage')}
							</Label>
						</FormGroup>
						{data.featured && (
							<FormGroup check className="mr-3 ml-4">
								<Input
									id="RoleAction-featureOwner"
									type="checkbox"
									name="featureOwner"
									onChange={() => { handleToggle('featureOwner'); }}
									checked={data.featureOwner}
								/>
								<Label check for="RoleAction-featureOwner">
									{t('MyOrganizationModal.OrganizationDetails.RoleForm.featureOwner')}
								</Label>
							</FormGroup>
						)}
					</div>
				</FormGroup>
				<FormGroup tag="fieldset">
					<FormLabel>{t('MyOrganizationModal.OrganizationDetails.RoleForm.paymentType')}</FormLabel>
					<div className="d-flex flex-column ml-3">
						<FormGroup id="FreePaymentTypeContainer" check className="mr-3">
							<Input
								id="RoleAction-freePaymentType"
								type="radio"
								name="paymentType"
								onChange={() => { handleChange({ paymentType: RolePaymentType.FREE }); }}
								checked={data.paymentType === RolePaymentType.FREE}
							/>
							<Label check for="RoleAction-freePaymentType">
								{t('MyOrganizationModal.OrganizationDetails.RoleForm.free')}
								<span
									onMouseEnter={() => setShowPopover([true, false])}
									onMouseLeave={() => setShowPopover([false, false])}
									id="FreeInfoIcon"
									className="ml-2"
								>
									<FaInfoCircle
										size={12}
										className="ml-1"
									/>
								</span>
							</Label>
							<Popover
								placement="right"
								isOpen={showPopover[0]}
								target="FreeInfoIcon"
								container="FreePaymentTypeContainer"
							>
								<PopoverBody className="text-black">
									{t('MyOrganizationModal.OrganizationDetails.RoleForm.freeInfo')}
								</PopoverBody>
							</Popover>
						</FormGroup>
						<FormGroup id="PaidPaymentTypeContainer" check className="mr-3">
							<Input
								id="RoleAction-paidPaymentType"
								type="radio"
								name="paymentType"
								onChange={() => { handleChange({ paymentType: RolePaymentType.PAID }); }}
								checked={data.paymentType === RolePaymentType.PAID}
							/>
							<Label check for="RoleAction-paidPaymentType">
								{t('MyOrganizationModal.OrganizationDetails.RoleForm.paid')}
								<span
									onMouseEnter={() => setShowPopover([false, true])}
									onMouseLeave={() => setShowPopover([false, false])}
									id="PaidInfoIcon"
									className="ml-2"
								>
									<FaInfoCircle
										size={12}
										className="ml-1"
									/>
								</span>
							</Label>
							<Popover
								placement="right"
								isOpen={showPopover[1]}
								target="PaidInfoIcon"
								container="PaidPaymentTypeContainer"
							>
								<PopoverBody className="text-black">
									{t('MyOrganizationModal.OrganizationDetails.RoleForm.paidInfo')}
								</PopoverBody>
							</Popover>
						</FormGroup>
					</div>
				</FormGroup>
				{!isPrivateOrganization && (
					<FormGroup check className="mr-3">
						<Input
							id="RoleAction-allowMemberJoinRequests"
							type="checkbox"
							name="allowMemberJoinRequests"
							onChange={() => { handleToggle('allowMemberJoinRequests'); }}
							checked={data.allowMemberJoinRequests}
						/>
						<Label check for="RoleAction-allowMemberJoinRequests">
							{t('MyOrganizationModal.OrganizationDetails.RoleForm.allowMemberJoinRequests')}
						</Label>
					</FormGroup>
				)}
				{data.allowMemberJoinRequests && (
					<FormGroup>
						<FormLabel htmlFor="memberJoinRequestPrice">{t('MyOrganizationModal.OrganizationDetails.RoleForm.memberJoinRequestPrice')}</FormLabel>
						<Field
							component={Input}
							name="memberJoinRequestPrice"
							type="number"
							value={data.memberJoinRequestPrice}
							onChange={(e) => handleChange({ memberJoinRequestPrice: +e.target.value })}
						/>
						<FieldError name="memberJoinRequestPrice" />
					</FormGroup>
				)}
				<div className="d-flex justify-content-end my-3">
					<Button
						className="btn-pill shadow-none mr-2"
						color="neutral-secondary"
						disabled={isCreating || isUpdating}
						onClick={onCancel}
					>
						{t('MyOrganizationModal.OrganizationDetails.RoleForm.cancel')}
					</Button>
					<FormSubmit
						className="btn-pill shadow-none"
						disabled={isActionDisabled}
						loading={isCreating || isUpdating}
						title={t('MyOrganizationModal.OrganizationInvites.sendByEmail')}
					>
						{
							t(role
								? 'MyOrganizationModal.OrganizationDetails.RoleForm.update'
								: 'MyOrganizationModal.OrganizationDetails.RoleForm.create')
						}
					</FormSubmit>
				</div>
				{hasUpdated && (
					<Alert color="success">
						{t('MyOrganizationModal.OrganizationDetails.RoleForm.changesSaved')}
					</Alert>
				)}
				{hasCreated && (
					<Alert color="success">
						{t('MyOrganizationModal.OrganizationDetails.RoleForm.roleCreated')}
					</Alert>
				)}
				{(createError && (
					<Alert color="danger">
						{createError?.response?.data?.message ?? t('Global.error')}
					</Alert>
				))}
				{(updateError && (
					<Alert color="danger">
						{updateError?.response?.data?.message ?? t('Global.error')}
					</Alert>
				))}
			</SchemaForm>
		</Container>
	);
};

RoleForm.propTypes = {
	role: PropTypes.shape({
		_id: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
		canStartStudio: PropTypes.bool.isRequired,
		canUploadVod: PropTypes.bool.isRequired,
		canSeeOrganizationBalance: PropTypes.bool.isRequired,
	}),
	onCancel: PropTypes.func.isRequired,
	onCreate: PropTypes.func.isRequired,
};

RoleForm.defaultProps = {
	role: null,
};
