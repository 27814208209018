import { useInfiniteQuery } from 'react-query';
import * as feedApi from '../../api/channel/feed';
import { axiosQueryWrapper } from '../utils/axios-wrapper';
import { useAuthentication } from '../../components/Authentication/Authentication';

export const FEED_ITEMS_PER_PAGE = 20;

export const CHANNEL_FEED_QUERY_KEYS = {
	infinityFetchChannelFeed: (channelId, itemsPerPage) => ['api', 'channel', 'feed', 'fetch', { channelId, itemsPerPage }],
};

export const useInfinityFetchFeed = (channelId, itemsPerPage = FEED_ITEMS_PER_PAGE) => {
	const { isLoggedIn } = useAuthentication();

	return useInfiniteQuery(
		CHANNEL_FEED_QUERY_KEYS.infinityFetchChannelFeed(channelId, itemsPerPage),
		({ pageParam = 1 }) => axiosQueryWrapper(
			feedApi.getChannelFeed, channelId, itemsPerPage, pageParam,
		)(),
		{
			enabled: !!isLoggedIn && !!channelId,
			getNextPageParam: (lastPage, allPages) => {
				const currentItemsLenght = allPages.reduce((acc, curr) => acc + curr.data.length, 0);
				if (currentItemsLenght < lastPage.totalItemsCount) {
					return allPages.length + 1;
				}
				return undefined;
			},
		},
	);
};
