import { api } from '../api';

const API_AD_CAMPAIGN_PATH = '/ads/campaign';

export const fetchAllAdCampaigns = async (status) => api.get(API_AD_CAMPAIGN_PATH, {
	params: { status },
});

export const fetchOneAdCampaignById = async (adCampaignId) => api.get(
	`${API_AD_CAMPAIGN_PATH}/${adCampaignId}`,
);

export const createAdCampaign = async (adCampaign) => api.post(`${API_AD_CAMPAIGN_PATH}`, adCampaign);

export const createDraftAdCampaign = async (adCampaign) => api.post(`${API_AD_CAMPAIGN_PATH}/draft`, adCampaign);

export const updateSponsorAdStatus = async ({ sponsorAdStatus, adCampaignId }) => api.put(`${API_AD_CAMPAIGN_PATH}/updateStatus/${adCampaignId}`,
	{ sponsorAdStatus });
