import { api } from '../api';

export const API_VOTE_PATH = '/channel/vote';
export const API_VOTE_TEMPLATE_PATH = '/channel/vote/template';

export const fetchChannelVotesByStudioId = (studioId) => api.get(
	`${API_VOTE_PATH}/history/${studioId}`,
);

export const fetchReport = (studioId) => api.get(
	`${API_VOTE_PATH}/report/${studioId}`,
);

export const fetchVoteReportVoteDetail = (voteId) => api.get(
	`${API_VOTE_PATH}/report/vote/${voteId}`,
);

export const fetchVoteReportUserDetail = (studioId, userId) => api.get(
	`${API_VOTE_PATH}/report/studio/${studioId}/user/${userId}`,
);

export const fetchReportCSV = (studioId, audienceType) => api.get(
	`${API_VOTE_PATH}/report/${studioId}/${audienceType}/csv`,
);

export const createVotes = (studioId, votes, optionalParameters) => api.post(
	`${API_VOTE_PATH}/${studioId}`,
	{ votes, ...optionalParameters },
);

export const endVote = (voteId) => api.put(
	`${API_VOTE_PATH}/${voteId}/end`,
);

export const voteOnVote = (voteId, answerId) => api.put(
	`${API_VOTE_PATH}/${voteId}/vote/${answerId}`,
);

export const publishVote = (voteId) => api.put(
	`${API_VOTE_PATH}/${voteId}/publish`,
);

export const endPublishVote = (voteId) => api.delete(
	`${API_VOTE_PATH}/${voteId}/publish/end`,
);

export const repositionVote = (voteId, position) => api.put(
	`${API_VOTE_PATH}/${voteId}/reposition`,
	{ position },
);

export const fetchChannelVoteTemplates = (
	isArchived,
	itemsPerPage,
	currentPage,
	sortValue,
) => api.get(
	`${API_VOTE_TEMPLATE_PATH}`,
	{
		params: {
			isArchived,
			itemsPerPage,
			currentPage,
			sortValue,
		},
	},
);

export const createVoteTemplate = (voteTemplate) => api.post(
	`${API_VOTE_TEMPLATE_PATH}`,
	voteTemplate,
);

export const deleteVoteTemplate = (voteTemplateId) => api.delete(`${API_VOTE_TEMPLATE_PATH}/${voteTemplateId}`);

export const restoreVoteTemplate = (voteTemplateId) => api.put(`${API_VOTE_TEMPLATE_PATH}/restore/${voteTemplateId}`);

export const sendReportThroughMail = (studioId, content) => api.post(
	`${API_VOTE_PATH}/report/email/${studioId}`,
	content,
);
