import { api } from '../api';

const API_CONTACT_PATH = '/contacts';

export const createContacts = async (data) => api.post(`${API_CONTACT_PATH}/list`, data);

export const fetchContactsList = async () => api.get(
	`${API_CONTACT_PATH}/list`,
);

export const createContact = async (data) => api.post(`${API_CONTACT_PATH}/add`, data);

export const removeContact = async (contactId) => api.delete(`${API_CONTACT_PATH}/delete/${contactId}`);

export const updateContact = async (data) => api.put(`${API_CONTACT_PATH}/${data._id}`, { contact: data });
