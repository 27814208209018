import { useMutation, useQuery } from 'react-query';
import { axiosMutationWrapper, axiosQueryWrapper } from '../utils/axios-wrapper';
import * as identityAuthenticationApi from '../../api/identity/authentication';
import { useAuthentication } from '../../components/Authentication/Authentication';

export const ORGANIZATION_QUERY_KEYS = {
	fetchOrganizationMemberInviteById: (inviteId) => ['api', 'identity', 'authentication', 'organization', 'invites', inviteId],
};

export const useFetchOrganizationInvite = (inviteId) => useQuery(
	ORGANIZATION_QUERY_KEYS.fetchOrganizationMemberInviteById(inviteId),
	axiosQueryWrapper(identityAuthenticationApi.fetchOrganizationMemberInviteById, inviteId),
	{ enabled: !!inviteId },
);

export const useSwitchToOrganizationPublicProfile = () => {
	const { switchTokens, storePreviousSession } = useAuthentication();

	return useMutation(
		axiosMutationWrapper(identityAuthenticationApi.switchToOrganizationPublicChannel),
		{
			onSuccess: (data) => {
				storePreviousSession();
				switchTokens(data);
			},
		},
	);
};
