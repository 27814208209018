import { api } from '../api';
import { upload } from '../upload';

const API_ORGANIZATION_PATH = '/organization';

export const fetchOrganizations = async () => api.get(
	`${API_ORGANIZATION_PATH}/all`,
);

export const fetchOrganizationDetails = async () => api.get(
	`${API_ORGANIZATION_PATH}/details`,
);

export const fetchOrganizationPublicChannel = async () => api.get(
	`${API_ORGANIZATION_PATH}/public/channel`,
);

export const fetchDefaultOrganization = async () => api.get(
	`${API_ORGANIZATION_PATH}/default`,
);

export const fetchOrganizationUserStatus = async (organizationId) => api.get(
	`${API_ORGANIZATION_PATH}/${organizationId}/userStatus`,
);

export const updateOrganizationDetails = async (details) => {
	if (details.logo === null) details.logo = ''; // remove logo only if null (not undefined)
	if (details.cover === null) details.cover = ''; // remove cover only if null (not undefined)

	let { logo, cover } = details;
	if (logo?.blob) {
		logo = await upload(logo.blob);
	}
	if (cover?.blob) {
		cover = await upload(cover.blob);
	}

	const detailsUpdate = {
		...details,
		logo,
		cover,
	};

	return api.put(
		`${API_ORGANIZATION_PATH}/details`,
		detailsUpdate,
	);
};

export const updateOrganizationSettings = async (settings) => api.put(
	`${API_ORGANIZATION_PATH}/settings`,
	settings,
);

export const fetchOrganizationMembers = async () => api.get(
	`${API_ORGANIZATION_PATH}/members`,
);

export const activateOrganizationMember = async (memberId) => api.put(
	`${API_ORGANIZATION_PATH}/members/${memberId}/activate`,
);

export const disableOrganizationMember = async (memberId) => api.put(
	`${API_ORGANIZATION_PATH}/members/${memberId}/disable`,
);

export const updateOrganizationMemberSettings = async ({ memberId, settings }) => api.put(
	`${API_ORGANIZATION_PATH}/members/${memberId}/settings`,
	settings,
);

export const updateOrganizationRolesOrder = async ({ rolesOrder }) => api.put(
	`${API_ORGANIZATION_PATH}/roles/featured/order`,
	{ rolesOrder },
);

export const removeOrganizationMember = async (memberId) => api.delete(
	`${API_ORGANIZATION_PATH}/members/${memberId}`,
);

export const fetchOrganizationMemberInvites = async () => api.get(
	`${API_ORGANIZATION_PATH}/members/invites`,
);

export const fetchOrganizationMemberInviteById = async (inviteId) => api.get(
	`${API_ORGANIZATION_PATH}/members/invites/${inviteId}`,
);

export const fetchOrganizationFreeTrialUsed = async () => api.get(
	`${API_ORGANIZATION_PATH}/trial`,
);

export const sendJoinOrganizationInvite = async ({ email, phoneNumber, message, role }) => api.post(
	`${API_ORGANIZATION_PATH}/members/invites`,
	{ email, phoneNumber, message, role },
);

export const removeJoinOrganizationInvite = async (inviteId) => api.delete(
	`${API_ORGANIZATION_PATH}/members/invites/${inviteId}`,
);

export const fetchOrganizationMemberRequests = async () => api.get(
	`${API_ORGANIZATION_PATH}/members/requests`,
);

export const requestOrganizationJoin = ({ organizationId, roleId }) => api.post(
	`${API_ORGANIZATION_PATH}/members/requests`,
	{ organizationId, roleId },
);

export const enterOrganization = (organizationId) => api.post(
	`${API_ORGANIZATION_PATH}/enter/${organizationId}`,
);

export const acceptOrganizationJoinRequest = (requestId) => api.post(
	`${API_ORGANIZATION_PATH}/members/requests/${requestId}`,
);

export const declineOrganizationJoinRequest = ({ requestId, reason }) => api.delete(
	`${API_ORGANIZATION_PATH}/members/requests/${requestId}`,
	{ params: { reason } },
);

export const fetchOrganizationRoles = async () => api.get(
	`${API_ORGANIZATION_PATH}/roles`,
);

export const createOrganizationRole = async (role) => api.post(
	`${API_ORGANIZATION_PATH}/roles`,
	role,
);

export const updateOrganizationRole = async ({ roleId, role }) => api.put(
	`${API_ORGANIZATION_PATH}/roles/${roleId}`,
	role,
);

export const makeOrganizationRoleDefault = async (roleId) => api.post(
	`${API_ORGANIZATION_PATH}/roles/${roleId}/default`,
);

export const deleteOrganizationRole = async (roleId) => api.delete(
	`${API_ORGANIZATION_PATH}/roles/${roleId}`,
);

export const joinOrganization = async (organizationId) => api.post(
	`${API_ORGANIZATION_PATH}/join/${organizationId}`,
);
