import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';
import clsx from 'clsx';

import { ReactComponent as InfoIcon } from './info-icon.svg';

const LOCAL_STORAGE_ALERT_DISMISSED_KEY = 'beeyou.alertTooltipDismissed';

const getSavedAlertTooltipDismissed = () => {
	const current = JSON.parse(localStorage.getItem(LOCAL_STORAGE_ALERT_DISMISSED_KEY)) || {};
	return current;
};

const saveAlertTooltipDismissed = (value) => {
	localStorage.setItem(LOCAL_STORAGE_ALERT_DISMISSED_KEY, JSON.stringify(value));
};

export const AlertTooltipContext = createContext();

export const useAlertTooltipContext = () => useContext(AlertTooltipContext);

export const AlertTooltipProvider = ({
	children,
}) => {
	const [alertTooltipsDismissed, setAlertTooltipDismissed] = useState(
		() => getSavedAlertTooltipDismissed(),
	);
	const [alertTooltipsIsOpen, setAlertTooltipsIsOpen] = useState({});

	const toggleAlertTooltipDimissedById = useCallback((id) => {
		setAlertTooltipDismissed((s) => ({
			...s,
			[id]: !s[id],
		}));
	}, []);

	const toggleAlertTooltipIsOpenById = useCallback((id) => {
		setAlertTooltipsIsOpen((s) => ({
			...s,
			[id]: !s[id],
		}));
	}, []);

	const updateAlertTooltipDimissedById = useCallback((id, value) => {
		setAlertTooltipDismissed((s) => ({
			...s,
			[id]: value,
		}));
	}, []);

	const updateAlertTooltipIsOpenById = useCallback((id, value) => {
		setAlertTooltipsIsOpen((s) => ({
			...s,
			[id]: value,
		}));
	}, []);

	const contextValue = useMemo(() => ({
		alertTooltipsDismissed,
		alertTooltipsIsOpen,
		toggleAlertTooltipDimissedById,
		toggleAlertTooltipIsOpenById,
		updateAlertTooltipDimissedById,
		updateAlertTooltipIsOpenById,

	}), [
		alertTooltipsIsOpen,
		alertTooltipsDismissed,
		toggleAlertTooltipDimissedById,
		toggleAlertTooltipIsOpenById,
		updateAlertTooltipDimissedById,
		updateAlertTooltipIsOpenById,
	]);

	useEffect(() => {
		saveAlertTooltipDismissed(alertTooltipsDismissed);
	}, [alertTooltipsDismissed]);

	return (
		<AlertTooltipContext.Provider value={contextValue}>
			{children}
		</AlertTooltipContext.Provider>
	);
};

AlertTooltipProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export const AlertTooltip = ({
	alertId,
	children,
	defaultIsOpen,
	...props
}) => {
	const {
		alertTooltipsDismissed,
		alertTooltipsIsOpen,
		updateAlertTooltipDimissedById,
		updateAlertTooltipIsOpenById,
	} = useAlertTooltipContext();

	const isDismissed = alertTooltipsDismissed[alertId];
	const isOpen = alertTooltipsIsOpen[alertId];
	const isOpenValue = isDismissed === true ? false : isOpen;

	const handleToggle = () => {
		updateAlertTooltipDimissedById(alertId, true);
		updateAlertTooltipIsOpenById(alertId, false);
	};

	useEffect(() => {
		updateAlertTooltipIsOpenById(alertId, defaultIsOpen);
	}, [alertId, defaultIsOpen, updateAlertTooltipIsOpenById]);

	return (
		<Alert {...props} isOpen={isOpenValue} toggle={handleToggle}>
			{children}
		</Alert>
	);
};

AlertTooltip.propTypes = {
	alertId: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
	defaultIsOpen: PropTypes.bool,
};

AlertTooltip.defaultProps = {
	defaultIsOpen: true,
};

export const AlertTooltipToggle = ({
	alertId,
	className,
}) => {
	const {
		alertTooltipsDismissed,
		alertTooltipsIsOpen,
		updateAlertTooltipDimissedById,
		updateAlertTooltipIsOpenById,
	} = useAlertTooltipContext();

	const isDismissed = alertTooltipsDismissed[alertId];
	const isOpen = alertTooltipsIsOpen[alertId];
	const isOpenValue = isDismissed === true ? false : isOpen;

	const handleToggle = (event) => {
		event.stopPropagation();
		updateAlertTooltipDimissedById(alertId, undefined);
		updateAlertTooltipIsOpenById(alertId, true);
	};

	return (
		<span
			className={clsx('cursor-pointer', className)}
			onClick={handleToggle}
			style={{
				visibility: isOpenValue ? 'hidden' : 'visible',
			}}
		>
			<InfoIcon />
		</span>
	);
};

AlertTooltipToggle.propTypes = {
	alertId: PropTypes.string.isRequired,
	className: PropTypes.string,
};

AlertTooltipToggle.defaultProps = {
	className: undefined,
};
