/* eslint-disable react/prop-types */
// @ts-check

import clsx from 'clsx';
import { Outlet } from 'react-router-dom';

import { Header } from '../../layout-components';
import { useScreenMode, ModeScreenLayout } from '../../components/Studio/ScreenMode/Provider';

/**
 * @typedef {{
 * 	children?: React.ReactNode;
 * 	isStudioRoute?: boolean;
 * 	noSearch?: boolean;
 * }} NoSidebarProps
 */

export const NoSidebar = (
	/** @type {NoSidebarProps} */
	{
		children,
		isStudioRoute = false,
		noSearch = false,
	},
) => {
	const { currentModeScreen } = useScreenMode();

	return (
		<div className="app-wrapper bg-darker content-darker">
			<div className={clsx('app-main',
				{ 'overflow-hidden': currentModeScreen === ModeScreenLayout.FULLSCREEN })}
			>
				<Header search={!noSearch} isStudioRoute={isStudioRoute} />
				<div className="app-content">
					<div className="app-content--inner">
						<div className="app-content--inner__wrapper">
							{children || <Outlet />}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
