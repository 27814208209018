import PropTypes from 'prop-types';
import { TabContent } from 'reactstrap';

const FormModalTabContent = ({ activeTab, children }) => (
	<TabContent activeTab={activeTab} className="h-100">
		{children}
	</TabContent>
);

FormModalTabContent.propTypes = {
	children: PropTypes.node.isRequired,
	activeTab: PropTypes.string.isRequired,
};

export default FormModalTabContent;
