import PropTypes from 'prop-types';
import Select from 'react-select';
import clsx from 'clsx';

export const SimpleSelector = (
	{ options,
		value,
		onChange,
		defaultOptions,
		isSearchable,
		placeholder,
		className,
		...props
	},
) => {
	const colors = {
		dark: 'var(--bs-dark)',
		text: 'var(--bs-content-light)',
		light: 'var(--bs-light)',
	};

	const customStyles = {
		control: (provided) => ({
			...provided,
			background: colors.dark,
		}),
		input: (provided) => ({
			...provided,
			color: colors.text,
		}),
		placeholder: (provided) => ({
			...provided,
			color: colors.text,
		}),
		singleValue: (provided) => ({
			...provided,
			color: colors.text,
		}),
		menuList: (provided) => ({
			...provided,
			background: colors.light,
		}),
		option: (provided, state) => ({
			...provided,
			background: state.isFocused ? colors.dark : colors.light,
			color: colors.text,
			cursor: 'pointer',
			fontWeight: state.isFocused ? 'bold' : '',
		}),
	};

	return (
		<Select
			className={clsx('SimpleSelector', className)}
			defaultOptions={defaultOptions}
			isSearchable={isSearchable}
			styles={customStyles}
			options={options || []}
			onChange={(val) => onChange(val)}
			value={value}
			placeholder={placeholder}
			{...props}
		/>
	);
};

SimpleSelector.propTypes = {
	options: PropTypes.arrayOf(PropTypes.shape({})),
	value: PropTypes.shape({}),
	onChange: PropTypes.func,
	defaultOptions: PropTypes.arrayOf(PropTypes.shape({})),
	isSearchable: PropTypes.bool,
	placeholder: PropTypes.string,
	className: PropTypes.string,
};

SimpleSelector.defaultProps = {
	options: [],
	value: null,
	onChange: () => {},
	defaultOptions: [],
	isSearchable: false,
	placeholder: '',
	className: '',
};
