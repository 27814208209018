/* eslint-disable react/prop-types */
// @ts-check
import { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { PlayerControlsDisplayContext } from './Context';

/**
 * @typedef {{
* 	children: React.ReactNode,
* 	enabled?: boolean,
* 	forceDisplay?: boolean,
* 	target?: string,
* }} PlayerControlsDisplayProvider
*/

/** @type {React.FC<PlayerControlsDisplayProvider>} */
export const PlayerControlsDisplayProvider = (
	{
		children,
		enabled = true,
		forceDisplay: forceDisplayProps = false,
		target = 'body',
	},
) => {
	const [showPlayerControls, setShowPlayerControls] = useState(false);
	const [forceDisplayState, setForceDisplayControls] = useState(false);

	useEffect(() => {
		if (!enabled) return undefined;

		const targetElement = document.querySelector(target);
		if (!targetElement) return undefined;

		/** @type {undefined | ReturnType<typeof setTimeout>} */
		let timeout;

		const toggleControls = () => {
			if (timeout) {
				clearTimeout(timeout);
			}

			setShowPlayerControls(true);

			timeout = setTimeout(() => {
				setShowPlayerControls(false);
			}, 2500);
		};

		targetElement.addEventListener('mousemove', toggleControls);
		targetElement.addEventListener('click', toggleControls);
		targetElement.addEventListener('keydown', toggleControls);
		targetElement.addEventListener('touchstart', toggleControls);

		toggleControls();

		return () => {
			clearTimeout(timeout);

			targetElement.removeEventListener('mousemove', toggleControls);
			targetElement.removeEventListener('click', toggleControls);
			targetElement.removeEventListener('keydown', toggleControls);
			targetElement.removeEventListener('touchstart', toggleControls);
		};
	}, [enabled, target]);

	const contextValue = useMemo(() => ({
		setForceDisplayControls,
		showPlayerControls,
	}), [
		setForceDisplayControls,
		showPlayerControls,
	]);

	const showControls = showPlayerControls || forceDisplayProps || forceDisplayState;

	return (
		<PlayerControlsDisplayContext.Provider value={contextValue}>
			<div className={clsx(
				'layout-wrapper',
				{ showPlayerControls: enabled && showControls },
				{ hidePlayerControls: enabled && !showControls },
			)}
			>
				{children}
			</div>
		</PlayerControlsDisplayContext.Provider>
	);
};

PlayerControlsDisplayProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
