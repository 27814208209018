// @ts-check

import { useEffect, useRef } from 'react';

/**
 * @see https://github.com/microsoft/fluentui/blob/master/packages/react-hooks/src/useConst.ts
 *
 * @template C
 * @param {C | (() => C)} initialValue
 * @returns {C}
 */
export const useConst = (initialValue) => {
	const ref = useRef(/** @type {{ value: C } | undefined}*/(undefined));
	if (typeof ref.current === 'undefined') {
		ref.current = {
			value: typeof initialValue === 'function'
				? /** @type {() => C} */(initialValue)()
				: initialValue,
		};
	}
	return ref.current.value;
};

/**
 * @template P
 * @param {P} value
 * @returns {P}
 */
export function usePrevious(value) {
	const ref = useRef(/** @type {P} */(undefined));
	useEffect(() => {
		ref.current = value;
	}, [value]);
	return ref.current;
}
