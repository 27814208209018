// @ts-check

import clsx from 'clsx';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import { isSafari } from '../../lib/userAgent';

import './ReallyPerfectScrollbar.scss';

/**
 * @typedef {{
* 	children?: React.ReactNode,
* 	className?: string,
* 	forwardedRef?: React.ForwardedRef<HTMLDivElement>,
* 	orientation?: 'horizontal' | 'vertical',
* } & React.HTMLProps<HTMLDivElement>} ReallyPerfectScrollbarProps
*/

const ReallyPerfectScrollbar = (
	/** @type {ReallyPerfectScrollbarProps} */
	{
		children,
		className,
		forwardedRef = null,
		orientation = 'vertical',
		...props
	},
) => (
	<div
		className={clsx('ReallyPerfectScrollbar', className, { isSafari }, orientation)}
		ref={forwardedRef}
		{...props}
	>
		{children}
	</div>
);

ReallyPerfectScrollbar.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	forwardedRef: PropTypes.shape({}),
	orientation: PropTypes.oneOf(['horizontal', 'vertical']),
};

ReallyPerfectScrollbar.defaultProps = {
	children: undefined,
	className: undefined,
	forwardedRef: null,
	orientation: 'vertical',
};

export default forwardRef(
	(
		/** @type {Omit<ReallyPerfectScrollbarProps, 'forwardedRef'>} */
		props,
		/** @type {React.ForwardedRef<HTMLDivElement> | undefined} */
		ref,
	) => <ReallyPerfectScrollbar {...props} forwardedRef={ref} />,
);
