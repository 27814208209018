import { createContext, useContext } from 'react';

export const OrganizationSettingsContext = createContext();

export const useOrganizationSettings = () => useContext(OrganizationSettingsContext);

export const OrganizationSettingsModal = {
	MY_ORGANIZATION: 'MY_ORGANIZATION',
};

export const MyOrganizationModalTab = {
	PROFILE: 'PROFILE',
	SETTINGS: 'SETTINGS',
	MEMBERS: 'MEMBERS',
	MEMBER_REQUESTS: 'MEMBER_REQUESTS',
	INVITATIONS: 'INVITATIONS',
	ROLES: 'ROLES',
};
