/* eslint-disable react/prop-types */
// @ts-check

import { Outlet } from 'react-router-dom';

import { ViewForbidden } from '../../views/Forbidden/Forbidden';
import ViewUnauthorized from '../../views/Forbidden/Unauthorized';
import { useAuthentication } from './Authentication';

/** @import { Role } from './Authentication'; */

/**
 * @typedef {{
 * 	element?: React.ReactNode
* 	role: Role
 * }} RouteIfRoleMatchProps
 */

const RouteIfRoleMatch = (
	/** @type {RouteIfRoleMatchProps} */
	{
		role,
		element,
	},
) => {
	const authentication = useAuthentication();

	if (authentication.isPending) return null; // wait for first getUser
	if (!authentication.isLoggedIn) {
		return <ViewForbidden />;
	}
	if (authentication.user.role !== role) {
		return <ViewUnauthorized />;
	}

	return element || <Outlet />;
};

export default RouteIfRoleMatch;
