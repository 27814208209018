import { createContext, useContext, useState, useMemo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

const ScreenThemeContext = createContext({});

export const ScreenThemeLayout = {
	LIGHT: 'light',
	DARK: 'dark',
};
export const useScreenTheme = () => useContext(ScreenThemeContext);

// The theme is set first in index.html, ScreenThemeProvider will replace it if user changes it

export const ScreenThemeProvider = ({ children }) => {
	const [currentScreenTheme, setCurrentScreenTheme] = useState(
		window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
			? ScreenThemeLayout.DARK
			: ScreenThemeLayout.LIGHT,
	);

	useEffect(() => {
		const theme = localStorage.getItem('theme');
		if (theme) {
			setCurrentScreenTheme(theme);
		}
	}, []);

	const handleChangeCurrentScreenTheme = useCallback((theme) => {
		setCurrentScreenTheme(theme);
		localStorage.setItem('theme', theme);
		document.body.className = document.body.className.replace(/\bapp-theme-\S+/g, `app-theme-${theme}`);
	}, []);

	const contextValue = useMemo(() => ({
		currentScreenTheme,
		handleChangeCurrentScreenTheme,
	}), [currentScreenTheme, handleChangeCurrentScreenTheme]);
	return (
		<ScreenThemeContext.Provider value={contextValue}>
			{children}
		</ScreenThemeContext.Provider>
	);
};

ScreenThemeProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
