import { useMemo } from 'react';
import { password } from '../Login/password.schema';
import { OrganizationVisibility } from '../OrganizationSettings/organizationSettings.schema';

export const SignupFormStep = {
	CREATE_ACCOUNT: 'CREATE_ACCOUNT',
	CREATE_ORGANIZATION: 'CREATE_ORGANIZATION',
	ORGANIZATION_CHALLENGE: 'ORGANIZATION_CHALLENGE',
};

export const PasswordValidationCriteria = {
	LENGHT: 'LENGTH',
	UPPERCASE_LETTER: 'UPPERCASE_LETTER',
	LOWECASE_LETTER: 'LOWECASE_LETTER',
	SPECIAL_CHARACTER: 'SPECIAL_CHARACTER',
	NUMBER: 'NUMBER',
};

export const AccountType = {
	PERSONAL: 'PERSONAL',
	NEW_ORGANIZATION: 'NEW_ORGANIZATION',
	JOIN_ORGANIZATION: 'JOIN_ORGANIZATION',
};

export const AccountTypeLabel = {
	PERSONAL: 'Onboarding.Signup.AccountTypeLabel.personal',
	NEW_ORGANIZATION: 'Onboarding.Signup.AccountTypeLabel.newOrganization',
};

export const IdentificationField = {
	EMAIL: 'EMAIL',
	PHONE: 'PHONE',
	GOOGLE: 'GOOGLE',
};

export const IdentificationFieldLabel = {
	EMAIL: 'Onboarding.Signup.IdentificationFieldLabel.email',
	PHONE: 'Onboarding.Signup.IdentificationFieldLabel.phoneNumber',
	GOOGLE: 'Onboarding.Signup.IdentificationFieldLabel.google',
};

export const CredentialsStrategy = {
	NEW: 'NEW',
	EXISTING: 'EXISTING',
};

export const CredentialsStrategyLabel = {
	NEW: 'Onboarding.Signup.CredentialsStrategyLabel.new',
	EXISTING: 'Onboarding.Signup.CredentialsStrategyLabel.existing',
};

const nickname = {
	type: 'string',
	minLength: 4,
	maxLength: 50,
};

const getSchemaProperties = (formData) => {
	const properties = {
		accountType: { type: 'string', enum: Object.values(AccountType) },
		organizationVisibility: { type: 'string', enum: Object.values(OrganizationVisibility) },
		nickname,
		identificationField: { type: 'string', enum: Object.values(IdentificationField) },
		credentialsStrategy: { type: 'string', enum: Object.values(CredentialsStrategy) },
		email: { type: 'string', format: 'email' },
		phoneNumber: { type: 'string', phone: true },
		password: (
			formData.accountType === AccountType.NEW_ORGANIZATION
			|| formData.accountType === AccountType.JOIN_ORGANIZATION
		) && formData.credentialsStrategy === CredentialsStrategy.EXISTING
			? { type: 'string' }
			: password,
	};

	if (formData.accountType === AccountType.JOIN_ORGANIZATION) {
		properties.organizationJoinInviteId = { type: 'string' };
	}

	return properties;
};

export const useGetSignupSchema = (formData) => useMemo(() => ({
	type: 'object',
	additionalProperties: false,
	properties: getSchemaProperties(formData),
	required: [
		'accountType',
		'nickname',
		'password',
		'identificationField',
		formData.accountType === AccountType.NEW_ORGANIZATION ? 'organizationName' : undefined,
		formData.accountType === AccountType.JOIN_ORGANIZATION ? 'organizationJoinInviteId' : undefined,
		formData.accountType === AccountType.NEW_ORGANIZATION
				|| formData.accountType === AccountType.JOIN_ORGANIZATION
			? 'credentialsStrategy'
			: undefined,
	].filter((el) => !!el),
}), [formData]);
