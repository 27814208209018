import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

import { AdminPath } from './RoutePathAdmin';
import RouteIfRoleMatch from './components/Authentication/RouteIfRoleMatch';
import { Role } from './components/Authentication/Authentication';

const AdminSidebar = lazy(() => import('./layout-blueprints/AdminSidebar'));

const ViewAdminChannelList = lazy(() => import('./views/Admin/ChannelList'));
const ViewAdminChannelPreviewList = lazy(() => import('./views/Admin/ChannelPreviewList'));
const ViewAdminStudioList = lazy(() => import('./views/Admin/StudioList'));
const ViewAdminStudioPreviewList = lazy(() => import('./views/Admin/StudioPreviewList'));
const ViewAdminStudioSettings = lazy(() => import('./views/Admin/Studio/Settings'));
const ViewAdminVodList = lazy(() => import('./views/Admin/VodList'));
const ViewAdminMembershipList = lazy(() => import('./views/Admin/MembershipList'));
const ViewAdminUserList = lazy(() => import('./views/Admin/UserList'));
const ViewAdminTransactionList = lazy(() => import('./views/Admin/TransactionList'));
const ViewAdminTagList = lazy(() => import('./views/Admin/TagList'));
const ViewAdminCategoryList = lazy(() => import('./views/Admin/CategoryList'));
const ViewAdminPageViews = lazy(() => import('./views/Admin/PageViews'));
const ViewAdminUserReportList = lazy(() => import('./views/Admin/UserReportList'));
const ViewAdminVodReportList = lazy(() => import('./views/Admin/VodReportList'));
const ViewAdminGuestReportList = lazy(() => import('./views/Admin/GuestReportList'));
const ViewAdminCashOutList = lazy(() => import('./views/Admin/CashOutList'));
const ViewAdminStudioSummary = lazy(() => import('./views/Admin/Studio/Summary'));
const ViewAdminAdSettings = lazy(() => import('./views/Admin/Ads/Settings'));
const ViewAdminAdCampaigns = lazy(() => import('./views/Admin/Ads/Ads'));
const AdminNewsletter = lazy(() => import('./views/Admin/Newsletter/Newsletter'));

const Routes = {
	element: <RouteIfRoleMatch role={Role.ADMIN} element={<AdminSidebar />} />,
	children: [
		{ path: AdminPath.ADS_CAMPAIGNS, element: <ViewAdminAdCampaigns /> },
		{ path: AdminPath.ADS_SETTINGS, element: <ViewAdminAdSettings /> },
		{ path: AdminPath.CASHOUTS, element: <ViewAdminCashOutList /> },
		{ path: AdminPath.CATEGORIES, element: <ViewAdminCategoryList /> },
		{ path: AdminPath.CHANNEL, element: <ViewAdminChannelList /> },
		{ path: AdminPath.CHANNELS, element: <ViewAdminChannelPreviewList /> },
		{ path: AdminPath.GUEST_REPORTS, element: <ViewAdminGuestReportList /> },
		{ path: AdminPath.MEMBERSHIPS, element: <ViewAdminMembershipList /> },
		{ path: AdminPath.NEWSLETTER, element: <AdminNewsletter /> },
		{ path: AdminPath.PAGEVIEWS, element: <ViewAdminPageViews /> },
		{ path: AdminPath.ROOT, element: <Navigate to={AdminPath.CHANNELS} replace /> },
		{ path: AdminPath.STUDIO_SETTINGS, element: <ViewAdminStudioSettings /> },
		{ path: AdminPath.STUDIO_SUMMARY, element: <ViewAdminStudioSummary /> },
		{ path: AdminPath.STUDIO, element: <ViewAdminStudioList /> },
		{ path: AdminPath.STUDIOS, element: <ViewAdminStudioPreviewList /> },
		{ path: AdminPath.TAGS, element: <ViewAdminTagList /> },
		{ path: AdminPath.TRANSACTIONS, element: <ViewAdminTransactionList /> },
		{ path: AdminPath.USER_REPORTS, element: <ViewAdminUserReportList /> },
		{ path: AdminPath.USERS, element: <ViewAdminUserList /> },
		{ path: AdminPath.VOD_REPORTS, element: <ViewAdminVodReportList /> },
		{ path: AdminPath.VODS, element: <ViewAdminVodList /> },
	],
};

export default Routes;
