// @ts-check

import { Path, getAbsoluteLink } from '../../RoutePath';
import { ResourceAccessRole } from '../../lib/ResourceAccessRole';
import { ResourceVisibility } from '../../lib/ResourceVisibility';

/** @enum {string} */
export const ShareAboutType = {
	CHANNEL: 'CHANNEL',
	STUDIO: 'STUDIO',
	VIDEO: 'VIDEO',
	SURVEY: 'SURVEY',
	POST: 'POST',
};

/** @enum {string} */
export const ShareRecipientType = {
	EMAIL: 'EMAIL',
	GROUP: 'GROUP',
	SMS: 'SMS',
	USER: 'USER',
	USERS: 'USERS',
	FOLLOWERS: 'FOLLOWERS',
	FRIENDS: 'FRIENDS',
};

/** @enum {string} */
export const ShareTabType = {
	EMAIL: 'EMAIL',
	GROUPS: 'GROUPS',
	LINK: 'LINK',
	POST: 'POST',
	SMS: 'SMS',
	STUDIO_PARTICIPANTS: 'STUDIO_PARTICIPANTS',
	USERS: 'USERS',
	EMBED: 'EMBED',
	USERS_FOLLOWERS_FRIENDS: 'USERS_FOLLOWERS_FRIENDS',
	QRCODE: 'QRCODE',
};

/**
 * @param {{
 * 	channel: { hashtag: string };
 * 	studio?: { code: string; password: string; watchLinkId: string };
 * 	video?: { watchLinkId: string; visibility:string; privateShareId:string };
 *  role?: ResourceAccessRole;
 *  post?: { slug: string };
 *  privateShareId?: string
 * }} param0
 * @returns {string}
 */
export const getShareLink = ({ channel, studio, video, role, post, privateShareId }) => {
	if (!channel) throw new TypeError('Incorrect share');
	const { hashtag } = channel;
	if (post) return getAbsoluteLink(Path.POST, { hashtag, slug: post.slug });
	if (studio && role === ResourceAccessRole.VIEWER) {
		return getAbsoluteLink(Path.WATCH_LINK, { hashtag, watchLinkId: studio.watchLinkId });
	}
	if (studio && role === ResourceAccessRole.PARTICIPANT) {
		return `${getAbsoluteLink(Path.STUDIO_PARTICIPANT, { hashtag, code: studio.code })}?pwd=${studio.password}`;
	}
	if (video) {
		const baseUrl = getAbsoluteLink(Path.WATCH_LINK, { hashtag, watchLinkId: video.watchLinkId });
		const sharePrivate = video.privateShareId || privateShareId;
		return video.visibility === ResourceVisibility.JUST_ME && sharePrivate
			? `${baseUrl}?privateShareId=${sharePrivate}`
			: baseUrl;
	}
	return getAbsoluteLink(Path.CHANNEL, { hashtag });
};

/**
 * @param {{
 * 	channel: { hashtag: string };
 * 	studio?: { watchLinkId: string };
 *  video?: { watchLinkId: string };
 * }} param0
 * @returns {string}
 */
export const getEmbedShareLink = ({ channel, studio, video }) => {
	if (!channel) throw new TypeError('Incorrect share');
	const { hashtag } = channel;

	const watchLinkId = video?.watchLinkId || studio?.watchLinkId;
	if (!watchLinkId) throw new TypeError('Incorrect share');

	return getAbsoluteLink(Path.WATCH_LINK_EMBED, { hashtag, watchLinkId });
};
