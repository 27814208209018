import { useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

export const PlayerColorPicker = ({ videoRef, onClick }) => {
	const colorPickerCanvas = useRef(null);
	const colorPickerPreviewCanvas = useRef(null);
	const mousePreviewOffset = useMemo(() => ({ x: 15, y: -15 }), []);
	useEffect(() => {
		if (!colorPickerCanvas.current) {
			colorPickerCanvas.current = document.createElement('canvas');
		}
		let offOnClickVideoEvent;
		let offMouseVideoEvent;
		let pixelData = [0, 0, 0];
		let mousePosition;
		const videoElement = videoRef.current;
		const canvas = colorPickerCanvas.current;
		const mousePreview = colorPickerPreviewCanvas.current;

		if (videoElement) {
			canvas.width = videoElement.offsetWidth;
			canvas.height = videoElement.offsetHeight;

			const draw = () => {
				const context = canvas.getContext('2d');
				context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
				pixelData = context.getImageData(
					mousePosition?.x || 0,
					mousePosition?.y || 0,
					1,
					1,
				).data;
				context.clearRect(0, 0, canvas.width, canvas.height);
			};

			offOnClickVideoEvent = () => {
				draw();
				onClick(pixelData[0], pixelData[1], pixelData[2]);
			};
			offMouseVideoEvent = ({ layerX, layerY }) => {
				draw();
				mousePosition = { x: layerX, y: layerY };
				mousePreview.style.left = `${layerX - mousePreviewOffset.x}px`;
				mousePreview.style.top = `${layerY - mousePreviewOffset.y}px`;

				const context = mousePreview.getContext('2d');
				context.fillStyle = `rgb(${pixelData[0]}, ${pixelData[1]}, ${pixelData[2]})`;
				context.fillRect(0, 0, mousePreview.width, mousePreview.height);
				context.lineWidth = 2;
				context.strokeStyle = 'black';
				context.strokeRect(0, 0, mousePreview.width, mousePreview.height);
			};
			videoElement.addEventListener('mousemove', offMouseVideoEvent);
			videoElement.addEventListener('click', offOnClickVideoEvent);
		}
		return () => {
			if (offOnClickVideoEvent) {
				videoElement.removeEventListener('click', offOnClickVideoEvent);
			}
			if (offMouseVideoEvent) {
				videoElement.removeEventListener('mousemove', offMouseVideoEvent);
			}
			if (canvas) {
				canvas.remove();
				colorPickerCanvas.current = undefined;
			}
		};
	}, [onClick, videoRef, mousePreviewOffset]);

	return (
		<canvas
			className="position-absolute"
			ref={colorPickerPreviewCanvas}
			width={10}
			height={10}
		/>
	);
};

PlayerColorPicker.propTypes = {
	videoRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
	onClick: PropTypes.func.isRequired,
};
