// copied from https://github.com/twilio/twilio-webrtc.js/blob/master/lib/getusermedia.js
let date1;
let date2;
const GETUSERMEDIA_COOLDOWN = 500;
const GETUSERMEDIA_TIMEOUT = 500;

//IMPORTANT: The timeout is a temporary fix
//TODO: Remove the timeout and fix the underlying issue
export default async function getUserMedia(constraints) {
	if (!date1) date1 = Date.now();
	if (!date2) date2 = Date.now();

	if (date2 - date1 < GETUSERMEDIA_COOLDOWN) {
		await new Promise((resolve) => {
			setTimeout(resolve, GETUSERMEDIA_TIMEOUT);
		});
	}
	date1 = Date.now();
	date2 = undefined;
	if (typeof navigator === 'object'
		&& typeof navigator.mediaDevices === 'object'
		&& typeof navigator.mediaDevices.getUserMedia === 'function') {
		constraints = constraints || { audio: true, video: true };
		return navigator.mediaDevices.getUserMedia(constraints);
	}
	return Promise.reject(new Error('getUserMedia is not supported'));
}
