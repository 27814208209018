/* eslint-disable react/prop-types */
// @ts-check
import { createContext, useContext, useMemo } from 'react';
import { createDispatchHook, createSelectorHook, createStoreHook, Provider } from 'react-redux';

import store from '../../store';

/** @import { AuthenticatedUser } from './ReactVideo'; */

/**
 * @typedef {{
 * 	getConnectionConfig: () => Promise<any>, // TODO: type
 * 	isController: boolean,
 * 	user: AuthenticatedUser,
 * }} IReactVideoContext
 */

export const ReactVideoContext = createContext(/** @type {IReactVideoContext} */({}));

export const useReactVideo = () => useContext(ReactVideoContext);

/**
 * @import { ReactReduxContextValue } from 'react-redux';
 * @import { RootState, AppDispatch, AppAction } from '../../store';
 */

/** @typedef {ReactReduxContextValue<RootState, AppAction>} ReduxStore */

/**
 * Initial value here is only used for type checks purposes.
 * It is overwritten with the internal state of Provider.
 */
export const ChannelStreamReduxContext = createContext(/** @type {ReduxStore} */({
	store,
	storeState: store.getState(),
}));
export const useStore = createStoreHook(ChannelStreamReduxContext);
/** @type {() => AppDispatch} */
export const useDispatch = createDispatchHook(ChannelStreamReduxContext);
export const useSelector = createSelectorHook(ChannelStreamReduxContext);

/**
 * @typedef {{
 * 	children: React.ReactNode,
 * 	getConnectionConfig: () => Promise<any>, // TODO: type
 * 	isController?: boolean,
 * 	user: AuthenticatedUser,
 * }} ReactVideoProviderProps
 */

/** @type {React.FC<ReactVideoProviderProps>} */
export const ReactVideoProvider = ({
	children,
	getConnectionConfig,
	isController = false,
	user,
}) => {
	const contextValue = useMemo(() => ({
		getConnectionConfig,
		isController,
		user,
	}), [
		getConnectionConfig,
		isController,
		user,
	]);

	return (
		<Provider context={ChannelStreamReduxContext} store={store}>
			<ReactVideoContext.Provider value={contextValue}>
				{children}
			</ReactVideoContext.Provider>
		</Provider>
	);
};
