import PropTypes from 'prop-types';
import { useCallback, useId, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaImage } from 'react-icons/fa';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Label } from 'reactstrap';
import { useUploadImage } from '../../api-hooks/channel/images';
import { resizeImageDefault } from '../../lib/file';
import { useProfile } from '../Profile/ProfileContext';
import { TooltipTimeout } from '../Tooltip/Timeout';
import { UserSettingsModal, useUserSettings } from '../UserSettings/Context';

export const PostAssetType = {
	IMAGE: 'IMAGE',
	VIDEO: 'VIDEO',
};

export const AssetUploadMode = {
	LIBRARY: 'MediaInput.chooseFromLibrary',
	UPLOAD: 'MediaInput.upload',
};

export const isFileAcceptable = (file, accept) => {
	const acceptedTypes = accept.replaceAll(' ', '').split(',');
	return acceptedTypes.includes(file.type);
};

export const ImageInput = ({
	uploadedAssets,
	setUploadedAssets,
	setImageUploadProgress,
	iconSize,
	label,
}) => {
	const { t } = useTranslation();
	const [imageDropdownOpen, setImageDropdownOpen] = useState(false);

	const [imageIncorrectTypeError, setImageIncorrectTypeError] = useState(false);

	const { mutate: uploadImage } = useUploadImage();

	const { profile } = useProfile();
	const { openModal, closeModal } = useUserSettings();

	const imageInput = useRef(null);

	const id = useId();
	const imageId = `MediaInput-image${id.replaceAll(':', '__')}`;

	const onImageUploadProgress = useCallback((progress) => {
		setImageUploadProgress(progress);
	}, [setImageUploadProgress]);

	const handleImageUploaded = useCallback((image) => {
		if (image) {
			setUploadedAssets([
				...uploadedAssets,
				{
					image: image._id,
					assetType: PostAssetType.IMAGE,
					filename: image.filename,
					label: image.label,
				},
			]);
		}
	}, [setUploadedAssets, uploadedAssets]);

	const handleBrowserFiles = useCallback(() => {
		imageInput.current.click();
	}, [imageInput]);

	const handleUploadImage = useCallback((imageData) => {
		uploadImage({
			channelId: profile._id,
			image: imageData,
			onUploadProgress: onImageUploadProgress,
		}, {
			onSuccess: (data) => {
				handleImageUploaded(data);
				setImageUploadProgress(undefined);
			},
		});
	}, [handleImageUploaded, onImageUploadProgress, profile, setImageUploadProgress, uploadImage]);

	const handleImageChange = useCallback(async (event) => {
		setImageIncorrectTypeError(false);
		const files = Array.from(event.target.files);

		if (!files?.length) {
			return;
		}

		const file = files[0];

		if (!isFileAcceptable(file, event.target.accept)) {
			setImageIncorrectTypeError(true);
			return;
		}

		const blob = await resizeImageDefault(file);
		const imageData = {
			blob,
			label: file.name.replace(/\.[^/.]+$/, ''),
			filename: URL.createObjectURL(blob),
			isGfx: false,
		};

		handleUploadImage(imageData);
	}, [handleUploadImage]);

	const handleOpenSelectAssetModal = useCallback((callback, modalType) => {
		const call = (asset) => {
			closeModal();
			return callback(asset);
		};
		openModal(modalType, undefined, undefined, call);
	}, [closeModal, openModal]);

	return (
		<>
			{!!label && (
				<Label className="cursor-pointer" onClick={() => setImageDropdownOpen(!imageDropdownOpen)}>
					{t('Channel.Goals.image')} *
				</Label>
			)}
			<Dropdown
				isOpen={imageDropdownOpen}
				toggle={() => setImageDropdownOpen(!imageDropdownOpen)}
			>
				<DropdownToggle
					className="font-size-lg p-0 d-inline-block shadow-none border-0 text-center d-30 btn-transition-none mr-1 bg-transparent text-secondary"
					title={t('MediaInput.uploadImage')}
					type="button"
					id={imageId}
				>
					<FaImage size={iconSize} />
				</DropdownToggle>
				{imageDropdownOpen && (
					<DropdownMenu>
						<DropdownItem onClick={() => handleOpenSelectAssetModal(
							handleImageUploaded,
							UserSettingsModal.SELECT_IMAGES,
						)}
						>
							{t(AssetUploadMode.LIBRARY)}
						</DropdownItem>
						<DropdownItem divider />
						<DropdownItem onClick={handleBrowserFiles}>
							{t(AssetUploadMode.UPLOAD)}
						</DropdownItem>
					</DropdownMenu>
				)}

			</Dropdown>
			<input
				className="d-none"
				accept="image/jpeg, .jpeg, image/png, .png"
				type="file"
				onChange={handleImageChange}
				ref={imageInput}
			/>
			<TooltipTimeout
				onTimeout={() => setImageIncorrectTypeError(false)}
				isOpen={imageIncorrectTypeError}
				placement="top"
				popperClassName="tooltip-danger"
				target={imageId}
			>
				{t('MediaInput.imageFormatAllowed')}
			</TooltipTimeout>
		</>
	);
};

ImageInput.propTypes = {
	uploadedAssets: PropTypes.arrayOf(PropTypes.shape({})),
	setUploadedAssets: PropTypes.func,
	setImageUploadProgress: PropTypes.func,
	iconSize: PropTypes.string,
	label: PropTypes.string,
};

ImageInput.defaultProps = {
	uploadedAssets: [],
	setUploadedAssets: undefined,
	setImageUploadProgress: undefined,
	iconSize: '',
	label: '',
};
