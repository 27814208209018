/**
 * draw text on canvas
 * @param {{
 * 		backgroundColor: string,
 * 		backgroundTransparency: number,
 * 		font: string,
 * 		fontColor: string,
 *		fontSize: number,
 * 		text: string,
 * }} textConfig
 * @param {CanvasRenderingContext2D} context
 */
export const drawText = (textConfig, context) => {
	const { width, height } = context.canvas;
	context.clearRect(0, 0, width, height);

	const scaleFactor = { horizontal: 1, vertical: 1 };

	let updatedText = textConfig.text;
	const x = 0;
	const y = 0;
	const lineMargin = 15 * scaleFactor.vertical;

	context.font = `${textConfig.fontSize * scaleFactor.vertical}px ${textConfig.font}`;

	const textBox = { width: 0, height: 0, x, y };

	const lines = [];

	textConfig.text.split('\n').forEach((originalLine, i) => {
		let cutLine = originalLine;
		const textHeight = parseInt(context.font, 10);
		let textWidth = context.measureText(originalLine).width;
		while (textWidth > width) {
			cutLine = cutLine.slice(0, -1);
			textWidth = context.measureText(cutLine).width;
		}
		if (cutLine !== originalLine) {
			updatedText = textConfig.text.replace(originalLine, cutLine);
			textWidth = context.measureText(cutLine).width;
		}
		if (textBox.width < textWidth) textBox.width = textWidth;
		if (textBox.height + textHeight <= height) {
			textBox.height += textHeight / 2 + lineMargin;
		}
	});

	let lastY = 0;
	updatedText.split('\n').forEach((line, i) => {
		const textWidth = context.measureText(line).width;
		const textHeight = parseInt(context.font, 10);

		if (i === 0) {
			textBox.x = x + width / 2 - textBox.width / 2;
			textBox.y = y + height / 2 - textBox.height / 2;
		}

		const lineY = lastY !== 0 ? lastY + textHeight / 2 + lineMargin
			: textBox.y + (textBox.height / updatedText.split('\n').length);
		lastY = lineY;

		if (lineY <= y + height && textWidth < width) {
			lines.push({
				line,
				x: textBox.x,
				y: lineY,
			});
		}
	});

	context.fillStyle = textConfig.backgroundColor;
	context.globalAlpha = 1 - textConfig.backgroundTransparency;
	context.fillRect(
		x,
		y,
		width,
		height,
	);

	context.globalAlpha = 1;
	context.fillStyle = textConfig.fontColor;
	lines.forEach((text) => {
		context.fillText(text.line, text.x, text.y);
	});
};
