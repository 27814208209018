// @ts-check

import { createSelector } from 'reselect';

import {
	SOURCE_TYPE_PARTICIPANT,
} from '../../lib/stream';
import {
	selectParticipantsMediaStreams,
} from './mediaStreams';
import {
	selectChannelParticipantsStreams,
} from './channelStreams';
import {
	isParticipantDefaultSourceId,
	isParticipantSourceId,
	isScreenshareSourceId,
	isVideoshareSourceId,
	isImageshareSourceId,
	isAudioshareSourceId,
	isTalkbackSourceId,
} from '../lib/helpers';

/** @import { ChannelStream } from '../../lib/store/channelStream' */
/** @import { ChannelMediaStream } from '../lib/MediastreamCache' */

/**
 * @typedef {{
 * sourceId: string;
 * ownerAvatar?: string;
 * label?: string;
 * own?: boolean;
 * muted: boolean;
 * src?: ChannelMediaStream;
 * type: string;
 * }} ParticipantSource
 */

/**
 * @param {ChannelStream[]} participantStreams
 * @param {ChannelMediaStream[]} [participantsMediaStreams]
 * @returns {ParticipantSource[]}
 */
const getParticipantSourcesResult = (participantStreams, participantsMediaStreams = []) => (
	participantsMediaStreams.map((participantMediaStream) => {
		const participantStream = participantStreams
			.find(({ id }) => id === participantMediaStream.stream.id);
		if (!participantStream) throw new Error('Participant stream not found');

		let src;
		let muted = true;

		if (participantStream.own) {
			src = undefined;
		} else {
			src = participantMediaStream;
			muted = false; /* source will be muted in SourceCard component
			if participant is already in live */
		}

		return {
			sourceId: participantStream.id,
			ownerAvatar: participantStream.user.avatar,
			label: participantStream.user.nickname,
			own: participantStream.own,
			muted,
			src,
			type: SOURCE_TYPE_PARTICIPANT,
		};
	})
);

export const selectParticipantSources = createSelector(
	selectChannelParticipantsStreams,
	selectParticipantsMediaStreams,
	getParticipantSourcesResult,
);

export const selectGuestSources = createSelector(
	selectParticipantSources,
	(participantSources = []) => participantSources.filter(({ own }) => (!own)),
);

export const selectControllerSources = createSelector(
	selectParticipantSources,
	(participantSources = []) => participantSources.filter(
		({ sourceId, own }) => (own && isParticipantSourceId(sourceId)),
	),
);

export const selectControllerParticipantSources = createSelector(
	selectParticipantSources,
	(participantSources = []) => participantSources.find(
		({ sourceId, own }) => (own && isParticipantSourceId(sourceId)),
	),
);

export const selectControllerParticipantDefaultSource = createSelector(
	selectParticipantSources,
	(participantSources = []) => participantSources.find(
		({ sourceId, own }) => (own && isParticipantDefaultSourceId(sourceId)),
	),
);

export const selectControllerVideoSource = createSelector(
	selectParticipantSources,
	(participantSources = []) => participantSources.find(
		({ sourceId, own }) => (own && isVideoshareSourceId(sourceId)),
	),
);

export const selectControllerImageSource = createSelector(
	selectParticipantSources,
	(participantSources = []) => participantSources.find(
		({ sourceId, own }) => (own && isImageshareSourceId(sourceId)),
	),
);

export const selectControllerAudioSource = createSelector(
	selectParticipantSources,
	(participantSources = []) => participantSources.find(
		({ sourceId, own }) => (own && isAudioshareSourceId(sourceId)),
	),
);

export const selectControllerScreenShareSource = createSelector(
	selectParticipantSources,
	(participantSources = []) => participantSources.find(
		({ sourceId, own }) => (own && isScreenshareSourceId(sourceId)),
	),
);

export const selectTalkbackSources = createSelector(
	selectParticipantSources,
	(participantSources = []) => participantSources.filter(
		({ sourceId }) => isTalkbackSourceId(sourceId),
	),
);
