import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useAsyncCallback } from 'react-async-hook';
import { Form, Field, FieldError } from 'react-jsonschema-form-validation';
import { FormGroup, Input } from 'reactstrap';
import { ButtonPillOutline } from '../../Button';
import { FormLabel } from '../../Form/Label';
import { ResourceAccessRole } from '../../../lib/ResourceAccessRole';
import { ShareRoleInput } from '../RoleInput';
import { ShareRecipientType, ShareTabType } from '../Share.helper';
import FormSubmit from '../../Form/Submit';
import { SelectUsersMulti } from '../../SelectUsersMulti/SelectUsersMulti';
import { AlertTimeout } from '../../Alert/Timeout';

const formSchema = {
	type: 'object',
	additionalProperties: false,
	properties: {
		message: {
			type: 'string',
			maxLength: 100,
		},
		users: {
			type: 'array',
			minItems: 1,
		},
	},
};

const getInitialData = (message) => ({
	users: [],
	message: message || '',
});

const projectName = import.meta.env.VITE_PROJECT;

export const ShareTabUsers = ({
	alreadySharedUsers,
	defaultMessage,
	inputColor,
	isLoading,
	onShare,
	roles,
	selectedRole,
	setSelectedRole,
	setSuggestionsSearchValue,
	shareButtonTranslationKey,
	showSuccessMessage,
	studio,
	suggestions,
	showUsersSelect,
}) => {
	const { t } = useTranslation();

	const [formData, setFormData] = useState(getInitialData(defaultMessage));

	const searchLabel = selectedRole === ResourceAccessRole.OPERATOR
		? t('Share.Tab.Users.searchOperator')
		: t('Share.Tab.Users.searchForAnyBeeYouUser', { projectName });

	const alreadySharedUsersIds = useMemo(() => alreadySharedUsers.map((p) => {
		if (p.type === ShareRecipientType.USER || p.type === ShareRecipientType.USERS) {
			return p.user._id;
		}
		return null;
	}), [alreadySharedUsers]);

	const clear = () => setFormData((s) => ({ ...s, users: [] }));

	const handleSubmitAsync = useAsyncCallback(async () => {
		const participants = (
			showUsersSelect
				? formData.users
				: [{}]
		).map((data) => ({
			user: data.user,
			message: formData.message,
			type: ShareRecipientType.USER,
		}));

		await onShare(participants, selectedRole);
		clear();
	});

	const handleClear = () => { clear(); };

	useEffect(() => {
		clear();
	}, [selectedRole]);

	const isEmpty = !(formData.users?.length > 0);
	const isActionDisabled = showUsersSelect && (isEmpty || handleSubmitAsync.loading);

	return (
		<Form
			className="ShareTabUsers"
			data={formData}
			onSubmit={handleSubmitAsync.execute}
			schema={showUsersSelect ? formSchema : {}}
		>
			{showSuccessMessage && handleSubmitAsync.status === 'success' && (
				<AlertTimeout color="success" onTimeout={handleSubmitAsync.reset} timeoutTime={3000}>
					{t('Share.shareSuccessfullySent')}
				</AlertTimeout>
			)}
			{handleSubmitAsync.error && (
				<AlertTimeout color="danger" onTimeout={handleSubmitAsync.reset}>
					{t('Global.error')}
				</AlertTimeout>
			)}
			{selectedRole !== ResourceAccessRole.PUBLIC && (
				<ShareRoleInput
					value={selectedRole}
					onChange={(role) => setSelectedRole(role)}
					roles={roles}
					shareTabType={ShareTabType.USERS}
				/>
			)}
			{showUsersSelect && (
				<FormGroup>
					<FormLabel>{t('Share.sendTo')}</FormLabel>
					<SelectUsersMulti
						alreadySelectedUsersIds={alreadySharedUsersIds}
						color={inputColor}
						isLoading={isLoading}
						name="users"
						placeholder={searchLabel}
						propSuggestions={suggestions}
						setPropSuggestionsSearchValue={setSuggestionsSearchValue}
						setUsers={(users) => setFormData({ ...formData, users })}
						suggestionsFilter={(sugg) => sugg.id !== studio?.owner?._id}
						users={formData.users}
					/>
					<FieldError
						name="users"
						errorMessages={{
							minItems: () => t('Share.Tab.Users.pleaseAddAtLeastOneUser'),
						}}
					/>
				</FormGroup>
			)}
			<div className="d-flex flex-column flex-sm-row justify-content-start justify-content-sm-between align-items-start align-items-sm-center pl-1">
				<div className="d-flex justify-content-end mb-3">
					{showUsersSelect && (
						<ButtonPillOutline
							className="mr-2"
							color={inputColor}
							disabled={isActionDisabled}
							onClick={handleClear}
						>
							{t('Share.Buttons.clear')}
						</ButtonPillOutline>
					)}
					<FormSubmit
						className="btn-pill shadow-none"
						disabled={isActionDisabled}
						loading={handleSubmitAsync.loading}
						title={t('Share.Tab.Users.sendInvitationToUsers')}
					>
						{t(shareButtonTranslationKey)}
					</FormSubmit>
				</div>
			</div>
			<FormGroup>
				<FormLabel>{t('Share.Message.message')}</FormLabel>
				<Field
					className={`Share_InviteMessage bg-${inputColor} content-${inputColor} rounded-1 border-${inputColor}`}
					component={Input}
					name="message"
					onChange={(e) => setFormData({ ...formData, message: e.target.value })}
					placeholder={t('Share.Message.addCustomMessage')}
					rows="3"
					type="textarea"
					value={formData.message}
				/>
				<FieldError
					errorMessages={{
						maxLength: () => t('Share.Message.messageIsTooLong'),
					}}
					name="message"
				/>
			</FormGroup>
		</Form>
	);
};

ShareTabUsers.propTypes = {
	alreadySharedUsers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	defaultMessage: PropTypes.string,
	inputColor: PropTypes.oneOf(['dark', 'light']),
	isLoading: PropTypes.bool,
	onShare: PropTypes.func.isRequired,
	roles: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(ResourceAccessRole))).isRequired,
	selectedRole: PropTypes.oneOf(Object.keys(ResourceAccessRole)).isRequired,
	setSelectedRole: PropTypes.func.isRequired,
	setSuggestionsSearchValue: PropTypes.func,
	shareButtonTranslationKey: PropTypes.string.isRequired,
	showSuccessMessage: PropTypes.bool,
	studio: PropTypes.shape({
		owner: PropTypes.shape({
			_id: PropTypes.string,
		}),
	}),
	suggestions: PropTypes.arrayOf(PropTypes.shape({})),
	showUsersSelect: PropTypes.bool,
};

ShareTabUsers.defaultProps = {
	defaultMessage: '',
	inputColor: 'dark',
	isLoading: false,
	setSuggestionsSearchValue: undefined,
	showSuccessMessage: true,
	studio: { owner: { _id: undefined } },
	suggestions: undefined,
	showUsersSelect: true,
};
