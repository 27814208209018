import { api } from '../api';

export const API_CHANNEL_FEED_PATH = '/channel/feed';

export const getChannelFeed = (channelId, itemsPerPage, pageParam) => api.get(
	`${API_CHANNEL_FEED_PATH}/byChannelId/${channelId}`, {
		params: {
			itemsPerPage,
			currentPage: pageParam,
		},
	},
);
