import { api } from '../api';

const API_PROFILE_SIGNUP_PATH = '/profile/signup';

export const signup = async ({
	password,
	email,
	phoneNumber,
	nickname,
	organization,
	organizationJoinInviteId,
}) => api.post(
	`${API_PROFILE_SIGNUP_PATH}`,
	{
		password,
		email,
		phoneNumber,
		nickname,
		organization,
		organizationJoinInviteId,
	},
);

export const googleSignup = async (data) => api.post(`${API_PROFILE_SIGNUP_PATH}/google`, data);
