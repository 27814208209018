// @ts-check

import { api } from '../api';
import { upload } from '../upload';

const API_STUDIO_SCHEDULE_PATH = '/studios/schedule';

export const fetchStudioSchedules = async (
	channelId, state, itemsPerPage, currentPage,
) => api.get(
	`${API_STUDIO_SCHEDULE_PATH}/byChannelId/${channelId}`, { params: { state, itemsPerPage, currentPage } },
);

export const fetchStudioScheduleById = async (id) => api.get(
	`${API_STUDIO_SCHEDULE_PATH}/${id}`,
);

export const fetchCalendarStudioSchedule = async (
	channelId,
	startDate,
	endDate,
) => api.get(
	`${API_STUDIO_SCHEDULE_PATH}/calendar/byChannelId/${channelId}`,
	{
		params: { startDate, endDate },
	},
);

export const fetchManyStudioScheduleSoon = async () => api.get(
	`${API_STUDIO_SCHEDULE_PATH}/soon`,
);

/**
 * @param {string} studioId
 * @returns {Promise<import('axios').AxiosResponse<boolean>>}
 */
export const fetchStudioCalendarStatus = async (studioId) => api.get(
	`${API_STUDIO_SCHEDULE_PATH}/calendarStatus/${studioId}`,
);

const uploadBanner = async (studioSchedule) => {
	if (studioSchedule.banner === null) studioSchedule.banner = ''; // remove banner only if null (not undefined)

	let { banner } = studioSchedule;
	if (banner?.blob) {
		banner = await upload(banner.blob);
	}

	const studioScheduleWithBanner = {
		...studioSchedule,
		banner,
	};

	return studioScheduleWithBanner;
};

export const createStudioSchedule = async ({ channelId, studioSchedule }) => {
	const studioScheduleWithBanner = await uploadBanner(studioSchedule);
	return api.post(
		`${API_STUDIO_SCHEDULE_PATH}/toChannelById/${channelId}`,
		studioScheduleWithBanner,
	);
};

/**
 * @param {string} studioId
 * @returns {Promise<import('axios').AxiosResponse<void>>}
 */
export const createCalendarEvent = async (studioId) => api.post(
	`${API_STUDIO_SCHEDULE_PATH}/addToCalendar/${studioId}`,
);

export const updateStudioSchedule = async ({ id, studioSchedule }) => {
	const studioScheduleWithBanner = await uploadBanner(studioSchedule);
	return api.put(
		`${API_STUDIO_SCHEDULE_PATH}/${id}`,
		studioScheduleWithBanner,
	);
};

export const cancelStudioSchedule = async (id) => api.post(
	`${API_STUDIO_SCHEDULE_PATH}/cancel/${id}`,
);

export const updateStudioScheduleTime = async ({
	studioId,
	startAt,
	plannedDuration,
}) => api.put(
	`${API_STUDIO_SCHEDULE_PATH}/${studioId}/time`,
	{ plannedDuration, startAt },
);
