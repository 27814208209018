/* eslint-disable react/prop-types */
// @ts-check

import clsx from 'clsx';

/**
 * @typedef {{
 * 	className?: string,
 * 	title: string,
 * }} ListTitleProps
 */

/** @type {React.FC<ListTitleProps>} */
export const ListTitle = ({
	className = '',
	title,
}) => (
	<h1 className={clsx('d-flex align-items-center mt-2 mb-3 ml-4 font-size-xl mb-3', className)}>
		<strong className="text-nowrap">{title}</strong>
	</h1>
);
