import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FormGroup } from 'reactstrap';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import { FormLabel } from '../Form/Label';
import { ShareTabType } from './Share.helper';
import { ResourceAccessRole } from '../../lib/ResourceAccessRole';

export const ShareRoleInput = ({
	roles,
	value,
	onChange,
	shareTabType,
}) => {
	const { t } = useTranslation();
	const [openDropwdown, setOpenDropdown] = useState(false);

	// Remove the operator roles in all tabs except in the users tab
	const filteredRoles = roles.filter((role) => (role
		&& (shareTabType === ShareTabType.USERS || role !== ResourceAccessRole.OPERATOR)
		&& [
			ResourceAccessRole.PARTICIPANT,
			ResourceAccessRole.VIEWER,
			ResourceAccessRole.OPERATOR,
		].includes(role)));

	return filteredRoles.length > 0 && (
		<FormGroup tag="fieldset">
			<FormLabel className="mb-0">{t('Share.RoleInput.inviteAs')}</FormLabel>
			<Dropdown
				isOpen={openDropwdown}
				toggle={() => (filteredRoles.length > 1
					? setOpenDropdown((prevState) => !prevState) : null)}
				className="ml-0 pl-0 pt-0 mt-0"
			>
				<DropdownToggle type="button" color="content-darker" className="h-100 pl-0 ml-0 border-0 pt-0 mt-0">
					<small><strong className="text-capitalize mr-1">{t(`Share.RoleInput.role.${value}`)}</strong></small>
					{filteredRoles.length > 1 && (openDropwdown ? <FaCaretUp /> : <FaCaretDown />)}
				</DropdownToggle>
				{openDropwdown && (
					<DropdownMenu className="bg-light">
						{filteredRoles.map((role) => (
							<DropdownItem onClick={() => onChange(role)} key={`role-input-${role}`}>
								<small>{t(`Share.RoleInput.role.${role}`)}</small>
							</DropdownItem>
						))}
					</DropdownMenu>
				)}
			</Dropdown>
		</FormGroup>
	);
};

ShareRoleInput.propTypes = {
	value: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	roles: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(ResourceAccessRole))).isRequired,
	shareTabType: PropTypes.oneOf(Object.keys(ShareTabType)).isRequired,
};
