import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Field, FieldError } from 'react-jsonschema-form-validation';
import { FormGroup, Input } from 'reactstrap';
import { FormLabel } from '../../../../../../Form/Label';

export const AdvertisingCampaignsCreateViewes = ({ adCampaign, setAdCampaign, isViewOnly }) => {
	const handleChangeViews = useCallback((views) => {
		const { ads } = adCampaign;

		if (adCampaign.ads.length === 1) {
			ads[0].views = views;
		}

		setAdCampaign((prevAdCampaign) => ({
			...prevAdCampaign,
			viewTarget: views,
			ads,
		}));
	}, [adCampaign, setAdCampaign]);

	return (
		<>
			<FormGroup>
				<FormLabel>Total Target View Objective</FormLabel>
				<Field
					component={Input}
					type="select"
					name="viewTarget"
					value={adCampaign.viewTarget}
					onChange={(e) => handleChangeViews(e.target.value)}
					disabled={isViewOnly}
				>
					<option value="1000">1000</option>
					<option value="10000">10000</option>
					<option value="100000">100000</option>
				</Field>
				<FieldError name="viewTarget" />
			</FormGroup>

			<FormGroup>
				<FormLabel>Daily Ad View Restriction</FormLabel>
				<Field
					component={Input}
					type="select"
					name="dailyViewRestriction"
					value={adCampaign.dailyViewRestriction}
					disabled={isViewOnly}
				>
					<option value="1000">500</option>
					<option value="10000">1000</option>
					<option value="100000">2000</option>
					<option value="100000">5000</option>
					<option value="100000">10000</option>
				</Field>
				<FieldError name="dailyViewRestriction" />
			</FormGroup>
		</>
	);
};

AdvertisingCampaignsCreateViewes.propTypes = {
	adCampaign: PropTypes.shape({
		viewTarget: PropTypes.string,
		dailyViewRestriction: PropTypes.string,
		ads: PropTypes.arrayOf(PropTypes.shape({
			views: PropTypes.number,
		})),
	}).isRequired,
	setAdCampaign: PropTypes.func.isRequired,
	isViewOnly: PropTypes.bool,
};

AdvertisingCampaignsCreateViewes.defaultProps = {
	isViewOnly: false,
};
