import { api } from '../api';

export const API_CHANNEL_OPERATORS_PATH = '/channel/operators';

export const fetchOperatorsByChannelId = (channelId) => api
	.get(`${API_CHANNEL_OPERATORS_PATH}/byChannelId/${channelId}`);

export const fetchMyHosts = () => api.get(`${API_CHANNEL_OPERATORS_PATH}/my/hosts`);

export const fetchOperatorCandidates = () => api.get(`${API_CHANNEL_OPERATORS_PATH}/candidates`);

export const addOperators = ({ channelIds, validUntil }) => api.post(
	`${API_CHANNEL_OPERATORS_PATH}/add`,
	{ channelIds, validUntil },
);

export const removeOperator = (channelId) => api.delete(
	`${API_CHANNEL_OPERATORS_PATH}/${channelId}`,
);
