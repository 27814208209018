import { useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import { useNotification } from './Provider';
import NotificationToast from './Toast/Toast';
import { NotificationAction, NotificationActionHandler } from './notificationAction';

import './Manager.scss';

const NotificationManager = () => {
	const navigate = useNavigate();
	const { notifications, removeNotification } = useNotification();

	useEffect(() => {
		notifications.forEach((notification) => {
			if (Object.values(NotificationAction).includes(notification.message)) {
				NotificationActionHandler[notification.message]({ notification, navigate });
			}

			const { etag } = notification;
			if (toast.isActive(etag)) {
				toast.update(
					etag,
					{
						containerId: 'invitationNotification',
						render: (props) => (
							<NotificationToast
								onClose={removeNotification}
								notification={notification}
								{...props}
							/>
						),
					},
				);
			} else {
				toast.info(
					<NotificationToast
						onClose={removeNotification}
						notification={notification}
					/>,
					{
						containerId: 'invitationNotification',
						toastId: etag,
					},
				);
			}
		});
	}, [removeNotification, notifications, navigate]);

	return (
		<ToastContainer
			enableMultiContainer
			newestOnTop
			containerId="invitationNotification"
			position="top-right"
			autoClose={false}
			closeOnClick={false}
			rtl={false}
			draggable
			closeButton={false}
			className="NotificationToastContainer mt-5 pt-3 pt-sm-0"
		/>
	);
};

export default NotificationManager;
