// @ts-check
/* eslint-disable react/prop-types */

import { useLayoutEffect, useState } from 'react';
import { IoPhonePortraitOutline, IoTvOutline } from 'react-icons/io5';

export const Orientation = {
	LANDSCAPE: 'LANDSCAPE',
	PORTRAIT: 'PORTRAIT',
};

export const getScreenOrientation = () => {
	if (window.screen?.orientation?.type.includes('portrait')) {
		return Orientation.PORTRAIT;
	}
	return Orientation.LANDSCAPE;
};

/**
 * @typedef {{
 * 	className?: string,
 *  orientation?: string,
 *  showOnlyIfDifferent?: boolean,
 *  size?: string,
 * }} ScreenOrientationIndicatorProps
 */

export const ScreenOrientationIndicator = (
	/** @type {ScreenOrientationIndicatorProps} */
	{
		className,
		orientation,
		showOnlyIfDifferent,
		size = '1.5rem',
	},
) => {
	const [screenOrientation, setScreenOrientation] = useState(() => getScreenOrientation());

	useLayoutEffect(() => {
		if (!window.screen?.orientation) return undefined;

		const orientationChangeListener = () => {
			setScreenOrientation(getScreenOrientation());
		};
		window.screen.orientation.addEventListener('change', orientationChangeListener);

		return () => {
			window.screen.orientation.removeEventListener('change', orientationChangeListener);
		};
	}, []);

	if (!orientation) return null;

	let Icon;
	if (orientation && orientation === Orientation.LANDSCAPE) {
		Icon = IoTvOutline;
	} else if (orientation && orientation === Orientation.PORTRAIT) {
		Icon = IoPhonePortraitOutline;
	} else {
		return null;
	}

	if (showOnlyIfDifferent && orientation === screenOrientation) return null;

	return <Icon className={className} size={size} />;
};
