// @ts-check

// [Params=void[]] fixes an issue with useMutation

/**
 * @template DataType
 * @template {any[]} [Params=void[]]
 * @typedef {(...params: Params)
 * 	=> Promise<import('axios').AxiosResponse<DataType>>} FetchFunction
 */

/**
 * @template DataType
 * @template {any[]} [Params=void[]]
 * @param {FetchFunction<DataType, Params>} fn
 * @param {Params} params
 * @returns {Promise<DataType>}
 */
const responseWrap = async (fn, ...params) => {
	const { data } = await fn(...params);
	return data;
};

/**
 * @template DataType
 * @template {any} [Param0=void]
 * @param {FetchFunction<DataType, [Param0]>} fn
 * @returns {(param: Param0) => ReturnType<typeof responseWrap<DataType, [Param0]>>}
 */
export const axiosMutationWrapper = (fn) => async (param) => responseWrap(fn, param);

/**
 * @template DataType
 * @template {any[]} [Params=void[]]
 * @param {FetchFunction<DataType, Params>} fn
 * @param {Params} params
 * @returns {() => ReturnType<typeof responseWrap<DataType, Params>>}
 */
export const axiosQueryWrapper = (fn, ...params) => async () => responseWrap(fn, ...params);
