import PropTypes from 'prop-types';
import { Alert, Spinner } from 'reactstrap';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { useTranslation } from 'react-i18next';
import { useFetchAdGenderAnalytics } from '../../../../../../api-hooks/analytics/ads';
import { COLORS, LABELS } from '../../../Analytics/Followers/GenderGraph';
import { renderCustomizedLabel } from '../../../Analytics/PieChart';

export const AdvertisingDashboardDemographicsGender = ({ adCampaignId }) => {
	const { data: adGenderAnalytics, isError, isLoading } = useFetchAdGenderAnalytics(adCampaignId);
	const { t } = useTranslation();

	if (isError) {
		return <Alert color="danger">{t('Global.error')}</Alert>;
	}

	if (isLoading) {
		return <Spinner />;
	}

	return adGenderAnalytics ? (
		<div>
			<div className="GrahpHeading m-0 p-3">
				{t('AnalyticsAndAdvertising.Ad.audience Gender')}
			</div>
			<div className="d-flex GraphContainer">
				<ResponsiveContainer width="100%" height="100%">
					<PieChart width={400} height={400}>
						<Pie
							data={adGenderAnalytics}
							cx="50%"
							cy="50%"
							labelLine={false}
							label={renderCustomizedLabel}
							outerRadius={90}
							fill="#8884d8"
							dataKey="value"
						>
							{adGenderAnalytics.map((entry) => (
								<Cell name={t(LABELS[entry.name])} key={`cell-${entry.name}`} fill={COLORS[entry.name]} />
							))}
						</Pie>
						<Legend verticalAlign="top" height={36} />
					</PieChart>
				</ResponsiveContainer>
			</div>
		</div>
	) : null;
};

AdvertisingDashboardDemographicsGender.propTypes = {
	adCampaignId: PropTypes.string,
};

AdvertisingDashboardDemographicsGender.defaultProps = {
	adCampaignId: undefined,
};
