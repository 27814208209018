import { createContext, useState, useContext, useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { COOKIES_NAME, COOKIES_EXPIRATION_HOURS, defaultCookies } from './index';
import { createCookie, getCookie } from '../../lib/cookies';

export const CookieContext = createContext({});

export const useCookies = () => useContext(CookieContext);

const getInitialCookies = () => {
	const cookieValue = getCookie(COOKIES_NAME);
	let savedCookies = {};
	if (cookieValue) {
		savedCookies = JSON.parse(cookieValue);
	}
	return {
		areCookiesConfigured: savedCookies?.areCookiesConfigured || false,
		...defaultCookies,
		...savedCookies,
	};
};

export const CookieProvider = ({ children }) => {
	const [cookies, setCookies] = useState(getInitialCookies);
	const [cookiesUpdated, setCookiesUpdated] = useState(false);
	const [showApproval, setShowApproval] = useState(false);

	const configureCookies = useCallback((cookiesUpdate) => {
		setCookies((state) => ({
			...state,
			...cookiesUpdate,
			areCookiesConfigured: true,
		}));
		setCookiesUpdated(true);
	}, []);

	useEffect(() => {
		if (!cookies.areCookiesConfigured) {
			setShowApproval(true);
		}
	}, [cookies.areCookiesConfigured]);

	useEffect(() => {
		if (cookies.areCookiesConfigured) {
			createCookie(COOKIES_NAME, JSON.stringify(cookies), COOKIES_EXPIRATION_HOURS);
		}
	}, [cookies]);

	const acceptAll = useCallback(() => {
		const newCookies = { ...cookies };
		Object.keys(cookies).forEach((key) => {
			newCookies[key] = true;
		});
		configureCookies(newCookies);
	}, [configureCookies, cookies]);

	const rejectAll = useCallback(() => {
		const newCookies = { ...cookies };
		Object.keys(cookies).forEach((key) => {
			newCookies[key] = false;
		});
		configureCookies(newCookies);
	}, [configureCookies, cookies]);

	const toggleCategory = useCallback((list, isAccepted) => {
		const cookiesUpdate = {};
		list.forEach((cookie) => {
			cookiesUpdate[cookie.id] = isAccepted;
		});
		configureCookies(cookiesUpdate);
	}, [configureCookies]);

	const accept = useCallback((cookieId) => {
		configureCookies({ [cookieId]: true });
	}, [configureCookies]);

	const reject = useCallback((cookieId) => {
		configureCookies({ [cookieId]: false });
	}, [configureCookies]);

	const isAccepted = useCallback((cookieName) => cookies[cookieName], [cookies]);

	const contextValue = useMemo(() => ({
		acceptAll,
		cookiesUpdated,
		rejectAll,
		accept,
		reject,
		isAccepted,
		setCookiesUpdated,
		showApproval,
		cookies,
		toggleCategory,
	}), [
		acceptAll,
		cookiesUpdated,
		rejectAll,
		accept,
		reject,
		isAccepted,
		setCookiesUpdated,
		showApproval,
		cookies,
		toggleCategory,
	]);

	return (
		<CookieContext.Provider value={contextValue}>
			{children}
		</CookieContext.Provider>
	);
};

CookieProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
