import { useQuery } from 'react-query';
import * as analyticsEngagementApi from '../../api/analytics/engagement';
import { axiosQueryWrapper } from '../utils/axios-wrapper';

export const ANALYTICS_ENGAGEMENT_QUERY_KEYS = {
	fetchEngagementViewsAnalytics: ({ channelId, dates, range }) => [
		'analytics', 'engagement', 'views', 'fetch', { channelId, dates, range },
	],
	fetchEngagementLikesAnalytics: ({ channelId, dates, range }) => [
		'analytics', 'engagement', 'likes', 'fetch', { channelId, dates, range },
	],
	fetchEngagementDurationAnalytics: ({ channelId, dates, range }) => [
		'analytics', 'engagement', 'duration', 'fetch', { channelId, dates, range },
	],
	fetchEngagementLikesTotalsAnalytics: ({ channelId, dates }) => [
		'analytics', 'engagement', 'likes', 'totals', 'fetch', { channelId, dates },
	],
	fetchEngagementCommentsTotalsAnalytics: ({ channelId, dates }) => [
		'analytics', 'engagement', 'comments', 'totals', 'fetch', { channelId, dates },
	],
	fetchEngagementSharesTotalsAnalytics: ({ channelId, dates }) => [
		'analytics', 'engagement', 'shares', 'totals', 'fetch', { channelId, dates },
	],
	fetchEngagementVideosOverview: (channelId) => [
		'analytics', 'engagement', 'videos', 'overview', 'fetch', channelId,
	],
	fetchEngagementStudiosOverview: (channelId) => [
		'analytics', 'engagement', 'studios', 'overview', 'fetch', channelId,
	],
	fetchEngagementVideoDetails: (videoId) => [
		'analytics', 'engagement', 'videos', 'details', videoId,
	],
};

export const useFetchEngagementViewsAnalytics = (channelId, dates, range) => useQuery(
	ANALYTICS_ENGAGEMENT_QUERY_KEYS.fetchEngagementViewsAnalytics({ channelId, dates, range }),
	axiosQueryWrapper(analyticsEngagementApi.fetchEngagementViewsAnalytics, channelId, dates, range),
	{ enabled: !!dates && !!range, channelId },
);

export const useFetchEngagementLikesAnalytics = (channelId, dates, range) => useQuery(
	ANALYTICS_ENGAGEMENT_QUERY_KEYS.fetchEngagementLikesAnalytics({ channelId, dates, range }),
	axiosQueryWrapper(analyticsEngagementApi.fetchEngagementLikesAnalytics, channelId, dates, range),
	{ enabled: !!dates && !!range, channelId },
);

export const useFetchEngagementDurationAnalytics = (channelId, dates, range) => useQuery(
	ANALYTICS_ENGAGEMENT_QUERY_KEYS.fetchEngagementDurationAnalytics({ channelId, dates, range }),
	axiosQueryWrapper(analyticsEngagementApi
		.fetchEngagementDurationAnalytics, channelId, dates, range),
	{ enabled: !!dates && !!range && !!channelId },
);

export const useFetchEngagementLikesTotalsAnalytics = (channelId, dates) => useQuery(
	ANALYTICS_ENGAGEMENT_QUERY_KEYS.fetchEngagementLikesTotalsAnalytics({ channelId, dates }),
	axiosQueryWrapper(analyticsEngagementApi
		.fetchEngagementLikesTotalsAnalytics, channelId, dates),
	{ enabled: !!dates && !!channelId },
);

export const useFetchEngagementCommentsTotalsAnalytics = (channelId, dates) => useQuery(
	ANALYTICS_ENGAGEMENT_QUERY_KEYS.fetchEngagementCommentsTotalsAnalytics(
		{ channelId, dates },
	),
	axiosQueryWrapper(analyticsEngagementApi
		.fetchEngagementCommentsTotalsAnalytics, channelId, dates),
	{ enabled: !!dates && !!channelId },
);

export const useFetchEngagementSharesTotalsAnalytics = (channelId, dates) => useQuery(
	ANALYTICS_ENGAGEMENT_QUERY_KEYS.fetchEngagementSharesTotalsAnalytics({ channelId, dates }),
	axiosQueryWrapper(analyticsEngagementApi
		.fetchEngagementSharesTotalsAnalytics, channelId, dates),
	{ enabled: !!dates && !!channelId },
);

export const useFetchEngagementVideosOverview = (channelId) => useQuery(
	ANALYTICS_ENGAGEMENT_QUERY_KEYS.fetchEngagementVideosOverview(),
	axiosQueryWrapper(analyticsEngagementApi
		.fetchEngagementVideosOverview, channelId),
	{ staleTime: 0, enabled: !!channelId },
);

export const useFetchEngagementStudiosOverview = (channelId) => useQuery(
	ANALYTICS_ENGAGEMENT_QUERY_KEYS.fetchEngagementStudiosOverview(),
	axiosQueryWrapper(analyticsEngagementApi
		.fetchEngagementStudiosOverview, channelId),
	{ staleTime: 0, enabled: !!channelId },
);

export const useFetchEngagementVideoDetails = (videoId) => useQuery(
	ANALYTICS_ENGAGEMENT_QUERY_KEYS.fetchEngagementVideoDetails(videoId),
	axiosQueryWrapper(analyticsEngagementApi.fetchEngagementVideoDetails, videoId),
	{ enabled: !!videoId, staleTime: 0 },
);
