import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash.throttle';

import handRaised from './audioFiles/handRaised.mp3';
import newConnection from './audioFiles/newConnection.mp3';
import newMessage from './audioFiles/newMessage.mp3';
import notification from './audioFiles/notification.mp3';

const AUDIO_PLAY_THROTTLE_DELAY = 2000;

export const SoundContext = createContext();

export const useSound = () => useContext(SoundContext);

export const Sound = {
	HAND_RAISED: 'HAND_RAISED',
	NEW_CONNECTION: 'NEW_CONNECTION',
	NEW_MESSAGE: 'NEW_MESSAGE',
	NOTIFICATION: 'NOTIFICATION',
};

export const SoundAudioFiles = {
	[Sound.HAND_RAISED]: handRaised,
	[Sound.NEW_CONNECTION]: newConnection,
	[Sound.NEW_MESSAGE]: newMessage,
	[Sound.NOTIFICATION]: notification,
};

const soundAudioElements = {};

Object.entries(SoundAudioFiles).forEach(([soundKey, soundFile]) => {
	const audioElement = new Audio();
	audioElement.preload = 'none';
	audioElement.src = soundFile;
	audioElement.debouncedPlay = throttle(async () => {
		audioElement.pause();
		audioElement.currentTime = 0;
		return audioElement.play();
	}, AUDIO_PLAY_THROTTLE_DELAY);
	soundAudioElements[soundKey] = audioElement;
});

const loadAudioElements = () => {
	Object.values(soundAudioElements).forEach((soundAudioElement) => {
		soundAudioElement.load();
	});
};

const initialSoundStatusState = Object.keys(soundAudioElements).reduce((acc, soundKey) => ({
	...acc,
	[soundKey]: true,
}), {});

export const SoundProvider = ({ children }) => {
	const [soundsStatus, setSoundsStatus] = useState(initialSoundStatusState);

	const contextValue = useMemo(() => ({
		playSound: async (soundKey) => {
			if (!soundsStatus[soundKey]) return;
			try {
				await soundAudioElements[soundKey].debouncedPlay();
			} catch (error) {
				if (error.name === 'NotAllowedError') {
					// eslint-disable-next-line no-console
					console.warn(error);
				} else {
					throw error;
				}
			}
		},
		setSoundStatus: (soundKey, status) => {
			setSoundsStatus((s) => ({ ...s, [soundKey]: status }));
		},
		setSoundsStatus,
		soundsStatus,
	}), [soundsStatus]);

	useEffect(() => {
		const handleDocumentClick = () => {
			// Load audio elements every time the user clicks anywhere on the page.
			// This hack allows to call play on the audio element without user gesture.
			loadAudioElements();
			// removeEventListener is commented because on safari iOS the audio
			// won't be played again if the play does not follow a user gesture.
			// document.removeEventListener('click', handleDocumentClick);
		};

		document.addEventListener('click', handleDocumentClick);

		return () => {
			document.removeEventListener('click', handleDocumentClick);
		};
	}, []);

	return (
		<SoundContext.Provider value={contextValue}>
			{children}
		</SoundContext.Provider>
	);
};

SoundProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
