import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Row, TabPane } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { PostInput } from '../../../Channel/Posts/Input';
import { useCreatePost } from '../../../../api-hooks/channel/posts';
import { VideoThumbnail } from '../../../Video/Thumbnail';
// import { ChannelPost } from '../../../Channel/Posts/ChannelPost';
import { AlertTimeout } from '../../../Alert/Timeout';

import './Post.scss';

export const ShareTabPost = ({ inputColor, post, showSuccessMessage, video }) => {
	const { t } = useTranslation();
	const {
		isLoading: createPostLoading,
		isSuccess: createPostSuccess,
		mutate: createPost,
		reset: resetCreatePost,
	} = useCreatePost();

	const handleCreatePost = useCallback((text, images, videos) => {
		createPost({
			text,
			images,
			videos,
			sharedVideo: video ? video._id : undefined,
			share: post ? post._id : undefined,
		});
	}, [createPost, video, post]);

	return (
		<TabPane tabId="Post">
			<Row className={`ShareTabPost_PostInput_${inputColor} g-0 h-100 w-100 d-flex justify-content-center align-items-center`}>
				<PostInput
					onSubmit={handleCreatePost}
					isDisabled={createPostLoading}
				/>
				{!!video && (
					<VideoThumbnail
						video={video}
						className="mt-3 mx-auto h-100 w-100"
					/>
				)}
				{/*
				Creates a dependency cycle because post imports mediagallery
				that imports HLSPlayer that imports Share.
				At the moment we cannot share a post from a post, so removing this import to fix the cycle.
				{!!post && (
					<ChannelPost
						post={post}
						minimal
					/>
				)} */}
				{showSuccessMessage && createPostSuccess && (
					<AlertTimeout color="success" onTimeout={resetCreatePost} timeoutTime={3000}>
						{t('Share.Tab.Post.success')}
					</AlertTimeout>
				)}
			</Row>
		</TabPane>
	);
};

ShareTabPost.propTypes = {
	inputColor: PropTypes.oneOf(['dark', 'light']),
	post: PropTypes.shape({
		_id: PropTypes.string.isRequired,
	}),
	showSuccessMessage: PropTypes.bool,
	video: PropTypes.shape({
		_id: PropTypes.string.isRequired,
	}),
};

ShareTabPost.defaultProps = {
	inputColor: 'dark',
	post: undefined,
	showSuccessMessage: true,
	video: undefined,
};
