import { createContext, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useFetchGraphGlobalsBalances, useSetGraphGlobalsBalances } from '../../api-hooks/graph/globals';
import { useAd } from '../Ad/Context';

const PointsContext = createContext({});

export const usePoints = () => useContext(PointsContext);

const getIntegerOrUndefined = (value) => {
	if (value === undefined) return undefined;
	const integer = parseInt(value, 10);
	if (Number.isInteger(integer)) return integer;
	return undefined;
};

export const PointsProvider = ({ children }) => {
	const { data: balances } = useFetchGraphGlobalsBalances();
	const setBalances = useSetGraphGlobalsBalances();
	const { estimatedCurrentAdReward } = useAd();

	const balance = balances?.balance ?? 0;
	const chipBalance = balances?.chipBalance ?? 0;
	const adCreditBalance = balances?.adCreditBalance ?? 0;
	const balanceWithChips = balance + chipBalance;
	const lastBalanceChangeReceived = useRef();

	const handleEventBalanceChange = useCallback((change) => {
		if (estimatedCurrentAdReward > 0) {
			lastBalanceChangeReceived.current = change;
			return;
		}

		setBalances((currentBalances) => ({
			...currentBalances,
			balance: getIntegerOrUndefined(change.balance) ?? currentBalances.balance,
			chipBalance: getIntegerOrUndefined(change.chipBalance) ?? currentBalances.chipBalance,
		}));
	}, [estimatedCurrentAdReward, setBalances]);

	// Used when watching ads: we only update the balances when ads are finished playing
	useEffect(() => {
		if (estimatedCurrentAdReward === 0 && lastBalanceChangeReceived.current) {
			handleEventBalanceChange(lastBalanceChangeReceived.current);
			lastBalanceChangeReceived.current = null;
		}
	}, [estimatedCurrentAdReward, handleEventBalanceChange]);

	const contextValue = useMemo(() => ({
		balance,
		chipBalance,
		adCreditBalance,
		balanceWithChips,
		handleEventBalanceChange,
		setBalances,
	}), [
		balance,
		chipBalance,
		balanceWithChips,
		adCreditBalance,
		handleEventBalanceChange,
		setBalances,
	]);

	return (
		<PointsContext.Provider value={contextValue}>
			{children}
		</PointsContext.Provider>
	);
};

PointsProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
