import { useQuery } from 'react-query';

import * as membershipPointsApi from '../../api/membership/points';
import { axiosQueryWrapper } from '../utils/axios-wrapper';

const MEMBERSHIP_POINTS_QUERY_KEYS = {
	fetchPointsRatio: (orderBundleType, currency) => ['api', 'membership', 'points', 'ratio', { orderBundleType, currency }],
	fetchPiTransactions: (startDate, endDate) => ['api', 'membership', 'points', 'transactions', startDate, endDate].filter((el) => !!el),
};

export const useFetchPiTransactions = ({
	startDate,
	endDate,
}) => useQuery(MEMBERSHIP_POINTS_QUERY_KEYS.fetchPiTransactions(startDate, endDate),
	axiosQueryWrapper(membershipPointsApi.fetchPiTransactions, { startDate, endDate }));

export const useFetchPointsRatio = (orderBundleType, currency) => useQuery(
	MEMBERSHIP_POINTS_QUERY_KEYS.fetchPointsRatio(orderBundleType, currency),
	axiosQueryWrapper(membershipPointsApi.fetchPointsRatio, orderBundleType, currency),
);
