// @ts-check

import createCachedSelector from 're-reselect';

/**
 * @typedef {{
 * 	hashtag: string,
 * }} Channel
 */

/**
 * @template P1, R1, R2, T
 * @param {(param1: P1, param2: Channel) => R1} selector1
 * @param {(param1: P1, param2: Channel) => R2} selector2
 * @param {(res1: R1, res2: R2) => T} combiner
 */
export const createChannelSelector = (selector1, selector2, combiner) => createCachedSelector(
	selector1, selector2, combiner,
)((_, { hashtag }) => hashtag);
