import { useState, useCallback, useEffect } from 'react';

export const getMessageText = (text, type) => {
	if (type === 'deleted') {
		return 'Message was removed';
	} if (type === 'leave') {
		return 'User left conversation';
	}

	return text;
};

export const useRestrictedLengthInput = (value, setValue, sizeLimit) => {
	const [warning, setWarning] = useState(null);
	const [localValue, setLocalValue] = useState(null);

	useEffect(() => {
		if (value !== localValue) {
			setWarning(value.length > sizeLimit
				? `Maximum length is ${sizeLimit}`
				: null);
			if (value.length > sizeLimit) {
				setValue(value.substring(0, sizeLimit));
				setLocalValue(value.substring(0, sizeLimit));
			} else {
				setLocalValue(value);
			}
		}
	}, [value, localValue, sizeLimit, setValue]);

	const handleInputChange = useCallback((e) => {
		setWarning(
			e.target.value.length > sizeLimit
				? `Maximum length is ${sizeLimit}`
				: null,
		);
		setValue(e.target.value.substring(0, sizeLimit));
		setLocalValue(e.target.value.substring(0, sizeLimit));
	}, [sizeLimit, setValue]);

	return [warning,	handleInputChange];
};
