export const createCookie = (cookieName, cookieValue, hoursToExpire) => {
	const maxAge = (hoursToExpire * 60 * 60);
	document.cookie = `${cookieName}=${cookieValue}; max-age=${maxAge}`;
};

export const getCookie = (cookieName) => {
	const name = `${cookieName}=`;
	if (!document.cookie.split(';').some((item) => item.trim().startsWith(name))) return false;
	const cookieValue = document.cookie.split('; ').find((row) => row.startsWith(cookieName)).split('=')[1];
	return cookieValue;
};

export const deleteCookie = (cookieName) => {
	createCookie(cookieName, '', -1);
};
