import { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import clsx from 'clsx';
import { FaTimes } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

import { MediaUserPermissionStatus } from './UserAccessStatus';
import { isSafari } from '../../lib/userAgent';

const getModalInputsText = (userAudioPromptStatus, userVideoPromptStatus, status) => {
	let text = '';
	if (userVideoPromptStatus === status) text += 'camera';
	if (
		userVideoPromptStatus === status
		&& userAudioPromptStatus === status
	) text += ' and ';
	if (userAudioPromptStatus === status) text += 'microphone';
	return text;
};

export const MediaUserPermissionModal = ({
	userAudioPromptStatus,
	userVideoPromptStatus,
}) => {
	const { t } = useTranslation();
	const [isDeniedClosed, setIsDeniedClosed] = useState(false);
	const [isPromptClosed, setIsPromptClosed] = useState(false);

	const promptAudio = userAudioPromptStatus === MediaUserPermissionStatus.PROMPT;
	const promptVideo = userVideoPromptStatus === MediaUserPermissionStatus.PROMPT;
	const prompt = promptAudio || promptVideo;

	const deniedAudio = userAudioPromptStatus === MediaUserPermissionStatus.DENIED;
	const deniedVideo = userVideoPromptStatus === MediaUserPermissionStatus.DENIED;
	const denied = deniedAudio || deniedVideo;

	const promptOpen = prompt && !isPromptClosed;
	const deniedOpen = denied && !isDeniedClosed;

	return (
		<>
			<Modal
				centered
				contentClassName={clsx(
					'border-0 shadow-lg rounded overflow-hidden bg-transparent mx-auto mb-auto text-black',
					!isSafari && 'mt-auto',
				)}
				isOpen={promptOpen}
				toggle={() => setIsPromptClosed(true)}
				zIndex={9999}
			>
				<ModalHeader
					className="bg-primary border-0 pb-0"
					close={(
						<button className="close" onClick={() => setIsPromptClosed(true)} type="button">
							<FaTimes />
						</button>
					)}
				>
					<strong className="d-block mb-0 h4">{t('Media.UserPermissionModal.permissionNeeded')}</strong>
				</ModalHeader>
				<ModalBody className="bg-primary">
					<p className="mb-0">
						{t('Media.UserPermissionModal.beeyouNeedPermission')}
						{getModalInputsText(
							userAudioPromptStatus,
							userVideoPromptStatus,
							MediaUserPermissionStatus.PROMPT,
						)}.
						{t('Media.UserPermissionModal.clickAllow')}
					</p>
				</ModalBody>
			</Modal>
			<Modal
				centered
				contentClassName="border-0 shadow-lg rounded overflow-hidden bg-transparent m-auto text-black"
				isOpen={deniedOpen}
				toggle={() => setIsDeniedClosed(true)}
				zIndex={9999}
			>
				<ModalBody className="bg-danger text-white">
					<strong className="d-block h4 mb-2">{t('Media.UserPermissionModal.permissionDenied')}</strong>
					<p>
						{t('Media.UserPermissionModal.youDeniedPermission')}
						{getModalInputsText(
							userAudioPromptStatus,
							userVideoPromptStatus,
							MediaUserPermissionStatus.DENIED,
						)}.
						{t('Media.UserPermissionModal.ifNeeded')}<b>{t('Media.UserPermissionModal.browserSettings')}</b>.
					</p>
					<p className="d-flex mb-0">
						<Button
							className="ml-auto btn-pill"
							color="dark"
							onClick={() => setIsDeniedClosed(true)}
							size="sm"
						>
							{t('Media.UserPermissionModal.close')}
						</Button>
					</p>
				</ModalBody>
			</Modal>
		</>
	);
};

MediaUserPermissionModal.propTypes = {
	userAudioPromptStatus: PropTypes.oneOf(Object.keys(MediaUserPermissionStatus)),
	userVideoPromptStatus: PropTypes.oneOf(Object.keys(MediaUserPermissionStatus)),
};

MediaUserPermissionModal.defaultProps = {
	userAudioPromptStatus: MediaUserPermissionStatus.INITIAL,
	userVideoPromptStatus: MediaUserPermissionStatus.INITIAL,
};
