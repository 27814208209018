import { createContext, useContext } from 'react';

/**
 * @typedef {{
 * 	setForceDisplayControls: React.Dispatch<React.SetStateAction<boolean>>
 * 	showPlayerControls: boolean,
 * }} IPlayerControlsDisplayContext
 */

export const PlayerControlsDisplayContext = createContext(
/** @type {IPlayerControlsDisplayContext} */({}),
);

export const usePlayerControlsDisplay = () => useContext(PlayerControlsDisplayContext);
