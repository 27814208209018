// @ts-check
/* eslint-disable react/prop-types */

import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useMixing } from './Mixing';

/**
 * @typedef {{
 *  id: string,
 *  type: string,
 *  muted?: boolean,
 *  volume?: number,
 * }} ISource
 */

/**
 * @typedef {{
 *  id: string,
 *  type: string,
 *  properties: {
 *  	muted?: boolean,
 *  	volume?: number,
 *  }
 * }} SourceProperties
 */

/**
 * @typedef {{
 *  findSourcePropertiesBySource: (source: ISource) => SourceProperties | undefined,
 *  sourcesProperties: SourceProperties[],
 * 	updateSourceProperties: (source: ISource) => void,
 * 	updateSourcesProperties: (sources: ISource[]) => void,
 * }} ISourcesPropertiesContext
 */

const SourcesPropertiesContext = createContext(/** @type {ISourcesPropertiesContext} */({}));

export const useSourcesProperties = () => useContext(SourcesPropertiesContext);

/**
 * @param {ISource} source
 * @returns {SourceProperties | undefined}
 */
export const useSourceProperties = (source) => (
	useSourcesProperties().findSourcePropertiesBySource(source)
);

/**
 *
 * @param {ISource} source
 * @returns {SourceProperties}
 */
const getSourceProperties = (source) => {
	/** @type {SourceProperties} */
	const sourceProperties = {
		id: source.id,
		type: source.type,
		properties: {},
	};

	if (source.muted !== undefined) {
		sourceProperties.properties.muted = source.muted;
	}

	if (source.volume !== undefined) {
		sourceProperties.properties.volume = source.volume;
	}

	return sourceProperties;
};

/**
 * @param {ISource[]} sources
 * @returns {SourceProperties[]}
 */
const getSourcesProperties = (sources = []) => sources.map(getSourceProperties);

/**
 * @param {ISource} source1
 * @param {ISource} source2
 * @returns {boolean}
 */
const isSameSource = (source1, source2) => (
	source1.id === source2.id
	&& source1.type === source2.type
);

/**
 * @typedef {{
 *  children: import('react').ReactNode
 * }} SourcesPropertiesProviderProps
 */

export const SourcesPropertiesProvider = (
	/** @type {SourcesPropertiesProviderProps} */
	{ children },
) => {
	const { channelMixerStatus } = useMixing();

	const [sourcesProperties, setSourceProperties] = useState(
		/** @returns {SourceProperties[]} */
		() => getSourcesProperties(channelMixerStatus?.sources),
	);

	const findSourcePropertiesBySource = useCallback(
		/**
		 * @param {ISource} source
		 * @returns {SourceProperties | undefined}
		 */
		(source) => (
			source
				? sourcesProperties.find((sourceProperties) => isSameSource(sourceProperties, source))
				: undefined
		),
		[sourcesProperties],
	);

	const updateSourcesProperties = useCallback(
		/**
		 * @param {ISource[]} newSourcesProperties
		 * @returns {void}
		 */
		(newSourcesProperties) => {
			setSourceProperties((currentSourcesProperties) => {
				let updatedSourcesProperties = currentSourcesProperties;

				newSourcesProperties.forEach((newSourceProperties) => {
					const currentSourcePropertiesIndex = updatedSourcesProperties.findIndex(
						(sourceProperties) => isSameSource(sourceProperties, newSourceProperties),
					);

					if (currentSourcePropertiesIndex < 0) {
						updatedSourcesProperties = [
							...updatedSourcesProperties,
							getSourceProperties(newSourceProperties),
						];
						return;
					}

					const currentSourceProperties = updatedSourcesProperties[currentSourcePropertiesIndex];

					updatedSourcesProperties = [
						...updatedSourcesProperties.slice(0, currentSourcePropertiesIndex),
						{
							...currentSourceProperties,
							properties: {
								...currentSourceProperties.properties,
								...getSourceProperties(newSourceProperties).properties,
							},
						},
						...updatedSourcesProperties.slice(currentSourcePropertiesIndex + 1),
					];
				});

				return updatedSourcesProperties;
			});
		},
		[],
	);

	const updateSourceProperties = useCallback(
		/**
		 * @param {ISource} source
		 * @returns {void}
		 */
		(source) => updateSourcesProperties([source]),
		[updateSourcesProperties],
	);

	const currentSources = channelMixerStatus?.sources;

	useEffect(() => {
		if (!currentSources) return;
		updateSourcesProperties(currentSources);
	}, [currentSources, updateSourcesProperties]);

	const contextValue = useMemo(() => ({
		findSourcePropertiesBySource,
		sourcesProperties,
		updateSourceProperties,
		updateSourcesProperties,
	}), [
		findSourcePropertiesBySource,
		sourcesProperties,
		updateSourceProperties,
		updateSourcesProperties,
	]);

	return (
		<SourcesPropertiesContext.Provider value={contextValue}>
			{children}
		</SourcesPropertiesContext.Provider>
	);
};
