const categoryOptions = [
	'technology',
	'education',
	'gaming',
	'sports',
	'nature',
	'media',
	'fashion',
	'entertainment',
	'lifestyle',
	'music',
];

export const OPTIONS_CATEGORIES = {
	TECHNOLOGY: categoryOptions[0],
	EDUCATION: categoryOptions[1],
	GAMING: categoryOptions[2],
	SPORTS: categoryOptions[3],
	NATURE: categoryOptions[4],
	MEDIA: categoryOptions[5],
	FASHION: categoryOptions[6],
	ENTERTAINMENT: categoryOptions[7],
	LIFESTYLE: categoryOptions[8],
	MUSIC: categoryOptions[9],
};

export default categoryOptions;
