import { api } from '../api';

export const API_ANALYTICS_ENGAGEMENT_PATH = '/analytics/engagement';

export const fetchEngagementViewsAnalytics = (channelId, dates, range) => api.get(
	`${API_ANALYTICS_ENGAGEMENT_PATH}/${channelId}/views`,
	{
		params: {
			startDate: dates.startDate,
			endDate: dates.endDate,
			range,
		},
	},
);

export const fetchEngagementLikesAnalytics = (channelId, dates, range) => api.get(
	`${API_ANALYTICS_ENGAGEMENT_PATH}/${channelId}/likes`,
	{
		params: {
			startDate: dates.startDate,
			endDate: dates.endDate,
			range,
		},
	},
);

export const fetchEngagementDurationAnalytics = (channelId, dates, range) => api.get(
	`${API_ANALYTICS_ENGAGEMENT_PATH}/${channelId}/duration`,
	{
		params: {
			startDate: dates.startDate,
			endDate: dates.endDate,
			range,
		},
	},
);

export const fetchEngagementLikesTotalsAnalytics = (
	channelId,
	dates,
) => api.get(
	`${API_ANALYTICS_ENGAGEMENT_PATH}/${channelId}/likes/totals`,
	{
		params: {
			startDate: dates.startDate,
			endDate: dates.endDate,
		},
	},
);

export const fetchEngagementCommentsTotalsAnalytics = (
	channelId,
	dates,
) => api.get(
	`${API_ANALYTICS_ENGAGEMENT_PATH}/${channelId}/comments/totals`,
	{
		params: {
			startDate: dates.startDate,
			endDate: dates.endDate,
		},
	},
);

export const fetchEngagementSharesTotalsAnalytics = (
	channelId,
	dates,
) => api.get(
	`${API_ANALYTICS_ENGAGEMENT_PATH}/${channelId}/shares/totals`,
	{
		params: {
			startDate: dates.startDate,
			endDate: dates.endDate,
		},
	},
);

export const fetchEngagementVideosOverview = (channelId) => api.get(
	`${API_ANALYTICS_ENGAGEMENT_PATH}/${channelId}/videos/overview`,
);

export const fetchEngagementStudiosOverview = (channelId) => api.get(
	`${API_ANALYTICS_ENGAGEMENT_PATH}/${channelId}/studios/overview`,
);

export const fetchEngagementVideoDetails = (videoId) => api.get(
	`${API_ANALYTICS_ENGAGEMENT_PATH}/videos/${videoId}/details`,
);
