import { useMutation, useQueryClient } from 'react-query';

import { axiosMutationWrapper } from '../utils/axios-wrapper';
import * as credentialsApi from '../../api/identity/credentials';
import { PROFILE_QUERY_KEYS } from '../profile/me';

export const useUpdateCredentials = () => {
	const queryClient = useQueryClient();

	return useMutation(
		axiosMutationWrapper(credentialsApi.updateCredentials),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(PROFILE_QUERY_KEYS.fetch());
			},
		},
	);
};
