// @ts-check

import { createContext, useContext } from 'react';

/**
 * @typedef {{
 * handleLiveStopped: () => void,
 * isLiveAboutToStart: boolean,
 * isLiveRecordingStarted: boolean,
 * isLiveActive: boolean,
 * isLiveAlmostTerminated: boolean,
 * isLiveEnabled: boolean,
 * isLivePlanned: boolean,
 * isLiveStopped: boolean,
 * isStudioCancelled: boolean,
 * isStudioPending: boolean,
 * isStudioPlanned: boolean,
 * isStudioPublic: boolean,
 * isStudioRunning: boolean,
 * isStudioStopped: boolean,
* }} IStudioStatusContext
*/

export const StudioStatusContext = createContext(
	/** @type {IStudioStatusContext | undefined} */(undefined),
);

export const useStudioStatus = () => {
	const studioStatusContext = useContext(StudioStatusContext);
	// type guard (removes undefined type)
	if (!studioStatusContext) {
		throw new Error('useStudioStatus must be used within a StudioStatusProvider');
	}
	return studioStatusContext;
};
