// @ts-check

import { createContext, useContext } from 'react';

/**
 * @typedef {{
* 	duration: number,
* 	playedAt: Date,
* 	studio: string,
* }} AdInfo
*/

/**
 * @typedef {{
 * 		adDuration: number,
 * 		adInfo: AdInfo | {},
 * 		currentAdsTimeElapsed: {},
 * 		estimatedCurrentAdReward: number,
 * 		handleAdEnded: () => void,
 * 		handleEventAdLaunch: ({
 * 			adInfo,
 * 		}: {
 * 			adInfo: AdInfo,
 * 		}) => void,
 * 		isAdActive: boolean,
 * 		isAdPlaying: boolean,
 * 		setChipsEarnedBySeconds: (chips: number) => void,
 * 		setCurrentAdsTimeElapsed: (timeElapsed: {}) => void,
 * 		setIsAdPlaying: (isPlaying: boolean) => void,
* }} IAdContext
*/

export const AdContext = createContext(/** @type {IAdContext} */({}));

export const useAd = () => {
	const adContext = useContext(AdContext);
	// type guard (removes undefined type)
	if (!adContext) {
		throw new Error('useAd must be used within a AdProvider');
	}
	return adContext;
};
