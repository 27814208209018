import PropTypes from 'prop-types';
import { NotificationProvider } from '../Notification/Provider';
import { GlobalSocketConnector } from './Connector';
import { StatusOverridesProvider } from './StatusOverridesProvider';
import { StreamSocketProvider } from '../Stream/SocketProvider';

export const GlobalSocketWrapper = ({ children }) => (
	<NotificationProvider>
		<StatusOverridesProvider>
			<StreamSocketProvider>
				<GlobalSocketConnector />
				{children}
			</StreamSocketProvider>
		</StatusOverridesProvider>
	</NotificationProvider>
);

GlobalSocketWrapper.propTypes = {
	children: PropTypes.node.isRequired,
};
