export const Currency = {
	PI_POINTS: 'PI_POINTS',
	CHIPS_AND_PI_POINTS: 'CHIPS_AND_PI_POINTS',
	CHIPS: 'CHIPS',
	AD_CREDIT: 'AD_CREDIT',
};

export const FiatCurrency = {
	USD: 'USD',
	EUR: 'EUR',
	BRL: 'BRL',
};

export const FiatCurrencyLabel = {
	[FiatCurrency.USD]: 'US Dollars',
	[FiatCurrency.EUR]: 'Euro',
	[FiatCurrency.BRL]: 'Brazilian Real',
};

export const FiatCurrencyWithPiPoints = {
	...FiatCurrency,
	PI_POINTS: 'PI_POINTS',
};

export const FiatCurrencyWithPiPointsLabel = {
	...FiatCurrencyLabel,
	[FiatCurrencyWithPiPoints.PI_POINTS]: 'Pi points',
};
