import { ButtonLoading, ButtonPill } from '../Button';

const FormSubmit = (props) => (
	<ButtonLoading
		color="primary"
		component={ButtonPill}
		type="submit"
		{...props}
	/>
);

export default FormSubmit;
