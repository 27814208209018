// @ts-check

import { useQueryClient, useMutation, useQuery } from 'react-query';
import { axiosQueryWrapper } from '../utils/axios-wrapper';
import * as goalsApi from '../../api/channel/goals';

export const GOALS_QUERY_KEYS = {
	fetchGoalsByChannelId: (
		/** @type {string | undefined} */ channelId,
	) => ['api', 'channel', 'goals', channelId],
	fetchOneGoalById: (
		/** @type {string | undefined} */ goalId,
	) => ['api', 'channel', 'goals', goalId],
};

/**
 * @param {string} [channelId]
 */
export const useFetchGoalsByChannelId = (channelId) => useQuery(
	GOALS_QUERY_KEYS.fetchGoalsByChannelId(channelId),
	axiosQueryWrapper(goalsApi.fetchGoalsByChannelId, channelId || ''),
	{ enabled: !!channelId },
);

/**
 * @param {string} [goalId]
 */
export const useFetchOneGoalById = (goalId) => useQuery(
	GOALS_QUERY_KEYS.fetchOneGoalById(goalId),
	axiosQueryWrapper(goalsApi.fetchOneGoalById, goalId || ''),
	{ enabled: !!goalId },
);

export const useCreateGoal = () => {
	const queryClient = useQueryClient();

	return useMutation(
		goalsApi.createGoal,
		{
			onSuccess: (data) => {
				queryClient.invalidateQueries(GOALS_QUERY_KEYS.fetchGoalsByChannelId(data.data.owner));
			},
		},
	);
};

export const useDeleteGoal = () => {
	const queryClient = useQueryClient();

	return useMutation(
		goalsApi.deleteOneGoalById,
		{
			onSuccess: (data) => {
				queryClient.invalidateQueries(GOALS_QUERY_KEYS.fetchGoalsByChannelId(data.data.owner));
			},
		},
	);
};
