import Bowser from 'bowser';

export const browser = Bowser.getParser(window.navigator.userAgent);
export const isDesktop = browser.is('desktop');
export const isMobile = !isDesktop;
export const isSafari = browser.is('safari');
export const isMacOS = browser.is('macOS');
export const isIOS = browser.is('iOS');
export const isApple = isMacOS || isIOS;
export const isAndroid = browser.is('Android');
export const isChromeOS = browser.getOSName() === 'Chrome OS';
export const isIpad = /Macintosh/i.test(window.navigator.userAgent) && window.navigator.maxTouchPoints && window.navigator.maxTouchPoints > 1;
export const isMobileOrIpad = isMobile || isIpad;
