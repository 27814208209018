// @ts-check

import { api } from '../api';

const API_MODERATION_BAN_PATH = '/moderation/ban';

export const fetchManyByChannelId = async (channelId) => api.get(
	`${API_MODERATION_BAN_PATH}/byChannelId/${channelId}`,
);

/**
 * @param {string} userId
 * @param {string} role
 * @returns {Promise<import('axios').AxiosResponse<boolean>>}
 */
export const fetchBanStatus = async (userId, role) => api.get(
	`${API_MODERATION_BAN_PATH}/banStatus/${userId}`,
	{ params: { role } },
);

/**
 *
 * @param {string} studioId
 * @param {string} userId
 * @param {string} until
 * @param {string} role
 * @returns {Promise<import('axios').AxiosResponse<void>>}
 */
export const banUserFromStudio = async (studioId, userId, until, role) => api.post(
	`${API_MODERATION_BAN_PATH}/studio/${studioId}`,
	{
		until,
		userId,
		role,
	},
);

/**
 * @param {string} studioId
 * @param {string} userId
 * @param {string} role
 * @returns {Promise<import('axios').AxiosResponse<void>>}
 */
export const unbanUser = async (studioId, userId, role) => api.delete(
	`${API_MODERATION_BAN_PATH}/studio/${studioId}`,
	{
		data: {
			userId,
			role,
		},
	},
);
