// @ts-check

import { useQuery, useQueryClient, useMutation } from 'react-query';

import { useAuthentication } from '../../components/Authentication/Authentication';
import * as simulCastApi from '../../api/channel/simulCast';
import { axiosQueryWrapper } from '../utils/axios-wrapper';

const SIMULCAST_QUERY_KEYS = {
	fetchSimulCasts: (
		/** @type {string | undefined} */ channelId,
	) => ['api', 'channel', 'simulCast', 'fetch', channelId],
	fetchSimulCastsPaginated: (
		/** @type {string | undefined} */ channelId,
		/** @type {string | undefined} */ mode,
		/** @type {number | undefined} */ itemsPerPage,
		/** @type {number | undefined} */ currentPage,
	) => ['api', 'channel', 'simulCast', 'fetch', channelId, mode, { itemsPerPage, currentPage }],
};

/**
 * @param {{
 *  channelId?: string,
 *  mode?: string,
 * 	itemsPerPage?: number,
 * 	currentPage?: number,
 * }} param0
 */
export const useFetchSimulCasts = ({ channelId, mode, itemsPerPage, currentPage }) => useQuery(
	SIMULCAST_QUERY_KEYS.fetchSimulCastsPaginated(channelId, mode, itemsPerPage, currentPage),
	axiosQueryWrapper(simulCastApi.fetchSimulCasts, channelId, mode, itemsPerPage, currentPage),
);

/**
 * @param {string} [channelId]
 */
export const useCreateSimulCast = (channelId) => {
	const queryClient = useQueryClient();
	const { user } = useAuthentication();

	return useMutation(
		simulCastApi.createSimulCast,
		{
			onSuccess: () => {
				queryClient.invalidateQueries(SIMULCAST_QUERY_KEYS.fetchSimulCasts(channelId));
				// Also invalidate the simulcasts of the user (if it's not the same as the channel)
				// This is to ensure the simulcasts list in studio scheduler is up to date
				if (user?.sub !== channelId) {
					queryClient.invalidateQueries(SIMULCAST_QUERY_KEYS.fetchSimulCasts(user?.sub));
				}
			},
		},
	);
};

/**
 * @param {string} [channelId]
 */
export const useUpdateSimulCast = (channelId) => {
	const queryClient = useQueryClient();
	const { user } = useAuthentication();

	return useMutation(
		simulCastApi.updateSimulCast,
		{
			onSuccess: () => {
				queryClient.invalidateQueries(SIMULCAST_QUERY_KEYS.fetchSimulCasts(channelId));
				// Also invalidate the simulcasts of the user (if it's not the same as the channel)
				// This is to ensure the simulcasts list in studio scheduler is up to date
				if (user?.sub !== channelId) {
					queryClient.invalidateQueries(SIMULCAST_QUERY_KEYS.fetchSimulCasts(user?.sub));
				}
			},
		},
	);
};

/**
 * @param {string} [channelId]
 */
export const useDeleteSimulCast = (channelId) => {
	const queryClient = useQueryClient();
	const { user } = useAuthentication();

	return useMutation(
		simulCastApi.deleteSimulCast,
		{
			onSuccess: () => {
				queryClient.invalidateQueries(SIMULCAST_QUERY_KEYS.fetchSimulCasts(channelId));
				// Also invalidate the simulcasts of the user (if it's not the same as the channel)
				// This is to ensure the simulcasts list in studio scheduler is up to date
				if (user?.sub !== channelId) {
					queryClient.invalidateQueries(SIMULCAST_QUERY_KEYS.fetchSimulCasts(user?.sub));
				}
			},
		},
	);
};
