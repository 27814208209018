// @ts-check
import { api } from '../api';

export const API_PUBLIC_CHANNEL_PATH = '/public/channel';

/** @typedef {import('./channel.dto').IChannelPublicDto} IChannelPublicDto */

/**
 * @param {string} hashtag
 * @returns {Promise<import('axios').AxiosResponse<IChannelPublicDto>>}
 */
export const fetchOneChannelByHashtag = (hashtag) => api.get(`${API_PUBLIC_CHANNEL_PATH}/byhashtag/${hashtag}`);
