// @ts-check

import { api } from '../api';

export const API_PUBLIC_GUEST_PATH = '/public/guest';

/**
 * @param {import('./guest.dto').IGuestUpdateDto} guest
 * @returns {Promise<import('axios').AxiosResponse<import('./guest.dto').IGuestPublicDto>>}
 */
export const updateGuestMe = (guest) => api.put(
	`${API_PUBLIC_GUEST_PATH}/me`,
	guest,
);
