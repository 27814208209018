import { api } from './api';

const API_VERIFICATION_PATH = '/verification';

export const verificationBegin = async ({
	email,
	phoneNumber,
	userLanguage,
}) => api.post(
	`${API_VERIFICATION_PATH}/begin`,
	{ email, phoneNumber, userLanguage },
);

export const verificationConfirm = async ({ verification_token, code }) => api.post(
	`${API_VERIFICATION_PATH}/confirm`,
	{ verification_token, code },
);

export const verificationResend = async (verificationToken, userLanguage) => api.post(
	`${API_VERIFICATION_PATH}/resend`,
	{
		verification_token: verificationToken,
		userLanguage,
	},
);
