import { api } from './api';

export const API_CONVERSATION_PATH = '/conversation';

export const fetchConversations = (lastTimestamp, tab = 'all') => api.get(
	API_CONVERSATION_PATH,
	{ params: { lastTimestamp, tab } },
);

export const fetchGroupConversationsWithUser = (userId) => api.get(
	`${API_CONVERSATION_PATH}/group/user/${userId}`,
);

export const createConversation = ({ users, name, groupId, target }) => api.post(
	API_CONVERSATION_PATH,
	{ users, name, groupId, target },
);

export const deleteConversation = (conversationId) => api.delete(
	`${API_CONVERSATION_PATH}/${conversationId}`,
);

export const hideConversation = (conversationId) => api.delete(
	`${API_CONVERSATION_PATH}/${conversationId}/hide`,
);

export const fetchConversationMessages = (conversationId, lastTimestamp) => api.get(
	`${API_CONVERSATION_PATH}/${conversationId}/messages`,
	{ params: { lastTimestamp } },
);

export const sendMessage = ({ conversationId, text, images, videos }) => api.post(
	`${API_CONVERSATION_PATH}/${conversationId}/messages`,
	{ text, images, videos },
);

export const updateMessage = ({
	conversationId, messageId, text, images, videos,
}) => api.put(
	`${API_CONVERSATION_PATH}/${conversationId}/messages/${messageId}`,
	{ text, images, videos },
);

export const sendMessageToManyUsers = ({
	text, images, videos, target }) => api.post(
	`${API_CONVERSATION_PATH}/messages/followers`,
	{ text, images, videos, target },
);

export const deleteMessage = ({ conversationId, messageId }) => api.delete(
	`${API_CONVERSATION_PATH}/${conversationId}/messages/${messageId}`,
);

export const markConversationAsRead = (conversationId) => api.put(
	`${API_CONVERSATION_PATH}/${conversationId}/mark_as_read`,
);
