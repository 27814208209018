import PropTypes from 'prop-types';
import {
	Row,
	Col,
	Container,
	Card,
	CardBody,
	CardText,
	CardTitle,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { OrderBundleType } from '../Checkout/Paypal/Order/Button';

const BuyPointsSuccess = ({ amount, orderBundleType }) => {
	const { t } = useTranslation();

	return (
		<Container className="mt-5 mb-5">
			<Row>
				<Col xl={{ size: 6, offset: 3 }} md={{ size: 8, offset: 2 }}>
					<Card>
						<CardBody className="text-black">
							<>
								<CardTitle>{t('BuyPoints.BuyPointsSuccess.congratulations')}</CardTitle>
								<CardText>
									{t(orderBundleType !== OrderBundleType.DONATION
										? 'BuyPoints.BuyPointsSuccess.success'
										: 'BuyPoints.BuyPointsSuccess.successDonation')}{' '}
									{amount}{' '}
									{t(
										orderBundleType === OrderBundleType.AD_CREDITS
											? 'BuyPoints.BuyPointsSuccess.adCredits'
											: 'BuyPoints.BuyPointsSuccess.points',
									)}
								</CardText>
								{orderBundleType !== OrderBundleType.DONATION && (
									<CardText>{t('BuyPoints.BuyPointsSuccess.willBeAddedToBalance')}</CardText>
								)}
							</>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

BuyPointsSuccess.propTypes = {
	amount: PropTypes.number.isRequired,
	orderBundleType: PropTypes.string,
};

BuyPointsSuccess.defaultProps = {
	orderBundleType: OrderBundleType.PI_POINTS,
};

export default BuyPointsSuccess;
