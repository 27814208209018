// @ts-check

import { createSelector } from 'reselect';
import { STREAM_TYPE_LIVE, STREAM_TYPE_PREVIEW } from '../../lib/stream';
import { isTalkbackSourceId } from '../lib/helpers';

export const STATE_KEY = 'publications';

/** @import { RootState } from '..'; */
/** @import { Publication } from '../../lib/store/publication'; */

/**
 * selectors
 * @param {RootState} state
 */
export const select = (state) => state[STATE_KEY];

export const selectIsPublicationStored = createSelector(
	select,
	/**
	 * @param {any} _
	 * @param {Publication} publication
	 * @returns {Publication}
	 */
	(_, publication) => publication,
	(publicationsStreams = [], publication) => publicationsStreams
		.find(({ publications }) => (
			!!publications.find(({ producerId }) => publication.producerId === producerId)
		)),
);

export const selectLiveStreamPublication = createSelector(
	select,
	(publicationsStreams = []) => publicationsStreams
		.find(({ streamType }) => streamType === STREAM_TYPE_LIVE),
);

export const selectParticipantStreamPublications = createSelector(
	select,
	(publicationsStreams = []) => publicationsStreams
		.filter(({ id, streamType }) => streamType === STREAM_TYPE_PREVIEW
			&& !isTalkbackSourceId(id)),
);

export const selectTalkbackStreamsPublications = createSelector(
	select,
	(publicationsStreams = []) => publicationsStreams
		.filter(({ id }) => isTalkbackSourceId(id)),
);
