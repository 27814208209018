import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ButtonPill } from '../../Button';
import { ChannelProvider } from '../Provider';
import { useLocalizedTimeAgo } from '../../../lib/timeAgo';
import { getLink, Path } from '../../../RoutePath';

const ModerationBannedLayout = ({
	children,
}) => (
	<div className="bg-danger d-flex h-100 py-4 align-items-center justify-content-center">
		<Col md="6" lg="5" xl="4">
			{children}
		</Col>
	</div>
);

ModerationBannedLayout.propTypes = {
	children: PropTypes.node.isRequired,
};

export const ModerationBannedContent = ({
	onBanEnded,
	until,
}) => {
	const { t } = useTranslation();
	const timeAgo = useLocalizedTimeAgo();
	const forever = !until;
	const [remainingTime, setRemainingTime] = useState('');

	useEffect(() => {
		if (forever) return undefined;

		let timeout;

		const untilDate = until && new Date(until);
		const diff = untilDate.getTime() - Date.now();

		const banEndedTimeout = setTimeout(() => {
			onBanEnded();
		}, diff);

		const loop = () => {
			setRemainingTime(timeAgo.format(untilDate, 'round'));
			timeout = setTimeout(loop, 1000);
		};

		loop();

		return () => {
			clearTimeout(banEndedTimeout);
			clearTimeout(timeout);
		};
	}, [forever, onBanEnded, timeAgo, until]);

	if (forever) {
		return (
			<ModerationBannedLayout>
				<div className="text-center d-flex flex-column align-items-center">
					<h2>{t('Moderation.Banned.bannedMessage')}</h2>
					<ButtonPill
						color="white"
						tag={Link}
						to={getLink(Path.ROOT)}
					>
						{t('Moderation.Banned.backToHome')}
					</ButtonPill>
				</div>
			</ModerationBannedLayout>
		);
	}

	return (
		<ModerationBannedLayout>
			<div className="text-center d-flex flex-column align-items-center">
				<h2>{t('Moderation.Banned.breakoutRoom')}</h2>
				<p>{t('Moderation.Banned.youWillBeBack')}</p>
				<p className="px-4 py-2 rounded bg-white text-danger">
					<strong>
						{remainingTime}
					</strong>
				</p>
				<p>{t('Moderation.Banned.behave')}</p>
			</div>
		</ModerationBannedLayout>
	);
};

ModerationBannedContent.propTypes = {
	onBanEnded: PropTypes.func.isRequired,
	until: PropTypes.string,
};

ModerationBannedContent.defaultProps = {
	until: undefined,
};

export const ModerationBanned = ({
	channel,
	onBanEnded,
	until,
}) => (
	<ChannelProvider channel={channel}>
		<ModerationBannedContent
			onBanEnded={onBanEnded}
			until={until}
		/>
	</ChannelProvider>
);

ModerationBanned.propTypes = {
	channel: PropTypes.shape({
		hashtag: PropTypes.string.isRequired,
	}).isRequired,
	// eslint-disable-next-line react/require-default-props
	onBanEnded: ModerationBannedContent.propTypes.onBanEnded,
	// eslint-disable-next-line react/require-default-props
	until: ModerationBannedContent.propTypes.until,
};
