// @ts-check

import { createContext, useContext } from 'react';

/**
 * @typedef {{
 * isSearchOpen: boolean,
 * setSearchOpen: (isOpen: boolean) => void,
* }} IHeaderSearchContext
*/

export const HeaderSearchContext = createContext(
	/** @type {IHeaderSearchContext | undefined} */(undefined),
);

export const useHeaderSearch = () => {
	const headerSearchContext = useContext(HeaderSearchContext);
	// type guard (removes undefined type)
	if (!headerSearchContext) {
		throw new Error('useHeaderSearch must be used within a HeaderSearchProvider');
	}
	return headerSearchContext;
};
