import { useMutation, useQuery, useQueryClient } from 'react-query';
import * as slackApi from '../../api/slack/slack';
import { axiosMutationWrapper, axiosQueryWrapper } from '../utils/axios-wrapper';

export const SLACK_TOKEN_LENGTH = 57;

export const SLACK_QUERY_KEYS = {
	fetchSlackChannels: () => ['api', 'slack', 'channels', 'fetch'],
	fetchSlackChannel: (slackChannelId) => [
		'api', 'slack', 'channels', 'fetch', slackChannelId,
	],
	fetchSlackOAuthUrl: () => ['api', 'slack', 'oauthUrl'],
};

export const useFetchSlackChannels = () => useQuery(
	SLACK_QUERY_KEYS.fetchSlackChannels(),
	axiosQueryWrapper(slackApi.fetchSlackChannels),
);

export const useFetchSlackChannel = (slackChannelId) => useQuery(
	SLACK_QUERY_KEYS.fetchSlackChannel(slackChannelId),
	axiosQueryWrapper(slackApi.fetchSlackChannel, slackChannelId),
	{ staleTime: 0, enabled: !!slackChannelId },
);

export const useFetchSlackOAuthUrl = () => useQuery(
	SLACK_QUERY_KEYS.fetchSlackOAuthUrl(),
	axiosQueryWrapper(slackApi.fetchSlackOAuthUrl),
);

export const useImportSlackChannel = () => useMutation(
	axiosMutationWrapper(slackApi.importSlackChannel),
);

export const useConnectSlackChannel = () => useMutation(
	axiosMutationWrapper(slackApi.connectSlackChannel),
);

export const useDeleteSlackChannel = () => {
	const queryClient = useQueryClient();

	return useMutation(
		axiosMutationWrapper(slackApi.deleteSlackChannel),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(SLACK_QUERY_KEYS.fetchSlackChannels);
			},
		},
	);
};
