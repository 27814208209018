import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useMediaKeyPerformer } from './useMediaKeyPerformer';
import Preview from '../../Preview/Preview';
import { KeyDetectionMode } from './KeyConfig';

export const MediaKeyPreviewOutput = ({
	className,
	config,
	videoTrack,
}) => {
	const { t } = useTranslation();
	const [previewMediastream] = useState(() => new MediaStream());

	const { keyVideoTrack } = useMediaKeyPerformer({
		config,
		videoSourceTrack: videoTrack,
		options: {
			fps: 12,
			outputWidth: 160,
			outputHeight: 90,
		},
	});

	const isKeyEnabled = config?.detection?.mode
		&& config.detection.mode !== KeyDetectionMode.DISABLED;

	useEffect(() => {
		if (keyVideoTrack) {
			previewMediastream.addTrack(keyVideoTrack);
			return () => {
				previewMediastream.removeTrack(keyVideoTrack);
			};
		}
		return undefined;
	}, [previewMediastream, keyVideoTrack]);

	return (
		<div className="w-100 d-flex flex-shrink-1">
			{keyVideoTrack ? (
				<Preview
					autoPlay
					className={`MediaKeySourcePreview w-100 ${className}`}
					muted
					src={previewMediastream}
				/>
			) : (
				<span className="d-flex mx-auto my-auto">
					{t('Media.MediaKeyPreview.Status', { context: isKeyEnabled ? 'loading' : 'disabled' })}
				</span>
			)}
		</div>
	);
};

MediaKeyPreviewOutput.propTypes = {
	className: PropTypes.string,
	config: PropTypes.shape({
		detection: PropTypes.shape({
			mode: PropTypes.oneOf(Object.values(KeyDetectionMode)),
		}),
	}),
	videoTrack: PropTypes.instanceOf(MediaStreamTrack),
};

MediaKeyPreviewOutput.defaultProps = {
	className: '',
	config: undefined,
	videoTrack: undefined,
};

export const MediaKeyPreviewSource = ({
	className,
	eyeDropperEnabled,
	onPickColor,
	videoTrack,
}) => {
	const [previewMediastream] = useState(() => new MediaStream());

	useEffect(() => {
		if (videoTrack) {
			previewMediastream.addTrack(videoTrack);
			return () => {
				previewMediastream.removeTrack(videoTrack);
			};
		}
		return undefined;
	}, [previewMediastream, videoTrack]);

	return (
		<div
			className="w-100 d-flex flex-shrink-1"
			style={{
				boxShadow: eyeDropperEnabled ? '0 0 0 3px #FEC82E' : 'none',
			}}
		>
			<Preview
				allowColorPicking={eyeDropperEnabled}
				autoPlay
				className={`MediaKeySourcePreview w-100 ${className}`}
				muted
				onPickColor={onPickColor}
				src={previewMediastream}
			/>
		</div>
	);
};

MediaKeyPreviewSource.propTypes = {
	className: PropTypes.string,
	eyeDropperEnabled: PropTypes.bool,
	onPickColor: PropTypes.func,
	videoTrack: PropTypes.instanceOf(MediaStreamTrack),
};

MediaKeyPreviewSource.defaultProps = {
	className: '',
	eyeDropperEnabled: false,
	onPickColor: undefined,
	videoTrack: undefined,
};

// export const MediaKeyPreviewDouble = ({
// 	config,
// 	eyeDropperEnabled,
// 	onPickColor,
// 	videoTrack,
// }) => (
// 	<div className="d-flex">
// 		{videoTrack && (
// 			<>
// 				<MediaKeyPreviewSource
// 					className="w-50 mr-1"
// 					eyeDropperEnabled={eyeDropperEnabled}
// 					onPickColor={onPickColor}
// 					videoTrack={videoTrack}
// 				/>
// 				<MediaKeyPreviewOutput
// 					className="w-50 ml-1"
// 					config={config}
// 					videoTrack={videoTrack}
// 				/>
// 			</>
// 		)}
// 	</div>
// );

// MediaKeyPreviewDouble.propTypes = {
// 	config: PropTypes.shape({}),
// 	eyeDropperEnabled: PropTypes.bool,
// 	onPickColor: PropTypes.func,
// 	videoTrack: PropTypes.instanceOf(MediaStreamTrack),
// };

// MediaKeyPreviewDouble.defaultProps = {
// 	config: undefined,
// 	eyeDropperEnabled: false,
// 	onPickColor: undefined,
// 	videoTrack: undefined,
// };

// MediaKeyPreviewDouble.propTypes = {

// };
