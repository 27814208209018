import { getConnect, getDisconnect, getSocket } from '@technomiam/wsapi-client';

const { VITE_WSAPI_URL } = import.meta.env;
const PUBLIC_URL = `${VITE_WSAPI_URL}/public`;

export const connectSockets = getConnect(PUBLIC_URL);
export const disconnectSockets = getDisconnect(PUBLIC_URL);
export const socket = getSocket(PUBLIC_URL);

export const onEventLiveNotification = (listener) => {
	const sock = socket();
	sock.on('event.live.notification', listener);
	return () => {
		sock.off('event.live.notification', listener);
	};
};
