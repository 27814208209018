import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import * as chatApi from '../../../api/channel/chat';
import * as publicChatApi from '../../../api/public/chat';
import { ResourceAccessLevel } from '../../../lib/ResourceAccessLevel';

const ChatContext = createContext({});

export const useChat = () => useContext(ChatContext);

export const ChatStatus = {
	ENABLED: 'ENABLED',
	ENABLED_PARTICIPANT: 'ENABLED_PARTICIPANT',
	ENABLED_OPERATOR: 'ENABLED_OPERATOR',
	DISABLED: 'DISABLED',
};

export const ChatProvider = ({ studioId, children }) => {
	const [chatMessages, setChatMessages] = useState([]);
	const [chatStatus, setChatStatus] = useState(ChatStatus.ENABLED);
	const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);

	const isChatEnabled = useCallback((role) => {
		const statusConfig = {
			[ChatStatus.ENABLED]:
				true,
			[ChatStatus.ENABLED_PARTICIPANT]:
				ResourceAccessLevel[role] >= ResourceAccessLevel.PARTICIPANT,
			[ChatStatus.ENABLED_OPERATOR]:
				ResourceAccessLevel[role] >= ResourceAccessLevel.OPERATOR,
			[ChatStatus.DISABLED]:
				false,
		};
		return statusConfig[chatStatus];
	}, [chatStatus]);

	const fetchChatMessages = useCallback(async () => {
		const { data } = await publicChatApi.fetchPublicChatMessages(studioId);

		if (data) {
			setChatMessages(data.messages);
			setChatStatus(data.chatStatus);
		}
	}, [studioId]);

	const sendChatMessage = useCallback(async (role, text, images, videos, isGuest = false) => {
		if (isGuest) {
			await publicChatApi.sendPublicMessage(studioId, role, text);
		} else {
			await chatApi.sendMessage(studioId, role, text, images, videos);
		}
	}, [studioId]);

	const updateChatMessage = useCallback(async (id, text, images, videos, isGuest = false) => {
		if (isGuest) {
			await publicChatApi.updatePublicChatMessage(id, text);
		} else {
			await chatApi.updateChatMessage(id, text, images, videos);
		}
	}, []);

	const deleteChatMessage = useCallback(async (id, isGuest = false) => {
		if (isGuest) {
			await publicChatApi.deleteGuestMessage(id, isGuest);
		} else {
			await chatApi.deleteMessage(id);
		}
	}, []);

	const clearChat = useCallback(async () => {
		await chatApi.clearChat(studioId);
	}, [studioId]);

	const handleEventChatMessage = useCallback((msg) => {
		setChatMessages((prevChatMessages) => [...prevChatMessages, msg]);

		setUnreadMessagesCount((prevUnreadMessagesCount) => prevUnreadMessagesCount + 1);
	}, []);

	const handleClearUnreadMessagesCount = useCallback(() => {
		setUnreadMessagesCount(0);
	}, []);

	const handleEventChatMessageUpdate = useCallback((msg) => {
		setChatMessages(
			(prevChatMessages) => prevChatMessages.map((m) => (m._id === msg._id ? msg : m)),
		);
	}, []);

	const handleEventChatMessageDelete = useCallback((msg) => {
		setChatMessages(
			(prevChatMessages) => prevChatMessages.map((m) => (m._id === msg._id ? msg : m)),
		);
	}, []);

	const handleEventChatMessagesClear = useCallback(() => {
		setChatMessages([]);
	}, []);

	const handleEventChatStatus = useCallback((chatStatusNewValue) => {
		setChatStatus(chatStatusNewValue);
	}, []);

	const value = useMemo(() => ({
		fetchChatMessages,
		chatMessages,
		chatStatus,
		sendChatMessage,
		updateChatMessage,
		deleteChatMessage,
		clearChat,
		handleEventChatMessage,
		handleEventChatMessageUpdate,
		handleEventChatMessageDelete,
		handleEventChatMessagesClear,
		handleEventChatStatus,
		isChatEnabled,
		unreadMessagesCount,
		handleClearUnreadMessagesCount,
	}), [
		fetchChatMessages,
		chatMessages,
		chatStatus,
		sendChatMessage,
		updateChatMessage,
		deleteChatMessage,
		clearChat,
		handleEventChatMessage,
		handleEventChatMessageUpdate,
		handleEventChatMessageDelete,
		handleEventChatMessagesClear,
		handleEventChatStatus,
		isChatEnabled,
		unreadMessagesCount,
		handleClearUnreadMessagesCount,
	]);

	return (
		<ChatContext.Provider value={value}>
			{children}
		</ChatContext.Provider>
	);
};

ChatProvider.propTypes = {
	studioId: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
};
