import { useQuery } from 'react-query';
import * as adsApi from '../../api/ads/ads';
import { axiosQueryWrapper } from '../utils/axios-wrapper';

export const AD_QUERY_KEYS = {
	fetchAdSettings: () => ['api', 'ads', 'settings', 'fetch'],
	fetchVoDAds: (videoId) => ['api', 'ads', 'vod', videoId],
	fetchSponsoredAds: () => ['api', 'ads', 'sponsoredAds', 'fetch'],
};

export const useFetchAdSettings = () => useQuery(
	AD_QUERY_KEYS.fetchAdSettings(),
	axiosQueryWrapper(adsApi.fetchAdSettings),
);

export const useFetchVoDAds = (videoId) => useQuery(
	AD_QUERY_KEYS.fetchVoDAds(videoId),
	axiosQueryWrapper(adsApi.fetchVodAds, videoId),
	{ enabled: !!videoId },
);

export const useFetchSponsoredAds = () => useQuery(
	AD_QUERY_KEYS.fetchSponsoredAds(),
	axiosQueryWrapper(adsApi.fetchSponsoredAds),
);
