// @ts-check

import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Label } from 'reactstrap';

/**
 * @typedef {{
* 	className?: string,
* } & import('reactstrap').LabelProps} FormLabelProps
*/

export const FormLabel = (
	/**  @type {FormLabelProps} */
	{
		className = '',
		...props
	},
) => (
	<Label
		className={clsx('font-size-sm font-weight-bold mb-1', className)}
		{...props}
	/>
);

FormLabel.propTypes = {
	className: PropTypes.string,
};

FormLabel.defaultProps = {
	className: '',
};
