import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
/* eslint-disable react/prop-types */
// @ts-check

import { Resolution, stopTrack, MediaShareVideoType } from '../utils';

const MAX_FPS = 30;
const Perf = performance || Date;

export const MediaShareVideoParams = {
	ACCEPTED_FORMATS: ['video/*'],
};

/**
 * @typedef {MediaStreamTrack & {
* 	firefoxFixCaptureSettings?: { width: number, height: number },
* }} FixedMediaStreamTrack
*/

/**
* @typedef {{
* 	name: string,
* 	duration: number,
* 	src: string,
* }} VideoShareData
*/

/**
 * @typedef {{
 * handleChangeVideoShareTimeCodes: (startTime: number, endTime: number) => void,
 * isVideoPaused: boolean,
 * pauseVideo: () => void,
 * playVideo: () => void,
 * requestVideoshare: (requestVideoshareFile: File) => Promise<void>
 * setVideoshareRequestError: (error: Error?) => void,
 * stopVideoshare: () => void,
 * videoshareActive: boolean,
 * videoshareActiveTracks: FixedMediaStreamTrack[],
 * videoshareData: VideoShareData?,
 * videoshareMediastream?: MediaStream,
 * videoshareRequestError: Error?,
* }} IMediaShareVideoContext
*/

const MediaShareVideoContext = createContext(/** @type {IMediaShareVideoContext} */({}));

export const useMediaShareVideo = () => useContext(MediaShareVideoContext);

/**
 * @param {number} sourceWidth
 * @param {number} sourceHeight
 * @param {number} destinationWidth
 * @param {number} destinationHeight
 * @returns {{ height: number, width: number, x: number, y: number }}
 * */
export const contain = (sourceWidth, sourceHeight, destinationWidth, destinationHeight) => {
	const ratio = (sourceHeight && sourceWidth)
		? Math.min(destinationHeight / sourceHeight, destinationWidth / sourceWidth)
		: 0;

	const newHeight = Math.floor(sourceHeight * ratio);
	const newWidth = Math.floor(sourceWidth * ratio);

	const newX = Math.floor((destinationWidth - newWidth) / 2);
	const newY = Math.floor((destinationHeight - newHeight) / 2);

	return {
		height: newHeight,
		width: newWidth,
		x: newX,
		y: newY,
	};
};

/**
 * @param {number} res
 * @returns {{ height: number, width: number }}
 */
const getCanvasSize = (res) => ({
	height: res,
	width: Math.floor(res * (16 / 9)),
});

/**
 * @typedef {{
 * 	activeShareType: MediaShareVideoType?,
 * 	children: React.ReactNode,
 * 	disabled?: boolean,
 * 	isHost?: boolean,
 * 	onShare: (type: MediaShareVideoType) => void,
 *  resolution?: Resolution,
 * }} MediaShareVideoProps
 */

export const MediaShareVideo = (
	/** @type {MediaShareVideoProps} */
	{
		activeShareType,
		children,
		disabled = false,
		isHost = false,
		onShare,
		resolution = Resolution.P720,
	},
) => {
	const [videoshareData, setVideoshareData] = useState(/** @type {VideoShareData?} */(null));
	const [videoShareTimeCodes, setVideoShareTimeCodes] = useState(
		{
			startTime: 0,
			endTime: 0,
		},
	);
	const [isVideoPaused, setIsVideoPaused] = useState(false);

	const videoRef = useRef(/** @type {HTMLVideoElement?} */(null));

	const [videoshareActiveTracks, setVideoshareActiveTracks] = useState(
		/** @type {FixedMediaStreamTrack[]} */([]),
	);
	const [videoshareRequestError, setVideoshareRequestError] = useState(/** @type {Error?} */(null));

	const videoshareActive = videoshareActiveTracks.length > 0;

	const videoshareMediastream = useMemo(() => {
		if (videoshareActiveTracks.length > 0) {
			const mediastream = new MediaStream(videoshareActiveTracks);
			// Refresh mediastream when tracks change to avoid player image stuck
			return mediastream;
		}
		return undefined;
	}, [videoshareActiveTracks]);

	const isAllowed = (isHost || activeShareType === MediaShareVideoType.VIDEO);

	const resolutionRef = useRef(resolution);
	useEffect(() => { resolutionRef.current = resolution; }, [resolution]);
	const handleLoopVideo = useCallback(() => {
		if (videoRef.current
			&& videoRef.current.currentTime >= videoShareTimeCodes.endTime) {
			videoRef.current.currentTime = videoShareTimeCodes.startTime;
			videoRef.current.play();
			if (videoShareTimeCodes.endTime) {
				videoRef.current.addEventListener('timeupdate', handleLoopVideo);
			}
			setIsVideoPaused(false);
		}
	}, [videoShareTimeCodes.endTime, videoShareTimeCodes.startTime]);

	const playVideo = useCallback(() => {
		if (!videoRef.current) return;

		videoRef.current.play();
		if (videoShareTimeCodes.endTime) {
			videoRef.current.addEventListener('timeupdate', handleLoopVideo);
		}
		setIsVideoPaused(false);
	}, [handleLoopVideo, videoShareTimeCodes.endTime]);

	const pauseVideo = useCallback(() => {
		if (!videoRef.current) return;

		const saveTime = videoRef.current.currentTime;
		videoRef.current.pause();
		setIsVideoPaused(true);
		videoRef.current.currentTime = saveTime;
	}, []);

	useEffect(() => {
		if (videoRef.current && videoShareTimeCodes.endTime) {
			videoRef.current.removeEventListener('timeupdate', handleLoopVideo);
			videoRef.current.addEventListener('timeupdate', handleLoopVideo);
		}
		return () => {
			if (videoRef.current) {
				videoRef.current.removeEventListener('timeupdate', handleLoopVideo);
			}
		};
	}, [handleLoopVideo, videoShareTimeCodes]);

	const requestedVideoshareFile = useRef(/** @type {File?} */(null));

	const handleChangeVideoShareTimeCodes = useCallback((
		/** @type {number} */startTime,
		/** @type {number} */endTime,
	) => {
		if (!videoRef.current) return;

		if (startTime !== videoShareTimeCodes.startTime) {
			setVideoShareTimeCodes((prev) => ({ ...prev, startTime }));
			videoRef.current.currentTime = startTime;
		}
		if (endTime !== videoShareTimeCodes.endTime) {
			setVideoShareTimeCodes((prev) => ({ ...prev, endTime }));
		}
	}, [videoShareTimeCodes.endTime, videoShareTimeCodes.startTime]);

	const requestVideoshare = useCallback(async (
		/** @type {File} */requestVideoshareFile,
	) => {
		requestedVideoshareFile.current = requestVideoshareFile;

		try {
			setVideoshareRequestError(null);

			const video = document.createElement('video');
			videoRef.current = video;
			video.preload = 'auto';
			video.loop = true;
			video.crossOrigin = 'anonymous';
			video.volume = 1;
			video.src = URL.createObjectURL(requestVideoshareFile);

			await video.play();
			pauseVideo();

			setVideoshareData({
				name: requestVideoshareFile.name,
				duration: videoRef.current.duration,
				src: video.src,
			});

			if (!requestedVideoshareFile.current) return;

			const canvas = document.createElement('canvas');
			const context = canvas.getContext('2d');
			if (!context) throw new Error('Canvas not supported');

			const canvasSize = getCanvasSize(resolutionRef.current);

			canvas.width = canvasSize.width;
			canvas.height = canvasSize.height;

			const audioContext = new AudioContext();

			const destination = audioContext.createMediaStreamDestination();

			const gainNode = audioContext.createGain();
			gainNode.gain.value = 1;
			gainNode.connect(destination);

			const source = audioContext.createMediaElementSource(video);
			source.connect(gainNode);

			let nextContainCallTime = 0;
			let drawSize = contain(
				video.videoWidth,
				video.videoHeight,
				canvas.width,
				canvas.height,
			);

			const trottledContain = () => {
				const now = Perf.now();
				if (now > nextContainCallTime) {
					drawSize = contain(
						video.videoWidth,
						video.videoHeight,
						canvas.width,
						canvas.height,
					);
					nextContainCallTime = now + 2000;
				}
				return drawSize;
			};

			let lastRender = Perf.now();
			const interval = Math.floor(1000 / MAX_FPS);
			const mediastream = (
				/**
				 * @type {Omit<MediaStream, 'getVideoTracks'> & {
				 * 	requestFrame?: () => void,
				 *  getVideoTracks: () => CanvasCaptureMediaStreamTrack[],
				 * }}
				 */
				(canvas.captureStream(0))
			);

			const animation = () => {
				if (!requestedVideoshareFile.current) return;

				requestAnimationFrame(animation);

				const now = Perf.now();
				const delta = now - lastRender;
				if (delta < interval) return;
				lastRender = now - (delta % interval);

				const { x, y, width, height } = trottledContain();
				context.drawImage(video, x, y, width, height);

				// mediastream will not contains the same object depending on the web browser
				// - Firefox: CanvasCaptureMediaStream
				// - Chrome: MediaStream
				if (mediastream.requestFrame) mediastream.requestFrame();
				else mediastream.getVideoTracks()[0].requestFrame();
			};
			requestAnimationFrame(animation);

			const videoTracks = mediastream.getTracks().map((
				/** @type {FixedMediaStreamTrack} */videoTrack,
			) => {
				// Firefox MediaStreamTrack.getSettings returns an empty object in case of captureStream
				videoTrack.firefoxFixCaptureSettings = {
					width: canvas.width,
					height: canvas.height,
				};

				return videoTrack;
			});

			const audioTracks = destination.stream.getTracks();

			setVideoshareActiveTracks((state) => [...state, ...videoTracks, ...audioTracks]);
			onShare(MediaShareVideoType.VIDEO);
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error(error);
			setVideoshareRequestError(/** @type {Error} */(error));
		}
	}, [onShare, pauseVideo]);

	const stopVideoshare = useCallback(() => {
		setVideoshareRequestError(null);
		setVideoshareData(null);

		requestedVideoshareFile.current = null;

		videoRef.current = null;

		videoshareActiveTracks.forEach(stopTrack);
		setVideoshareActiveTracks([]);
	}, [videoshareActiveTracks]);

	useEffect(() => {
		/** @param {Event} event */
		const handleTrackEnded = ({ target: track }) => {
			if (!track) return;

			track.removeEventListener('trackended', handleTrackEnded);
			setVideoshareActiveTracks((state) => state.filter((t) => t !== track));
		};

		videoshareActiveTracks.forEach((track) => {
			track.addEventListener('ended', handleTrackEnded);
		});

		return () => {
			videoshareActiveTracks.forEach((track) => {
				track.removeEventListener('ended', handleTrackEnded);
			});
		};
	}, [videoshareActiveTracks]);

	useEffect(() => {
		const shouldStopVideoshare = requestedVideoshareFile.current && (disabled || !isAllowed);
		if (shouldStopVideoshare) stopVideoshare();
	}, [disabled, isAllowed, stopVideoshare]);

	useEffect(() => {
		if (videoshareActiveTracks.length > 0 && !isHost) {
			playVideo();
		}
	}, [isHost, playVideo, videoshareActiveTracks]);

	const videoshareActiveTracksRef = useRef(videoshareActiveTracks);
	useEffect(
		() => { videoshareActiveTracksRef.current = videoshareActiveTracks; },
		[videoshareActiveTracks],
	);
	if (videoRef.current?.readyState === HTMLMediaElement.HAVE_ENOUGH_DATA
			&& videoRef.current.paused) {
		videoRef.current.currentTime = videoShareTimeCodes.startTime;
	}
	// cleanup
	useEffect(() => () => {
		requestedVideoshareFile.current = null;

		if (videoRef.current) {
			pauseVideo();
			videoRef.current = null;
		}

		videoshareActiveTracksRef.current.forEach(stopTrack);
	}, [pauseVideo]);

	const value = useMemo(() => ({
		handleChangeVideoShareTimeCodes,
		isVideoPaused,
		pauseVideo,
		playVideo,
		requestVideoshare,
		setVideoshareRequestError,
		stopVideoshare,
		videoshareActive,
		videoshareActiveTracks,
		videoshareData,
		videoshareMediastream,
		videoshareRequestError,
	}), [
		handleChangeVideoShareTimeCodes,
		isVideoPaused,
		pauseVideo,
		playVideo,
		requestVideoshare,
		setVideoshareRequestError,
		stopVideoshare,
		videoshareActive,
		videoshareActiveTracks,
		videoshareData,
		videoshareMediastream,
		videoshareRequestError,
	]);

	return (
		<MediaShareVideoContext.Provider value={value}>
			{children}
		</MediaShareVideoContext.Provider>
	);
};
