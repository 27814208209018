import { lazy } from 'react';

import { AdminPath } from './RoutePathAdmin';
import RouteIfRoleMatch from './components/Authentication/RouteIfRoleMatch';
import { Role } from './components/Authentication/Authentication';

const AdminAnalyticsSidebar = lazy(() => import('./layout-blueprints/AdminAnalyticsSidebar/AdminAnalyticsSidebar'));

const ViewAdminFinanceAnalytics = lazy(() => import('./views/Admin/Analytics/Finance'));
const ViewAdminGeneralAnalytics = lazy(() => import('./views/Admin/Analytics/General'));
const ViewAdminAnalitycsMarketing = lazy(() => import('./views/Admin/Analytics/Marketing'));
const ViewAdminAnlaitycsResearch = lazy(() => import('./views/Admin/Analytics/Research'));
const ViewAdminAnalyticsModeration = lazy(() => import('./views/Admin/Analytics/Moderation'));
const ViewAdminAnalyticsAds = lazy(() => import('./views/Admin/Analytics/Ads'));

const RoutesAdminAnalytics = {
	element: <RouteIfRoleMatch role={Role.ADMIN} element={<AdminAnalyticsSidebar />} />,
	children: [
		{ path: AdminPath.ANALYTICS_ADS, element: <ViewAdminAnalyticsAds /> },
		{ path: AdminPath.ANALYTICS_FINANCE, element: <ViewAdminFinanceAnalytics /> },
		{ path: AdminPath.ANALYTICS_MARKETING, element: <ViewAdminAnalitycsMarketing /> },
		{ path: AdminPath.ANALYTICS_MODERATION, element: <ViewAdminAnalyticsModeration /> },
		{ path: AdminPath.ANALYTICS_RESEARCH, element: <ViewAdminAnlaitycsResearch /> },
		{ path: AdminPath.ANALYTICS_ROOT, element: <ViewAdminGeneralAnalytics /> },
	],
};

export default RoutesAdminAnalytics;
