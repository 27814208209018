/* eslint-disable react/prop-types */
// @ts-check

import clsx from 'clsx';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';

/**
 * @typedef {{
* 	children?: React.ReactNode
* 	className?: string;
* 	component?: React.ElementType;
* 	disabled?: boolean;
* 	loading?: boolean;
* 	smallSpinner?: boolean;
* } & import('reactstrap').ButtonProps} ButtonLoadingProps
* */

export const ButtonLoading = (
	/** @type {ButtonLoadingProps} */
	{
		children,
		className = '',
		component: Component = Button,
		disabled = false,
		loading = false,
		smallSpinner = true,
		...props
	},
) => {
	const { t } = useTranslation();
	return (
		<Component
			className={clsx('position-relative', { 'btn-spinner': loading }, className)}
			disabled={disabled || loading}
			{...props}
		>
			<div className={clsx({ 'opacity-2': loading })}>{children}</div>
			{loading && (
				<div className="position-absolute w-100 h-100 top-0 left-0 d-flex align-items-center">
					<span
						className={`btn-wrapper--icon spinner-border ${smallSpinner ? 'spinner-border-sm' : ''} ml-1 mr-auto`}
						role="status"
						aria-hidden="true"
					/>
					<span className="visually-hidden">{t('Button.loading')}</span>
				</div>
			)}
		</Component>
	);
};

/**
 * @typedef {{
* 	children?: React.ReactNode
* 	className?: string;
* 	component?: React.ElementType;
* 	loading?: boolean;
* } & import('reactstrap').ButtonProps} ButtonLoadingIconProps
* */

export const ButtonLoadingIcon = (
	/** @type {ButtonLoadingIconProps} */
	{
		children,
		className = '',
		component: Component = Button,
		loading = false,
		...props
	},
) => (
	<Component
		className={clsx('position-relative', { 'btn-spinner': loading }, className)}
		disabled={loading}
		{...props}
	>
		{loading ? (
			<div className="w-100 h-100 align-items-center">
				<span
					className="btn-wrapper--icon spinner-border spinner-border-sm"
					role="status"
					aria-hidden="true"
				/>
			</div>
		) : (
			<div className={clsx({ 'opacity-2': loading })}>{children}</div>
		)}
	</Component>
);

/**
 * @typedef {{
 * 	buttonComponent?: React.ElementType;
 * 	buttonLoadingComponent?: React.ElementType;
 *  onClick: () => void;
 * 	watchValue?: any;
 * } & import('reactstrap').ButtonProps} ButtonValueLoadingProps
 * */

/** @type {React.FC<ButtonValueLoadingProps>} */
export const ButtonValueLoading = ({
	buttonComponent = Button,
	buttonLoadingComponent: ButtonLoadingComponent = ButtonLoading,
	onClick,
	watchValue,
	...props
}) => {
	const [loading, setLoading] = useState(false);

	const handleClick = useCallback(() => {
		setLoading(true);
		onClick();
	}, [onClick]);

	// Reset loading state when watchValue change
	useEffect(() => {
		setLoading(false);
	}, [watchValue]);

	return (
		<ButtonLoadingComponent
			component={buttonComponent}
			loading={loading}
			onClick={handleClick}
			{...props}
		/>
	);
};
