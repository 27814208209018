export default {
	category: 'Cookies.Functional.functionalCookies',
	description: 'Cookies.Functional.description',
	cookiesList: [
		{
			name: 'Cookies.Functional.googleAnalytics',
			description: 'Cookies.Functional.googleAnalytics.description',
			id: 'functionalGa',
		},
		{
			name: 'Cookies.Functional.rollbar',
			description: 'Cookies.Functional.rollbar.description',
			id: 'functionalRollbar',
		},
	],
	canBeDeactivated: true,
};
