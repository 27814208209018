// @ts-check

import {
	soupSession,
} from '../../api/soup';

/**
 * @import { Publication } from '../../lib/store/publication';
 * @import { Action } from 'redux';
 * */

const ACTION_KEY = 'publications';

export const ADD_STREAM_PUBLICATION = `${ACTION_KEY}/addStreamPublication`;
export const CLEAR_STREAM_PUBLICATION = `${ACTION_KEY}/clearStreamPublication`;
export const REMOVE_STREAM_PUBLICATION = `${ACTION_KEY}/removeStreamPublication`;
export const SUBSCRIBE_PUBLICATIONS = `${ACTION_KEY}/subscribePublications`;
export const UNSUBSCRIBE_PUBLICATIONS = `${ACTION_KEY}/unsubscribePublications`;

// TODO : type soupSession to not have any[] in payload
/**
 * @typedef {Action<typeof ADD_STREAM_PUBLICATION> & { payload: Publication } |
* 		Action<typeof CLEAR_STREAM_PUBLICATION> |
* 		Action<typeof REMOVE_STREAM_PUBLICATION> & { payload: Publication } |
* 		Action<typeof SUBSCRIBE_PUBLICATIONS> & { payload: (() => Promise<any[]>) } |
* 		Action<typeof UNSUBSCRIBE_PUBLICATIONS> & { payload: (() => Promise<any[]>) }
* } PublicationAction
* */

/**
 * @param {Publication} publication
 * @returns {PublicationAction}
 */
export const addStreamPublicationAction = (publication) => ({
	type: ADD_STREAM_PUBLICATION,
	payload: publication,
});

/**
 * @returns {PublicationAction}
 */
export const clearStreamPublicationAction = () => ({
	type: CLEAR_STREAM_PUBLICATION,
});

/**
 * @param {Publication} publication
 * @returns {PublicationAction}
 */
export const removeStreamPublicationAction = (publication) => ({
	type: REMOVE_STREAM_PUBLICATION,
	payload: publication,
});

/**
 * @param {Publication[]} publications
 * @param {{
 * 	spatialLayer: number,
 * }} [preferredLayers]
 * @returns {PublicationAction}
 */
export const subscribePublicationsAction = (publications, preferredLayers) => ({
	type: SUBSCRIBE_PUBLICATIONS,
	payload: async () => {
		const soup = soupSession();
		if (!soup) throw new Error('No soup session');
		return Promise.all(
			publications.map((publication) => soup.subscribe(publication, preferredLayers)),
		);
	},
});

/**
 * @param {Publication[]} publications
 * @returns {PublicationAction}
 */
export const unsubscribePublicationsAction = (publications) => ({
	type: UNSUBSCRIBE_PUBLICATIONS,
	payload: async () => {
		const soup = soupSession();
		if (!soup) throw new Error('No soup session');
		return Promise.all(publications.map((publication) => soup.unsubscribe(publication)));
	},
});
