// @ts-check

import { FaFacebookSquare, FaInstagramSquare, FaLinkedin, FaTwitch, FaVimeo, FaYoutube } from 'react-icons/fa';
import { FaTiktok, FaXTwitter } from 'react-icons/fa6';

export const MuxSimulCastStatus = {
	IDLE: 'idle',
	STARTING: 'starting',
	BROADCASTING: 'broadcasting',
	ERRORED: 'errored',
};

export const MuxSimulCastStatusColor = {
	[MuxSimulCastStatus.IDLE]: 'secondary',
	[MuxSimulCastStatus.STARTING]: 'info',
	[MuxSimulCastStatus.BROADCASTING]: 'success',
	[MuxSimulCastStatus.ERRORED]: 'danger',
};

/**
 * @readonly
 * @enum {string}
 */
export const SimulCastPlatformKey = {
	FACEBOOK: 'FACEBOOK',
	INSTAGRAM: 'INSTAGRAM',
	TIKTOK: 'TIKTOK',
	TWITCH: 'TWITCH',
	TWITTER: 'TWITTER',
	VIMEO: 'VIMEO',
	YOUTUBE: 'YOUTUBE',
	LINKEDIN: 'LINKEDIN',
};

export const SimulCastPlatformData = {
	[SimulCastPlatformKey.FACEBOOK]: {
		_id: 'facebook-new',
		name: 'Facebook',
		platformKey: SimulCastPlatformKey.FACEBOOK,
		icon: FaFacebookSquare,
		iconColor: '#3b5998',
		url: 'rtmps://live-api-s.facebook.com:443/rtmp/',
		streamKey: '',
	},
	[SimulCastPlatformKey.INSTAGRAM]: {
		_id: 'instagram-new',
		name: 'Instagram',
		platformKey: SimulCastPlatformKey.INSTAGRAM,
		icon: FaInstagramSquare,
		iconColor: '#ff0069',
		url: 'rtmps://live-api-s.facebook.com:443/rtmp/',
		streamKey: '',
	},
	[SimulCastPlatformKey.TIKTOK]: {
		_id: 'tiktok-new',
		name: 'TikTok',
		platformKey: SimulCastPlatformKey.TIKTOK,
		icon: FaTiktok,
		iconColor: '#000000',
		url: '',
		streamKey: '',
	},
	[SimulCastPlatformKey.TWITCH]: {
		_id: 'twitch-new',
		name: 'Twitch',
		platformKey: SimulCastPlatformKey.TWITCH,
		icon: FaTwitch,
		iconColor: '#6441a5',
		url: 'rtmp://live.twitch.tv/app/',
		streamKey: '',
	},
	[SimulCastPlatformKey.TWITTER]: {
		_id: 'twitter-new',
		name: 'X (Twitter)',
		platformKey: SimulCastPlatformKey.TWITTER,
		icon: FaXTwitter,
		iconColor: '#000000',
		url: '',
		streamKey: '',
	},
	[SimulCastPlatformKey.VIMEO]: {
		_id: 'vimeo-new',
		name: 'Vimeo',
		platformKey: SimulCastPlatformKey.VIMEO,
		icon: FaVimeo,
		iconColor: '#1ab7ea',
		url: 'rtmps://rtmp-global.cloud.vimeo.com:443/live/',
		streamKey: '',
	},
	[SimulCastPlatformKey.YOUTUBE]: {
		_id: 'youtube-new',
		name: 'YouTube',
		platformKey: SimulCastPlatformKey.YOUTUBE,
		icon: FaYoutube,
		iconColor: '#ff0000',
		url: 'rtmp://a.rtmp.youtube.com/live2/',
		streamKey: '',
	},
	[SimulCastPlatformKey.LINKEDIN]: {
		_id: 'linkedin-new',
		name: 'LinkedIn (Beta)',
		platformKey: SimulCastPlatformKey.LINKEDIN,
		icon: FaLinkedin,
		iconColor: '#0077b5',
		url: '',
		streamKey: '',
	},
};
