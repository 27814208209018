import { MdFiberManualRecord } from 'react-icons/md';
import { useTranslation } from 'react-i18next';

import { useMixing } from '../ReactVideo/Mixing';
import './RecordingState.scss';

const RecordingState = () => {
	const { t } = useTranslation();
	const { channelMixerStatus } = useMixing();

	if (!channelMixerStatus?.isRecording) return null;

	return (
		<div className="position-absolute left-0 top-0 p-2 d-flex align-items-center text-danger font-size-sm" style={{ zIndex: 1 }}>
			<MdFiberManualRecord className="font-size-xl" />
			<span className="ml-1 RecordingState_text" title={t('RecordingState.RecordingState.liveCurrentlyRecorded')}>{t('RecordingState.RecordingState.recording')}</span>
		</div>
	);
};

export default RecordingState;
