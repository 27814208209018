import { createContext, useContext } from 'react';

export const StudioContext = createContext();

export const useStudio = () => useContext(StudioContext);

export const StudioModal = {
	TERMINATE: 'TERMINATE',
	LEAVE_CONFIRMATION: 'LEAVE_CONFIRMATION',
};
