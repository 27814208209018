import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import PropTypes from 'prop-types';

import { Resolution, stopTrack, MediaShareVideoType } from '../utils';

const getDisplayMediaVideoConstraints = (res) => ({
	height: { max: res },
	// ideal is not supported by safari 16+ :
	// https://bugs.webkit.org/show_bug.cgi?id=247310
	width: { max: Math.round(res * (16 / 9)) },
	frameRate: { max: 5 },
});

const MediaShareScreenContext = createContext();

export const useMediaShareScreen = () => useContext(MediaShareScreenContext);

export const MediaShareScreen = ({
	activeShareType,
	children,
	disabled,
	isHost,
	onShare,
	resolution,
}) => {
	const [screenshareActiveTracks, setScreenshareActiveTracks] = useState([]);
	const [screenshareRequestError, setScreenshareRequestError] = useState();

	const screenshareActive = screenshareActiveTracks.length > 0;

	const screenshareMediastream = useMemo(() => {
		if (screenshareActiveTracks.length > 0) {
			const mediastream = new MediaStream(screenshareActiveTracks);
			// Refresh mediastream when tracks change to avoid player image stuck
			return mediastream;
		}
		return undefined;
	}, [screenshareActiveTracks]);

	const resolutionRef = useRef(resolution);
	useEffect(() => { resolutionRef.current = resolution; }, [resolution]);

	const isAllowed = (isHost || activeShareType === MediaShareVideoType.SCREEN);

	const isScreenshareRequested = useRef(false);

	const requestScreenshare = useCallback(async () => {
		// const constraints = await dispatch(getUserMediaConstraintsAction());
		// const { audio } = constraints;
		console.log('requestScreenshare');

		isScreenshareRequested.current = true;

		try {
			setScreenshareRequestError(undefined);

			const mediastream = await navigator.mediaDevices.getDisplayMedia({
				audio: true,
				video: getDisplayMediaVideoConstraints(resolutionRef.current),
			});

			// Allow cancellation
			const isStillScreenshareRequested = isScreenshareRequested.current;

			// Possible cancellation while getUserMedia was pending
			const tracks = mediastream.getTracks();
			if (!isStillScreenshareRequested) tracks.forEach((track) => track.stop());
			else {
				setScreenshareActiveTracks((state) => [...state, ...tracks]);
				onShare(MediaShareVideoType.SCREEN);
			}
		} catch (error) {
			console.error(error);
			setScreenshareRequestError(error);
		}
	}, [onShare]);

	useEffect(() => {
		const handleTrackEnded = ({ target: track }) => {
			console.log('handleTrackEnded', track.kind);
			track.removeEventListener('trackended', handleTrackEnded);
			setScreenshareActiveTracks((state) => state.filter((t) => t !== track));
		};

		screenshareActiveTracks.forEach((track) => {
			track.addEventListener('ended', handleTrackEnded);
		});

		return () => {
			screenshareActiveTracks.forEach((track) => {
				track.removeEventListener('ended', handleTrackEnded);
			});
		};
	}, [screenshareActiveTracks]);

	const stopScreenshare = useCallback(() => {
		isScreenshareRequested.current = false;
		screenshareActiveTracks.forEach(stopTrack);
		setScreenshareActiveTracks((s) => (s.length < 1 ? s : [])); // Avoid hyperloop
		setScreenshareRequestError(undefined);
	}, [screenshareActiveTracks]);

	useEffect(() => {
		// If screenshare is stopped from system button, disable screenshare
		if (screenshareActiveTracks.length < 1) stopScreenshare();
	}, [screenshareActiveTracks, stopScreenshare]);

	useEffect(() => {
		const shouldStopScreenshare = isScreenshareRequested.current && (disabled || !isAllowed);
		if (shouldStopScreenshare) stopScreenshare();
	}, [disabled, isAllowed, stopScreenshare]);

	const screenshareActiveTracksRef = useRef(screenshareActiveTracks);
	useEffect(
		() => { screenshareActiveTracksRef.current = screenshareActiveTracks; },
		[screenshareActiveTracks],
	);

	// cleanup
	useEffect(() => () => {
		isScreenshareRequested.current = false;
		screenshareActiveTracksRef.current.forEach(stopTrack);
	}, []);

	const toggleScreenshare = useCallback(() => {
		if (screenshareActive) stopScreenshare();
		else requestScreenshare();
	}, [requestScreenshare, screenshareActive, stopScreenshare]);

	const value = useMemo(() => ({
		requestScreenshare,
		screenshareActive,
		screenshareActiveTracks,
		screenshareMediastream,
		screenshareRequestError,
		stopScreenshare,
		toggleScreenshare,
	}), [
		requestScreenshare,
		screenshareActive,
		screenshareActiveTracks,
		screenshareMediastream,
		screenshareRequestError,
		stopScreenshare,
		toggleScreenshare,
	]);

	return (
		<MediaShareScreenContext.Provider value={value}>
			{children}
		</MediaShareScreenContext.Provider>
	);
};

MediaShareScreen.propTypes = {
	children: PropTypes.node.isRequired,
	disabled: PropTypes.bool,
	resolution: PropTypes.oneOf(Object.values(Resolution)),
};

MediaShareScreen.defaultProps = {
	disabled: false,
	resolution: Resolution.P720,
};
