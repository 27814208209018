/* eslint-disable react/prop-types */
// @ts-check
import { useCallback, useMemo, useState, createContext, useContext, useEffect } from 'react';

import { useMediaUser } from '../User';
import { defaultForcedKeyConfig, KeyDetectionMode } from './KeyConfig';
import { useMediaCrop } from '../MediaCrop/Provider';

/** @import { UserMediaStream, UserMediaStreamTrack } from '../User'; */

/**
 * @typedef {{
 * addKeyVideoTrack: (track: UserMediaStreamTrack) => void,
 * isKeyForced: boolean,
 * isReplacementConfigOverriden: boolean,
 * removeKeyVideoTrackByTrack: (track: UserMediaStreamTrack) => void,
 * setKeyVideoActiveTracks: React.Dispatch<React.SetStateAction<UserMediaStreamTrack[]>>,
 * keyVideoActiveTracks: UserMediaStreamTrack[],
 * keyOrUserVideoActiveTracks: UserMediaStreamTrack[],
 * keyOrUserActiveTracks: UserMediaStreamTrack[],
 * keyOrUserMediastream: UserMediaStream[],
 * }} IMediaKeyContext
 */

export const MediaKeyContext = createContext(/** @type {IMediaKeyContext} */({}));

export const useMediaKey = () => useContext(MediaKeyContext);

const LOCAL_KEY_CONFIG_KEY = 'beeyou_localKeyConfig';

const getLocalKeyConfig = () => {
	const localKeyConfig = localStorage.getItem(LOCAL_KEY_CONFIG_KEY);
	return localKeyConfig && JSON.parse(localKeyConfig);
};
const setLocalKeyConfig = (config) => localStorage.setItem(
	LOCAL_KEY_CONFIG_KEY,
	JSON.stringify(config),
);

/**
 * @typedef {{
 * 	children?: React.ReactNode,
 * 	isHost?: boolean,
 * }} MediaKeyProviderProps
 */

export const MediaKeyProvider = (
	/** @type {MediaKeyProviderProps} */
	{
		children,
		isHost = false,
	},
) => {
	// TODO : type localConfig
	const [localConfig, setLocalConfig] = useState(() => getLocalKeyConfig());
	const [keyVideoActiveTracks, setKeyVideoActiveTracks] = useState(
		/** @type {UserMediaStreamTrack[]} */([]),
	);
	const {
		cropOrUserVideoActiveTracks,
		cropOrUserMediastreams,
	} = useMediaCrop();
	const {
		userAudioActiveTracks,
	} = useMediaUser();

	const [channelMixerKeyConfig, setChannelMixerKeyConfig] = useState();

	const configOverride = useMemo(() => {
		const replacementOverride = isHost
			? channelMixerKeyConfig?.own?.replacement
			: channelMixerKeyConfig?.all?.replacement;

		if (!replacementOverride) {
			return undefined;
		}

		return {
			...defaultForcedKeyConfig,
			replacement: replacementOverride,
		};
	}, [isHost, channelMixerKeyConfig]);

	const isReplacementConfigOverriden = !!configOverride;

	const isKeyForced = isReplacementConfigOverriden && isHost;

	// The 2-pass key is not available for the host.
	// For the host, the key settings defined in scenes completely
	// replace the his default key settings.
	const config = useMemo(() => {
		if (isKeyForced) return undefined;
		return localConfig;
	}, [isKeyForced, localConfig]);

	const addKeyVideoTrack = useCallback((
		/** @type {UserMediaStreamTrack}*/track,
	) => {
		setKeyVideoActiveTracks((prev) => [...prev, track]);
	}, []);

	const removeKeyVideoTrackByTrack = useCallback((
		/** @type {UserMediaStreamTrack}*/track,
	) => {
		setKeyVideoActiveTracks((prev) => prev.filter(({ configId }) => configId !== track.configId));
	}, []);

	const handleCMSEvents = useCallback((cms) => {
		const { key } = cms;
		setChannelMixerKeyConfig(key);
	}, []);

	const isKeyEnabled = (
		(
			!!config?.detection.mode
			&& config.detection.mode !== KeyDetectionMode.DISABLED
		)
		|| (
			!!configOverride?.detection.mode
			&& configOverride.detection.mode !== KeyDetectionMode.DISABLED
		)
	);

	const keyOrUserVideoActiveTracks = useMemo(() => {
		// ensure user track is removed even if key track is not yet available
		// in order to avoid publish two tracks in a row
		if (isKeyEnabled) {
			return [
				...keyVideoActiveTracks,
				...cropOrUserVideoActiveTracks.filter(({ configId }) => configId !== 0),
			];
		}
		return cropOrUserVideoActiveTracks;
	}, [isKeyEnabled, keyVideoActiveTracks, cropOrUserVideoActiveTracks]);

	const keyOrUserActiveTracks = useMemo(
		() => [
			...keyOrUserVideoActiveTracks,
			...userAudioActiveTracks,
		], [keyOrUserVideoActiveTracks, userAudioActiveTracks],
	);

	const keyOrUserMediastream = useMemo(() => {
		if (keyVideoActiveTracks?.length > 0) {
			/** @type {UserMediaStream} */
			const keyMediaStream = new MediaStream();
			keyMediaStream.configId = 0;
			keyMediaStream.addTrack(keyVideoActiveTracks[0]);
			const userMediaStream = cropOrUserMediastreams.find(({ configId }) => configId === 0);
			if (userMediaStream) {
				const track = userMediaStream.getAudioTracks()[0];
				if (track) keyMediaStream.addTrack(track);
			}

			// Refresh keyMediaStream when tracks change to avoid player image stuck
			return [
				...cropOrUserMediastreams.filter(({ configId }) => configId !== 0),
				keyMediaStream,
			];
		}
		return cropOrUserMediastreams;
	}, [keyVideoActiveTracks, cropOrUserMediastreams]);

	useEffect(() => {
		setLocalKeyConfig(localConfig);
	}, [localConfig]);

	const context = useMemo(() => ({
		addKeyVideoTrack,
		config,
		configOverride,
		handleCMSEvents,
		isKeyForced,
		isReplacementConfigOverriden,
		localConfig,
		removeKeyVideoTrackByTrack,
		setLocalConfig,
		setKeyVideoActiveTracks,
		keyVideoActiveTracks,
		keyOrUserVideoActiveTracks,
		keyOrUserActiveTracks,
		keyOrUserMediastream,
	}), [
		addKeyVideoTrack,
		config,
		configOverride,
		handleCMSEvents,
		isKeyForced,
		isReplacementConfigOverriden,
		localConfig,
		removeKeyVideoTrackByTrack,
		keyVideoActiveTracks,
		keyOrUserVideoActiveTracks,
		keyOrUserActiveTracks,
		keyOrUserMediastream,
	]);

	return (
		<MediaKeyContext.Provider value={context}>
			{children}
		</MediaKeyContext.Provider>
	);
};
