import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { StudioSettingsContext } from './Context';

const defaultValue = {
	value: {
		label: 'Default',
		leftMenu: { favoriteTab: '', isOpen: false, isCollapsed: false },
		leftFirst: { favoriteTab: '', isOpen: false, isCollapsed: false },
		leftSecond: { favoriteTab: '', isOpen: false, isCollapsed: false },
		leftThird: { favoriteTab: '', isOpen: false, isCollapsed: false },
		leftFourth: { favoriteTab: '', isOpen: false, isCollapsed: false, drawTab: true },
		leftFifth: { favoriteTab: '', isOpen: false, isCollapsed: false },
		leftSixth: { favoriteTab: '', isOpen: false, isCollapsed: false },
		rightFirst: { favoriteTab: '', isOpen: false, isCollapsed: false },
		rightSecond: { favoriteTab: '', isOpen: false, isCollapsed: false },
		rightThird: { favoriteTab: '', isOpen: false, isCollapsed: false },
		rightFourth: { favoriteTab: '', isOpen: false, isCollapsed: false },
		rightMenu: { favoriteTab: '', isOpen: false, isCollapsed: false },
		midRightMenu: { favoriteTab: '', isOpen: false, isCollapsed: false },
	},
};

export const fullScreenMenuItems = ['leftFirst', 'leftSecond', 'leftThird', 'leftFourth', 'leftFifth', 'leftSixth', 'rightFirst', 'rightSecond', 'rightThird', 'rightFourth'];

export const StudioSettingsProvider = ({ children }) => {
	const [studioSettings, setStudioSettings] = useState(defaultValue);

	const context = useMemo(() => ({
		handleChangeStudioSettings: (data) => {
			setStudioSettings({
				value: {
					...data,
				},
			});
		},
		handleChangeRightMenu: (data) => {
			setStudioSettings((prev) => ({
				...prev,
				value: {
					...prev.value,
					rightMenu: {
						...prev.value.rightMenu,
						...data,
					},
				},
			}));
		},
		handleChangeMidRightMenu: (data) => {
			setStudioSettings((prev) => ({
				...prev,
				value: {
					...prev.value,
					midRightMenu: {
						...prev.value.midRightMenu,
						...data,
					},
				},
			}));
		},
		handleChangeLeftMenu: (data) => {
			setStudioSettings((prev) => ({
				...prev,
				value: {
					...prev.value,
					leftMenu: {
						...prev.value.leftMenu,
						...data,
					},
				},
			}));
		},
		handleChangeMenuItem: (menuKey, data) => {
			setStudioSettings((prev) => ({
				...prev,
				value: {
					...prev.value,
					...(fullScreenMenuItems.includes(menuKey)
						? fullScreenMenuItems.reduce((acc, key) => ({
							...acc,
							[key]: key === menuKey
								? {
									...prev.value[key],
									...data,
									isOpen: !prev.value[key].isOpen,
								}
								: { ...prev.value[key], isOpen: false },
						}), {})
						: { [menuKey]: { ...prev.value[menuKey], ...data } }
					),
				},
			}));
		},
		studioSettings,
		defaultValue,
	}), [studioSettings]);

	return (
		<StudioSettingsContext.Provider value={context}>
			{children}
		</StudioSettingsContext.Provider>
	);
};

StudioSettingsProvider.propTypes = {
	children: PropTypes.node,
};

StudioSettingsProvider.defaultProps = {
	children: undefined,
};
