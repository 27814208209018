import PropTypes from 'prop-types';
import { lazy, useCallback, useState } from 'react';
import { Col, NavItem, NavLink, Row, TabContent } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { ButtonPill } from '../Button';
import { ModalLazyContent } from '../Modal/LazyContent';
import { ModalScreenHeader, ModalScreenHeaderNav } from '../Modal/Screen/Header';
import { ModalScreen } from '../Modal/Screen/Screen';
import { useSurveyTemplate } from '../Survey/Providers/SurveyTemplateProvider';
import { ResourceStatus, SurveyVoteTemplateTab, useUserSettings } from './Context';
import { usePagination } from '../Pagination/Context';

const SurveyVoteTemplateModalContent = lazy(() => import('./SurveyVoteTemplateModalContent'));

export const SurveyVoteTemplateModal = ({ defaultTab, isOpen }) => {
	const { t } = useTranslation();
	const [activeTab, setActiveTab] = useState(defaultTab);
	const { closeModal } = useUserSettings();
	const { goFirstPage } = usePagination();

	const [resourceStatus, setResourceStatus] = useState(ResourceStatus.ACTIVE);

	const [addNewVoteTemplateFlag, setAddNewVoteTemplateFlag] = useState(false);
	const [voteTemplateInEditionFlag, setVoteTemplateInEditionFlag] = useState(false);

	const { handleOpenSurveyTemplateModal } = useSurveyTemplate();

	const handleChangeAddingNewVoteTemplateFlag = useCallback((state) => () => {
		setAddNewVoteTemplateFlag(state);
	}, []);

	const resourceIsArchived = resourceStatus === ResourceStatus.ARCHIVED;

	const handleChangeActiveTab = useCallback((tab) => {
		setAddNewVoteTemplateFlag(false);
		setVoteTemplateInEditionFlag(false);
		setActiveTab(tab);
		goFirstPage();
	}, [goFirstPage]);

	return (
		<ModalScreen
			bodyClassName="p-0"
			header={(
				<ModalScreenHeader
					onClose={closeModal}
					title={t('UserSettings.SurveyVoteTemplateModal.publicOpinionAnalysis')}
				>
					<Row className="m-0 p-0 w-100">
						<Col xs={10} className="p-0 m-0">
							<ModalScreenHeaderNav className="mt-3">
								<NavItem className="mr-3">
									<NavLink
										title={t('UserSettings.SurveyVoteTemplateModal.surveys')}
										onClick={() => handleChangeActiveTab(SurveyVoteTemplateTab.SURVEY)}
										active={activeTab === SurveyVoteTemplateTab.SURVEY}
									>
										<span>{t('UserSettings.SurveyVoteTemplateModal.surveys')}</span>
										<div className="divider" />
									</NavLink>
								</NavItem>
								<NavItem className="mx-3">
									<NavLink
										title={t('UserSettings.SurveyVoteTemplateModal.voteTemplates')}
										onClick={() => handleChangeActiveTab(SurveyVoteTemplateTab.VOTE_TEMPLATE)}
										active={activeTab === SurveyVoteTemplateTab.VOTE_TEMPLATE}
									>
										<span>{t('UserSettings.SurveyVoteTemplateModal.voteTemplates')}</span>
										<div className="divider" />
									</NavLink>
								</NavItem>
							</ModalScreenHeaderNav>
						</Col>
						<Col xs={2} className="p-0 m-0">
							<div className="d-flex align-itmes-center justify-content-end w-100">
								{!resourceIsArchived && !voteTemplateInEditionFlag && (
									<ButtonPill
										color="primary"
										className="btn-pill mx-1"
										onClick={
											activeTab === SurveyVoteTemplateTab.SURVEY
												? handleOpenSurveyTemplateModal
												: handleChangeAddingNewVoteTemplateFlag(true)
										}
									>
										{t('UserSettings.SurveyVoteTemplateModal.addNew')}
									</ButtonPill>
								)}
							</div>
						</Col>
					</Row>
				</ModalScreenHeader>
			)}
			isOpen={isOpen}
			onClose={closeModal}
		>
			<TabContent activeTab={activeTab}>
				<ModalLazyContent>
					<SurveyVoteTemplateModalContent
						activeTab={activeTab}
						addNewVoteTemplateFlag={addNewVoteTemplateFlag}
						handleChangeAddingNewVoteTemplateFlag={handleChangeAddingNewVoteTemplateFlag}
						resourceIsArchived={resourceIsArchived}
						resourceStatus={resourceStatus}
						setResourceStatus={setResourceStatus}
						setVoteTemplateInEditionFlag={setVoteTemplateInEditionFlag}
						voteTemplateInEditionFlag={voteTemplateInEditionFlag}
					/>
				</ModalLazyContent>
			</TabContent>
		</ModalScreen>
	);
};

SurveyVoteTemplateModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	defaultTab: PropTypes.string,
};

SurveyVoteTemplateModal.defaultProps = {
	defaultTab: SurveyVoteTemplateTab.SURVEY,
};
