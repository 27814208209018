/** @enum */
export const KeyDetectionMode = {
	AI: 'AI',
	DISABLED: 'DISABLED',
	RGB: 'RGB',
};

/** @enum */
export const KeyReplacementMode = {
	BLUR: 'BLUR',
	COLOR: 'COLOR',
	IMAGE: 'IMAGE',
	TRANSPARENT: 'TRANSPARENT',
};

/**
 * @typedef {{
 * 	r: number,
 * 	g: number,
 * 	b: number,
 * }} RGBColor
 *
 * @typedef {{
 * 	detection: {
 *		color?: RGBColor,
 *		mode: KeyDetectionMode,
 *		sensitivity: number,
 *	},
 * 	replacement: {
 * 		blur?: number,
 * 		color?: RGBColor,
 * 		edgeBlur?: number,
 * 		imageUrl?: string,
 * 		mode: KeyReplacementMode,
 *  	transparentColor?: RGBColor,
 *  	transparentColorSensitivity?: number,
 * 	},
 * }} KeyConfig
*/

/** @type {RGBColor} */
export const TRANSPARENT_KEY_DEFAULT_COLOR = { r: 18, g: 32, b: 44 };
export const TRANSPARENT_KEY_DEFAULT_COLOR_SENSITIVITY = 100;

/** @type {KeyConfig} */
export const defaultKeyConfig = {
	detection: {
		color: { r: 0, g: 255, b: 0 },
		mode: KeyDetectionMode.DISABLED,
		sensitivity: 80,
	},
	replacement: {
		blur: 5,
		color: { r: 255, g: 255, b: 255 },
		edgeBlur: 5,
		mode: KeyReplacementMode.BLUR,
		transparentColor: TRANSPARENT_KEY_DEFAULT_COLOR,
		transparentColorSensitivity: TRANSPARENT_KEY_DEFAULT_COLOR_SENSITIVITY,
	},
};

/** @type {KeyConfig} */
export const defaultForcedKeyConfig = {
	...defaultKeyConfig,
	detection: {
		...defaultKeyConfig.detection,
		mode: KeyDetectionMode.AI,
	},
	replacement: {
		...defaultKeyConfig.replacement,
		mode: KeyReplacementMode.TRANSPARENT,
	},
};
