import DEFlagImage from './de.png';
import ESFlagImage from './es.png';
import FRFlagImage from './fr.png';
import ITFlagImage from './it.png';
import USFlagImage from './en.png';

// DEFlag Component
export const DEFlag = () => <img src={DEFlagImage} alt="Germany Flag" style={{ width: 20 }} />;

// ESFlag Component
export const ESFlag = () => <img src={ESFlagImage} alt="Spain Flag" style={{ width: 20 }} />;

// FRFlag Component
export const FRFlag = () => <img src={FRFlagImage} alt="France Flag" style={{ width: 20 }} />;

// ITFlag Component
export const ITFlag = () => <img src={ITFlagImage} alt="Italy Flag" style={{ width: 20 }} />;

// USFlag Component
export const USFlag = () => <img src={USFlagImage} alt="United States Flag" style={{ width: 20 }} />;
