/* eslint-disable react/prop-types */
// @ts-check

import { useEffect, useRef, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, FieldError } from 'react-jsonschema-form-validation';
import { Badge, Button, Fade, FormGroup, Input, InputGroup, Popover, PopoverBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { FaExclamationTriangle, FaTimes } from 'react-icons/fa';

import { useFetchSimulCasts } from '../../../api-hooks/channel/simulCast';
import {
	MuxSimulCastStatusColor,
	SimulCastPlatformKey,
	SimulCastPlatformData,
} from '../../SimulCast/helper';
import { useAuthentication } from '../../Authentication/Authentication';

/** @typedef {import('../../../api/channel/simulCast.dto').ISimulCastDto} ISimulCastDto */
/** @typedef {ISimulCastDto & { status?: string? }} SimulCastPopulated */

/**
 * @param {ISimulCastDto[]} allSimulCasts
 * @param {SimulCastPopulated[]} selectedSimulCasts
 * @returns {ISimulCastDto[]}
 */
const filterSelectedSimulCasts = (allSimulCasts, selectedSimulCasts) => allSimulCasts
	.filter((simul) => !selectedSimulCasts
		.find((selectedSimul) => selectedSimul._id === simul._id
			|| selectedSimul.platformKey === simul.platformKey));

/**
 * @typedef {{
* 	onSelect: (simulCast: ISimulCastDto) => void,
* 	selectedSimulCasts: SimulCastPopulated[],
* 	studioOwnerId: string,
* }} StudioScheduleSettingsFormSocialNetworkSharingAddProps
*/

const StudioScheduleSettingsFormSocialNetworkSharingAdd = (
	/** @type {StudioScheduleSettingsFormSocialNetworkSharingAddProps} */
	{
		onSelect,
		selectedSimulCasts,
		studioOwnerId,
	},
) => {
	const { t } = useTranslation();
	const { user } = useAuthentication();

	const {
		isLoading,
		data: simulCasts,
	} = useFetchSimulCasts({
		channelId: studioOwnerId,
		mode: studioOwnerId === user.sub ? 'all' : undefined,
	});

	const availableSimulCasts = useMemo(
		() => filterSelectedSimulCasts(Object.values(SimulCastPlatformData), selectedSimulCasts),
		[selectedSimulCasts],
	);

	const availableSimulCastsFromLibrary = useMemo(
		() => filterSelectedSimulCasts((simulCasts?.data || []), selectedSimulCasts),
		[selectedSimulCasts, simulCasts?.data],
	);

	const options = useMemo(() => [
		{
			label: t('SettingsForm.SocialNetwork.fromLibrary'),
			options: availableSimulCastsFromLibrary.length > 0
				? availableSimulCastsFromLibrary.map((/** @type {ISimulCastDto} */simulCast) => ({
					value: simulCast._id,
					label: `${simulCast.name} (${SimulCastPlatformData[simulCast.platformKey].name})`,
					simulCast,
				}))
				: [{
					label: t('SettingsForm.SocialNetwork.noSimulCastInLibrary'),
					value: null,
					isDisabled: true,
					simulCast: /** @type {ISimulCastDto} */({}),
				}]
			,
		},
		{
			label: t('SettingsForm.SocialNetwork.addNew'),
			options: availableSimulCasts.map((/** @type {ISimulCastDto} */simulCast) => ({
				value: simulCast._id,
				label: simulCast.name,
				simulCast,
			})),
		},
	], [availableSimulCasts, availableSimulCastsFromLibrary, t]);

	const showSelect = !!(availableSimulCasts.length || availableSimulCastsFromLibrary.length);

	return showSelect && (
		<Select
			classNamePrefix="react-select-light-border"
			closeMenuOnSelect
			isLoading={isLoading}
			isSearchable
			menuPortalTarget={document.body}
			onChange={(
				(/** @type {{ simulCast: ISimulCastDto } | null} */value) => {
					if (value) onSelect(value?.simulCast);
				})}
			options={options}
			placeholder={t('SettingsForm.SocialNetwork.addOne')}
			styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
			value={null}
		/>
	);
};

/**
 * @typedef {{
* 	currentFocus?: boolean,
* 	isEditable?: boolean,
* 	onChange: (simulCastId: string, updatedSimulCast: Partial<ISimulCastDto>) => void,
* 	onRemove: (simulCastId: string) => void,
* 	simulCast: SimulCastPopulated,
* 	simulCastIndex: number,
* }} StudioScheduleSettingsFormSocialNetworkSharingItemProps
*/

const StudioScheduleSettingsFormSocialNetworkSharingItem = (
	/** @type {StudioScheduleSettingsFormSocialNetworkSharingItemProps} */
	{
		currentFocus = false,
		isEditable = false,
		onChange,
		onRemove,
		simulCast,
		simulCastIndex,
	},
) => {
	const { t } = useTranslation();

	const [showLinkedInWarningPopover, setShowLinkedInWarningPopover] = useState(false);

	const inputElement = useRef(/** @type {HTMLInputElement?} */(null));
	useEffect(() => {
		if (inputElement.current && currentFocus) {
			inputElement.current.focus();
		}
	}, [currentFocus]);

	const platformData = SimulCastPlatformData[simulCast.platformKey];
	const PlatformIcon = platformData.icon;

	const showLinkedInWarning = isEditable && simulCast.platformKey === SimulCastPlatformKey.LINKEDIN;

	return (
		<div>
			<div className={isEditable ? '' : 'mb-1'}>
				<strong className="font-size-sm">
					<PlatformIcon className="me-2" style={{ color: platformData.iconColor }} />
					{simulCast.name}
				</strong>
				{showLinkedInWarning && (
					<span
						onMouseEnter={() => setShowLinkedInWarningPopover(true)}
						onMouseLeave={() => setShowLinkedInWarningPopover(false)}
						id="LinkedInWarningIcon"
					>
						<FaExclamationTriangle
							size={12}
							className="ms-2 text-warning"
						/>
					</span>
				)}
				{!isEditable && simulCast.status && (
					<Badge
						className="ms-2"
						color={MuxSimulCastStatusColor[simulCast.status] || 'secondary'}
					>
						{t(`Mux.SimulCast.Status.${simulCast.status}`)}
					</Badge>
				)}
			</div>
			{showLinkedInWarning && (
				<Popover
					placement="bottom"
					isOpen={showLinkedInWarningPopover}
					target="LinkedInWarningIcon"
					container="Session_SocialNetworkSharing"
				>
					<PopoverBody className="text-black">{t('SocialNetwork.Sharing.Item.linkedInWarningMessage')}</PopoverBody>
				</Popover>
			)}
			<FormGroup className="d-flex">
				{isEditable ? (
					<div className="w-100">
						<InputGroup>
							<Field
								autoFocus={!simulCast.url && currentFocus}
								component={Input}
								placeholder={t('SocialNetwork.Sharing.Item.url')}
								type="text"
								name={`simulCast.${simulCastIndex}.url`}
								value={simulCast.url || ''}
								onChange={(
									/** @type {React.ChangeEvent<HTMLInputElement>}*/e,
								) => onChange(simulCast._id, { url: e.target.value })}
								readOnly={simulCast.platformKey === SimulCastPlatformKey.LINKEDIN}
							/>
							<Field
								autoFocus={!!simulCast.url && !simulCast.streamKey && currentFocus}
								component={Input}
								placeholder={t('SocialNetwork.Sharing.Item.streamKey')}
								type="text"
								name={`simulCast.${simulCastIndex}.streamKey`}
								value={simulCast.streamKey || ''}
								onChange={(
									/** @type {React.ChangeEvent<HTMLInputElement>}*/e,
								) => onChange(simulCast._id, { streamKey: e.target.value })}
							/>
							<Button
								className="shadow-none px-2"
								color="neutral-danger"
								title={t('SocialNetwork.Sharing.Item.remove')}
								onClick={() => onRemove(simulCast._id)}
							>
								<FaTimes size={20} />
							</Button>
						</InputGroup>
						<FieldError
							errorMessages={{
								required: () => t('SocialNetwork.Sharing.Item.pleaseEnterURL'),
							}}
							name={`simulCast.${simulCastIndex}.url`}
						/>
						<FieldError
							errorMessages={{
								required: () => t('SocialNetwork.Sharing.Item.pleaseEnterStreamKey'),
							}}
							name={`simulCast.${simulCastIndex}.streamKey`}
						/>
					</div>
				) : (
					<Field
						component={Input}
						type="text"
						value={`${simulCast.url}${simulCast.streamKey}`}
						readOnly
					/>
				)}
			</FormGroup>
		</div>
	);
};

/**
 * @typedef {{
* 		isEditable?: boolean,
* 		onChange?: (selectedSimulCasts: ISimulCastDto[]) => void,
* 		selectedSimulCasts?: ISimulCastDto[],
* 		studioOwnerId?: string,
* }} StudioScheduleSettingsFormSocialNetworkSharingProps
*/

export const StudioScheduleSettingsFormSocialNetworkSharing = (
	/** @type {StudioScheduleSettingsFormSocialNetworkSharingProps} */
	{
		isEditable = false,
		onChange = () => {},
		selectedSimulCasts = [],
		studioOwnerId,
	},
) => {
	const handleSimulCastSelect = (/** @type {ISimulCastDto} */simulCast) => {
		onChange([
			...selectedSimulCasts,
			{
				_id: simulCast._id,
				name: simulCast.name,
				platformKey: simulCast.platformKey,
				streamKey: simulCast.streamKey,
				url: simulCast.url,
			},
		]);
	};

	const handleSimulCastChange = (
		/** @type {string} */ simulCastId,
		/** @type {Partial<ISimulCastDto>} */ updatedSimulCast,
	) => {
		onChange(selectedSimulCasts.map((simul) => {
			if (simul._id === simulCastId) {
				return {
					...simul,
					...updatedSimulCast,
				};
			}
			return simul;
		}));
	};

	const handleSimulCastRemove = (/** @type {string} */ simulCastId) => {
		onChange(selectedSimulCasts.filter(
			(/** @type {ISimulCastDto} */ simul) => simul._id !== simulCastId,
		));
	};

	return (
		<FormGroup>
			{selectedSimulCasts.map((simul, i) => (
				<Fade in key={simul.name}>
					<StudioScheduleSettingsFormSocialNetworkSharingItem
						currentFocus
						isEditable={isEditable}
						onChange={handleSimulCastChange}
						onRemove={handleSimulCastRemove}
						simulCast={simul}
						simulCastIndex={i}
					/>
				</Fade>
			))}
			{isEditable && studioOwnerId && (
				<StudioScheduleSettingsFormSocialNetworkSharingAdd
					onSelect={handleSimulCastSelect}
					selectedSimulCasts={selectedSimulCasts}
					studioOwnerId={studioOwnerId}
				/>
			)}
		</FormGroup>
	);
};

StudioScheduleSettingsFormSocialNetworkSharing.propTypes = {
	isEditable: PropTypes.bool,
	onChange: PropTypes.func,
	selectedSimulCasts: PropTypes.arrayOf(PropTypes.shape({
		_id: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
		streamKey: PropTypes.string,
		url: PropTypes.string,
	}).isRequired),
	studioOwnerId: PropTypes.string,
};

StudioScheduleSettingsFormSocialNetworkSharing.defaultProps = {
	isEditable: false,
	onChange: () => {},
	selectedSimulCasts: [],
	studioOwnerId: undefined,
};
