import { useCallback, useEffect, useRef } from 'react';

const NOT_STARTED = -1;

export const useTrackWatchTime = (videoRef, onVideoWatchTime) => {
	const playStartTime = useRef(NOT_STARTED);

	const previousTime = useRef(0);
	const currentTime = useRef(0);

	const handlePlayDurationFinished = useCallback(() => {
		if (!videoRef.current || playStartTime.current === NOT_STARTED) return;

		const playEndTime = Math.abs(
			(videoRef.current?.currentTime() || 0) - previousTime.current,
		) > 0.5
			? previousTime.current
			: videoRef.current?.currentTime();

		if (playEndTime - playStartTime.current > 0) {
			onVideoWatchTime(playStartTime.current, playEndTime);
		}

		playStartTime.current = NOT_STARTED;
	}, [onVideoWatchTime, videoRef]);

	const onPlay = useCallback(() => {
		playStartTime.current = videoRef.current.currentTime();
	}, [videoRef]);

	const onTimeUpdate = useCallback(() => {
		// helper variables to work around seek logic
		// after seek two more events would occur (if video was playing)
		// "pause" and "play". So if the video was playing and "pause" event was triggered
		// we would try to update watch time, but this.currentTime() would get back time
		// after seek, that's why we store "previousTime" on each time update, and we can
		// use it for watchTime calculation when seek is recognized
		previousTime.current = currentTime.current;
		currentTime.current = videoRef.current.currentTime();
	}, [videoRef]);

	useEffect(() => {
		window.addEventListener('beforeunload', handlePlayDurationFinished);
		return () => {
			handlePlayDurationFinished();
			window.removeEventListener('beforeunload', handlePlayDurationFinished);
		};
	}, [handlePlayDurationFinished]);

	return {
		onPlay,
		onPause: handlePlayDurationFinished,
		onTimeUpdate,
	};
};
