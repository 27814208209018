import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { getLink, Path } from '../../RoutePath';
import './Forbidden.scss';

const ViewUnauthorized = () => {
	const { t } = useTranslation();
	return (
		<div className="h-100 w-100">
			<div className="ViewForbidden h-100 w-100">
				<h2>{t('Forbidden.Unauthorized.notFound')}</h2>
				<p>
					<Button color="link" tag={Link} to={getLink(Path.ROOT)}>{t('Forbidden.Unauthorized.home')}</Button>
				</p>
			</div>
		</div>
	);
};

export default ViewUnauthorized;
