// @ts-check

import { api } from '../../api';

/**
 * @typedef {import('./donationSubscription.dto')
 * .IDonationSubscriptionDto} IDonationSubscriptionDto
 */

export const API_CHANNEL_DONATION_SUBSCRIPTION_PATH = '/channel/donation/subscription';

/**
 * @param {string} channelId
 * @returns {Promise<import('axios').AxiosResponse<IDonationSubscriptionDto>>}
 */
export const fetchDonationSubscription = (channelId) => api.get(
	`${API_CHANNEL_DONATION_SUBSCRIPTION_PATH}/byChannelId/${channelId}`,
);

/**
 * @typedef {{
 *    data: IDonationSubscriptionDto[],
 *    totalItemsCount: number,
 * }} DonationSubscriptionsGetResponse
 */

/**
 * @param {number | undefined} itemsPerPage
 * @param {number | undefined} currentPage
 * @returns {Promise<import('axios').AxiosResponse<DonationSubscriptionsGetResponse>>}
 */
export const fetchDonationSubscriptions = (itemsPerPage, currentPage) => api.get(
	API_CHANNEL_DONATION_SUBSCRIPTION_PATH,
	{
		params: {
			itemsPerPage,
			currentPage,
		},
	},
);

/**
 * @typedef {import('../../../components/Channel/Donate/Subscription/PaypalButton')
* .DonationSubscriptionCreate} DonationSubscriptionCreate
*/

/**
 * @param {DonationSubscriptionCreate} donationSubscription
 * @returns {Promise<import('axios').AxiosResponse<IDonationSubscriptionDto>>}
 */
export const createDonationSubscription = (donationSubscription) => api.post(
	API_CHANNEL_DONATION_SUBSCRIPTION_PATH,
	{
		...donationSubscription,
	},
);

/**
 * @typedef {Object} PayPalSubscriptionResponse
 * @property {string} id
 * @property {string} status
 * @property {string} status_update_time
 * @property {string} start_time
 * @property {string} create_time
 * @property {string} update_time
 * @property {Object} plan
 * @property {string} plan.id
 * @property {string} plan.name
 * @property {string} plan.description
 * @property {string} plan.status
 * @property {string} plan.create_time
 * @property {string} plan.update_time
 * @property {Object} billing_info
 * @property {Object} billing_info.outstanding_balance
 * @property {string} billing_info.cycle_executions
 * @property {Object} billing_info.last_payment
 * @property {Object} billing_info.next_billing_time
 * @property {Object} billing_info.final_payment_time
 * @property {Object} billing_info.failed_payments_count
 * @property {Object} subscriber
 * @property {Object} subscriber.name
 * @property {Object} subscriber.email_address
 * @property {Object} subscriber.shipping_address
 * @property {Object} shipping_amount
 * @property {Object} application_context
 * @property {string} custom_id
 */

/**
 * @param {DonationSubscriptionCreate} donationSubscription
 * @returns {Promise<import('axios').AxiosResponse<PayPalSubscriptionResponse>>}
 */
export const createPaypalDonationSubscription = (donationSubscription) => api.post(
	`${API_CHANNEL_DONATION_SUBSCRIPTION_PATH}/paypal`,
	{
		...donationSubscription,
	},
);

/**
 * @param {{
 * 		paypalSubscriptionId: string,
 * 		includeFee: boolean,
 * 		goal: string,
 * 		currency: string,
 * }} param0
 * @returns {Promise<import('axios').AxiosResponse<IDonationSubscriptionDto>>}
 */
export const capturePaypalDonationSubscription = (
	{ paypalSubscriptionId, includeFee, goal, currency },
) => api.post(
	`${API_CHANNEL_DONATION_SUBSCRIPTION_PATH}/paypal/${paypalSubscriptionId}/capture`,
	{ includeFee, goal, currency },
);

/**
 * @param {string} subscriptionId
 * @returns {Promise<import('axios').AxiosResponse<IDonationSubscriptionDto>>}
 */
export const cancelDonationSubscription = (subscriptionId) => api.delete(
	`${API_CHANNEL_DONATION_SUBSCRIPTION_PATH}/${subscriptionId}`,
);
