import { createContext, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

export const VuMeterContext = createContext({});
export const usePlayerLiveVuMeter = () => useContext(VuMeterContext);

export const PlayerLiveVuMeterProvider = ({
	children,
}) => {
	const [isEnabled, setIsEnabled] = useState(true);

	const contextValue = useMemo(() => ({
		isEnabled,
		setIsEnabled,
	}), [
		isEnabled,
		setIsEnabled,
	]);

	return (
		<VuMeterContext.Provider value={contextValue}>
			{children}
		</VuMeterContext.Provider>
	);
};

PlayerLiveVuMeterProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
