import { useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';

import './Stepper.scss';

export const Stepper = ({ currentStep, setStep, steps }) => {
	const handleChangeStep = useCallback((step) => {
		setStep(step);
	}, [setStep]);

	return (
		<div className="Stepper mb-3 mt-1 w-100 d-flex justify-content-center align-items-center">
			<div className="w-100">
				<div className="StepsWrapper d-flex justify-content-between m-auto align-items-center">
					{steps.map((_, i) => (
						<Fragment key={`stepper-step-${steps[i]}`}>
							<div
								className="mx-3 d-flex flex-column align-items-center cursor-pointer"
								onClick={() => handleChangeStep(i + 1)}
							>
								<p className={`Step text-center ${currentStep === i + 1 ? 'bg-primary' : 'bg-secondary'} text-white d-flex align-items-center justify-content-center`}>
									{i + 1}
								</p>
								<h5 className="font-size-sm text-secondary">{steps[i]}</h5>
							</div>
							{i !== steps.length - 1 && (
								<div className="divider" />
							)}
						</Fragment>
					))}
				</div>
			</div>
		</div>
	);
};

Stepper.propTypes = {
	currentStep: PropTypes.number.isRequired,
	setStep: PropTypes.func.isRequired,
	steps: PropTypes.arrayOf(PropTypes.string).isRequired,
};
