import { useCallback, useEffect, useState } from 'react';

export const useInternetStatus = () => {
	const [isOnline, setIsOnline] = useState(navigator.onLine);

	const handleOnline = useCallback(() => {
		setIsOnline(true);
	}, []);

	const handleOffline = useCallback(() => {
		setIsOnline(false);
	}, []);

	useEffect(() => {
		window.addEventListener('online', handleOnline);
		window.addEventListener('offline', handleOffline);

		return () => {
			window.removeEventListener('online', handleOnline);
			window.removeEventListener('offline', handleOffline);
		};
	}, [handleOffline, handleOnline]);

	const fetchData = useCallback(async () => {
		try {
			const response = await fetch('.');
			if (response.ok) {
				setIsOnline(true);
			} else {
				setIsOnline(false);
			}
		} catch (error) {
			setIsOnline(false);
		}
	}, []);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	return isOnline;
};
