// @ts-check

import { createContext, useContext } from 'react';

/** @typedef {import('./Inputs').InputConfig} InputConfig */
/** @typedef {import('./Inputs').InputDeviceInfo} InputDeviceInfo */
/** @typedef {import('./Inputs').AVAILABLE_MOBILE_CAMS} AVAILABLE_MOBILE_CAMS */

/**
 * @typedef {{
 * activateAudioInput: (configId: number) => void,
 * activateVideoInput: (configId: number) => void,
 * addNewConfig: ({ label, id }: { label: string; id: number; }) => void,
 * audioInputDevices: InputDeviceInfo[],
 * changeAudioInputDevice: (configId: number, id: string, audioDeviceLabel: string) => void,
 * changeConfigName: (configId: number, name: string) => void,
 * changeVideoInputDevice: (
 * 	configId: number, id: string | undefined, videoDeviceLabel: string | undefined
 * ) => void,
 * currentlyEnabledDefaultDevices: InputDeviceInfo[],
 * deactivateAudioInput: (configId: number) => void,
 * deactivateVideoInput: (configId: number) => void,
 * defaultFaceingMode: AVAILABLE_MOBILE_CAMS,
 * deleteConfig: (configId: number) => void,
 * getAvailableInputId: (inputId: string, configId: number) => string | undefined,
 * inputsConfig: InputConfig[],
 * setDefaultFaceingMode: (TARGET: AVAILABLE_MOBILE_CAMS) => void,
 * setEnabled: (enabled: React.SetStateAction<boolean>) => void,
 * setInputDevices: (devices: React.SetStateAction<InputDeviceInfo[]>) => void,
 * setInputsConfig: (configs: React.SetStateAction<InputConfig[]>) => void,
 * setPartialInputsConfig: (config: Partial<InputConfig>) => void,
 * setTargetCamera: (TARGET: AVAILABLE_MOBILE_CAMS) => void
 * swapFrontAndBackCamera: () => Promise<void>,
 * updateInputDevices: () => Promise<void>,
 * videoInputDevices: InputDeviceInfo[],
 * changeAudioConstraints: (configId: number, constraints: Object) => void,
 * }} IInputsContext
 */

export const InputsContext = createContext(
	/** @type {IInputsContext | undefined} */(undefined),
);

export const useInputs = () => {
	const inputsContext = useContext(InputsContext);
	// type guard (removes undefined type)
	if (!inputsContext) {
		throw new Error('useInputs must be used within a Inputs');
	}
	return inputsContext;
};
