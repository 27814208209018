/* eslint-disable react/prop-types */
// @ts-check

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * @typedef {{
 * 	children?: React.ReactNode,
 * 	elementRef?: React.RefObject<HTMLElement>,
 * }} ScrollToTopProps
 */

const ScrollToTop = (
	/** @type {ScrollToTopProps} */
	{ children, elementRef },
) => {
	const { pathname } = useLocation();
	const element = elementRef ? elementRef.current : window;

	useEffect(() => {
		if (!element) return;
		element.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	}, [pathname, element]);

	return children || null;
};

export default ScrollToTop;
