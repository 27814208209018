// @ts-check

/**
 * !!! IMPORTANT :
 * every route path that is not related to a channel
 * must start with a 4-chararacters long maximum root path in order
 * to avoid conflicts with channel paths (channel hashtags are 5 characters long minimum)
 */

export const PublicPathPrefix = import.meta.env.VITE_PROJECT === 'beeyou'
	? '/bytv'
	: '/comv';

/** @enum {string} */
export const Path = {
	CHANNEL: '/:hashtag',
	CHANNEL_FRAME: '/:hashtag/frame',
	CHECKOUT: `${PublicPathPrefix}/checkout`,
	DONATE: `${PublicPathPrefix}/donate`,
	HELP: `${PublicPathPrefix}/help`,
	HELP_USERGUIDE: `${PublicPathPrefix}/help/userGuide`,
	HELP_ANCHOR: `${PublicPathPrefix}/help/:anchor`,
	HOME: `${PublicPathPrefix}`,
	HOME_ANCHOR: `${PublicPathPrefix}/:anchor`,
	HOME_WATCH_VIDEOS: `${PublicPathPrefix}/watch`,
	HOME_MEET_BEES: `${PublicPathPrefix}/meet`,
	LEGAL_COOKIES: `${PublicPathPrefix}/legal/cookies`,
	LEGAL_TERMS: `${PublicPathPrefix}/legal/terms`,
	MEDIATEST: `${PublicPathPrefix}/mediatest`,
	NEWUSERINFO: `${PublicPathPrefix}/welcome`,
	NEWUSERINFO_ANCHOR: `${PublicPathPrefix}/welcome/:anchor`,
	POST: '/:hashtag/post/:slug',
	PRICES: `${PublicPathPrefix}/prices`,
	ROOT: '/',
	SEARCH: `${PublicPathPrefix}/search`,
	SLACK: `${PublicPathPrefix}/slack`,
	SLACK_CONNECT: `${PublicPathPrefix}/slack/connect`,
	STUDIO: `${PublicPathPrefix}/studio`,
	STUDIO_HOST: '/:hashtag/studio/:code',
	STUDIO_HOST_ELEMENTS: '/:hashtag/studio/:code/elements',
	STUDIO_ACTIVITY: '/:hashtag/studio/:code/activity',
	STUDIO_CHAT: '/:hashtag/studio/:code/chat',
	STUDIO_TOOLS: '/:hashtag/studio/:code/tools',
	STUDIO_PARTICIPANTS: '/:hashtag/studio/:code/participants',
	STUDIO_TEMPLATES: '/:hashtag/studio/:code/templates',
	STUDIO_OPERATOR: '/:hashtag/o/:code',
	STUDIO_PARTICIPANT: '/:hashtag/p/:code',
	SURVEY: `${PublicPathPrefix}/survey/:surveyCode`,
	SURVEY_STUDIO_ATTENDANCE: `${PublicPathPrefix}/survey/studio/attendance`,
	WEBRTCTEST: `${PublicPathPrefix}/webrtctest`,
	BLOG_PREVIEW: `${PublicPathPrefix}/blog/preview/:id`,
	BLOG_EDIT: `${PublicPathPrefix}/blog/:id`,
	NEWSLETTER_UNSUBSCRIBE: `${PublicPathPrefix}/newsletter/unsubscribe`,
	NEWSLETTER: `${PublicPathPrefix}/newsletter/:id`,
	WATCH_LINK: '/:hashtag/watch/:watchLinkId',
	WATCH_LINK_EMBED: '/:hashtag/watch/:watchLinkId/embed',
	VOD: '/:hashtag/vod/:id',
	EMBED_VOD: `${PublicPathPrefix}/embed/vod/:id`,
	// TODO: STUDIO_VIEWER and STUDIO_VIEWER_FRAME are deprecated and
	// should be removed in the future, they are replaced by WATCH_LINK and WATCH_LINK_EMBED
	// We keep them for now to do a "progressive" migration for the users
	STUDIO_VIEWER: '/:hashtag/v/:code', // TODO: to remove
	STUDIO_VIEWER_FRAME: '/:hashtag/v/:code/frame', // TODO: to remove
};

/** @enum {string} */
export const DevPath = {
	STUDIO: '/dev/studio',
	STUDIO_PARTICIPANT: '/dev/p/:hashtag',
	CHANNEL_FRAME_TEST: '/:hashtag/frametest',
	WATCH_LINK_EMBED_TEST: '/:hashtag/watch/:watchLinkId/embedtest',
};

/**
 * Convert a path to an URL with the given path params
 *
 * @param {Path | DevPath | import('./RoutePathAdmin').AdminPath} path
 * @param {{ [key: string]: string | undefined }} pathParams
 * @returns {string}
 */
export function getLink(path, pathParams = {}) {
	const pathParamsKeys = Object.keys(pathParams);
	const pathParamsCount = (path.match(/:/g) || []).length;

	if (pathParamsCount !== pathParamsKeys.length) {
		// eslint-disable-next-line no-console
		console.warn(`Path params count mismatch for path ${path}`);
	}

	return pathParamsKeys.reduce((acc, key) => {
		const value = pathParams[key];
		if (!value) return acc;

		return acc.replace(`:${key}`, value);
	}, path);
}

/**
 * @param {string} hashtag
 * @returns {string}
 */
export const getChannelLink = (hashtag) => getLink(Path.CHANNEL, { hashtag });

/**
 * @param {string} hashtag
 * @param {string} watchLinkId
 * @param {boolean} [isEmbed]
 * @returns {string}
 */
export const getWatchLink = (hashtag, watchLinkId, isEmbed = false) => getLink(
	isEmbed ? Path.WATCH_LINK_EMBED : Path.WATCH_LINK,
	{ hashtag, watchLinkId },
);

export const Redirects = [
	{ from: '/c/:hashtag/o/:code', to: '/:hashtag/o/:code' },
	{ from: '/c/:hashtag/p/:code', to: '/:hashtag/p/:code' },
	{ from: '/c/:hashtag/studio/:code', to: '/:hashtag/studio/:code' },
	{ from: '/c/:hashtag/studio/:code/elements', to: '/:hashtag/studio/:code/elements' },
	{ from: '/c/:hashtag/studio/:code/activity', to: '/:hashtag/studio/:code/activity' },
	{ from: '/c/:hashtag/studio/:code/chat', to: '/:hashtag/studio/:code/chat' },
	{ from: '/c/:hashtag/studio/:code/tools', to: '/:hashtag/studio/:code/tools' },
	{ from: '/c/:hashtag/studio/:code/participants', to: '/:hashtag/studio/:code/participants' },
	{ from: '/c/:hashtag/studio/:code/templates', to: '/:hashtag/studio/:code/templates' },
	{ from: '/c/:hashtag/v/:code', to: '/:hashtag/v/:code' },
	{ from: '/c/:hashtag/vod/:id', to: '/:hashtag/vod/:id' },
	{ from: '/c/:hashtag/post/:id', to: '/:hashtag/post/:id' },
	{ from: '/c/:hashtag', to: '/:hashtag' },
];

export const RedirectsFromPath = Redirects.map(({ from }) => from);

/**
 * @param {string} fromPath
 * @param {{ [key: string]: string | undefined }} fromParams
 * @returns {string}
 * @throws {Error} - Thrown if no redirect is found for the given path
 */
export const getRedirectToLink = (fromPath, fromParams) => {
	const redirect = Redirects.find(({ from }) => from === fromPath);
	if (!redirect) throw new Error(`No redirect found for path ${fromPath}`);
	return getLink(redirect.to, fromParams);
};

/**
 * @param {string} path
 * @param {{ [key: string]: string }} pathParams
 * @returns {string}
 */
export const getAbsoluteLink = (path, pathParams) => {
	const link = getLink(path, pathParams);
	return `${window.origin}${link}`;
};
