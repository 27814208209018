import slugify from 'slugify';

const tagify = (str = '') => {
	str = slugify(
		str,
		{
			replacement: '-',
			// remove: /[*+~.()'"!:@`·]/g,
			lower: true,
		},
	);
	str = str.replace(/^[^0-9a-z-]+/, '');
	str = str.replace(/[^0-9a-z-]+/, '-');
	str = str.replace(/--+/, '-');
	str = str.replace(/^-+(.)/, '$1');
	return str;
};

export default tagify;
