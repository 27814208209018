import categoryOptions from '../../../lib/categoryOptions';

export const Gender = {
	MALE: 'MALE',
	FEMALE: 'FEMALE',
	NOT_DISCLOSED: 'NOT_DISCLOSED',
	LGBTQ_PLUS: 'LGBTQ_PLUS',
};

const name = {
	type: 'string',
	minLength: 2,
	maxLength: 50,
};

const nickname = {
	type: 'string',
	minLength: 2,
	maxLength: 50,
};

export const tag = {
	type: 'string',
	minLength: 2,
	maxLength: 50,
	pattern: '^[0-9a-z-]+$',
};

export const properties = {
	avatar: {
		oneOf: [
			{ type: 'string' },
			{
				type: 'object',
				properties: {
					blob: { type: 'object' },
				},
				required: ['blob'],
			},
		],
	},
	bio: { type: 'string', maxLength: 400 },
	cover: {
		oneOf: [
			{ type: 'string' },
			{
				type: 'object',
				properties: {
					blob: { type: 'object' },
				},
				required: ['blob'],
			},
		],
	},
	hashtag: tag,
	nickname,
	interests: { type: 'array' },
	birthdateDay: { type: 'string' },
	birthdateMonth: { type: 'string' },
	birthdateYear: { type: 'string' },
	country: { type: 'string' },
	phoneNumber: { type: 'string', phone: true },
	firstname: name,
	gender: { type: 'string', enum: Object.values(Gender) },
	lastname: name,
	email: { type: 'string', format: 'email' },
	zipcode: { type: 'string' },
	category: {
		type: 'string',
		enum: categoryOptions,
	},
	tags: {
		type: 'array',
		items: tag,
	},
};

export const updateSchema = {
	type: 'object',
	additionalProperties: false,
	properties,
	required: [
		'hashtag',
		'nickname',
		'country',
		'category',
	],
};

export const getSchema = (profile) => {
	const requiredMap = {
		hashtag: true,
		nickname: true,
		country: !!profile?.country,
		category: !!profile?.category,
		firstname: !!profile?.firstname,
		lastname: !!profile?.lastname,
		zipcode: !!profile?.zipcode,
	};

	const required = Object.keys(requiredMap).filter((key) => requiredMap[key]);

	return {
		type: 'object',
		additionalProperties: true,
		properties,
		required,
	};
};
