import { api } from '../../api';

export const API_CHANNEL_DONATION_PATH = '/channel/donation';

export const createDonation = (channelId, payload) => api.post(
	API_CHANNEL_DONATION_PATH,
	{
		...payload,
		channelId,
	},
);
