/* eslint-disable react/prop-types */
// @ts-check
import { createContext, useContext, useMemo, useState } from 'react';
import { useSelector } from '../ReactVideo/Provider';
import { selectTalkbackStreamsPublications } from '../../store/selectors/publications';

/**
 * @typedef {{
 * 	isDisabled: boolean,
 * 	isMuted: boolean,
 *  setIsMuted: React.Dispatch<React.SetStateAction<boolean>>,
 * }} ILiveVolumeContext
 */

export const LiveVolumeContext = createContext(
	/** @type {ILiveVolumeContext | undefined} */(undefined),
);

export const usePlayerLiveVolume = () => {
	const liveVolumeContext = useContext(LiveVolumeContext);
	// type guard (removes undefined type)
	if (!liveVolumeContext) {
		throw new Error('usePlayerLiveVolume must be used within a PlayerLiveVolumeProvider');
	}
	return liveVolumeContext;
};

/**
 * @typedef {{
 * 	children: React.ReactNode,
 * }} PlayerLiveVolumeProviderProps
 */

export const PlayerLiveVolumeProvider = (
	/** @type {PlayerLiveVolumeProviderProps} */
	{
		children,
	},
) => {
	const [isMuted, setIsMuted] = useState(false);

	const talkbackStreamsPublications = useSelector(selectTalkbackStreamsPublications);
	const isTalkbackReceiving = talkbackStreamsPublications.length > 0;

	const contextValue = useMemo(() => ({
		isDisabled: isTalkbackReceiving,
		isMuted: isMuted || isTalkbackReceiving,
		setIsMuted,
	}), [
		isMuted,
		isTalkbackReceiving,
		setIsMuted,
	]);

	return (
		<LiveVolumeContext.Provider value={contextValue}>
			{children}
		</LiveVolumeContext.Provider>
	);
};
