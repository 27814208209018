// @ts-check
/* eslint-disable react/prop-types */

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Progress } from 'reactstrap';

import { getWatchLink } from '../../RoutePath';
import { ButtonPill } from '../../components/Button';
import { formatDate } from '../../components/Presale/PresaleUtil';
import { BANNER_SIZE, getFileUrl } from '../../lib/file';
import './Presale.scss';

/**
 * @typedef {{
 *  studio: {
 *  	banner: string,
 *  	channelHashtag: string,
 *  	description: string,
 *  	owner: {
 *	 		nickname: string,
 *  	},
 *  	presale: {
 * 			viewer: {
 *  			active: boolean,
 * 				discount: boolean,
 * 				endDate: string,
 * 				limitedDates: boolean,
 *  			limitedTickets: boolean,
 * 				price: number,
 * 				startDate: string,
 * 				ticketLimit: number,
 * 				ticketsSold: number,
 * 			},
 * 		},
 *  	price: {
 *  		viewer: number,
 *  	},
 *  	startAt: string,
 *  	subject: string,
 *  	watchLinkId: string,
 *  },
 * 	host: boolean,
 * }} PresaleDisplayProps
 */

const PresaleDisplay = (
	/** @type {PresaleDisplayProps} */
	{
		studio,
		host,
	},
) => {
	const { t } = useTranslation();
	if (!studio?.presale?.viewer?.active) { return null; }

	const { subject, startAt, owner, description, presale, banner } = studio;
	const { viewer } = presale;

	// Placeholder presale variables
	const limitedDate = viewer.limitedDates;
	const startDate = new Date(viewer.startDate);
	const endDate = new Date(viewer.endDate);

	const { limitedTickets } = viewer;
	const { ticketLimit } = viewer;
	const { ticketsSold } = viewer;

	const today = new Date();

	const ticketsRemaining = ticketLimit - ticketsSold;
	const soldOut = (limitedTickets && ticketsRemaining === 0);

	let displayPrice;
	let totalEarned;

	if (viewer.discount === true) {
		displayPrice = viewer.price;
		totalEarned = ticketsSold * viewer.price;
	} else {
		displayPrice = studio.price.viewer;
		totalEarned = ticketsSold * studio.price.viewer;
	}

	const style = {
		backgroundImage: `url(${getFileUrl({ name: banner }, BANNER_SIZE.width)})`,
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
	};

	return (
		!host ? (
			// Buyer view
			<div>
				<div className="h-100 p-5 d-flex flex-column align-items-center justify-content-center" style={style} />
				<div className="d-flex flex-column align-items-center justify-content-center">
					<div className="Presale_Title">
						{subject}
					</div>
					<div className="Presale_Details">
						{formatDate(new Date(startAt))}
					</div>
					<div className="Presale_Details">
						{t('Presale.HostedBy')} {owner.nickname}
					</div>
					<div className="Presale_Body">
						{description}
					</div>
					{!soldOut && (
						<div className="Presale_Tickets">
							<div>
								{t('Presale.PricePer')} {viewer.price}
							</div>
							<div>
								{t('Presale.RegPrice')} {studio.price.viewer}
							</div>
							{limitedTickets && (
								<div>
									{t('Presale.TicketsAvailable')} {ticketsRemaining}
								</div>
							)}
						</div>
					)}
					{limitedTickets && (
						<div className="Presale_Progress_Buyer">
							<Progress value={ticketsSold || 0} max={ticketLimit || 0} />
						</div>
					)}
					{!soldOut && limitedDate && startDate < today && today < endDate && (
						<div>
							<div className="Presale_Tickets">
								<div className="Presale_Button">
									<ButtonPill
										className="ChannelSliderItem_button mr-auto"
										color="primary"
										tag={Link}
										to={getWatchLink(studio.channelHashtag, studio.watchLinkId)}
									>
										{t('Presale.BuyTicket')} {displayPrice ? `${t('Slider.Slider.for')} ${displayPrice}p` : ''}
									</ButtonPill>
								</div>
							</div>
						</div>
					)}
					{limitedTickets && ticketsRemaining === 0 ? (
						<div className="Presale_Body Presale_Footer">
							{t('Presale.NoMoreTickets')}
						</div>
					) : (
						<div className="Presale_Body Presale_Footer">
							{t('Presale.WillEnd')} {formatDate(endDate)}.
						</div>
					)}
					{limitedDate && startDate > today && (
						<div>
							<div className="Presale_Body Presale_Footer">
								{t('Presale.WillBegin')} {formatDate(startDate)}.
							</div>
						</div>
					)}
					{limitedDate && endDate < today && (
						<div>
							<div className="Presale_Body_Footer">
								{t('Presale.Ended')} {formatDate(endDate)}.
							</div>
						</div>
					)}
					{!limitedDate && !soldOut && (
						<div className="Presale_Button">
							<ButtonPill
								className="ChannelSliderItem_button mr-auto"
								color="primary"
								tag={Link}
								to={getWatchLink(studio.channelHashtag, studio.watchLinkId)}
							>
								{t('Presale.BuyTicket')} {displayPrice ? `${t('Slider.Slider.for')} ${displayPrice}p` : ''}
							</ButtonPill>
						</div>
					)}
				</div>
			</div>
		) : (
			// Host view
			<div className="d-flex flex-column align-items-center justify-content-center">
				<div className="Presale_Title">
					{subject}
				</div>
				<div className="Presale_Details">
					{formatDate(new Date(startAt))}
				</div>
				<div className="Presale_Tickets">
					<div>
						{t('Presale.PricePer')} {viewer.price}
					</div>
					<div>
						{t('Presale.RegPrice')} {studio.price.viewer}
					</div>
				</div>
				{limitedTickets && (
					<div className="Presale_Progress">
						<Progress value={ticketsSold || 0} max={ticketLimit || 0} />
					</div>
				)}
				<div>
					<div className="Presale_Tickets">
						<div>
							{t('Presale.TicketsSold')}:	 {ticketsSold}
						</div>
						{limitedTickets && (
							<div>
								{t('Presale.TicketsAvailable')} {ticketsRemaining}
							</div>
						)}
						<div>
							{t('Presale.TotalEarned')}: {totalEarned}
						</div>
					</div>
				</div>
				{limitedDate && startDate < today && today < endDate && (
					<div className="Presale_Body Presale_Footer">
						{t('Presale.WillEnd')} {formatDate(endDate)}.
					</div>
				)}
				{limitedDate && startDate > today && (
					<div className="Presale_Body Presale_Footer">
						{t('Presale.WillBegin')} {formatDate(startDate)}.
					</div>
				)}
				{limitedDate && endDate < today && (
					<div className="Presale_Body Presale_Footer">
						{t('Presale.Ended')} {formatDate(endDate)}.
					</div>
				)}
			</div>
		)
	);
};

export default PresaleDisplay;
