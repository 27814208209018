// @ts-check
/* eslint-disable react/prop-types */

import { useState } from 'react';
import { FormGroup, Input, Label, Alert } from 'reactstrap';
import { Form, Field, FieldError } from 'react-jsonschema-form-validation';
import { useAsyncCallback } from 'react-async-hook';
import { useTranslation } from 'react-i18next';

import { useAsyncErrorLog } from '../../lib/hooks';
import FormSubmit from '../Form/Submit';
import * as publicGuestApi from '../../api/public/guest';
import { ResourceAccessCard } from './Card';
import { useAuthentication } from '../Authentication/Authentication';
import { ButtonPill, ButtonPillOutline } from '../Button';
import { useLogin } from '../Login/Provider';

const schema = {
	type: 'object',
	additionalProperties: false,
	properties: {
		nickname: {
			type: 'string',
			minLength: 5,
			maxLenght: 50,
		},
	},
	required: [
		'nickname',
	],
};

/**
 * @typedef {{
 * 	nickname: string,
 * }} NicknameFormData
 */

/**
 * @typedef {{
 * 	hideGuest?: boolean
 * 	onContinue?: () => void,
 * }} ResourceAccessNicknameProps
 */

export const ResourceAccessNickname = (
	/** @type {ResourceAccessNicknameProps} */
	{
		hideGuest,
		onContinue,
	},
) => {
	const { t } = useTranslation();
	const { openLogin, openSignup } = useLogin();
	const { guest, refreshSession } = useAuthentication();
	const [data, setData] = useState(/** @type {NicknameFormData} */({ nickname: guest.nickname || '' }));

	const handleSubmitAsync = useAsyncCallback(async () => {
		// Update current guest and refresh session,
		// or call onContinue if nickname is the same
		if (data.nickname !== guest.nickname) {
			await publicGuestApi.updateGuestMe({
				_id: guest._id,
				nickname: data.nickname,
			});
			return refreshSession();
		}
		return onContinue && onContinue();
	});

	const { error } = handleSubmitAsync;
	const unexpectedErrorMsg = t('Global.error');

	useAsyncErrorLog({ error });

	const handleChange = (/** @type {NicknameFormData} */newData) => {
		setData(newData);
	};

	const handleClick = () => {
		openLogin();
	};

	const handleClickOnboarding = () => {
		openSignup();
	};

	const submitErrorMessage = error && (
		/** @type {import('axios').AxiosError} */(error).response?.data?.message
		|| unexpectedErrorMsg
	);

	const title = t(hideGuest ? 'ResourceAccess.Nickname.titleSignin' : 'ResourceAccess.Nickname.title');

	return (
		<ResourceAccessCard>
			<h3 className="mb-4">
				{title}
			</h3>
			<p>
				<ButtonPillOutline color="secondary" onClick={handleClick}>{t('Forbidden.Forbidden.signIn')}</ButtonPillOutline>
				<ButtonPill className="ml-2" color="primary" onClick={handleClickOnboarding}>{t('Forbidden.Forbidden.signUp')}!</ButtonPill>
			</p>
			{!hideGuest && (
				<Form
					className="text-left"
					data={data}
					schema={schema}
					onChange={handleChange}
					onSubmit={() => handleSubmitAsync.execute()}
				>
					<FormGroup>
						<Label className="text-secondary">{t('ResourceAccess.Nickname.nicknameLabel')}</Label>
						<Field
							component={Input}
							name="nickname"
							value={data.nickname}
							type="text"
							maxLength="50"
						/>
						<FieldError name="nickname" />
					</FormGroup>
					{submitErrorMessage && (
						<Alert color="danger">
							{submitErrorMessage}
						</Alert>
					)}
					<footer className="mt-4 mx-auto d-flex">
						<FormSubmit
							className="btn-pill shadow-none btn btn-primary ml-auto"
							loading={handleSubmitAsync.loading}
							disabled={handleSubmitAsync.loading}
						>
							{t('ResourceAccess.Nickname.continue')}
						</FormSubmit>
					</footer>
				</Form>
			)}
		</ResourceAccessCard>
	);
};
