import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { useTranslation } from 'react-i18next';
import { Button, Container } from 'reactstrap';
import { ChannelStatus } from '../../lib/ChannelStatus';
import { FormLabel } from '../Form/Label';
import { ProfileAvatar } from '../Profile/Avatar';

import './StepOrganizationChallenge.scss';
import { useProfile } from '../Profile/ProfileContext';

export const LoginStepOrganizationChallenge = ({
	onSubmit,
	onBack,
	availableOptions,
	excludeCurrentOrganization,
}) => {
	const { t } = useTranslation();

	const { profile } = useProfile();

	const handleSubmitAsync = useAsyncCallback((organizationId) => onSubmit(organizationId));

	useEffect(() => {
		if (!availableOptions?.length && onBack) {
			onBack();
		}
	}, [availableOptions, onBack]);

	useEffect(() => {
		if (handleSubmitAsync.error) {
			onBack();
		}
	}, [onBack, handleSubmitAsync.error]);

	const options = !excludeCurrentOrganization
		? availableOptions
		: availableOptions.filter((option) => option.organization?._id !== profile?.organization);

	return (
		<Container fluid>
			<div className="d-flex flex-wrap flex-column">
				{options?.map(({
					_id,
					organization,
					...channel
				}) => (
					<div
						key={`organization-select-${_id}`}
						className={clsx(
							'px-4 py-3 my-1 border-2 border-darker rounded d-flex flex-wrap align-items-center',
							{ 'bg-gray': channel.status !== ChannelStatus.ACTIVE },
						)}
					>
						<div className="d-flex flex-column flex-grow-1">
							<div className="d-flex justify-content-between align-items-center">
								<FormLabel className="mb-0 mr-4">{t('Login.StepOrganizationChallenge.channel')}</FormLabel>
								<FormLabel className="mb-0 mr-4">{t('Login.StepOrganizationChallenge.organization')}</FormLabel>
							</div>
							<div className="d-flex justify-content-between align-items-center">
								<div className="d-flex align-items-center">
									<ProfileAvatar avatarUrl={channel.avatar} />
									<span>{channel.nickname}</span>
									<span className="ml-2 mr-4">({channel.hashtag})</span>
								</div>
								<div>
									<strong>{organization.name}</strong>
									<small className="ml-2 mr-4">({channel.organizationRole?.name})</small>
								</div>
							</div>
						</div>
						{channel.status === ChannelStatus.ACTIVE ? (
							<Button
								color="primary"
								className="flex-shrink-0 mt-2"
								onClick={() => handleSubmitAsync.execute(organization._id)}
							>
								{t('Login.StepOrganizationChallenge.access')}
							</Button>
						) : (
							<div className="ml-4 flex-shrink-0">{t('Login.StepOrganizationChallenge.disabled')}</div>
						)}
					</div>
				))}
				{!!onBack && (
					<div className="mt-5 d-flex justify-content-end mobileCancel">
						<Button color="secondary" onClick={onBack}>{t('Login.StepOrganizationChallenge.cancel')}</Button>
					</div>
				)}
			</div>
		</Container>
	);
};

LoginStepOrganizationChallenge.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	onBack: PropTypes.func.isRequired,
	availableOptions: PropTypes.arrayOf({}),
	excludeCurrentOrganization: PropTypes.bool,
};

LoginStepOrganizationChallenge.defaultProps = {
	availableOptions: [],
	excludeCurrentOrganization: false,
};
