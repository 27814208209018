// @ts-check

import { api } from '../api';

export const API_PUBLIC_VOTE_PATH = '/public/vote';

/**
 * @param {string} voteId
 * @param {string} answerId
 * @returns {Promise<import('axios').AxiosResponse<void>>}
 */
export const publicVoteOnVote = (voteId, answerId) => api.put(
	`${API_PUBLIC_VOTE_PATH}/${voteId}/vote/${answerId}`,
);
