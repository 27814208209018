const { VITE_CONTENT_ENDPOINT, VITE_MUX_THUMBNAIL } = import.meta.env;
const THUMBNAIL_WIDTH = 320;

const baseUrlContent = VITE_CONTENT_ENDPOINT;
const baseUrlMuxThumbnail = VITE_MUX_THUMBNAIL;

export const getThumbnailUrlFromMux = (playbackId, time) => (
	`${baseUrlMuxThumbnail}${playbackId}/thumbnail.png?width=${THUMBNAIL_WIDTH}${time !== undefined ? `&time=${time}` : ''}`
);
export const getFileUrl = (file) => `${baseUrlContent}${file.name}`;

export const getCrossOriginFileUrl = (fileUrl) => {
	const parsedFileUrl = new URL(fileUrl);
	parsedFileUrl.searchParams.set('crossorigin', 'anonymous');
	return parsedFileUrl.href;
};

export const getThumbnail = (video) => {
	switch (video?.status) {
	case 'ready':
		return video.playbackId && getThumbnailUrlFromMux(video.playbackId, video?.thumbnailTimeCode);
	default:
		return video.filename && getFileUrl({ name: video.filename });
	}
};
