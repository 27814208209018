import PropTypes from 'prop-types';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { isInIframe } from '../../lib/isInFrame';
import { useHandleBeforeinstallprompt, useHandleInstallApp, useIsAppAlreadyInstalled } from '../../lib/serviceWorkerHook';
import { isSafari } from '../../lib/userAgent';

// const SHOW_PWA_MODAL = 2;
// const LOCAL_STORAGE_KEY_PWA = 'beeyou_pwa_modal';

export const PwaContext = createContext({});

export const usePwa = () => useContext(PwaContext);

export const PwaProvider = ({ children }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [deferredPrompt, setDeferredPrompt] = useState(null);
	const openPromt = useHandleBeforeinstallprompt();
	const installApp = useHandleInstallApp();
	const isInstalled = useIsAppAlreadyInstalled();
	// const [shouldShowModal, setShouldShowModal] = useState(false);
	const shouldShowModal = false;
	const isIFrame = isInIframe();

	// TODO: Disable PWA modal until feature is reviewed

	// useEffect(() => {
	// 	const pwaInstalationModal = parseInt(localStorage.getItem(LOCAL_STORAGE_KEY_PWA), 10) || 0;
	// 	if (pwaInstalationModal <= SHOW_PWA_MODAL) {
	// 		localStorage.setItem(LOCAL_STORAGE_KEY_PWA, pwaInstalationModal + 1);
	// 		setShouldShowModal(true);
	// 	}
	// }, []);

	const openModal = useCallback((force = false) => {
		if (shouldShowModal || force) {
			setIsOpen(true);
		}
	}, [shouldShowModal]);

	const closeModal = useCallback(() => {
		setIsOpen(false);
	}, []);

	const handleSetDeferredPrompt = useCallback((e) => {
		setDeferredPrompt(e);
		openModal();
	}, [openModal]);

	const handleInstallApp = useCallback(() => {
		installApp(handleSetDeferredPrompt, deferredPrompt, closeModal);
	}, [deferredPrompt, handleSetDeferredPrompt, installApp, closeModal]);

	const openInstallationModal = useCallback(() => {
		if (isIFrame) return;
		if (!isInstalled && isSafari) {
			openModal();
		} else if (!isInstalled) {
			openPromt(handleSetDeferredPrompt);
		}
	}, [handleSetDeferredPrompt, isIFrame, isInstalled, openModal, openPromt]);

	useEffect(() => {
		openInstallationModal();
	}, [openInstallationModal]);

	const openModalCustom = useCallback(() => {
		if (!isInstalled) {
			openModal(true);
		}
	}, [isInstalled, openModal]);

	const value = useMemo(() => ({
		isOpen,
		openModal,
		closeModal,
		handleInstallApp,
		openInstallationModal,
		openModalCustom,
	}), [closeModal, isOpen, openModal, handleInstallApp, openInstallationModal, openModalCustom]);

	return (
		<PwaContext.Provider value={value}>
			{children}
		</PwaContext.Provider>
	);
};

PwaProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
