import { v4 as uuidv4 } from 'uuid';

import { setSessionId as setAxiosSessionId } from './axios';
import { setSessionId as setRollbarSessionId, setUser } from './rollbar';
import { identity } from '../components/Authentication/Authentication';

export const sessionId = localStorage.sessionId || uuidv4();
localStorage.sessionId = sessionId;

setAxiosSessionId(sessionId);
setRollbarSessionId(sessionId);

const updateRollbarUser = () => {
	identity.getUser()
		.then((user) => {
			setUser({ hashtag: user.preferred_username, _id: user.sub });
		})
		.catch(() => {
			setUser(null);
		});
};
updateRollbarUser();
identity.on('login', updateRollbarUser);
identity.on('logout', updateRollbarUser);
identity.on('refresh', updateRollbarUser);
