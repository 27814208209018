import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { FaTeamspeak, FaFileInvoice, FaQuestionCircle, FaUsers } from 'react-icons/fa';

import { Path, getLink } from '../../RoutePath';
import { useAuthentication } from '../../components/Authentication/Authentication';
import { useProfile } from '../../components/Profile/ProfileContext';
import { usePwa } from '../../components/Pwa/Provider';
import { LiveIcon, MeetIcon, WatchIcon } from '../SidebarMenu/icons';

const isBeeyou = import.meta.env.VITE_PROJECT === 'beeyou';
const isComvision = import.meta.env.VITE_PROJECT === 'comvision';

export const useSidebarItems = () => {
	const { user } = useAuthentication();
	const { profile } = useProfile();
	const { t } = useTranslation();
	const location = useLocation();
	const { openModalCustom } = usePwa();

	const isCurrentLocationHome = /\/|\/home\/|\/help\//.test(location.pathname);

	const secondarySidebarItems = useMemo(() => [
		(isBeeyou || !!user) && {
			label: t('SidebarMenu.Index.live'),
			icon: <LiveIcon className="icon" />,
			to: getLink(Path.HOME_ANCHOR, { anchor: 'coming-up-live' }),
			toggleSidebarOnClick: !isCurrentLocationHome,
		},
		(isBeeyou || !!user) && {
			label: isComvision ? t('CV.myTeam') : t('SidebarMenu.Index.meetTheBees'),
			icon: isComvision ? <FaTeamspeak className="icon" /> : <MeetIcon className="icon" />,
			to: getLink(Path.HOME_MEET_BEES),
			toggleSidebarOnClick: false,
		},
		(isBeeyou || !!user) && {
			label: t('SidebarMenu.Index.watchVideos'),
			icon: <WatchIcon className="icon" />,
			to: getLink(Path.HOME_WATCH_VIDEOS),
			toggleSidebarOnClick: false,
		},
		profile && isBeeyou && {
			label: t('SidebarMenu.Index.posts'),
			icon: <FaFileInvoice className="icon" />,
			to: `/${profile.hashtag}?tab=POSTS`,
			toggleSidebarOnClick: false,
		},
	], [isCurrentLocationHome, profile, t, user]);

	const helpCenterParentItem = useMemo(() => ({
		label: t('SidebarMenu.Index.helpCenter'),
		icon: <FaQuestionCircle className="icon" />,
	}), [t]);

	const helpCenterItems = useMemo(() => [
		{ label: t('SidebarMenu.Index.gettingStarted'), to: getLink(Path.HELP_ANCHOR, { anchor: 'gettingStarted' }), hideSidebarMenu: true, toggleSidebarOnClick: true },
		{ label: t('SidebarMenu.Index.userGuide'), to: getLink(Path.HELP_USERGUIDE), hideSidebarMenu: true, toggleSidebarOnClick: true },
		{ label: t('SidebarMenu.Index.faqs'), to: getLink(Path.HELP_ANCHOR, { anchor: 'faq' }), hideSidebarMenu: true, toggleSidebarOnClick: true },
		{ label: t('SidebarMenu.Index.troubleshooting'), to: getLink(Path.HELP_ANCHOR, { anchor: 'troubleshooting' }), hideSidebarMenu: true, toggleSidebarOnClick: true },
		{ label: t('SidebarMenu.Index.InstallApp'), to: location.pathname, onClick: openModalCustom, hideSidebarMenu: true, toggleSidebarOnClick: true },
	], [location, openModalCustom, t]);

	const aboutParentItem = useMemo(() => ({
		label: t('SidebarMenu.Index.about'),
		icon: <FaUsers className="icon" />,
	}), [t]);

	const aboutSidebarItems = useMemo(() => [
		{ label: t('NewUser.Banner.Blue.title'), to: getLink(Path.NEWUSERINFO_ANCHOR, { anchor: 'about' }), hideSidebarMenu: true, toggleSidebarOnClick: true },
		{ label: t('NewUser.Banner.Green.title'), to: getLink(Path.NEWUSERINFO_ANCHOR, { anchor: 'monetization' }), hideSidebarMenu: true, toggleSidebarOnClick: true },
		{ label: t('NewUser.Banner.Teal.title'), to: getLink(Path.NEWUSERINFO_ANCHOR, { anchor: 'philosophy' }), hideSidebarMenu: true, toggleSidebarOnClick: true },
		{ label: t('NewUser.Banner.Yellow.title'), to: getLink(Path.NEWUSERINFO_ANCHOR, { anchor: 'users' }), hideSidebarMenu: true, toggleSidebarOnClick: true },
	], [t]);

	return useMemo(() => ({
		secondarySidebarItems,
		helpCenterItems,
		helpCenterParentItem,
		aboutParentItem,
		aboutSidebarItems,
	}), [
		secondarySidebarItems,
		helpCenterItems,
		helpCenterParentItem,
		aboutParentItem,
		aboutSidebarItems,
	]);
};
