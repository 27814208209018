import PropTypes from 'prop-types';
import { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { MyOrganizationModalTab, OrganizationSettingsContext, OrganizationSettingsModal } from './Context';
import { MyOrganizationModal } from './MyOrganizationModal';
import { OrganizationExpiredModal } from './Expired/Modal';
import { useAuthentication } from '../Authentication/Authentication';
import { useFetchDefaultOrganization } from '../../api-hooks/public/organization';
import { UserSettingsModal, useUserSettings } from '../UserSettings/Context';
import { MembershipModalTab } from '../UserSettings/MembershipModalTab';
import { useFetchGraphGlobalsSubscriptions } from '../../api-hooks/graph/globals';
import { useProfile } from '../Profile/ProfileContext';

const isBeeyou = import.meta.env.VITE_PROJECT === 'beeyou';

export const OrganizationSettingsProvider = ({ children }) => {
	const [modalOpen, setModalOpen] = useState();
	const [activeTab, setActiveTab] = useState();

	const [openOrganizationExpiredModal, setOpenOrganizationExpiredModal] = useState(false);

	const { profile } = useProfile();

	const { isLoggedIn } = useAuthentication();
	const { data: defaultOrganization } = useFetchDefaultOrganization();

	const { data: subscriptions } = useFetchGraphGlobalsSubscriptions();

	const organizationSubscription = subscriptions?.organization;

	const {
		openModal,
		modalOpen: userSettingsModalOpen,
		activeTab: userSettingsActiveTab,
	} = useUserSettings();

	const closeOrganizationModal = useCallback(() => {
		setModalOpen(undefined);
		setActiveTab(undefined);
	}, []);

	const openOrganizationModal = useCallback((tab = MyOrganizationModalTab.PROFILE) => {
		setModalOpen(OrganizationSettingsModal.MY_ORGANIZATION);
		setActiveTab(tab);
	}, []);

	const profileOrganizationId = (profile?.organization && typeof profile.organization === 'object')
		? profile.organization._id
		: profile?.organization;

	const isDataLoaded = !!(defaultOrganization && profile && organizationSubscription);
	const isDefaultOrganization = defaultOrganization?._id === profileOrganizationId;
	const isOrganizationAccessRevoked = !isDefaultOrganization
		&& !organizationSubscription?.canAccess;

	const isCheckoutTabOpen = !!userSettingsModalOpen
		&& userSettingsActiveTab === MembershipModalTab.CHECKOUT;

	useEffect(() => {
		if (
			isBeeyou && isLoggedIn && isDataLoaded
				&& isOrganizationAccessRevoked
				&& !isCheckoutTabOpen
		) {
			if (profile.organizationOwner) {
				openModal(UserSettingsModal.MEMBERSHIP, MembershipModalTab.PRICING);
			} else {
				setOpenOrganizationExpiredModal(true);
			}
		}
	}, [
		isCheckoutTabOpen,
		isDataLoaded,
		isLoggedIn,
		isOrganizationAccessRevoked,
		openModal,
		profile,
	]);

	const context = useMemo(() => ({
		activeTab,
		closeOrganizationModal,
		modalOpen,
		openOrganizationModal,
		setActiveTab,
		setModalOpen,
	}), [
		activeTab,
		closeOrganizationModal,
		modalOpen,
		openOrganizationModal,
	]);

	return (
		<OrganizationSettingsContext.Provider value={context}>
			{children}
			<Suspense fallback={null}>
				{modalOpen === OrganizationSettingsModal.MY_ORGANIZATION && (
					<MyOrganizationModal
						isOpen
						activeTab={activeTab}
						setActiveTab={setActiveTab}
					/>
				)}
				<OrganizationExpiredModal
					open={openOrganizationExpiredModal}
					closeModal={() => setOpenOrganizationExpiredModal(false)}
				/>
			</Suspense>
		</OrganizationSettingsContext.Provider>
	);
};

OrganizationSettingsProvider.propTypes = {
	children: PropTypes.node,
};

OrganizationSettingsProvider.defaultProps = {
	children: undefined,
};
