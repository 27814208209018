import { useCallback } from 'react';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';
import * as videosApi from '../../api/channel/videos';
import { uploadMultipart } from '../../api/upload';
import { axiosMutationWrapper, axiosQueryWrapper } from '../utils/axios-wrapper';
import { GFX_QUERY_KEYS } from './gfx';
import { isVideoVod } from '../../lib/video';
import { CHANNEL_FEED_QUERY_KEYS, FEED_ITEMS_PER_PAGE } from './feed';

const ITEMS_PER_PAGE = 20;

export const VIDEO_QUERY_KEYS = {
	fetchVideos: (channelId, mode, itemsPerPage, currentPage) => ['api', 'channel', 'videos', 'fetch', { channelId, mode, itemsPerPage, currentPage }],
	fetchVideosBase: () => ['api', 'channel', 'videos', 'fetch'],
	getDownloadLink: (videoId) => ['api', 'channel', 'videos', 'download', videoId],
};

export const useFetchVideos = (channelId, mode, itemsPerPage, currentPage) => useQuery(
	VIDEO_QUERY_KEYS.fetchVideos(channelId, mode, itemsPerPage, currentPage),
	axiosQueryWrapper(videosApi.fetchVideos, channelId, mode, itemsPerPage, currentPage),
);

export const useInfinityFetchVideos = (
	channelId,
	mode,
	itemsPerPage = ITEMS_PER_PAGE,
) => useInfiniteQuery(
	VIDEO_QUERY_KEYS.fetchVideos(channelId, mode, itemsPerPage),
	({ pageParam = 1 }) => axiosQueryWrapper(
		videosApi.fetchVideos,
		channelId,
		mode,
		itemsPerPage,
		pageParam,
	)(),
	{
		getNextPageParam: (lastPage, allPages) => {
			const currentItemsLenght = allPages.reduce((acc, curr) => acc + curr.data.length, 0);
			if (currentItemsLenght < lastPage.totalItemsCount) {
				return allPages.length + 1;
			}
			return undefined;
		},
	},
);

export const useUploadVideo = () => {
	const queryClient = useQueryClient();

	return useMutation(async ({
		channelId,
		videoFile,
		onUploadProgress,
	}) => {
		const { blob } = videoFile;
		const filename = await uploadMultipart(blob, onUploadProgress);
		const video = {
			label: videoFile.label,
			vodDescription: isVideoVod(videoFile) ? videoFile?.vodDescription : undefined,
			vodPrice: videoFile?.vodPrice || 0,
			areCommentsDisabled: videoFile?.areCommentsDisabled || false,
			mimeType: blob.type,
			filename,
			fileSize: blob.size,
			isGfx: videoFile.isGfx || false,
			isShared: videoFile.isShared || undefined,
			thumbnailTimeCode: videoFile.thumbnailTimeCode,
			uploader: videoFile.uploader,
			audienceType: videoFile.audienceType,
			currencies: videoFile.currencies,
			playAds: videoFile.playAds,
			layerConfig: videoFile.layerConfig,
			visibility: videoFile.visibility,
			clip: videoFile.clip,
			addBeeyouWatermark: videoFile.addBeeyouWatermark || false,
		};

		const videoCreated = await axiosMutationWrapper(videosApi.createVideo)({ channelId, video });

		return videoCreated;
	}, {
		onSuccess: (data) => {
			queryClient.invalidateQueries(VIDEO_QUERY_KEYS.fetchVideosBase());
			queryClient.invalidateQueries(GFX_QUERY_KEYS.fetchGfx());
			queryClient.invalidateQueries(CHANNEL_FEED_QUERY_KEYS.infinityFetchChannelFeed(
				data?.owner, FEED_ITEMS_PER_PAGE,
			));
		},
	});
};

export const useCreateShareableLink = () => {
	const queryClient = useQueryClient();

	return useMutation(
		axiosMutationWrapper(videosApi.createShareableLink),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(VIDEO_QUERY_KEYS.fetchVideosBase());
			},
		},
	);
};

export const useUpdateVideo = () => {
	const queryClient = useQueryClient();

	return useMutation(async (videoData) => {
		const video = {
			_id: videoData._id,
			label: videoData.label,
			vodDescription: videoData.vodDescription,
			vodPrice: videoData.vodPrice,
			areCommentsDisabled: videoData.areCommentsDisabled,
			audienceType: videoData.audienceType,
			currencies: videoData.currencies,
			playAds: videoData.playAds,
			thumbnailTimeCode: videoData.thumbnailTimeCode,
			layerConfig: videoData.layerConfig,
			visibility: videoData.visibility,
			clip: videoData.clip,
		};

		return axiosMutationWrapper(videosApi.updateVideo)(video);
	}, {
		onSuccess: () => {
			queryClient.invalidateQueries(VIDEO_QUERY_KEYS.fetchVideosBase());
			queryClient.invalidateQueries(GFX_QUERY_KEYS.fetchGfx());
		},
	});
};

export const useDeleteVideo = () => {
	const queryClient = useQueryClient();

	return useMutation(
		axiosMutationWrapper(videosApi.deleteVideo),
		{
			onSuccess: (data) => {
				queryClient.invalidateQueries(VIDEO_QUERY_KEYS.fetchVideosBase());
				queryClient.invalidateQueries(GFX_QUERY_KEYS.fetchGfx());
				queryClient.invalidateQueries(CHANNEL_FEED_QUERY_KEYS.infinityFetchChannelFeed(
					data?.owner._id, FEED_ITEMS_PER_PAGE,
				));
			},
		},
	);
};

export const useDeleteShareableLink = () => {
	const queryClient = useQueryClient();

	return useMutation(
		axiosMutationWrapper(videosApi.deleteShareableLink),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(VIDEO_QUERY_KEYS.fetchVideosBase());
			},
		},
	);
};

export const useCloneVideo = () => {
	const queryClient = useQueryClient();

	return useMutation(async (video) => {
		const videoData = {
			clip: video.clip,
			label: video.label,
			visibility: video.visibility,
			vodDescription: isVideoVod(video) && video.vodDescription ? video.vodDescription : '',
			vodPrice: isVideoVod(video) && video.vodPrice ? video.vodPrice : 0,
			areCommentsDisabled: isVideoVod(video) && video.areCommentsDisabled
				? video.areCommentsDisabled : false,
			thumbnailTimeCode: video.thumbnailTimeCode,
			addBeeyouWatermark: video.addBeeyouWatermark || false,
		};
		const data = await axiosMutationWrapper(videosApi.cloneVideo)({
			cloneFromVideoId: video.cloneFrom,
			videoData,
		});

		return data;
	}, {
		onSuccess: () => {
			queryClient.invalidateQueries(VIDEO_QUERY_KEYS.fetchVideosBase());
			queryClient.invalidateQueries(GFX_QUERY_KEYS.fetchGfx());
		},
	});
};

export const useUpdateRecord = () => {
	const queryClient = useQueryClient();

	return useCallback((channelId, mode, record, itemsPerPage,
		currentPage) => {
		queryClient.setQueryData(
			VIDEO_QUERY_KEYS.fetchVideos(channelId, mode, itemsPerPage, currentPage),
			(videos) => (
				{
					data: videos?.data?.map((video) => (video._id === record._id ? record : video)),
					totalItemsCount: videos?.totalItemsCount,
				}
			),
		);
	}, [queryClient]);
};

export const useRevalidateVideos = () => {
	const queryClient = useQueryClient();
	return useCallback((channelId, mode, itemsPerPage = ITEMS_PER_PAGE) => {
		queryClient.invalidateQueries(VIDEO_QUERY_KEYS.fetchVideos(channelId, mode, itemsPerPage));
	}, [queryClient]);
};
