import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import { ButtonPill } from '../Button';
import { PublicPathPrefix } from '../../RoutePath';

export const NewUserLearnMoreButton = ({
	className,
	Component,
	anchor,
}) => {
	const { t } = useTranslation();

	return (
		<Component
			className={className}
			tag={Link}
			to={`${PublicPathPrefix}/welcome/${anchor}`}
		>
			{t('NewUser.Button.LearnMore')}
		</Component>
	);
};

NewUserLearnMoreButton.propTypes = {
	className: PropTypes.string,
	Component: PropTypes.elementType,
	anchor: PropTypes.string,
};

NewUserLearnMoreButton.defaultProps = {
	className: undefined,
	Component: ButtonPill,
	anchor: `${PublicPathPrefix}/welcome`,
};
