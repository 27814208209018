/* eslint-disable react/prop-types */
// @ts-check
/* eslint-disable no-nested-ternary */

import { useCallback, useMemo } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, Pagination, PaginationItem, UncontrolledDropdown } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { usePagination, ITEMS_PER_PAGE_OPTIONS } from './Context';

import './ItemLine.scss';

/**
 * @typedef {{
 * 	isDisabled?: boolean;
 * }} PaginationItemLineProps
 */

export const PaginationItemLine = (
	/** @type {PaginationItemLineProps} */
	{
		isDisabled = false,
	},
) => {
	const { t } = useTranslation();
	const {
		currentPage,
		itemsPerPage,
		changeItemsPerPage,
		itemsCount,
	} = usePagination();

	const itemsPerPageOptions = useMemo(() => ITEMS_PER_PAGE_OPTIONS
		.map((value) => ({ value, label: t(`Pagination.ItemLine.itemsPerPage.${value}`) })), [t]);

	const handleChangeItemsPerPage = useCallback((
		/** @type {typeof itemsPerPageOptions[number]} */option,
	) => () => {
		changeItemsPerPage(option.value);
	}, [changeItemsPerPage]);

	return (
		<Pagination listClassName="flex-wrap justify-content-center">
			{itemsCount > 0 ? (
				<PaginationItem className="d-flex align-items-center mr-2">
					<UncontrolledDropdown direction="up">
						<DropdownToggle caret className="ItemLine__toggle shadow-none border-0 bg-transparent content-dark" color="" disabled={isDisabled}>
							{itemsPerPage !== -1 ? itemsPerPage : t('Pagination.ItemLine.all')} {t('Pagination.ItemLine.items')} {itemsPerPage !== -1 ? t('Pagination.ItemLine.perPage') : ''}
						</DropdownToggle>
						<DropdownMenu end className="w-100 bg-light">
							{itemsPerPageOptions.map((option) => (
								<DropdownItem
									key={`select-items-per-page-${option.value}`}
									onClick={handleChangeItemsPerPage(option)}
									className="ItemLine__option"
								>
									{option.label}
								</DropdownItem>
							))}
						</DropdownMenu>
					</UncontrolledDropdown>
				</PaginationItem>
			) : null}
			<PaginationItem className="d-flex align-items-center text-secondary">
				{itemsPerPage !== -1 && itemsCount > 0
					? `${t('Pagination.ItemLine.showing')} ${
						(currentPage - 1) * itemsPerPage + 1
					} - ${
						(currentPage * itemsPerPage) > itemsCount ? itemsCount : currentPage * itemsPerPage
					} ${t('Pagination.ItemLine.outOf')} ${itemsCount}`
					: (itemsCount > 0 ? `${t('Pagination.ItemLine.totalItems')}: ${itemsCount}` : `${t('Pagination.ItemLine.totalItems')}: 0`)}
			</PaginationItem>
		</Pagination>
	);
};
