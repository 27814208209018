import PropTypes from 'prop-types';
import { Field, FieldError } from 'react-jsonschema-form-validation';
import { FormGroup, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { VerificationType } from '../../../lib/VerificationType';
import { InputVerification, InputVerificationOrientation } from '../../Input/Verification';

export const VerifiedEmailInput = ({ email, orientation }) => {
	const { t } = useTranslation();

	return (
		<FormGroup>
			<InputVerification
				value={email}
				type={VerificationType.EMAIL}
				orientation={orientation}
				input={(
					<Field
						component={Input}
						name="email"
						placeholder={t('Input.Verified.email')}
						type="email"
						value={email || ''}
					/>
				)}
			/>
			<FieldError
				name="email"
				errorMessages={{
					format: () => 'Invalid email',
				}}
			/>
		</FormGroup>
	);
};

VerifiedEmailInput.propTypes = {
	email: PropTypes.string,
	orientation: PropTypes.oneOf(Object.values(InputVerificationOrientation)),
};

VerifiedEmailInput.defaultProps = {
	email: undefined,
	orientation: InputVerificationOrientation.VERTICAL,
};
