import { useMutation, useQuery, useQueryClient } from 'react-query';
import * as opeartorsApi from '../../api/channel/operators';
import { useProfile } from '../../components/Profile/ProfileContext';
import { axiosMutationWrapper, axiosQueryWrapper } from '../utils/axios-wrapper';

export const OPERATOR_QUERY_KEYS = {
	fetchOperatorsByChannelId: (channelId) => ['api', 'channel', 'operators', 'byChannelId', channelId],
	fetchMyHosts: () => ['api', 'channe', 'operators', 'myHosts'],
	fetchOperatorCandidates: () => ['api', 'channel', 'operators', 'candidates'],
};

export const useFetchOperatorsByChannelId = (channelId, isLoggedIn) => useQuery(
	OPERATOR_QUERY_KEYS.fetchOperatorsByChannelId(channelId),
	axiosQueryWrapper(opeartorsApi.fetchOperatorsByChannelId, channelId),
	{ enabled: !!channelId && !!isLoggedIn },
);

// we don't want to cache hosts, since change doesn't depend on us
export const useFetchMyHosts = () => useQuery(
	OPERATOR_QUERY_KEYS.fetchMyHosts(),
	axiosQueryWrapper(opeartorsApi.fetchMyHosts),
	{ staleTime: 0 },
);

export const useFetchOperatorCandidates = () => useQuery(
	OPERATOR_QUERY_KEYS.fetchOperatorCandidates(),
	axiosQueryWrapper(opeartorsApi.fetchOperatorCandidates),
);

export const useAddOperators = () => {
	const queryClient = useQueryClient();
	const { profile } = useProfile();

	return useMutation(
		axiosMutationWrapper(opeartorsApi.addOperators),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(OPERATOR_QUERY_KEYS.fetchOperatorsByChannelId(profile._id));
				queryClient.invalidateQueries(OPERATOR_QUERY_KEYS.fetchOperatorCandidates);
			},
		},
	);
};

export const useRemoveOperator = () => {
	const queryClient = useQueryClient();
	const { profile } = useProfile();

	return useMutation(
		axiosMutationWrapper(opeartorsApi.removeOperator),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(OPERATOR_QUERY_KEYS.fetchOperatorsByChannelId(profile._id));
				queryClient.invalidateQueries(OPERATOR_QUERY_KEYS.fetchOperatorCandidates);
			},
		},
	);
};
