// @ts-check
/* eslint-disable no-underscore-dangle */
// eslint-disable-next-line import/no-extraneous-dependencies
import { SoupSession } from '@technomiam/soup-client';
import {
	connect as connectSignaling,
	disconnect as disconnectSignaling,
} from './ws/signaling';

/** @import { Publication } from '../lib/store/publication'; */

/** @type {SoupSession?} */
let _soupSession = null;

export function soupSession() {
	return _soupSession;
}

// window.localStorage.setItem('debug', 'mediasoup-client*');

/**
 * @param {any} connectionConfig // TODO: type
 * @param {string} hashtag
 * @returns {Promise<SoupSession>}
 */
export async function connect(connectionConfig, hashtag) {
	const signaling = await connectSignaling(connectionConfig);
	_soupSession = new SoupSession(signaling, hashtag);
	return _soupSession;
}

export function disconnect() {
	if (!_soupSession) return;
	_soupSession.leave();
	_soupSession.destroy();
	_soupSession = null;
	disconnectSignaling();
}

export async function join() {
	await _soupSession?.join();
}

/**
 * @param {MediaStreamTrack} track
 * @param {Partial<Publication['appData']>} appData
 * @param {any} encodings // TODO: type
 * @returns {Promise<any>} // TODO: type
 */
export async function publishTrack(track, appData, encodings) {
	return _soupSession?.publishTrack(track, appData, encodings);
}

/**
 * @param {{ id: string }} track
 * @returns {Promise<void>}
 */
export async function unpublishTrack(track) {
	return _soupSession?.unpublishTrack(track);
}

/**
 * @param {{ id: string }} track
 * @param {Partial<Publication['appData']>} appData
 * @returns {Promise<void>}
 */
export async function updateTrack(track, appData) {
	return _soupSession?.updateTrack(track, { appData });
}
