import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useMatch } from 'react-router-dom';

import { useFetchOperatorsByChannelId } from '../../api-hooks/channel/operators';
import { useAuthentication } from '../Authentication/Authentication';
import { useProfile } from '../Profile/ProfileContext';
import { useActiveStudio } from './Active/Context';
import { useStudio } from './Context';
import { getLink, Path } from '../../RoutePath';

export const useCurrentStudio = () => {
	const { profile } = useProfile();
	const { isLoggedIn } = useAuthentication();

	const {
		activeStudio,
		isActiveStudioStarted,
		estimation: activeEstimation,
		handleStudioCharge: handleActiveStudioCharge,
		isEstimationBellowLimit: isActiveEstimationBellowLimit,
	} = useActiveStudio();
	const {
		studio,
		isStudioStarted,
		estimation,
		handleStudioCharge,
		isEstimationBellowLimit,
	} = useStudio();

	const hasOtherStudio = useMemo(
		() => !!studio && studio._id !== activeStudio?._id,
		[activeStudio, studio],
	);

	const currentStudio = useMemo(
		() => (hasOtherStudio ? studio : activeStudio),
		[activeStudio, hasOtherStudio, studio],
	);

	const { data: studioOperators } = useFetchOperatorsByChannelId(
		currentStudio?.owner?._id,
		isLoggedIn,
	);
	const currentStudioOperatorIds = useMemo(
		() => (studioOperators ? studioOperators.map((o) => o.channelId) : []),
		[studioOperators],
	);

	const isCurrentStudioStarted = useMemo(
		() => (hasOtherStudio ? isStudioStarted : isActiveStudioStarted),
		[hasOtherStudio, isActiveStudioStarted, isStudioStarted],
	);

	const currentStudioChannel = useMemo(
		() => (hasOtherStudio ? studio?.owner : activeStudio?.owner),
		[activeStudio, hasOtherStudio, studio],
	);

	const isOperatorRoute = useMatch({ path: getLink(
		Path.STUDIO_OPERATOR,
		{ hashtag: currentStudio?.owner?.hashtag, code: currentStudio?.code },
	) });

	const isCurrentStudioOperatedByMe = useMemo(
		() => profile && currentStudioOperatorIds.includes(profile._id) && isOperatorRoute?.isExact,
		[profile, currentStudioOperatorIds, isOperatorRoute?.isExact],
	);

	const currentEstimation = useMemo(
		() => (hasOtherStudio ? estimation : activeEstimation),
		[estimation, hasOtherStudio, activeEstimation],
	);

	const isCurrentEstimationBellowLimit = useMemo(
		() => (hasOtherStudio ? isEstimationBellowLimit : isActiveEstimationBellowLimit),
		[hasOtherStudio, isActiveEstimationBellowLimit, isEstimationBellowLimit],
	);

	const handleStudiosChargeRef = useRef({
		handleStudioCharge,
		handleActiveStudioCharge,
	});
	useEffect(() => {
		handleStudiosChargeRef.current = {
			handleStudioCharge,
			handleActiveStudioCharge,
		};
	});

	const handleCurrentStudioCharge = useCallback((notification) => {
		handleStudiosChargeRef.current.handleStudioCharge(notification);
		handleStudiosChargeRef.current.handleActiveStudioCharge(notification);
	}, []);

	return useMemo(() => ({
		currentStudio,
		isCurrentStudioStarted,
		currentStudioChannel,
		isCurrentStudioMine: !hasOtherStudio,
		isCurrentStudioOperatedByMe,
		currentEstimation,
		isCurrentEstimationBellowLimit,
		handleCurrentStudioCharge,
	}), [
		currentStudio,
		isCurrentStudioStarted,
		currentStudioChannel,
		hasOtherStudio,
		isCurrentStudioOperatedByMe,
		currentEstimation,
		isCurrentEstimationBellowLimit,
		handleCurrentStudioCharge,
	]);
};
