// @ts-check

import { createContext, useContext } from 'react';

/**
 * @typedef {{
* 	handleEventVodNew: ({ vod: { watchLinkId }}: { vod: { watchLinkId: string } }) => void,
*   newVodAvailable?: { watchLinkId: string },
* }} IChannelWatchContext
*/

export const ChannelWatchContext = createContext(
	/** @type {IChannelWatchContext | undefined} */(undefined),
);

export const useChannelWatch = () => {
	const channelWatchContext = useContext(ChannelWatchContext);
	// type guard (removes undefined type)
	if (!channelWatchContext) {
		throw new Error('useChannelWatch must be used within a ChannelWatchProvider');
	}
	return channelWatchContext;
};
