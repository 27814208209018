// @ts-check

import { getConnect, getDisconnect, getSocket } from '@technomiam/wsapi-client';

const { VITE_WSAPI_URL } = import.meta.env;
const CHANNEL_URL = `${VITE_WSAPI_URL}/channel`;

export const connectChannelSockets = getConnect(CHANNEL_URL);
export const disconnectChannelSockets = getDisconnect(CHANNEL_URL);
export const socket = getSocket(CHANNEL_URL);

/**
 * @typedef {(listener: (data: any) => void) => () => void} OnEvent
 */

/** @type {OnEvent} */
export const onEventAdLaunch = (listener) => {
	const sock = socket();

	sock.on('event.ad.launch', listener);
	return () => {
		sock.off('event.ad.launch', listener);
	};
};

/** @type {OnEvent} */
export const onEventAuction = (listener) => {
	const sock = socket();

	sock.on('event.auction', listener);
	return () => {
		sock.off('event.auction', listener);
	};
};

/** @type {OnEvent} */
export const onEventVotesActiveNew = (listener) => {
	const sock = socket();

	sock.on('event.vote.active.new', listener);
	return () => {
		sock.off('event.vote.active.new', listener);
	};
};

/** @type {OnEvent} */
export const onEventVoteActiveUpdate = (listener) => {
	const sock = socket();

	sock.on('event.vote.active.update', listener);
	return () => {
		sock.off('event.vote.active.update', listener);
	};
};

/** @type {OnEvent} */
export const onEventChatMessage = (listener) => {
	const sock = socket();
	sock.on('event.chat.message', listener);
	return () => {
		sock.off('event.chat.message', listener);
	};
};

/** @type {OnEvent} */
export const onEventChatMessageUpdate = (listener) => {
	const sock = socket();
	sock.on('event.chat.message.update', listener);
	return () => {
		sock.off('event.chat.message.update', listener);
	};
};

/** @type {OnEvent} */
export const onEventChatMessageDelete = (listener) => {
	const sock = socket();
	sock.on('event.chat.message.delete', listener);
	return () => {
		sock.off('event.chat.message.delete', listener);
	};
};

/** @type {OnEvent} */
export const onEventChatMessagesClear = (listener) => {
	const sock = socket();
	sock.on('event.chat.messages.clear', listener);
	return () => {
		sock.off('event.chat.messages.clear');
	};
};

/** @type {OnEvent} */
export const onEventChatStatus = (listener) => {
	const sock = socket();
	sock.on('event.chat.status', listener);
	return () => {
		sock.off('event.chat.status', listener);
	};
};

/** @type {OnEvent} */
export const onEventConnectionNew = (listener) => {
	const sock = socket();
	sock.on('event.channel.connection.new', listener);
	return () => {
		sock.off('event.channel.connection.new', listener);
	};
};

/** @type {OnEvent} */
export const onEventConnectionLeft = (listener) => {
	const sock = socket();
	sock.on('event.channel.connection.left', listener);
	return () => {
		sock.off('event.channel.connection.left', listener);
	};
};

/** @type {OnEvent} */
export const onEventConnectionAll = (listener) => {
	const sock = socket();
	sock.on('event.channel.connection.all', listener);
	return () => {
		sock.off('event.channel.connection.all', listener);
	};
};

/** @type {OnEvent} */
export const onEventStudioStatus = (listener) => {
	const sock = socket();
	sock.on('event.studio.status', listener);
	return () => {
		sock.off('event.studio.status', listener);
	};
};

/** @type {OnEvent} */
export const onEventVodNew = (listener) => {
	const sock = socket();
	sock.on('event.vod.new', listener);
	return () => {
		sock.off('event.vod.new', listener);
	};
};

/** @type {OnEvent} */
export const onEventPostNew = (listener) => {
	const sock = socket();
	sock.on('event.post.new', listener);
	return () => {
		sock.off('event.post.new', listener);
	};
};

/** @type {OnEvent} */
export const onEventPostUpdate = (listener) => {
	const sock = socket();
	sock.on('event.post.update', listener);
	return () => {
		sock.off('event.post.update', listener);
	};
};

/** @type {OnEvent} */
export const onEventPostDelete = (listener) => {
	const sock = socket();
	sock.on('event.post.delete', listener);
	return () => {
		sock.off('event.post.delete', listener);
	};
};

/** @type {OnEvent} */
export const onEventPostLikeChange = (listener) => {
	const sock = socket();
	sock.on('event.post.like.change', listener);
	return () => {
		sock.off('event.post.like.change', listener);
	};
};

/** @type {OnEvent} */
export const onEventPostPinChange = (listener) => {
	const sock = socket();
	sock.on('event.post.pin.change', listener);
	return () => {
		sock.off('event.post.pin.change', listener);
	};
};

/** @type {OnEvent} */
export const onEventPostCommentNew = (listener) => {
	const sock = socket();
	sock.on('event.post.comment.new', listener);
	return () => {
		sock.off('event.post.comment.new', listener);
	};
};

/** @type {OnEvent} */
export const onEventPostCommentDelete = (listener) => {
	const sock = socket();
	sock.on('event.post.comment.delete', listener);
	return () => {
		sock.off('event.post.comment.delete', listener);
	};
};

/** @type {OnEvent} */
export const onEventPostCommentUpdate = (listener) => {
	const sock = socket();
	sock.on('event.post.comment.update', listener);
	return () => {
		sock.off('event.post.comment.update', listener);
	};
};

/** @type {OnEvent} */
export const onEventPostCommentLikeChange = (listener) => {
	const sock = socket();
	sock.on('event.post.comment.like.change', listener);
	return () => {
		sock.off('event.post.comment.like.change', listener);
	};
};

/** @type {OnEvent} */
export const onEventPostCommentDislike = (listener) => { // Not used
	const sock = socket();
	sock.on('event.post.comment.dislike', listener);
	return () => {
		sock.off('event.post.comment.dislike', listener);
	};
};

/** @type {OnEvent} */
export const onEventHandRaised = (listener) => {
	const sock = socket();
	sock.on('event.hand.raised', listener);
	return () => {
		sock.off('event.hand.raised', listener);
	};
};

/** @type {OnEvent} */
export const onEventModerationBan = (listener) => {
	const sock = socket();
	sock.on('event.moderation.ban', listener);
	return () => {
		sock.off('event.moderation.ban', listener);
	};
};

/** @type {OnEvent} */
export const onEventModerationUnban = (listener) => {
	const sock = socket();
	sock.on('event.moderation.unban', listener);
	return () => {
		sock.off('event.moderation.unban', listener);
	};
};

/**
 *
 * @param {string} channelId
 * @param {string} [role]
 * @param {string} [studioId]
 * @returns {() => void}
 */
export const joinChannel = (
	channelId,
	role,
	studioId,
) => {
	const sock = socket();

	const join = () => sock.emit(
		'join.channel',
		{ channelId, role, studioId },
	);

	const onReconnect = () => { join(); };
	sock.on('reconnect', onReconnect);

	join();

	return () => {
		sock.off('reconnect', onReconnect);
		sock.emit('leave.channel', { channelId, studioId });
	};
};

/** @type {OnEvent} */
export const onDisconnect = (listener) => {
	const sock = socket();
	sock.on('disconnect', listener);
	return () => {
		sock.off('disconnect', listener);
	};
};
