// @ts-check

/** @enum {string} */
export const ResourceAccessRole = {
	PUBLIC: 'PUBLIC',
	VIEWER: 'VIEWER',
	PARTICIPANT: 'PARTICIPANT',
	MODERATOR: 'MODERATOR',
	OPERATOR: 'OPERATOR',
	OWNER: 'OWNER',
};
