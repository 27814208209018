// @ts-check

import { createContext, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

/** @enum {string} */
export const PlayerModes = {
	FLOATING_PIP: 'FLOATING_PIP',
	DRAW: 'DRAW',
	CROP: 'CROP',
};

/**
 * @typedef {{
 *  mode: PlayerModes | undefined,
 *  modeState: PlayerModes | undefined,
 *  setMode: (mode: PlayerModes | undefined) => void,
 * }} IPlayerModeContext
 */

export const PlayerModeContext = createContext(/** @type {IPlayerModeContext} */({}));

export const usePlayerMode = () => useContext(PlayerModeContext);

/**
 * @typedef {{
 *  children: React.ReactNode,
 *  isController?: boolean,
 * }} PlayerModeProviderProps
 */

export const PlayerModeProvider = (
	/** @type {PlayerModeProviderProps} */
	{
		children,
		isController,
	},
) => {
	const [modeState, setMode] = useState(/** @type {PlayerModes | undefined} */(undefined));
	const defaultMode = isController ? PlayerModes.FLOATING_PIP : undefined;
	const mode = modeState || defaultMode;

	const contextValue = useMemo(() => ({
		mode,
		modeState,
		setMode,
	}), [
		mode,
		modeState,
		setMode,
	]);

	return (
		<PlayerModeContext.Provider value={contextValue}>
			{children}
		</PlayerModeContext.Provider>
	);
};

PlayerModeProvider.propTypes = {
	children: PropTypes.node.isRequired,
	isController: PropTypes.bool,
};

PlayerModeProvider.defaultProps = {
	isController: false,
};
