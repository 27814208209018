export default {
	control: {
		color: 'var(--bs-white)',
		fontSize: 14,
		fontWeight: 'normal',
	},

	'&multiLine': {
		control: {
			fontFamily: 'inherit',
			minHeight: 70,
		},
		highlighter: {
			padding: 9,
			border: '1px solid transparent',
		},
		input: {
			zIndex: 0,
			color: 'var(--bs-content-dark)',
			padding: 9,
			borderRadius: '6px',
		},
	},

	suggestions: {
		list: {
			fontFamily: 'inherit',
			color: 'var(--bs-white)',
			border: '1px solid rgba(0,0,0,0.15)',
			fontSize: 12,
			backgroundColor: '#12202C',
		},
		item: {
			padding: '5px 15px',
			borderBottom: '1px solid rgba(0,0,0,0.15)',
			'&focused': {
				backgroundColor: '#101c25',
				color: 'var(--bs-white)',
			},
		},
	},
};
