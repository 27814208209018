import PropTypes from 'prop-types';
import clsx from 'clsx';
import { FaSpinner } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

import RatioContainer from '../RatioContainer/RatioContainer';
import './Loading.scss';

export const ChannelLoading = ({
	children,
	className,
	loading,
}) => {
	const { t } = useTranslation();

	if (loading) {
		return (
			<RatioContainer className={clsx(className)}>
				<div className="ChannelLoading">
					<p>
						<span className="ChannelLoading_icon">
							<FaSpinner className="icon-spin" />
						</span>
					</p>
					<p>{t('ChannelLoading.Loading.connection')}</p>
				</div>
			</RatioContainer>
		);
	}

	return children;
};

ChannelLoading.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	loading: PropTypes.bool,
};

ChannelLoading.defaultProps = {
	children: null,
	className: '',
	loading: true,
};
