/* eslint-disable react/prop-types */
// @ts-check

import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { useMediaUser } from '../User';

/** @import { UserMediaStreamTrack } from '../User'; */

/**
 * @typedef {UserMediaStreamTrack & {
 * 	originalTrackId?: string,
 * }} TalkbackMediaStreamTrack
 */

/**
 * @typedef {{
 * 	_id: string,
 * 	nickname: string,
 * }} TalkbackRecipientUser
 */

/**
 * @typedef {{
 *	isDisconnecting: boolean,
 *	recipientUsers: TalkbackRecipientUser[],
 * 	startTalkback: (user: TalkbackRecipientUser) => void,
 * 	stopAllTalkbacks: () => void,
 * 	stopTalkback: (userId: string) => void,
 *	talkbackActiveTrack: TalkbackMediaStreamTrack?,
 *	userAudioRequestError?: import('../User').MediaRequestError,
 * }} IMediaTalkbackContext
 */

const MediaTalkbackContext = createContext(/** @type {IMediaTalkbackContext} */({}));

export const useMediaTalkback = () => useContext(MediaTalkbackContext);

const CONFIG_ID = 0;

/**
 * @typedef {{
 * 	children: React.ReactNode,
 * }} MediaTalkbackProps
 */

export const MediaTalkback = (
	/** @type {MediaTalkbackProps} */
	{
		children,
	},
) => {
	const {
		toggleAudio,
		getIsUserAudioActive,
		userAudioActiveTracks,
		userAudioRequestErrors,
	} = useMediaUser();

	const audioWasOffBeforeTalkback = useRef(false);
	const [stopTalkbackWhenAudioOff, setStopTalkbackWhenAudioOff] = useState(false);

	const [recipientUsers, setRecipientUsers] = useState(
		/** @type {TalkbackRecipientUser[]} */([]),
	);

	const talkbackTrackRef = useRef(/** @type {TalkbackMediaStreamTrack?}*/(null));
	const talkbackActiveTrack = useMemo(() => {
		// Stop existing talkback track when recipientUsers is empty
		if (!recipientUsers.length) {
			talkbackTrackRef.current?.stop();
			talkbackTrackRef.current = null;
			return null;
		}

		if (talkbackTrackRef.current) return talkbackTrackRef.current;

		const defaultMicrophone = userAudioActiveTracks.find((track) => track.configId === CONFIG_ID);
		if (!defaultMicrophone) return null;

		/** @type {TalkbackMediaStreamTrack} */
		const talkbackTrack = defaultMicrophone.clone();
		talkbackTrack.originalTrackId = defaultMicrophone.id;
		talkbackTrackRef.current = talkbackTrack;
		return talkbackTrack;
	}, [recipientUsers, userAudioActiveTracks]);

	const startTalkback = useCallback((
		/** @type {TalkbackRecipientUser} */user,
	) => {
		if (recipientUsers.some((u) => u._id === user._id)) return;

		setRecipientUsers((prev) => [...prev, user]);

		const alreadyOn = recipientUsers.length > 0;
		// If talkback is starting, check if audio is off and turn it on
		if (!alreadyOn) {
			if (!getIsUserAudioActive(CONFIG_ID)) {
				toggleAudio(CONFIG_ID);
				audioWasOffBeforeTalkback.current = true;
			} else {
				audioWasOffBeforeTalkback.current = false;
			}
		}
	}, [getIsUserAudioActive, recipientUsers, toggleAudio]);

	const stopTalkback = useCallback((
		/** @type {string} */userId,
	) => {
		setRecipientUsers((prev) => {
			const updatedRecipients = prev.filter((u) => u._id !== userId);

			if (!updatedRecipients.length && audioWasOffBeforeTalkback.current) {
				toggleAudio(CONFIG_ID);
				setStopTalkbackWhenAudioOff(true);
				return prev;
			}

			return updatedRecipients;
		});
	}, [toggleAudio]);

	const stopAllTalkbacks = useCallback(() => {
		setRecipientUsers([]);
	}, []);

	// Use to wait for audio to be turned off before stopping talkback
	useEffect(() => {
		if (stopTalkbackWhenAudioOff && !getIsUserAudioActive(CONFIG_ID)) {
			setStopTalkbackWhenAudioOff(false);
			setRecipientUsers([]);
		}
	}, [getIsUserAudioActive, stopTalkbackWhenAudioOff]);

	const userAudioRequestError = userAudioRequestErrors.find((e) => (
		e.configId === CONFIG_ID && e.error
	));

	const value = useMemo(() => ({
		isDisconnecting: stopTalkbackWhenAudioOff,
		recipientUsers,
		startTalkback,
		stopAllTalkbacks,
		stopTalkback,
		talkbackActiveTrack,
		userAudioRequestError,
	}), [
		recipientUsers,
		startTalkback,
		stopAllTalkbacks,
		stopTalkback,
		stopTalkbackWhenAudioOff,
		talkbackActiveTrack,
		userAudioRequestError,
	]);

	return (
		<MediaTalkbackContext.Provider value={value}>
			{children}
		</MediaTalkbackContext.Provider>
	);
};
