import { useMutation } from 'react-query';
import * as verificationApi from '../../api/verification';
import { axiosMutationWrapper } from '../utils/axios-wrapper';

export const useBeginVerification = () => useMutation(
	axiosMutationWrapper(verificationApi.verificationBegin),
);

export const useConfirmVerification = () => useMutation(
	axiosMutationWrapper(verificationApi.verificationConfirm),
);
