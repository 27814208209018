import PropTypes from 'prop-types';
import { PopoverBody, UncontrolledPopover } from 'reactstrap';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { PublicPathPrefix } from '../../../../RoutePath';

const getErrorMessage = (error, type, t) => {
	const isDeniedError = error.name === 'NotAllowedError';

	if (isDeniedError) {
		return `${t('LocalUserMedia.MediaErrorPopover.beeyouNotPermitted')} ${type}`;
	}

	if (['video'].includes(type)) {
		return `${t('LocalUserMedia.MediaErrorPopover.beeyouCannotRead')} ${type}`;
	}

	if (['screen'].includes(type)) {
		return `${t('LocalUserMedia.MediaErrorPopover.beeyouCannotShare')} ${type}`;
	}

	if (['camera', 'microphone'].includes(type)) {
		return `${t('LocalUserMedia.MediaErrorPopover.beeyouCannotAccess')} ${type}`;
	}

	return t('Global.error');
};

export const MediaErrorPopover = ({ error, target, type }) => {
	const { t } = useTranslation();

	if (!error) return null;

	return (
		<UncontrolledPopover
			trigger="hover"
			placement="bottom"
			target={target}
		>
			<PopoverBody className="text-dark font-size-sm">
				<b className="text-danger">
					{getErrorMessage(error, type, t)}
				</b>
				{' '}({error.name}).<br />
				{t('LocalUserMedia.MediaErrorPopover.seeOur')}
				<a href={`${PublicPathPrefix}/help/troubleshooting`} target="_blank" rel="noopener noreferrer">{t('LocalUserMedia.MediaErrorPopover.troubleshootingGuide')}
					<FaExternalLinkAlt />
				</a>.
			</PopoverBody>
		</UncontrolledPopover>
	);
};

MediaErrorPopover.propTypes = {
	error: PropTypes.shape({
		name: PropTypes.string.isRequired,
	}),
	target: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
};

MediaErrorPopover.defaultProps = {
	error: undefined,
};
