// @ts-check

import thunkMiddleware from 'redux-thunk';
import promiseMiddleware from 'redux-promise-middleware';
import {
	createStore,
	applyMiddleware,
} from 'redux';
import { createLogger } from 'redux-logger';

import reducers from './reducers';

/**
 * @import { Middleware, Dispatch } from 'redux';
 * @import { ThunkDispatch } from 'redux-thunk';
 * @import { ChannelMixingAction } from './actions/channelMixing';
 * @import { ChannelStreamAction } from './actions/channelStreams';
 * @import { PublicationAction } from './actions/publications';
 * @import { TrackAction } from './actions/tracks';
 */

/**
 * @typedef {ChannelMixingAction | ChannelStreamAction | PublicationAction | TrackAction} AppAction
 */

const middlewares = [
	thunkMiddleware,
	promiseMiddleware,
];

if (import.meta.env.NODE_ENV !== 'production') {
	const logger = /** @type {Middleware<{}, any, Dispatch<AppAction>>} */(
		createLogger({
			predicate: () => true,
			collapsed: () => true,
		})
	);
	middlewares.push(logger);
}

const store = createStore(
	reducers,
	applyMiddleware(...middlewares),
);

/**
 * @typedef {typeof store} AppStore
 * @typedef {ReturnType<AppStore['getState']>} RootState
 * @typedef {Dispatch<AppAction> & ThunkDispatch<RootState, unknown, AppAction>} AppDispatch
 */

export default store;
