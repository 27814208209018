// @ts-check

import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Hls from 'hls.js';

import RatioContainer from '../RatioContainer/RatioContainer';

/**
 * @typedef {React.DetailedHTMLProps<
 * 	React.VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement
 * > & {
 * 	className?: string,
 * 	src?: string,
 * }} PlayerStreamProps
 */

export const PlayerStream = (
	/** @type {PlayerStreamProps} */
	{
		className,
		src,
		...props
	},
) => {
	const videoRef = useRef(/** @type {HTMLVideoElement?} */(null));

	useEffect(() => {
		const videoElement = videoRef.current;

		if (!videoElement || !src) return undefined;

		if (!Hls.isSupported()) {
			console.error(new Error('Hls not supported.'));
			return undefined;
		}

		const hls = new Hls({
			autoStartLoad: true,
			// TODO: Check if live config is used or not
			live: true,
			liveSyncDurationCount: 2,
			liveMaxLatencyDurationCount: 5,
			maxLiveSyncPlaybackRate: 1.5,
			lowLatencyMode: true,
			startPosition: -1,
		});

		hls.on(Hls.Events.MEDIA_ATTACHED, () => {
			hls.loadSource(src);
		});

		hls.attachMedia(videoElement);

		return () => {
			hls.destroy();
		};
	}, [src]);

	return (
		<RatioContainer
			className={
				clsx(
					'PlayerStream',
					className,
				)
			}
		>
			<video
				className="w-100 h-100"
				ref={videoRef}
				{...props}
			/>
		</RatioContainer>
	);
};

PlayerStream.propTypes = {
	className: PropTypes.string,
	src: PropTypes.string.isRequired,
};

PlayerStream.defaultProps = {
	className: undefined,
};
