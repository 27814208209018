import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
	ResponsiveContainer,
	PieChart,
	Pie,
	Cell,
	Legend,
} from 'recharts';

const RADIAN = Math.PI / 180;
export const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
	const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
	const x = cx + radius * Math.cos(-midAngle * RADIAN);
	const y = cy + radius * Math.sin(-midAngle * RADIAN);

	return (
		<text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
			{`${(percent * 100).toFixed(0)}%`}
		</text>
	);
};

export const AnalyticsPieChart = ({ analytics }) => {
	const { t } = useTranslation();

	return (
		<div className="d-flex GraphContainer bg-light">
			<ResponsiveContainer width="100%" height="100%">
				<PieChart width={400} height={400}>
					<Pie
						data={analytics}
						cx="50%"
						cy="50%"
						labelLine={false}
						label={renderCustomizedLabel}
						outerRadius={90}
						fill="#8884d8"
						dataKey="value"
					>
						{analytics.map((entry) => (
							<Cell name={t(entry.label)} key={`cell-${entry.name}`} fill={entry.color} />
						))}
					</Pie>
					<Legend verticalAlign="top" height={36} />
				</PieChart>
			</ResponsiveContainer>
		</div>
	);
};

AnalyticsPieChart.propTypes = {
	analytics: PropTypes.arrayOf(PropTypes.shape({
		label: PropTypes.string,
		name: PropTypes.string,
		color: PropTypes.string,
		value: PropTypes.number,
	})),
};

AnalyticsPieChart.defaultProps = {
	analytics: undefined,
};
