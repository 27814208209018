import { createAxiosInstance } from '../lib/axios';
import { identity } from '../components/Authentication/Authentication';

const { VITE_API_URL } = import.meta.env;

export const api = createAxiosInstance({
	baseURL: VITE_API_URL,
});

api.interceptors.request.use(async (config) => {
	try {
		const { token } = await identity.getAccessToken();

		return {
			...config,
			headers: {
				...config.headers,
				authorization: `Bearer ${token}`,
			},
		};
	} catch (error) {
		return config;
	}
});

api.interceptors.response.use((response) => response, (error) => {
	if (error.response?.status === 401) {
		// TODO : why logout ?
		// identity.logout();
		return { data: undefined };
	}
	throw error;
});
