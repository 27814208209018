// @ts-check

import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import isHotkey from 'is-hotkey';

import { HeaderSearchContext } from './SearchContext';

/**
 * @typedef {{
 * 	children: React.ReactNode,
 * }} HeaderSearchProviderProps
 */

export const HeaderSearchProvider = (
	/** @type {HeaderSearchProviderProps} */
	{ children },
) => {
	const [isSearchOpen, setSearchOpen] = useState(false);

	useEffect(() => {
		const toggleSearchBar = (
			/** @type {KeyboardEvent} */e,
		) => {
			if (isHotkey('mod+f', e)) {
				e.stopPropagation();
				e.preventDefault();
				setSearchOpen(true);
			}

			if (isHotkey('esc', e) && isSearchOpen) {
				e.stopPropagation();
				e.preventDefault();
				setSearchOpen(false);
			}
		};

		document.addEventListener('keydown', toggleSearchBar);

		return () => {
			document.removeEventListener('keydown', toggleSearchBar);
		};
	}, [isSearchOpen]);

	const contextValue = useMemo(() => ({
		isSearchOpen,
		setSearchOpen,
	}), [
		isSearchOpen,
		setSearchOpen,
	]);

	return (
		<HeaderSearchContext.Provider value={contextValue}>
			{children}
		</HeaderSearchContext.Provider>
	);
};

HeaderSearchProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
