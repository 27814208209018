/* eslint-disable react/prop-types */
// @ts-check

import { Outlet } from 'react-router-dom';

import { ViewForbidden } from '../../views/Forbidden/Forbidden';
import { useAuthentication } from './Authentication';

/**
 * @typedef {{
 * 	element?: React.ReactNode
 * 	fallback?: React.ReactNode
 * }} RouteIfAuthenticatedProps
 */

const RouteIfAuthenticated = (
	/** @type {RouteIfAuthenticatedProps} */
	{
		element,
		fallback,
	},
) => {
	const authentication = useAuthentication();

	// wait for first getUser (comment outdated because first getUser is now sync)
	if (authentication.isPending) return null;
	if (!authentication.isLoggedIn) {
		return (
			fallback || <ViewForbidden />
		);
	}

	return element || <Outlet />;
};

export default RouteIfAuthenticated;
