// @ts-check

import { api } from '../api';

export const API_CHANNEL_SIMULCAST_PATH = '/channel/simulcast';

/** @typedef {import('./simulCast.dto').ISimulCastDto} ISimulCastDto */

/**
 * @param {{
 * 		channelId: string,
 * 		simulCast: Omit<ISimulCastDto, '_id'>,
 * }} param0
 * @returns {Promise<import('axios').AxiosResponse<ISimulCastDto>>}
 */
export const createSimulCast = async ({ channelId, simulCast }) => api.post(
	`${API_CHANNEL_SIMULCAST_PATH}/toChannelById/${channelId}`,
	simulCast,
);

/**
 * @param {string?} channelId
 * @param {string?} mode
 * @param {number?} itemsPerPage
 * @param {number?} currentPage
 * @returns {Promise<
 * 	import('axios').AxiosResponse<import('../helpers').PaginatedResult<ISimulCastDto>>
 * >}
 */
export const fetchSimulCasts = (channelId, mode, itemsPerPage, currentPage) => api.get(
	`${API_CHANNEL_SIMULCAST_PATH}`,
	{
		params: {
			channelId,
			mode,
			itemsPerPage,
			currentPage,
		},
	},
);

/**
 * @param {ISimulCastDto} simulCast
 * @returns {Promise<import('axios').AxiosResponse<ISimulCastDto>>}
 */
export const updateSimulCast = async (simulCast) => api.put(
	API_CHANNEL_SIMULCAST_PATH,
	simulCast,
);

/**
 * @param {string} simulCastId
 * @returns {Promise<import('axios').AxiosResponse<void>>}
 */
export const deleteSimulCast = async (simulCastId) => api.delete(
	`${API_CHANNEL_SIMULCAST_PATH}/${simulCastId}`,
);
