import { useMutation, useQuery, useQueryClient } from 'react-query';
import * as contactsApi from '../../api/contacts/contacts';
import { axiosMutationWrapper, axiosQueryWrapper } from '../utils/axios-wrapper';

const CONTACTS_QUERY_KEYS = {
	fetchContactsList: () => ['api', 'fetch', 'contact', 'list'],
};

export const useCreateContacts = () => {
	const queryClient = useQueryClient();

	return useMutation(
		axiosMutationWrapper(contactsApi.createContacts), {
			onSuccess: () => {
				queryClient.invalidateQueries(contactsApi.fetchContactsList());
			},
		},
	);
};

export const useFetchContacts = () => useQuery(
	CONTACTS_QUERY_KEYS.fetchContactsList(),
	axiosQueryWrapper(contactsApi.fetchContactsList),
);

export const useCreateContact = () => {
	const queryClient = useQueryClient();

	return useMutation(axiosMutationWrapper(contactsApi.createContact), {
		onSuccess: () => {
			queryClient.invalidateQueries(contactsApi.fetchContactsList());
		},
	});
};

export const useRemoveContect = () => {
	const queryClient = useQueryClient();

	return useMutation(axiosMutationWrapper(contactsApi.removeContact), {
		onSuccess: () => {
			queryClient.invalidateQueries(contactsApi.fetchContactsList());
		},
	});
};

export const useUpdateContact = () => {
	const queryClient = useQueryClient();
	return useMutation(axiosMutationWrapper(contactsApi.updateContact), {
		onSuccess: () => {
			queryClient.invalidateQueries(contactsApi.fetchContactsList());
		},
	});
};
