import { api } from '../api';

export const API_COMMENTS_PATH = '/channel/comments';

export const fetchPostComments = (postId) => api.get(
	`${API_COMMENTS_PATH}/post/${postId}`,
);

export const createPostComment = (comment) => api.post(
	`${API_COMMENTS_PATH}/post`,
	comment,
);

export const deletePostComment = (commentId) => api.delete(
	`${API_COMMENTS_PATH}/post/${commentId}`,
);

export const updatePostComment = ({ id, text, images, videos }) => api.put(
	`${API_COMMENTS_PATH}/post/${id}`,
	{ text, images, videos },
);
