import { useState } from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DonateModal from '../Channel/Donate/Modal';

export const DonatePoints = ({ withBackground, channel }) => {
	const { t } = useTranslation();
	const [isDonateModalOpen, setIsDonateModalOpen] = useState(false);

	return (
		<>
			<div className={`d-flex align-items-center justify-content-center ${withBackground ? 'bg-primary py-2' : 'h-100'}`}>
				<p
					className={`m-0 p-0 ${!withBackground ? 'text-primary' : ''}`}
					style={withBackground ? { color: '#070919' } : {}}
				>
					{t('DonatePoints.studioAboutToExpire')}
				</p>
				<Button
					onClick={() => setIsDonateModalOpen(true)}
					color={!withBackground ? 'primary' : 'dark'}
					className="flex-shrink-0 mx-3"
				>
					<span className="btn-wrapper--label d-md-inline">
						{t('DonatePoints.donatePoints')}
					</span>
				</Button>
			</div>
			{isDonateModalOpen && (
				<DonateModal
					channel={channel}
					isOpen={isDonateModalOpen}
					toggle={() => setIsDonateModalOpen(!isDonateModalOpen)}
				/>
			)}
		</>
	);
};

DonatePoints.propTypes = {
	withBackground: PropTypes.bool,
	channel: PropTypes.shape({}),
};
DonatePoints.defaultProps = {
	withBackground: false,
	channel: undefined,
};
