import { api } from '../api';

export const API_ANALYTICS_ADS_PATH = '/analytics/ads';

export const fetchAdGenderAnalytics = (adCampaignId) => api.get(
	`${API_ANALYTICS_ADS_PATH}/${adCampaignId}/gender`,
);

export const fetchAdAgeAnalytics = (adCampaignId) => api.get(
	`${API_ANALYTICS_ADS_PATH}/${adCampaignId}/age`,
);

export const fetchAdCountryAnalytics = (adCampaignId) => api.get(
	`${API_ANALYTICS_ADS_PATH}/${adCampaignId}/country`,
);

export const fetchAdViewsAnalytics = (adCampaignId) => api.get(
	`${API_ANALYTICS_ADS_PATH}/${adCampaignId}/views`,
);

export const fetchIssuedAdCredits = (adCampaignId) => api.get(
	`${API_ANALYTICS_ADS_PATH}/${adCampaignId}/adcredits/issued`,
);
