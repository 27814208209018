// @ts-check
/* eslint-disable react/prop-types */

import { Col, Row, Spinner } from 'reactstrap';
import { FaTimesCircle } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

import { FiatCurrencyWithPiPoints } from '../../../../lib/currency';
import { formatDate } from '../../../../lib/time';
import { useCancelDonationSubscription, useFetchDonationSubscription } from '../../../../api-hooks/channel/donation/subscription';
import { DonateSubscriptionPaypalButton } from './PaypalButton';
import { ButtonPill } from '../../../Button';

/**
 * @typedef {{
* 	amount: number,
* 	channelId: string,
* 	includeFee: boolean,
* 	goalId: string,
*   currency: string,
* }} DonateSubscriptionProps
*/

export const DonateSubscription = (
	/** @type {DonateSubscriptionProps} */
	{
		amount,
		channelId,
		includeFee,
		goalId,
		currency,
	},
) => {
	const {
		data: donationSubscription,
		isLoading: isFetchSubscriptionDonationLoading,
	} = useFetchDonationSubscription(channelId);

	const {
		mutate: cancelDonationSubscription,
		isLoading: isCancelDonationSubscriptionLoading,
	} = useCancelDonationSubscription();

	const { t } = useTranslation();

	if (isFetchSubscriptionDonationLoading) {
		return <Spinner />;
	}

	return (
		<Row className="mt-4">
			<Col className="w-100 pb-2 d-flex flex-column justify-content-center align-items-center">
				{donationSubscription ? (
					<div className="w-75">
						<p className="mb-1 text-center"><strong>{t('Donation.Subscriptions.currentSubscription')}</strong></p>
						<div className="DrawerItem px-3 ">
							<div className="w-100 rounded-sm p-3 my-1 d-flex align-items-center justify-content-between shadow-sm-dark bg-light">
								<div className="d-flex align-items-center">
									<div>
										<div className="d-flex">
											<div className="font-size-sm d-flex flex-column">
												<strong>
													{donationSubscription.type === 'PAYPAL'
														? `$ ${donationSubscription.amount}`
														: `${donationSubscription.amount} Pi points`}
												</strong>
												<small className="font-italic text-secondary">{t('Donation.Subscriptions.nextBillingCycle')}: {formatDate(donationSubscription.expiresAt, t)}</small>
											</div>
										</div>
									</div>
								</div>
								<div>
									<div className="d-flex justify-content-end align-items-center">
										<ButtonPill
											className=""
											color="danger"
											onClick={() => cancelDonationSubscription(donationSubscription._id)}
											title="Cancel subscription"
											disabled={isCancelDonationSubscriptionLoading}
										>
											{isCancelDonationSubscriptionLoading ? (
												<Spinner />
											) : (
												<span><FaTimesCircle className="mr-2" />{t('Donation.Subscriptions.cancel')}</span>
											)}
										</ButtonPill>
									</div>
								</div>
							</div>
						</div>
					</div>
				) : (
					<div>
						{currency !== FiatCurrencyWithPiPoints.PI_POINTS && (
							<DonateSubscriptionPaypalButton
								amount={amount}
								channelId={channelId}
								includeFee={includeFee}
								goalId={goalId}
								currency={currency}
							/>
						)}
					</div>
				)}
			</Col>
		</Row>
	);
};
