import { lazy } from 'react';
import PropTypes from 'prop-types';

import { ModalScreen } from '../../../Modal/Screen/Screen';
import { useUserSettings } from '../../../UserSettings/Context';
import { AnalyticsProvider } from '../../../UserSettings/AnalyticsAndAdvertising/Analytics/Provider';

const AnalyticsContent = lazy(() => import('../../../UserSettings/AnalyticsAndAdvertising/Analytics/Content'));

export const AdminAnalyticsChannelOverviewModal = ({ isOpen, closeModal, channelId }) => {
	const { closeModal: closeUserSettingsModal } = useUserSettings();

	return (
		<ModalScreen
			bodyClassName="bg-gray py-0"
			isOpen={isOpen}
			onClose={closeModal || closeUserSettingsModal}
			className="AnalyticsAndAdvertisingModal"
		>
			<AnalyticsProvider>
				<AnalyticsContent
					channelId={channelId}
					closeModal={closeModal || closeUserSettingsModal}
				/>
			</AnalyticsProvider>
		</ModalScreen>
	);
};

AdminAnalyticsChannelOverviewModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	closeModal: PropTypes.func.isRequired,
	channelId: PropTypes.string,
};

AdminAnalyticsChannelOverviewModal.defaultProps = {
	channelId: undefined,
};
