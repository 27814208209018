import PropTypes from 'prop-types';
import { lazy } from 'react';
import { NavItem, NavLink, TabContent } from 'reactstrap';

import { useTranslation } from 'react-i18next';
import { ModalLazyContent } from '../Modal/LazyContent';
import { ModalScreenHeader, ModalScreenHeaderNav } from '../Modal/Screen/Header';
import { ModalScreen } from '../Modal/Screen/Screen';
import { MyOrganizationModalTab, useOrganizationSettings } from './Context';
import { useFetchOrganizationDetails } from '../../api-hooks/organization/organization';
import { OrganizationVisibility } from './organizationSettings.schema';

const MyOrganizationModalContent = lazy(() => import('./MyOrganizationModalContent'));

export const MyOrganizationModal = ({ isOpen, activeTab, setActiveTab }) => {
	const { t } = useTranslation();
	const { closeOrganizationModal } = useOrganizationSettings();
	const { data: organization } = useFetchOrganizationDetails();

	return (
		<ModalScreen
			backdrop={false}
			bodyClassName="py-0"
			header={(
				<ModalScreenHeader
					onClose={closeOrganizationModal}
					title={t('UserSettings.MyOrganizationModal.title')}
				>
					<ModalScreenHeaderNav className="mt-3">
						<NavItem className="mr-3">
							<NavLink
								title={t('UserSettings.MyOrganizationModal.profile')}
								onClick={() => setActiveTab(MyOrganizationModalTab.PROFILE)}
								active={activeTab === MyOrganizationModalTab.PROFILE}
							>
								<span>{t('UserSettings.MyOrganizationModal.profile')}</span>
								<div className="divider" />
							</NavLink>
						</NavItem>
						{organization?.visibility === OrganizationVisibility.PUBLIC && (
							<NavItem className="mr-3">
								<NavLink
									title={t('UserSettings.MyOrganizationModal.settings')}
									onClick={() => setActiveTab(MyOrganizationModalTab.SETTINGS)}
									active={activeTab === MyOrganizationModalTab.SETTINGS}
								>
									<span>{t('UserSettings.MyOrganizationModal.settings')}</span>
									<div className="divider" />
								</NavLink>
							</NavItem>
						)}
						<NavItem className="ml-3">
							<NavLink
								title={t('UserSettings.MyOrganizationModal.members')}
								onClick={() => setActiveTab(MyOrganizationModalTab.MEMBERS)}
								active={activeTab === MyOrganizationModalTab.MEMBERS}
							>
								<span>{t('UserSettings.MyOrganizationModal.members')}</span>
								<div className="divider" />
							</NavLink>
						</NavItem>
						<NavItem className="ml-3">
							<NavLink
								title={t('UserSettings.MyOrganizationModal.memberRequests')}
								onClick={() => setActiveTab(MyOrganizationModalTab.MEMBER_REQUESTS)}
								active={activeTab === MyOrganizationModalTab.MEMBER_REQUESTS}
							>
								<span>{t('UserSettings.MyOrganizationModal.memberRequests')}</span>
								<div className="divider" />
							</NavLink>
						</NavItem>
						<NavItem className="ml-3">
							<NavLink
								title={t('UserSettings.MyOrganizationModal.invitations')}
								onClick={() => setActiveTab(MyOrganizationModalTab.INVITATIONS)}
								active={activeTab === MyOrganizationModalTab.INVITATIONS}
							>
								<span>{t('UserSettings.MyOrganizationModal.invitations')}</span>
								<div className="divider" />
							</NavLink>
						</NavItem>
						<NavItem className="ml-3">
							<NavLink
								title={t('UserSettings.MyOrganizationModal.roles')}
								onClick={() => setActiveTab(MyOrganizationModalTab.ROLES)}
								active={activeTab === MyOrganizationModalTab.ROLES}
							>
								<span>{t('UserSettings.MyOrganizationModal.roles')}</span>
								<div className="divider" />
							</NavLink>
						</NavItem>
					</ModalScreenHeaderNav>
				</ModalScreenHeader>
			)}
			isOpen={isOpen}
			onClose={closeOrganizationModal}
		>
			<TabContent activeTab={activeTab}>
				<ModalLazyContent>
					<MyOrganizationModalContent setActiveTab={setActiveTab} activeTab={activeTab} />
				</ModalLazyContent>
			</TabContent>
		</ModalScreen>
	);
};

MyOrganizationModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	activeTab: PropTypes.oneOf(Object.values(MyOrganizationModalTab)),
	setActiveTab: PropTypes.func,
};

MyOrganizationModal.defaultProps = {
	activeTab: MyOrganizationModalTab.PROFILE,
	setActiveTab: PropTypes.func,
};
