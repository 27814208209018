import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModeScreenLayout, useScreenMode } from '../../components/Studio/ScreenMode/Provider';
import { Header } from '../../layout-components';
import { SignupFormStep } from '../../components/Signup/signup.schema';
import { SignupForm } from '../../components/Signup/Form/Form';

import './Comvision.scss';

const project = import.meta.env.VITE_PROJECT === 'beeyou' ? 'Beeyou' : 'Commando Vision';

export const ViewComvisionHome = () => {
	const { currentModeScreen } = useScreenMode();
	const { t } = useTranslation();

	const [signupStep, setSignupStep] = useState(SignupFormStep.CREATE_ORGANIZATION);
	const [stepOptions, setStepOptions] = useState({});

	const handleChangeStep = useCallback((step, options) => {
		setSignupStep(step);
		setStepOptions({ [step]: options });
	}, []);

	return (
		<div className="app-wrapper content-darker">
			<div className={clsx('app-main',
				{ 'overflow-hidden': currentModeScreen === ModeScreenLayout.FULLSCREEN })}
			>
				<Header
					search={false}
					isMobileLayout={false}
					isStudioRoute={false}
				/>
				<div className="app-content">
					<div className="app-content--inner">
						<div className="h-100 w-100 d-flex align-items-center justify-content-center">
							<div className="bg-light rounded d-flex flex-column align-items-start pl-5 pt-5 HomeSignupWrapper">
								<div className="pl-2 pt-2">
									<h2 className="font-weight-bold mb-1">
										{
											t(
												signupStep === SignupFormStep.CREATE_ORGANIZATION
													? 'Onboarding.Modal.createAnOrganization'
													: 'Onboarding.Modal.createAnAccount',
												{ project },
											)
										}
									</h2>
									<p className="text-secondary m-0">
										{
											t(
												signupStep === SignupFormStep.CREATE_ORGANIZATION
													? 'Onboarding.Modal.createAnOrganizationSubtitle'
													: 'Onboarding.Modal.createAnAccountSubtitle',
												{ project },
											)
										}
									</p>
								</div>
								<SignupForm
									currentStep={signupStep}
									onChangeStep={handleChangeStep}
									stepOptions={stepOptions}
								/>
							</div>

						</div>

					</div>
				</div>
			</div>
		</div>
	);
};

ViewComvisionHome.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			tags: PropTypes.string,
		}),
	}),
};

ViewComvisionHome.defaultProps = {
	match: null,
};
