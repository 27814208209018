import { createContext } from 'react';

export const NavCountContext = createContext(0);

export const withNavCount = (WrappedComponent) => {
	const WithNavCount = (props) => (
		<NavCountContext.Consumer>
			{(navCount) => <WrappedComponent {...props} navCount={navCount} />}
		</NavCountContext.Consumer>
	);

	WithNavCount.displayName = `WithNavCount(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

	return WithNavCount;
};
