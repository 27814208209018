// @ts-check

import { api } from '../api';

const API_STUDIOS_PATH = '/studios';

export const fetchActiveStudio = async () => api.get(
	`${API_STUDIOS_PATH}/active`,
);

export const fetchStudioOverview = async (studioId) => api.get(
	`${API_STUDIOS_PATH}/${studioId}/overview`,
);

export const stopStudio = async (id) => api.delete(
	`${API_STUDIOS_PATH}/${id}`,
);

export const getStudioParticipants = async (id) => api.get(
	`${API_STUDIOS_PATH}/${id}/participants`,
);

export const fetchStudioConfig = async (id) => api.get(
	`${API_STUDIOS_PATH}/config/${id}`,
);

export const fetchStudioFeatures = async (id) => api.get(
	`${API_STUDIOS_PATH}/${id}/features`,
);

export const updateStudioAccess = async (id, access) => api.put(
	`${API_STUDIOS_PATH}/access/${id}`,
	{
		...access,
	},
);

export const updateControllerJoined = async (id) => api.put(`${API_STUDIOS_PATH}/controllerjoined/${id}`);

export const golive = async (id, startTime) => api.post(
	`${API_STUDIOS_PATH}/golive/${id}`,
	{ startTime },
);

export const stoplive = async (id) => api.post(
	`${API_STUDIOS_PATH}/stoplive/${id}`,
);

export const fetchStudioPresaleTickets = async (studioId) => api.get(
	`${API_STUDIOS_PATH}/${studioId}/presale/tickets/`,
);

export const refundStudioPresaleTicket = async ({ studioId, transactionId }) => api
	.post(
		`${API_STUDIOS_PATH}/${studioId}/presale/tickets/refund`,
		{ transactionId },
	);

export const answerStudioAttendace = async ({ studioId, attendanceId, answer }) => api
	.post(
		`${API_STUDIOS_PATH}/${studioId}/attendance/${attendanceId}/answer`,
		{ answer },
	);
