import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const translationKeyRegex = /^[a-zA-Z]+(\.[a-zA-Z]+)*$/;

export const NotificationToastInformation = ({
	notification,
}) => {
	const { t } = useTranslation();
	const { message } = notification;
	const isTranslationKey = translationKeyRegex.test(message);

	return (
		<div className="d-flex align-items-center justify-content-between">
			{isTranslationKey ? t(message) : message}
		</div>
	);
};

NotificationToastInformation.propTypes = {
	notification: PropTypes.shape({
		message: PropTypes.string.isRequired,
	}).isRequired,
};
