import { createContext, useCallback, useContext, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';

const StreamSocketContext = createContext({});

export const useStreamSocket = () => useContext(StreamSocketContext);

export const StreamSocketProvider = ({ children }) => {
	const streamChangeHandlersRef = useRef([]);

	const removeStreamChangeHandler = useCallback((handler) => {
		streamChangeHandlersRef.current = streamChangeHandlersRef.current.filter(
			(h) => h !== handler,
		);
	}, []);

	const addStreamChangeHandler = useCallback((handler) => {
		streamChangeHandlersRef.current.push(handler);
		return () => removeStreamChangeHandler(handler);
	}, [removeStreamChangeHandler]);

	const handleStreamChange = useCallback((...args) => {
		streamChangeHandlersRef.current.forEach((handler) => {
			handler(...args);
		});
	}, []);

	const value = useMemo(() => ({
		removeStreamChangeHandler,
		addStreamChangeHandler,
		handleStreamChange,
	}), [
		removeStreamChangeHandler,
		addStreamChangeHandler,
		handleStreamChange,
	]);

	return (
		<StreamSocketContext.Provider value={value}>
			{children}
		</StreamSocketContext.Provider>
	);
};

StreamSocketProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
