import PropTypes from 'prop-types';
import { useEffect, useRef, useMemo } from 'react';

import { drawText } from '../../lib/sourceText';
import { LayerBounds } from '../../lib/studio';
import RatioContainer from '../RatioContainer/RatioContainer';

export const PreviewText = ({
	className,
	text,
}) => {
	const canvasRef = useRef();

	const canvasPosition = useMemo(() => {
		const { layerConfig } = text;

		const width = layerConfig.width / LayerBounds.width;
		const height = layerConfig.height / LayerBounds.height;

		const x = layerConfig.x / LayerBounds.width;
		const y = layerConfig.y / LayerBounds.height;

		return {
			left: `${x * 100}%`,
			top: `${y * 100}%`,
			width: `${width * 100}%`,
			height: `${height * 100}%`,
		};
	}, [text]);

	useEffect(() => {
		const context = canvasRef.current.getContext('2d');
		canvasRef.current.width = text.layerConfig.width;
		canvasRef.current.height = text.layerConfig.height;
		drawText(text, context);
	}, [text]);

	return (
		<div
			className={className}
			style={{
				zIndex: -1,
			}}
		>
			<RatioContainer>
				<canvas
					style={{
						zIndex: 1,
						maxWidth: '100%',
						maxHeight: '100%',
						...canvasPosition,
					}}
					className="position-absolute"
					ref={canvasRef}
				/>
			</RatioContainer>
		</div>
	);
};

PreviewText.propTypes = {
	className: PropTypes.string,
	text: PropTypes.shape({
		text: PropTypes.string,
		backgroundColor: PropTypes.string,
		backgroundTransparency: PropTypes.number,
		layerConfig: PropTypes.shape({
			width: PropTypes.number,
			height: PropTypes.number,
			x: PropTypes.number,
			y: PropTypes.number,
		}),
	}).isRequired,
};

PreviewText.defaultProps = {
	className: undefined,
};
