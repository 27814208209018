import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import { usePoints } from '../Membership/Points';
import { UserSettingsModal, useUserSettings } from '../UserSettings/Context';
import { MembershipModalTab } from '../UserSettings/MembershipModalTab';

export const BuyMore = ({ withBackground }) => {
	const { t } = useTranslation();
	const { balance, chipBalance } = usePoints();
	const { openModal } = useUserSettings();

	return (
		<div className={`d-flex align-items-center justify-content-center ${withBackground ? 'bg-primary py-2' : 'h-100'}`}>
			<p
				className={`m-0 p-0 ${!withBackground ? 'text-primary' : ''}`}
				style={withBackground ? { color: '#070919' } : {}}
			>
				<Trans i18nKey="Studio.BuyMore.balance" balance={balance}>
					You have only {{ balance }} pts
				</Trans> {chipBalance ? `(${chipBalance} chips)` : ''} {t('Studio.BuyMore.balance.left')}!
			</p>
			<Button
				onClick={() => openModal(UserSettingsModal.MEMBERSHIP, MembershipModalTab.BUY_POINTS)}
				color={!withBackground ? 'primary' : 'dark'}
				className="flex-shrink-0 mx-3"
			>
				<span className="btn-wrapper--label d-md-inline">
					{t('Studio.BuyMore.buy')}
				</span>
			</Button>
		</div>
	);
};

BuyMore.propTypes = {
	withBackground: PropTypes.bool,
};
BuyMore.defaultProps = {
	withBackground: false,
};
