// @ts-check

import axios from 'axios';

import tagify from './tagify';
import { createUpload } from './upchunk';

const {
	VITE_CONTENT_ENDPOINT,
	VITE_MUX_STREAM,
	VITE_MUX_THUMBNAIL,
} = import.meta.env;

const baseUrlContent = VITE_CONTENT_ENDPOINT;
const baseUrlMuxStream = VITE_MUX_STREAM;
const baseUrlMuxThumbnail = VITE_MUX_THUMBNAIL;

export const AVATAR_SIZE = { width: 64 };
export const COVER_SIZE = { height: 1080, width: 1920 };
export const COVER_SIZE_SM = { width: 256 };
export const THUMBNAIL_WIDTH = 320;
export const BANNER_CARD_SIZE = { width: 320 };
export const BANNER_SIZE = { height: 1080, width: 1920 };
export const FILE_CARD_SIZE = { width: 320 };
export const CHANNEL_THUMBNAIL_WIDTH = 200;
export const SEARCH_RESULT_AVATAR_WIDTH = 200;
export const SEARCH_RESULT_BANNER_WIDTH = 640;
export const LIBRARY_IMAGE_THUMBNAIL = { width: 230, height: 130 };
export const MESSAGE_IMAGE_THUMBNAIL = { width: 92, height: 92 };
export const MEDIA_UPLOAD_LIST_THUMBNAIL_SIZE = { width: 100, height: 56 };
export const PROFILE_BANNER_WIDTH = 680;

const canUseWebP = () => {
	const canvas = document.createElement('canvas');
	if (canvas.getContext && canvas.getContext('2d')) {
		return canvas.toDataURL('image/webp').indexOf('data:image/webp') === 0;
	}
	return false;
};

const isWebpSupported = canUseWebP();

export const getFileUrlFromMux = (playbackId) => `${baseUrlMuxStream}${playbackId}.m3u8`;

export const getThumbnailUrlFromMux = (playbackId, time) => (
	`${baseUrlMuxThumbnail}${playbackId}/thumbnail.jpg?width=${THUMBNAIL_WIDTH}${time !== undefined ? `&time=${time}` : ''}`
);

/**
 * @param {{ name: string }} file
 * @param {string | number} [width]
 * @param {string | number} [height]
 * @param {string | number} [fit]
 * @param {string} [format]
 * @returns {string}
 */
export const getFileUrl = (file, width, height, fit, format) => {
	if ((width || height)) {
		let useFormat = format;
		if (!format && isWebpSupported) useFormat = 'webp';
		else if (!format) useFormat = 'jpg';
		let queryString = '';
		if (width) queryString += `&width=${width}`;
		if (height) queryString += `&height=${height}`;
		if (fit) queryString += `&fit=${fit}`;
		if (useFormat) queryString += `&format=${useFormat}`;
		queryString = queryString.replace(/^&/, '?');
		return `${baseUrlContent}resize/${file.name}${queryString}`;
	}
	return `${baseUrlContent}${file.name}`;
};

export const getFileUrlForKey = (file) => {
	const fileUrl = `${baseUrlContent}${file.name}`;
	const paramSeparator = fileUrl.includes('?') ? '&' : '?';
	return `${fileUrl}${paramSeparator}crossorigin=anonymous`;
};

export const getDownloadLink = (file) => {
	const { filename, label } = file;
	if (!filename) {
		return '';
	}

	const attachmentName = label ? tagify(label) : filename;
	const extension = filename.split('.').pop();
	const contentDispositionParam = encodeURIComponent(`attachment;filename=${attachmentName}.${extension}`);
	return `${baseUrlContent}${filename}?response-content-disposition=${contentDispositionParam}`;
};

export const getThumbnail = (video) => {
	switch (video?.status) {
	case 'ready':
		return getThumbnailUrlFromMux(video.playbackId, video.thumbnailTimeCode);
	default:
		return video.filename && getFileUrl({ name: video.filename });
	}
};

export const getVideoSrc = (video) => {
	switch (video?.status) {
	case 'ready':
		return getFileUrlFromMux(video.playbackId);
	default:
		return video.filename && getFileUrl({ name: video.filename });
	}
};

export const upload = (url, blob, onUploadProgress) => axios.request({
	headers: {
		'content-type': blob.type,
	},
	method: 'put',
	url,
	data: blob,
	onUploadProgress,
});

const axiosUpload = axios.create();
delete axiosUpload.defaults.headers.put['Content-Type'];

export const uploadPart = async (url, chunk, onUploadProgress) => {
	const buffer = await chunk.arrayBuffer();
	return axiosUpload.put(url, buffer, {
		onUploadProgress,
	});
};

export const uploadMultipart = async ({
	chunkSize,
	file,
	onUploadProgress,
	presignedUrlsParts,
}) => {
	const up = createUpload({
		chunkSize,
		delayBeforeAttempt: 6,
		endpoint: '<replaced>',
		file,
	});

	up.sendChunk = async function sendChunk() {
		if (this.currentXhr) {
			throw new Error('UpChunk: sendChunk called while another chunk is in progress');
		}

		this.dispatch('attempt', {
			chunkNumber: this.chunkCount,
			chunkSize: this.chunk.size,
		});

		const chunkNumber = this.chunkCount;
		const url = presignedUrlsParts[chunkNumber];

		this.currentXhr = uploadPart(
			url,
			this.chunk,
			(event) => {
				const percentagePerChunk = 100 / this.totalChunks;
				const sizePerChunk = percentagePerChunk * this.file.size;
				const successfulPercentage = percentagePerChunk * this.chunkCount;
				const currentChunkProgress = event.loaded / (event.total ?? sizePerChunk);
				const chunkPercentage = currentChunkProgress * percentagePerChunk;
				this.dispatch('progress', Math.min(successfulPercentage + chunkPercentage, 100));
			},
		);

		let response;

		try {
			response = await this.currentXhr;
		} finally {
			this.currentXhr = undefined;
		}

		return {
			...response,
			statusCode: response.status,
		};
	};

	const parts = [];

	up.on('chunkSuccess', ({ detail }) => {
		const {	response } = detail;

		const { etag } = response.headers;
		const { url } = response.config;
		const fixedEtag = etag.replace(/^"/, '').replace(/"$/, '');

		const urlParsed = new URL(url);
		const partNumber = parseInt(urlParsed.searchParams.get('partNumber'), 10);

		const part = parts.find((p) => p.partNumber === partNumber);
		if (part) {
			part.etag = fixedEtag;
		} else {
			parts.push({
				partNumber,
				etag: fixedEtag,
			});
		}
	});

	up.on('progress', ({ detail: progress }) => {
		onUploadProgress({
			loaded: progress,
			total: 100,
		});
	});

	return new Promise((resolve, reject) => {
		up.on('success', () => resolve(parts));
		up.on('error', ({ detail: error }) => reject(error));
	});
};

export const read = (file) => new Promise((resolve, reject) => {
	const fr = new FileReader();
	fr.addEventListener('error', reject, false);
	fr.addEventListener('load', function onLoad() {
		resolve(this.result);
	}, false);
	fr.readAsArrayBuffer(file);
});

export const calculateAspectRatioFit = (srcWidth, srcHeight, maxWidth, maxHeight) => (
	Math.min(1, maxWidth / srcWidth, maxHeight / srcHeight)
);

const createCanvas = (offscreen = false, width = 0, height = 0) => {
	if (offscreen && window.OffscreenCanvas) return new window.OffscreenCanvas(width, height);
	const canvas = document.createElement('canvas');
	canvas.width = width;
	canvas.height = height;
	return canvas;
};

const getBlob = (canvas, options) => {
	if (window.OffscreenCanvas && canvas instanceof window.OffscreenCanvas) {
		return canvas.convertToBlob(options);
	}
	return new Promise((resolve) => {
		canvas.toBlob(resolve, options?.type, options?.quality);
	});
};

const IMAGE_SIZE_BOUNDS = { maxWidth: 1920, maxHeight: 1080 };

export const cropImage = async (img, completedCrop) => {
	const rect = img.getBoundingClientRect();
	const {
		width: exactWidth,
		height: exactHeight,
	} = rect;
	const scaleX = img.naturalWidth / (
		exactWidth || img.naturalWidth/* in case img is not displayed */
	);
	const scaleY = img.naturalHeight / (exactHeight || img.naturalHeight);

	const width = Math.floor(completedCrop.width * scaleX);
	const height = Math.floor(completedCrop.height * scaleY);

	if (width <= 0 || height <= 0) return null;
	const canvas = createCanvas(false, width, height);

	const ctx = canvas.getContext('2d');
	if (!ctx) return null;

	const cropX = completedCrop.x * scaleX;
	const cropY = completedCrop.y * scaleY;

	const centerX = img.naturalWidth / 2;
	const centerY = img.naturalHeight / 2;

	ctx.save();
	ctx.translate(-cropX, -cropY);
	ctx.translate(centerX, centerY);
	ctx.translate(-centerX, -centerY);
	ctx.drawImage(
		img,
		0,
		0,
		img.naturalWidth,
		img.naturalHeight,
		0,
		0,
		img.naturalWidth,
		img.naturalHeight,
	);

	ctx.restore();

	const boundedRatio = calculateAspectRatioFit(
		width,
		height,
		IMAGE_SIZE_BOUNDS.maxWidth,
		IMAGE_SIZE_BOUNDS.maxHeight,
	);
	const boundedWidth = Math.floor(width * boundedRatio);
	const boundedHeight = Math.floor(height * boundedRatio);

	const boundedCanvas = createCanvas(true, boundedWidth, boundedHeight);
	const boundedCtx = boundedCanvas.getContext('2d');
	boundedCtx.drawImage(canvas, 0, 0, width, height, 0, 0, boundedWidth, boundedHeight);

	const blob = await getBlob(boundedCanvas);
	return blob;
};

export const resizeImage = async (img) => cropImage(
	img,
	{ width: img.naturalWidth, height: img.naturalHeight, x: 0, y: 0 },
);

export const loadImage = async (imageInput) => 	new Promise((resolve, reject) => {
	const img = new Image();
	img.onload = () => resolve(img);
	img.onerror = () => reject(new Error('Could not load image'));
	img.src = URL.createObjectURL(imageInput);
});

export const resizeImageDefault = async (imageInput) => {
	if (!imageInput) return null;
	const img = await loadImage(imageInput);
	return resizeImage(img);
};
