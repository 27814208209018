import { useCallback } from 'react';
import { useFetchPointsRatio } from '../api-hooks/membership/points';
import { FiatCurrency, FiatCurrencyWithPiPoints } from './currency';

export const useCalculatePrice = (orderType, currency = FiatCurrency.USD) => {
	const { data: pointsRation } = useFetchPointsRatio(
		orderType,
		currency === FiatCurrencyWithPiPoints.PI_POINTS
			? FiatCurrency.USD
			: currency,
	);

	return useCallback((amount) => Math.ceil(amount * (pointsRation || 0)), [pointsRation]);
};
