import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-number-input';
import countries from 'i18n-iso-countries';
import 'react-phone-number-input/style.css';
import clsx from 'clsx';

import { useAuthentication } from '../Authentication/Authentication';
import './Phone.scss';

export const FormPhone = ({ className, ...props }) => {
	const { user } = useAuthentication();
	const country = user?.address?.country;
	const countryCode = countries.isValid(country) ? country : 'US';

	return (
		<PhoneInput
			className={clsx('FormPhone form-control d-flex align-items-center py-0', className)}
			defaultCountry={countryCode}
			international
			{...props}
		/>
	);
};

FormPhone.propTypes = {
	className: PropTypes.string,
};

FormPhone.defaultProps = {
	className: '',
};
