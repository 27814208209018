import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup, Input, Progress, Row } from 'reactstrap';
import { Field, FieldError } from 'react-jsonschema-form-validation';
import { FaTrash } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

import { FormLabel } from '../../../../../Form/Label';
import { VideoInput, VideoInputType } from '../../../../../Media/VideoInput';
import PlayerVideo from '../../../../../Player/Video';
import { getFileUrl } from '../../../../../../lib/file';
import { calculateAdCreditCostMultiplier, caluclateAdCreditCost } from '../../../../../../lib/ads';
import { useFetchAdSettings } from '../../../../../../api-hooks/ads/ads';

export const AdvertisingAdManagmentForm = ({
	adCampaign,
	setAdCampaign,
	isViewOnly,
}) => {
	const { t } = useTranslation();
	const { data: adSettings } = useFetchAdSettings();
	const [videoUploadProgress, setVideoUploadProgress] = useState();

	const handleRemoveVideo = useCallback((index) => {
		const { ads } = adCampaign;

		ads[index].video = undefined;

		setAdCampaign((prevAdCampaign) => ({
			...prevAdCampaign,
			ads,
		}));
	}, [adCampaign, setAdCampaign]);

	const handleRemoveAd = useCallback((index) => {
		const { ads } = adCampaign;

		if (ads.length === 2) {
			ads[0].views = parseInt(adCampaign.viewTarget, 10);
			ads[0].playPercentage = 100;
		}

		setAdCampaign((prevAdCampaign) => ({
			...prevAdCampaign,
			ads: ads.length > 1
				? prevAdCampaign.ads.filter((ad, adIndex) => adIndex !== index)
				: ads,
		}));
	}, [adCampaign, setAdCampaign]);

	const handleChangeAdVideo = useCallback((index, video) => {
		const { ads } = adCampaign;

		ads[index].video = video;

		setAdCampaign((prevAdCampaign) => ({
			...prevAdCampaign,
			ads,
		}));
	}, [adCampaign, setAdCampaign]);

	const calculateAdViews = useCallback(
		(viewTarget, playPercentage) => Math.floor(viewTarget * (playPercentage / 100)),
		[],
	);

	const handleChangeSharedAdProperty = useCallback((propName, value, index, limit) => {
		const currentValue = parseInt(value, 10) || 0;
		const currentTotalValue = adCampaign.ads.reduce((acc, cur, i) => (index !== i
			? acc + cur[propName]
			: acc
		), 0);

		const { ads } = adCampaign;

		if (value < 0) {
			return;
		} if (adCampaign.ads.length <= 1) {
			ads[index][propName] = limit;
		} else if (currentValue + currentTotalValue <= limit) {
			ads[index][propName] = currentValue;
		}

		setAdCampaign((prevAdCampaign) => ({
			...prevAdCampaign,
			ads,
		}));
	}, [adCampaign, setAdCampaign]);

	return (
		<>
			<h4 className="my-4">{t('AdCampaings.Ad.adManagment')}</h4>
			{adCampaign.ads.map((ad, i) => (
				// eslint-disable-next-line react/no-array-index-key
				<div key={`ad-item-${ad}`} className="border-bottom py-2 my-4">
					<Row>
						<Col md={4}>
							<FormGroup>
								<FormLabel>{t('AdCampaings.Ad.adName')}</FormLabel>
								<Field
									component={Input}
									type="text"
									name={`ads.${i}.name`}
									value={ad.name}
									placeholder={t('AdCampaings.Ad.enterAdName')}
									disabled={isViewOnly}
								/>
								<FieldError name={`ads.${i}.name`} />
							</FormGroup>
						</Col>
						<Col md={4} className="d-flex align-items-center">
							<div>
								<div className="d-flex align-items-center">
									{ad?.video ? (
										<PlayerVideo
											key={ad.video.filename}
											className="rounded-sm overflow-hidden size-sm mr-2"
											src={getFileUrl({ name: ad.video.filename })}
											videoId={ad.video.video}
											onRemove={() => handleRemoveVideo(i)}
										/>
									) : <p className="m-0 mt-1 mr-1">{t('AdCampaings.Ad.chooseAVideo')}</p>}
									{!isViewOnly && (
										<VideoInput
											setUploadedVideo={(video) => handleChangeAdVideo(i, video)}
											setVideoUploadProgress={setVideoUploadProgress}
											videoInputType={(
												adCampaign?.sponsoredChannel?.user || adCampaign?.sponsoredChannel?.id
											)
												? VideoInputType.SPONSORED_AD
												: VideoInputType.AD}
										/>
									)}
								</div>
								{videoUploadProgress && (
									<Progress className="mt-3" value={videoUploadProgress.loaded} max={videoUploadProgress.total} />
								)}
							</div>
						</Col>
						<Col className="d-flex align-items-center" md={1}>
							{adCampaign.ads.length > 1 && !isViewOnly && (
								<FaTrash
									className="text-danger cursor-pointer"
									onClick={() => handleRemoveAd(i)}
								/>
							)}
						</Col>
					</Row>
					<Row>
						<Col>
							<FormGroup>
								<FormLabel>{t('AdCampaings.Ad.duration')}</FormLabel>
								<Field
									component={Input}
									type="text"
									name={`ads.${i}.video.duration`}
									value={ad?.video?.duration || 0}
									disabled
								/>
								<FieldError name={`ads.${i}.video.duration`} />
							</FormGroup>
						</Col>
						<Col>
							<FormGroup>
								<FormLabel>{t('AdCampaings.Ad.views')}</FormLabel>
								<Field
									component={Input}
									type="number"
									value={calculateAdViews(adCampaign.viewTarget, ad?.playPercentage || 0)}
									disabled
								/>
							</FormGroup>
						</Col>
						<Col>
							<FormGroup>
								<FormLabel>{t('AdCampaings.Ad.percentOfPlay')}</FormLabel>
								<Field
									component={Input}
									type="number"
									name={`ads.${i}.playPercentage`}
									value={ad?.playPercentage || 0}
									onChange={(e) => handleChangeSharedAdProperty(
										'playPercentage',
										e.target.value,
										i,
										100,
									)}
									disabled={adCampaign.ads.length <= 1 || isViewOnly}
								/>
								<FieldError name={`ads.${i}.playPercentage`} />
							</FormGroup>
						</Col>
						<Col>
							<FormGroup>
								<FormLabel>{t('AdCampaings.Ad.estimatedAdCredit')}</FormLabel>
								<Field
									component={Input}
									type="estimatedAdCredit"
									name={`ads.${i}.views`}
									value={adSettings ? caluclateAdCreditCost(
										[ad],
										adCampaign.viewTarget,
										calculateAdCreditCostMultiplier(adCampaign, adSettings.maxPriceIncrease),
										adSettings.adCreditToChips,
									) : '-'}
									disabled
								/>
							</FormGroup>
						</Col>
					</Row>
				</div>
			))}
		</>
	);
};

AdvertisingAdManagmentForm.propTypes = {
	adCampaign: PropTypes.shape({
		ads: PropTypes.arrayOf(PropTypes.shape({
			name: PropTypes.string,
			language: PropTypes.string,
			video: PropTypes.shape({
				duration: PropTypes.number,
				filename: PropTypes.string,
			}),
			playPercentage: PropTypes.number,
			views: PropTypes.number,
		})),
		sponsoredChannel: PropTypes.shape({
			user: PropTypes.string,
			id: PropTypes.string,
		}),
		viewTarget: PropTypes.string,
		timeSlots: PropTypes.arrayOf(PropTypes.string),
		interests: PropTypes.arrayOf(PropTypes.string),
		ageGroups: PropTypes.arrayOf(PropTypes.string),
		genders: PropTypes.arrayOf(PropTypes.string),
	}),
	setAdCampaign: PropTypes.func,
	isViewOnly: PropTypes.bool,
};

AdvertisingAdManagmentForm.defaultProps = {
	adCampaign: undefined,
	setAdCampaign: undefined,
	isViewOnly: false,
};
