import { Form as SchemaForm } from 'react-jsonschema-form-validation';
import PropTypes from 'prop-types';

export const FormSchemaForm = ({ children, ...props }) => (
	<SchemaForm {...props}>
		{children}
	</SchemaForm>
);

FormSchemaForm.propTypes = {
	children: PropTypes.node.isRequired,
};
