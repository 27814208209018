import { ResourceAccessRole } from '../../lib/ResourceAccessRole';

export const getRoleField = (role) => {
	switch (role) {
	case ResourceAccessRole.PARTICIPANT:
		return 'participant';
	case ResourceAccessRole.VIEWER:
		return 'viewer';
	default:
		throw new TypeError(`Invalid role ${role}`);
	}
};
