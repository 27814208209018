import { api } from '../api';

const API_PAYMENT_PAYPAL_PATH = '/membership/paypal';

export const createOrder = async (
	{ amount, orderBundleType, channelId, includeDonationFee, goalId },
) => api
	.post(`${API_PAYMENT_PAYPAL_PATH}/order`, { amount, orderBundleType, channelId, includeDonationFee, goalId });

export const createCashOutOrder = async (cashOutId) => api
	.post(`${API_PAYMENT_PAYPAL_PATH}/cash-out`, { cashOutId });

export const captureOrder = async ({ orderId, orderType }) => api.post(
	`${API_PAYMENT_PAYPAL_PATH}/order/capture`,
	{ orderId, orderType },
);

export const createSubscription = async (plan) => api
	.post(`${API_PAYMENT_PAYPAL_PATH}/subscription`, { plan });

export const captureSubscription = async (subscriptionId) => api
	.post(`${API_PAYMENT_PAYPAL_PATH}/subscription/${subscriptionId}/capture`);
