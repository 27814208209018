// @ts-check

import { formatLiveDate } from '../../lib/time';

/**
 * @param {Date | string} videoDate
 * @param {import('react-i18next').TFunction} t
 */
export const liveStatusMessage = (videoDate, t) => {
	const time = Math.round((new Date(videoDate).getTime() - new Date().getTime()) / 60000);
	if (time > -30 && time < 2) return t('Channel.Helper.aboutToStart');
	if (time >= 2 && time < 5) return t('Channel.Helper.startIn5');
	if (time <= -30 || time > 60) return `${t('Channel.Helper.livePlanned')} ${formatLiveDate(videoDate, t)}.`;
	return `${t('Channel.Helper.liveStart')} ${formatLiveDate(videoDate, t)}.`;
};
