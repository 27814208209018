// @ts-check

import { api } from '../api';

const API_STUDIO_ANALYTICS_PATH = '/studios/analytics';

/**
 * @param {string} id
 * @returns {Promise<import('axios').AxiosResponse<
 * import('./analytics.dto').IStudioAnalyticsSummaryDto>>}
 */
export const fetchStudioAnalyticsSummary = async (id) => api.get(
	`${API_STUDIO_ANALYTICS_PATH}/${id}/summary`,
);

/**
 * @param {string} id
 * // TODO: DTO
 * @returns {Promise<import('axios').AxiosResponse<any[]>>}
 */
export const fetchStudioAnalyticsInvitations = async (id) => api.get(
	`${API_STUDIO_ANALYTICS_PATH}/${id}/invitations`,
);

/**
 * @param {string} id
 * // TODO: DTO
 * @returns {Promise<import('axios').AxiosResponse<any[]>>}
 */
export const fetchStudioAnalyticsMessages = async (id) => api.get(
	`${API_STUDIO_ANALYTICS_PATH}/${id}/messages`,
);

/**
 * @param {string} id
 * @returns {Promise<import('axios').AxiosResponse<
 * import('./analytics.dto').IStudioAnalyticsRecordingDto[]>>}
 */
export const fetchStudioAnalyticsRecordings = async (id) => api.get(
	`${API_STUDIO_ANALYTICS_PATH}/${id}/recordings`,
);

/**
 * @param {string} id
 * @returns {Promise<import('axios').AxiosResponse<
 * import('./analytics.dto').IStudioAnalyticsStudioParticipantDto[]>>}
 */
export const fetchStudioAnalyticsParticipants = async (id) => api.get(
	`${API_STUDIO_ANALYTICS_PATH}/${id}/participants`,
);

/**
 * @param {string} id
 * @returns {Promise<import('axios').AxiosResponse<
 * import('./analytics.dto').IStudioAnalyticsStudioParticipantDto[]>>}
 */
export const fetchStudioAnalyticsViewers = async (id) => api.get(
	`${API_STUDIO_ANALYTICS_PATH}/${id}/viewers`,
);

/**
 * @param {string} id
 * @returns {Promise<import('axios').AxiosResponse<
 * import('./analytics.dto').IStudioAnalyticsPricingDto>>}
 */
export const fetchStudioAnalyticsPricing = async (id) => api.get(
	`${API_STUDIO_ANALYTICS_PATH}/${id}/pricing`,
);
