import { useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { NavItem, NavLink } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { ModalScreenHeaderNav } from '../../../Modal/Screen/Header';
import { useUserSettings } from '../../Context';
import { AdvertisingAdList } from './Ads/List';
import { AdvertisingDashboard } from './Dashboard/Dashboard';
import { SponsorAdsTable } from './Ads/SponsorAdsTable';

export const AdvertisingsModalTab = {
	DASHBOARD: 'DASHBOARD',
	CAMPAIGNS: 'CAMPAIGNS',
	SPONSOR_ADS: 'SPONSOR_ADS',
};

export const AdvertisingContent = () => {
	const { t } = useTranslation();

	const [activeTab, setActiveTab] = useState(AdvertisingsModalTab.CAMPAIGNS);
	const { closeAnalyticsAndAdModal, advertisingActiveTab } = useUserSettings();

	useEffect(() => {
		if (advertisingActiveTab) {
			setActiveTab(advertisingActiveTab);
		}
	}, [advertisingActiveTab]);

	return (
		<div className="AdvertisingCampaigns">
			<div className="d-flex flex-md-row flex-column justify-content-between align-items-center AnalyticsModalHeader pt-4 pb-0">
				<ModalScreenHeaderNav className="m-0 w-75">
					<NavItem className="mr-3">
						<NavLink
							title="Dashboard"
							onClick={() => setActiveTab(AdvertisingsModalTab.DASHBOARD)}
							active={activeTab === AdvertisingsModalTab.DASHBOARD}
						>
							<span>Dashboard</span>
							<div className="divider" />
						</NavLink>
					</NavItem>
					<NavItem className="mr-3">
						<NavLink
							title="Campaigns"
							onClick={() => setActiveTab(AdvertisingsModalTab.CAMPAIGNS)}
							active={activeTab === AdvertisingsModalTab.CAMPAIGNS}
						>
							<span>Campaigns</span>
							<div className="divider" />
						</NavLink>
					</NavItem>
					<NavItem className="mr-3">
						<NavLink
							title="SponsorAds"
							onClick={() => setActiveTab(AdvertisingsModalTab.SPONSOR_ADS)}
							active={activeTab === AdvertisingsModalTab.SPONSOR_ADS}
						>
							<span>{t('AdCampaings.Ad.sponsorAds')}</span>
							<div className="divider" />
						</NavLink>
					</NavItem>
				</ModalScreenHeaderNav>
			</div>
			<div className="mt-4">
				<FaTimes
					className="cursor-pointer text-secondary mt-1 position-absolute top-0 right-0 mr-2 mt-2"
					onClick={closeAnalyticsAndAdModal}
				/>
				{activeTab === AdvertisingsModalTab.DASHBOARD && (
					<AdvertisingDashboard
						onCreateAdCampaign={() => setActiveTab(AdvertisingsModalTab.CAMPAIGNS)}
					/>
				)}
				{activeTab === AdvertisingsModalTab.CAMPAIGNS && (
					<AdvertisingAdList />
				)}
				{activeTab === AdvertisingsModalTab.SPONSOR_ADS && (
					<div className="pb-5 mb-5"><SponsorAdsTable /></div>
				)}
			</div>
		</div>
	);
};
