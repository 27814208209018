import {
	connect as wsapiConnect,
	disconnect as wsapiDisconnect,
	getSocket,
} from '@technomiam/wsapi-client';

const SIGNALING_PATH = '/signaling';
let endpoint;

export const connect = async ({ baseUrl, options }) => {
	endpoint = `${baseUrl}${SIGNALING_PATH}`;
	return wsapiConnect(endpoint, options);
};

export const disconnect = () => wsapiDisconnect(endpoint);

export const socket = () => getSocket(endpoint)();

export async function join(hashtag) {
	const sock = socket();

	const emitjoin = () => new Promise((resolve, reject) => {
		sock.emit('join', { hashtag }, ({ error, data }) => {
			if (error) {
				console.error(`Error while joining signaling of channel [${hashtag}]`);
				reject(error);
			} else {
				console.info(`joined ${data.hashtag}`);
				resolve(data);
			}
		});
	});

	const onReconnect = () => emitjoin();
	sock.on('reconnect', onReconnect);
	emitjoin();

	const emitleave = () => new Promise((resolve, reject) => {
		sock.off('reconnect', onReconnect);
		sock.emit('leave', { hashtag }, ({ error, data }) => {
			if (error) {
				console.error(`Error while leaving signaling of channel [${hashtag}]`);
				reject(error);
			} else {
				console.info(`leaved ${data.hashtag}`);
				resolve();
			}
		});
	});

	return async () => emitleave();
}
