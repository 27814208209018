import { Suspense } from 'react';
import { SurveyListModal } from './SurveyListModal';
import { SurveyOverviewModal } from './SurveyOverviewModal';
import { SurveyTemplateModal } from './SurveyTemplateModal';

export const SurveyModals = () => (
	<Suspense fallback={null}>
		<SurveyTemplateModal />
		<SurveyListModal />
		<SurveyOverviewModal />
	</Suspense>
);
