// @ts-check

import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { PlayerModes, usePlayerMode } from './PlayerModeProvider';

/**
 * @typedef {{
 *  toggleRatio: () => void,
 *  keepRatio: boolean,
 *  floatingPiPEnabled: boolean,
 * }} IPiPContext
*/

export const PiPContext = createContext(/** @type {IPiPContext} */({}));

export const usePiP = () => useContext(PiPContext);

/**
 * @typedef {{
 *  children: React.ReactNode
 * }} PlayerLivePiPProviderProps
 */

export const PlayerLivePiPProvider = (
	/** @type {PlayerLivePiPProviderProps} */
	{
		children,
	},
) => {
	const { mode } = usePlayerMode();
	const [keepRatio, setKeepRatio] = useState(true);

	const floatingPiPEnabled = !mode || mode === PlayerModes.FLOATING_PIP;

	const toggleRatio = useCallback(() => {
		setKeepRatio((prevState) => !prevState);
	}, [setKeepRatio]);

	const contextValue = useMemo(() => ({
		toggleRatio,
		keepRatio,
		floatingPiPEnabled,
	}), [
		toggleRatio,
		keepRatio,
		floatingPiPEnabled,
	]);

	return (
		<PiPContext.Provider value={contextValue}>
			{children}
		</PiPContext.Provider>
	);
};

PlayerLivePiPProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
