// @ts-check

import TimeAgo from 'javascript-time-ago';
import { useTranslation } from 'react-i18next';

import en from 'javascript-time-ago/locale/en';
import fr from 'javascript-time-ago/locale/fr';
import it from 'javascript-time-ago/locale/it';
import es from 'javascript-time-ago/locale/es';
import de from 'javascript-time-ago/locale/de';

import { getLanguageKey } from '../i18n';

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(fr);
TimeAgo.addLocale(it);
TimeAgo.addLocale(es);
TimeAgo.addLocale(de);

/** @type {Record<string, TimeAgo>} */
const TimeAgoLocalesInstances = {};

TimeAgoLocalesInstances.default = new TimeAgo('en-US');
TimeAgoLocalesInstances.en = TimeAgoLocalesInstances.default;
TimeAgoLocalesInstances.fr = new TimeAgo('fr-FR');
TimeAgoLocalesInstances.it = new TimeAgo('it-IT');
TimeAgoLocalesInstances.es = new TimeAgo('es-ES');
TimeAgoLocalesInstances.de = new TimeAgo('de-DE');

/**
 * Get the localized TimeAgo instance
 *
 * @returns {TimeAgo}
 */
export const useLocalizedTimeAgo = () => {
	const { i18n } = useTranslation();

	const locale = getLanguageKey(i18n.language);

	return TimeAgoLocalesInstances[locale] || TimeAgoLocalesInstances.default;
};
