import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Col, Row, Container, Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ModalScreenHeader } from '../../../../../Modal/Screen/Header';
import { ModalScreen } from '../../../../../Modal/Screen/Screen';
import { useFetchEngagementVideoDetails } from '../../../../../../api-hooks/analytics/engagement';
import { VideoThumbnail } from '../../../../../Video/Thumbnail';
import { useUserSettings } from '../../../../Context';
import { getChannelLink } from '../../../../../../RoutePath';

export const AnalyticsEngagementOverviewVideoModal = ({ isOpen, closeModal, video }) => {
	const { t } = useTranslation();
	const { data: videoDetails } = useFetchEngagementVideoDetails(video._id);
	const { closeModal: closeUserSettingsModal } = useUserSettings();

	return (
		<ModalScreen
			bodyClassName="py-0"
			header={(
				<ModalScreenHeader
					onClose={closeModal || closeUserSettingsModal}
					title={t('Overview.VideoModal.videoDetails')}
				/>
			)}
			isOpen={isOpen}
			onClose={closeModal || closeUserSettingsModal}
		>
			<Container className="my-5 mx-3">
				<Row className="mb-3">
					<Col>
						<div>
							<div className="d-flex align-items-center mb-5">
								{video && (
									<VideoThumbnail video={video} className="rounded-sm overflow-hidden size-sm flex-shrink-0 mr-2" />
								)}
								<h4 className="m-0 ml-3">{video.label}</h4>
							</div>
						</div>
						{!!videoDetails && !!videoDetails.length && (
							<div className="overflow-auto">
								<Table className="TransactionList_table">
									<thead className="content-dark">
										<tr>
											<th>{t('Overview.VideoModal.nickname')}</th>
											<th>{t('Overview.VideoModal.hashtag')}</th>
											<th>{t('Overview.VideoModal.views')}</th>
										</tr>
									</thead>
									<tbody className="text-secondary">
										{videoDetails.map((videoDetail) => (
											<tr key={videoDetail._id}>
												<td>
													<Link to={getChannelLink(videoDetail.hashtag)}>
														{videoDetail.nickname}
													</Link>
												</td>
												<td>{videoDetail.hashtag}</td>
												<td>{videoDetail.viewsCount}</td>
											</tr>
										))}
									</tbody>
								</Table>
							</div>
						)}
						{!!videoDetails && !videoDetails.length && (
							<p className="text-center">{t('Overview.VideoModal.noViews')}</p>
						)}
					</Col>
				</Row>
			</Container>
		</ModalScreen>
	);
};

AnalyticsEngagementOverviewVideoModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	closeModal: PropTypes.func,
	video: PropTypes.shape({
		_id: PropTypes.string,
		label: PropTypes.string,
	}),
};

AnalyticsEngagementOverviewVideoModal.defaultProps = {
	video: undefined,
	closeModal: undefined,
};
