import { t as tStatic } from 'i18next';

export const MIN_PASSWORD_LENGTH = 12;

export const passwordLowercaseSchema = {
	type: 'string',
	pattern: '^(?=.*[a-z])',
	message: 'Password must contain at least 1 lowercase letter (a)',
};

export const passwordUppercaseSchema = {
	type: 'string',
	pattern: '^(?=.*[A-Z])',
	message: tStatic('Onboarding.StepPassword.constraints.uppercase'),
};

export const passwordSpecialCharacterSchema = {
	type: 'string',
	pattern: '^(?=.*[@$!%*?&.,#^()_\\-\\[\\]{}])',
	message: tStatic('Onboarding.StepPassword.constraints.specialChar'),
};

export const passwordNumberSchema = {
	type: 'string',
	pattern: '^(?=.*\\d)',
	message: tStatic('Onboarding.StepPassword.constraints.number'),
};

export const passwordFullSchema = {
	type: 'string',
	pattern: '^(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&.,#^()_\\-\\[\\]{}])[A-Za-z\\d@$!%*?&.,#^()_\\-\\[\\]{}]{12,}$',
	message: tStatic('Onboarding.StepPassword.constraints.pattern'),
};

export const getPasswordValidationMessage = (pattern) => {
	switch (pattern) {
	case passwordLowercaseSchema.pattern:
		return passwordLowercaseSchema.message;
	case passwordUppercaseSchema.pattern:
		return passwordUppercaseSchema.message;
	case passwordSpecialCharacterSchema.pattern:
		return passwordSpecialCharacterSchema.message;
	case passwordNumberSchema.pattern:
		return passwordNumberSchema.message;
	case passwordFullSchema.pattern:
		return passwordFullSchema.message;
	default:
		return null;
	}
};

export const password = {
	allOf: [
		{
			type: 'string',
			minLength: MIN_PASSWORD_LENGTH,
		},
		passwordLowercaseSchema,
		passwordUppercaseSchema,
		passwordSpecialCharacterSchema,
		passwordNumberSchema,
		passwordFullSchema,
	],
};

export const properties = {
	password,
};

export const schema = {
	type: 'object',
	additionalProperties: false,
	properties,
	required: [
		'password',
	],
};
