import { createContext, useContext, useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';

import { connectSockets, disconnectSockets, onEventLiveNotification } from '../../api/ws/public';
import { sessionId } from '../../lib/session';

export const PublicSocketContext = createContext();

export const usePublicSocket = () => useContext(PublicSocketContext);

export const PublicSocketProvider = ({ children }) => {
	const offAllEvents = useRef([]);
	const liveNotificationHandlersRef = useRef([]);
	const isUnsubscribeAsked = useRef(false);

	const removeLiveNotificationHandler = useCallback((handler) => {
		liveNotificationHandlersRef.current = liveNotificationHandlersRef.current.filter(
			(h) => h !== handler,
		);
	}, []);

	const addLiveNotificationHandler = useCallback((handler) => {
		liveNotificationHandlersRef.current.push(handler);
		return () => removeLiveNotificationHandler(handler);
	}, [removeLiveNotificationHandler]);

	const subscribe = useCallback(async () => {
		isUnsubscribeAsked.current = false;

		await connectSockets({ params: { sessionId } });

		if (isUnsubscribeAsked.current) {
			return;
		}

		offAllEvents.current = [
			onEventLiveNotification((...args) => {
				liveNotificationHandlersRef.current.forEach((handler) => {
					handler(...args);
				});
			}),
		];
	}, []);

	const unsubscribe = useCallback(() => {
		isUnsubscribeAsked.current = true;
		offAllEvents.current.forEach((offEvent) => offEvent());
		disconnectSockets();
	}, []);

	useEffect(() => {
		subscribe();
		return unsubscribe;
	}, [subscribe, unsubscribe]);

	const contextValue = useMemo(() => ({
		addLiveNotificationHandler,
		removeLiveNotificationHandler,
	}), [
		addLiveNotificationHandler,
		removeLiveNotificationHandler,
	]);

	return (
		<PublicSocketContext.Provider value={contextValue}>
			{children}
		</PublicSocketContext.Provider>
	);
};

PublicSocketProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
