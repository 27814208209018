import PropTypes from 'prop-types';
import { lazy, useCallback, useEffect, useState } from 'react';
import { Button, NavItem, NavLink, TabContent } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { FaCalendarAlt, FaPlus } from 'react-icons/fa';

import { useFetchMyHosts } from '../../../../api-hooks/channel/operators';
import { useFetchStudioSchedules } from '../../../../api-hooks/studio/schedule';
import { useAsyncErrorLog } from '../../../../lib/hooks';
import { ModalLazyContent } from '../../../Modal/LazyContent';
import { ModalScreenHeaderNav } from '../../../Modal/Screen/Header';
import { ModalScreen } from '../../../Modal/Screen/Screen';
import { Pagination } from '../../../Pagination/Pagination';
import { usePagination } from '../../../Pagination/Context';
import { useProfile } from '../../../Profile/ProfileContext';
import { StudioScheduleModal, useStudioSchedule } from '../../Context';
import { StudioState } from '../../helper';
import { ChannelDropdownHeader } from './Header';
import { DropdownMode } from './ModalDropdownMode';

const StudioScheduleManagerModalContent = lazy(() => import('./Content'));

export const StudioScheduleManagerModal = ({
	defaultTab,
	isOpen,
	setStudioOwnerId,
	studioOwnerId,
}) => {
	const { t } = useTranslation();
	const { closeModal, openModal } = useStudioSchedule();
	const [studioState, setStudioState] = useState(defaultTab);
	const [activeDropdowMode, setActiveDropdownMode] = useState(DropdownMode.MY_STUDIOS);
	const [activeOperatorForChannel, setActiveOperatorForChannel] = useState();

	const {
		currentPage,
		itemsPerPage,
		updateItemsCount,
		goFirstPage,
	} = usePagination();

	const {
		data,
		error,
		isLoading: isStudioSchedulesLoading,
	} = useFetchStudioSchedules(studioOwnerId, studioState, itemsPerPage, currentPage);

	const { profile } = useProfile();
	const { data: myHosts } = useFetchMyHosts();

	useAsyncErrorLog({ error });

	const handleStudioStateChange = useCallback((state) => {
		setStudioState(state);
		goFirstPage();
	}, [goFirstPage]);

	useEffect(() => {
		const channelId = activeDropdowMode === DropdownMode.MY_STUDIOS
			? profile?._id
			: activeOperatorForChannel._id;

		setStudioOwnerId(channelId);
	}, [activeDropdowMode, activeOperatorForChannel, profile, setStudioOwnerId, studioState]);

	useEffect(() => {
		updateItemsCount(data?.totalItemsCount ?? 0);
	}, [data?.totalItemsCount, updateItemsCount]);

	const canStartStudio = profile?.organizationRole?.canStartStudio;

	return (
		<ModalScreen
			bodyClassName="p-3"
			header={(
				<ChannelDropdownHeader
					activeMode={activeDropdowMode}
					setActiveMode={setActiveDropdownMode}
					avatar={profile.avatar}
					onClose={closeModal}
					activeChannel={activeOperatorForChannel}
					channels={myHosts ?? []}
					setActiveChannel={setActiveOperatorForChannel}
					buttons={(
						<>
							{canStartStudio ? (
								<Button
									className="ml-auto px-2 mr-2 shadow-none"
									color="primary"
									onClick={() => openModal(StudioScheduleModal.EDIT)}
								>
									<FaPlus />
									<span className="ml-1 d-none d-lg-inline">{t('Modal.Modal.schedule')}</span>
								</Button>
							) : undefined}
							<Button
								className="px-2 shadow-none"
								color="darker"
								onClick={() => openModal(StudioScheduleModal.CALENDAR)}
							>
								<FaCalendarAlt />
								<span className="ml-1 d-none d-lg-inline">{t('Modal.Modal.view.calendar')}</span>
							</Button>
						</>
					)}
				>
					<ModalScreenHeaderNav className="mt-3">
						<NavItem className="mr-xl-3">
							<NavLink
								title={t('Modal.Modal.inProgress')}
								onClick={() => handleStudioStateChange(StudioState.ONGOING)}
								active={studioState === StudioState.ONGOING}
							>
								<span>{t('Modal.Modal.inProgress')}</span>
								<div className="divider" />
							</NavLink>
						</NavItem>
						<NavItem className="mr-xl-3">
							<NavLink
								title={t('Modal.Modal.planned')}
								onClick={() => handleStudioStateChange(StudioState.NOT_STARTED)}
								active={studioState === StudioState.NOT_STARTED}
							>
								<span>{t('Modal.Modal.planned')}</span>
								<div className="divider" />
							</NavLink>
						</NavItem>
						<NavItem className="mx-xl-3">
							<NavLink
								title={t('Modal.Modal.completed')}
								onClick={() => handleStudioStateChange(StudioState.TERMINATED)}
								active={studioState === StudioState.TERMINATED}
							>
								<span>{t('Modal.Modal.completed')}</span>
								<div className="divider" />
							</NavLink>
						</NavItem>
						<NavItem className="ml-xl-3">
							<NavLink
								title={t('Modal.Modal.cancelled')}
								onClick={() => handleStudioStateChange(StudioState.CANCELLED)}
								active={studioState === StudioState.CANCELLED}
							>
								<span>{t('Modal.Modal.cancelled')}</span>
								<div className="divider" />
							</NavLink>
						</NavItem>
					</ModalScreenHeaderNav>
				</ChannelDropdownHeader>
			)}
			isOpen={isOpen}
			onClose={closeModal}
		>
			<TabContent>
				<ModalLazyContent>
					<StudioScheduleManagerModalContent
						studioSchedules={data?.data}
						studioState={studioState}
						isStudioSchedulesLoading={isStudioSchedulesLoading}
					/>

				</ModalLazyContent>
			</TabContent>
			<Pagination className="mt-2" isDisabled={isStudioSchedulesLoading} />
		</ModalScreen>
	);
};

StudioScheduleManagerModal.propTypes = {
	defaultTab: PropTypes.oneOf(Object.values(StudioState)),
	isOpen: PropTypes.bool.isRequired,
	setStudioOwnerId: PropTypes.func.isRequired,
	studioOwnerId: PropTypes.string,
};

StudioScheduleManagerModal.defaultProps = {
	defaultTab: StudioState.NOT_STARTED,
	studioOwnerId: undefined,
};
