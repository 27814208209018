/* eslint-disable react/prop-types */
// @ts-check

import { Outlet } from 'react-router-dom';

export const EmbedPage = () => (
	<div className="vh-100 vw-100 d-flex justify-content-center align-items-center">
		<Outlet />
	</div>
);
