// @ts-check

import { useMutation, useQuery, useQueryClient } from 'react-query';
import * as donationSubscriptionApi from '../../../api/channel/donation/subscription';
import { axiosMutationWrapper, axiosQueryWrapper } from '../../utils/axios-wrapper';
import { GOALS_QUERY_KEYS } from '../goals';

const DONATION_SUBSCRIPTION_QUERY_KEYS = {
	fetchDonationSubscription: (
		/** @type {string | undefined} */
		channelId,
	) => ['api', 'channel', 'donation', 'subscription', channelId],
	fetchDonationSubscriptions: (
		/** @type {number | undefined} */ itemsPerPage,
		/** @type {number | undefined} */ currentPage,
	) => ['api', 'channel', 'donation', 'subscriptions', { itemsPerPage, currentPage }],
};

/**
 * @param {{
*  itemsPerPage?: number,
*  currentPage?: number,
* }} param0
*/
export const useFetchDonationSubscriptions = (
	{ itemsPerPage, currentPage },
) => useQuery(
	DONATION_SUBSCRIPTION_QUERY_KEYS.fetchDonationSubscriptions(itemsPerPage, currentPage),
	axiosQueryWrapper(donationSubscriptionApi.fetchDonationSubscriptions, itemsPerPage, currentPage),
);

/**
 * @param {string} [channelId]
 */
export const useFetchDonationSubscription = (channelId) => useQuery(
	DONATION_SUBSCRIPTION_QUERY_KEYS.fetchDonationSubscription(channelId),
	axiosQueryWrapper(donationSubscriptionApi.fetchDonationSubscription, channelId),
);

export const useCreateDonationSubscription = () => {
	const queryClient = useQueryClient();

	return useMutation(
		donationSubscriptionApi.createDonationSubscription,
		{
			onSuccess: (data) => {
				queryClient.invalidateQueries(
					DONATION_SUBSCRIPTION_QUERY_KEYS.fetchDonationSubscription(data.data.to),
				);

				if (data.data.goal) {
					queryClient.invalidateQueries(GOALS_QUERY_KEYS.fetchOneGoalById(data.data.goal));
					queryClient.invalidateQueries(GOALS_QUERY_KEYS.fetchGoalsByChannelId(data.data.to));
				}
			},
		},
	);
};

export const useCreatePaypalDonationSubscription = () => useMutation(
	axiosMutationWrapper(donationSubscriptionApi.createPaypalDonationSubscription),
);

export const useCapturePaypalDonationSubscription = () => {
	const queryClient = useQueryClient();

	return useMutation(
		donationSubscriptionApi.capturePaypalDonationSubscription,
		{
			onSuccess: (data) => {
				queryClient.invalidateQueries(
					DONATION_SUBSCRIPTION_QUERY_KEYS.fetchDonationSubscription(data.data.to),
				);
			},
		},
	);
};

export const useCancelDonationSubscription = () => {
	const queryClient = useQueryClient();

	return useMutation(
		donationSubscriptionApi.cancelDonationSubscription,
		{
			onSuccess: (data) => {
				queryClient.invalidateQueries(
					DONATION_SUBSCRIPTION_QUERY_KEYS.fetchDonationSubscription(data.data.to),
				);
			},
		},
	);
};
