import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ResourceAccessCard } from './Card';

export const AudienceRestriction = ({
	resourceType,
}) => {
	const { t } = useTranslation();

	let label = '';
	if (resourceType === 'STUDIO') {
		label = t('RessourceAccess.AudienceRestriction.meeting');
	} else if (resourceType === 'VIDEO') {
		label = t('RessourceAccess.AudienceRestriction.video');
	}

	return (
		<ResourceAccessCard>
			<h3>
				{t('RessourceAccess.AudienceRestriction.This')} {label} {t('RessourceAccess.AudienceRestriction.ageRestricted')}
			</h3>
			<p>
				{t('RessourceAccess.AudienceRestriction.youMustBe18')} {label}
			</p>
		</ResourceAccessCard>
	);
};

AudienceRestriction.propTypes = {
	resourceType: PropTypes.string.isRequired,
};
