import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';

import ScrollContainer from 'react-indiana-drag-scroll';
import { ListTitle } from '../ItemList/ListTitle';

import { ChevronIcon, ChevronIconDirection } from '../ChannelListHexagon/ChevronIcon/ChevronIcon';
import { VideoItem } from '../ItemList/VideoItem';
import './VideoListSlider.scss';

const ROW_ITEMS_COUNT = 8;

export const VideoListSlider = ({
	videos,
	className,
	forwardedRef,
	placeholderLength,
	title,
}) => {
	const scrollContainer = useRef();

	const [isScrollable, setIsScrollable] = useState(
		{ right: true, left: true },
	);

	const videosOrPlaceholder = videos?.length > 0
		? videos
		: Array.from({ length: placeholderLength });

	const firstRowData = useMemo(() => (
		videosOrPlaceholder?.slice(0, ROW_ITEMS_COUNT)
	), [videosOrPlaceholder]);

	const secondRowData = useMemo(() => (
		videosOrPlaceholder?.slice(ROW_ITEMS_COUNT)
	), [videosOrPlaceholder]);

	const checkScroll = useCallback(() => {
		if (scrollContainer.current) {
			setIsScrollable({
				left: scrollContainer.current.scrollLeft > 0,
				right: (scrollContainer.current.scrollLeft + scrollContainer.current.clientWidth)
					< (scrollContainer.current.scrollWidth),
			});
		}
	}, []);

	useLayoutEffect(() => {
		window.addEventListener('resize', checkScroll);
		checkScroll();
		return () => window.removeEventListener('resize', checkScroll);
	}, [checkScroll]);

	const handleHorizontalScrollRight = () => {
		scrollContainer.current.scrollLeft += (scrollContainer.current.clientWidth);
	};

	const handleHorizontalScrollLeft = () => {
		scrollContainer.current.scrollLeft += (-scrollContainer.current.clientWidth);
	};

	return (
		<section
			className={clsx('VideoListSlider ChannelList ChannelListHexagon size-lg', className)}
			ref={forwardedRef}
		>
			{title && <span className="d-inline-block ml-5"><ListTitle title={title} /></span>}
			<ScrollContainer
				className="ChannelListHexagon__scrollContainer content-white"
				innerRef={scrollContainer}
				onEndScroll={checkScroll}
				vertical={false}
				key="VideoScroll"
			>
				<div className="ChannelListHexagon_list">
					{firstRowData.map((video) => (
						!!video && (
							<VideoItem
								className="px-4 mb-4"
								key={`${title}-video-list-${video._id}`}
								video={video}
							/>
						)
					))}
					<div className="mr-5" />
				</div>
				<div className="ChannelListHexagon_list">
					{secondRowData.map((video) => (
						!!video && (
							<VideoItem
								className="px-4 mb-4"
								key={`${title}-video-list-${video._id}`}
								video={video}
							/>
						)
					))}
					<div className="mr-5" />
				</div>
			</ScrollContainer>
			{
				isScrollable.left && (
					<span
						className="ChannelList_chevron_left color-yellow"
						onClick={handleHorizontalScrollLeft}
					>
						<div className="position-absolute start-100 translate-middle">
							<ChevronIcon />
						</div>
					</span>
				)
			}
			{
				isScrollable.right && (
					<span
						className="ChannelList_chevron_right color-yellow"
						onClick={handleHorizontalScrollRight}
					>
						<div className="position-absolute translate-middle">
							<ChevronIcon direction={ChevronIconDirection.RIGHT} />
						</div>
					</span>
				)
			}
		</section>
	);
};

VideoListSlider.propTypes = {
	videos: PropTypes.arrayOf(PropTypes.shape({})),
	className: PropTypes.string,
	forwardedRef: PropTypes.shape({}),
	placeholderLength: PropTypes.number,
	title: PropTypes.node,
};

VideoListSlider.defaultProps = {
	videos: [],
	className: '',
	forwardedRef: null,
	title: null,
	placeholderLength: 16,
};
