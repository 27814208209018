import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useMatch } from 'react-router-dom';
import { FaGift } from 'react-icons/fa';

import { PublicPathPrefix, Path } from '../../RoutePath';
import { useAuthentication } from '../../components/Authentication/Authentication';
import { ButtonPill } from '../../components/Button';
import { LanguageSelector } from '../../components/Language/LanguageSelector';
import { SearchInput } from '../../components/SearchSuggestion/SearchInput';
import { StudioHeader } from '../../components/Studio/Header';
import { ModeScreenLayout, useScreenMode } from '../../components/Studio/ScreenMode/Provider';
import { PREVIOUS_SESSION_KEY } from '../../lib/Identity';
import { useHandleDeleteDisableSubscription } from '../../lib/serviceWorkerHook';
import HeaderAnonymousBox from '../HeaderAnonymousBox';
import HeaderUserbox from '../HeaderUserbox';
import SidebarHeader from '../SidebarHeader';
import { useHeaderSearch } from './SearchContext';
import DonateModal from '../../components/Channel/Donate/Modal';
import { useFetchOrganizationDetails } from '../../api-hooks/organization/organization';
import { useProfile } from '../../components/Profile/ProfileContext';
import { RolePaymentType } from '../../components/OrganizationSettings/Role/RoleForm';

const isBeeyou = import.meta.env.VITE_PROJECT === 'beeyou';

const Header = ({
	isStudioRoute,
	search,
}) => {
	const { isLoggedIn, restorePreviousSession } = useAuthentication();
	const { currentModeScreen } = useScreenMode();
	const navigate = useNavigate();
	const deleteSubscription = useHandleDeleteDisableSubscription();
	const { t } = useTranslation();
	const { data: organization } = useFetchOrganizationDetails();

	const { profile } = useProfile();

	const [isDonateModalOpen, setIsDonateModalOpen] = useState(false);

	const { isSearchOpen, setSearchOpen } = useHeaderSearch();

	const isOrgPublicChannel = !!localStorage.getItem(PREVIOUS_SESSION_KEY);

	const handleGoBackToOrganization = useCallback(async () => {
		restorePreviousSession();

		await deleteSubscription();
		navigate('/');
	}, [deleteSubscription, navigate, restorePreviousSession]);

	const isHomeRoute = useMatch({ path: '/', end: true });
	const isWelcome = useMatch({ path: Path.NEWUSERINFO, end: false });
	const isPrices = useMatch({ path: Path.PRICES });
	const isVideos = useMatch({ path: `${PublicPathPrefix}/videos` }); // TODO: Replace with a constant when route available

	const showLanguageSelector = isHomeRoute || isWelcome || isVideos || isPrices;

	const toggleDonateModal = useCallback(() => {
		setIsDonateModalOpen((prevIsDonateModalOpened) => !prevIsDonateModalOpened);
	}, []);

	const showDonation = useMemo(() => {
		if (!profile?.organization.default && !profile?.organizationOwner) {
			return profile?.organizationRole?.paymentType === RolePaymentType.PAID;
		}

		return false;
	},
	[profile]);

	return (
		<div
			className={clsx('d-block', { 'position-absolute w-100 app-main layout-fullscreen d-none': currentModeScreen === ModeScreenLayout.FULLSCREEN })}
			id="Header"
		>
			<div className="app-header app-header--shadow d-flex">
				<div
					className={clsx('LogoBeeyouContainer d-flex align-items-center flex-shrink-0',
						{ 'search-menu-open': isSearchOpen },
						{ 'search-menu-close': !isSearchOpen },
						{ 'w-auto': isStudioRoute })}
				>
					<SidebarHeader
						isStudioRoute={isStudioRoute}
						isSearchOpen={isSearchOpen}
					/>
				</div>
				{search && (isLoggedIn || isBeeyou) && (
					<div
						className={clsx(
							'd-flex align-items-center h-100 flex-grow-1 flex-shrink-1 min-w-0',
							{
								'mr-2': !isSearchOpen,
								'mr-md-2': isSearchOpen,
							},
						)}
						id="StudioSearchBar"
					>
						{!isStudioRoute && (
							<SearchInput
								isSearchOpen={isSearchOpen}
								setSearchOpen={setSearchOpen}
							/>
						)}
					</div>
				)}
				<StudioHeader isSearchOpen={isSearchOpen} />
				<div className="d-flex align-items-center h-100">
					{!!isLoggedIn && isOrgPublicChannel && (
						<ButtonPill
							color="primary"
							size="sm"
							className="mr-3"
							onClick={handleGoBackToOrganization}
						>
							{t('HeaderUserbox.Index.switchToOrganization')}
						</ButtonPill>
					)}
					{isLoggedIn && showDonation && isBeeyou && (
						<>
							<ButtonPill
								className="mr-3"
								color="primary"
								type="button"
								size="sm"
								onClick={toggleDonateModal}
							>
								<span className="btn-wrapper--icon"><FaGift /></span>
								<span className="btn-wrapper--label d-none-fullscreen-md">
									{t('Description.Description.donate')}
								</span>
							</ButtonPill>
							{isDonateModalOpen && organization && (
								<DonateModal
									channel={organization.owner}
									isOpen={isDonateModalOpen}
									toggle={toggleDonateModal}
								/>
							)}
						</>
					)}
					<div
						className={clsx('d-flex flex-shrink-0 flex-wrap align-items-center justify-content-center h-100',
							{ 'search-menu-open': isSearchOpen },
							{ 'search-menu-close': !isSearchOpen })}
					>
						{isLoggedIn
							? <HeaderUserbox />
							: <HeaderAnonymousBox />}
					</div>
				</div>
				{showLanguageSelector && (
					<LanguageSelector
						className={clsx('h-50 pb-2 pt-1 me-2 ml-2',
							{ 'd-none': isSearchOpen })}
						minimal
					/>
				)}
			</div>
		</div>
	);
};

Header.propTypes = {
	isStudioRoute: PropTypes.bool,
	search: PropTypes.bool,
};

Header.defaultProps = {
	isStudioRoute: false,
	search: false,
};

export default Header;
