import PropTypes from 'prop-types';
import { FaEye, FaFlag, FaUser, FaUsers } from 'react-icons/fa';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { AdSharedProps } from '../../Ads/createAds.schema';
import { AdAgeGroupLabel, AdGenderLabel } from '../../Ads/Campaigns/Create/Create';

export const AdvertisingDashboardGoals = ({ adCampaign }) => {
	const { t } = useTranslation();

	return (adCampaign ? (
		<Row className="mb-5">
			<Col md={3}>
				<Card className="h-100">
					<CardBody className="d-flex justify-content-center flex-column align-items-center text-black text-center py-5 px-5">
						<h4>{adCampaign.viewTarget}</h4>
						<h5 className="text-secondary"><FaEye className="mr-1" />
							{t('AdCampaings.Ad.views')}
						</h5>
					</CardBody>
				</Card>
			</Col>
			<Col md={3}>
				<Card className="h-100">
					<CardBody className="d-flex justify-content-center flex-column align-items-center text-black text-center py-5 px-5">
						{adCampaign.genders.includes(AdSharedProps.ANY) ? (
							<h4>{t('AdCampaings.Ad.any')}</h4>
						) : (
							<div className="d-flex flex-wrap mb-2">
								{adCampaign.genders.map((gender) => (
									<small key={`analytics-gender-${gender}`} className="bg-secondary text-white p-1 px-2 mr-1 rounded d-flex align-items-center justify-content-center mb-2">
										{AdGenderLabel[gender]}
									</small>
								))}
							</div>
						)}
						<h5 className="text-secondary"><FaUser className="mr-1" />
							{t('AdCampaings.Ad.genders')}
						</h5>
					</CardBody>
				</Card>
			</Col>
			<Col md={3}>
				<Card className="h-100">
					<CardBody className="d-flex justify-content-center flex-column align-items-center text-black text-center py-5 px-5">
						{!adCampaign.countries.lenght ? (
							<h4>{t('AdCampaings.Ad.any')}</h4>
						) : (
							<div className="d-flex flex-wrap mb-2">
								{adCampaign.countries.map((country) => (
									<small key={`analytics-country-${country}`} className="bg-secondary text-white p-1 px-2 mr-1 rounded d-flex align-items-center justify-content-center mb-2">
										{country}
									</small>
								))}
							</div>
						)}
						<h5 className="text-secondary"><FaFlag className="mr-1" />{t('AdCampaings.Ad.countries')}</h5>
					</CardBody>
				</Card>
			</Col>
			<Col md={3}>
				<Card className="h-100">
					<CardBody className="d-flex justify-content-center flex-column align-items-center text-black text-center py-5 px-5">
						{adCampaign.ageGroups.includes(AdSharedProps.ANY) ? (
							<h4>{t('AdCampaings.Ad.any')}</h4>
						) : (
							<div className="d-flex flex-wrap mb-2">
								{adCampaign.ageGroups.map((ageGroup) => (
									<small key={`analytics-ageGroup-${ageGroup}`} className="bg-secondary text-white p-1 px-2 mr-1 rounded d-flex align-items-center justify-content-center mb-2">
										{AdAgeGroupLabel[ageGroup]}
									</small>
								))}
							</div>
						)}
						<h5 className="text-secondary"><FaUsers className="mr-1" />
							{t('AdCampaings.Ad.ageGroups')}
						</h5>
					</CardBody>
				</Card>
			</Col>
		</Row>
	) : null);
};

AdvertisingDashboardGoals.propTypes = {
	adCampaign: PropTypes.shape({
		name: PropTypes.string,
		startDate: PropTypes.string,
		endDate: PropTypes.string,
		viewTarget: PropTypes.number,
		ads: PropTypes.arrayOf(PropTypes.shape()),
		genders: PropTypes.arrayOf(PropTypes.string),
		ageGroups: PropTypes.arrayOf(PropTypes.string),
		countries: PropTypes.arrayOf(PropTypes.string),
	}),
};

AdvertisingDashboardGoals.defaultProps = {
	adCampaign: undefined,
};
