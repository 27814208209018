import { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { useMixing } from '../ReactVideo/Mixing';
import { PlayerLivePiPOverlay } from './PiPOverlay';
import { useGfx } from '../Gfx/Provider';

const layerIndexRegex = /(?:pip)(\d+)$/;
const getLayerIndex = (layer) => {
	const regLayer = layerIndexRegex.exec(layer);
	return regLayer?.length === 2 ? parseInt(regLayer[1], 10) : null;
};

export const PlayerLivePiPHandler = ({
	safeZoneComponent: SafeZone,
	size,
}) => {
	const { channelMixerStatus, changePiPPosition } = useMixing();
	const { gfxInEdition, setGfxInEdition } = useGfx();
	const [isMovingPiP, setIsMovingPiP] = useState(false);

	const pips = useMemo(
		() => (channelMixerStatus?.sources || []).filter((src) => src.isResizable)
			.sort((pipA, pipB) => (getLayerIndex(pipA.layer) < getLayerIndex(pipB.layer) ? 1 : -1)),
		[channelMixerStatus?.sources],
	);

	const isGfxInEdition = !!gfxInEdition;

	const handleUpdatePiP = useCallback((data, pip) => {
		if (isGfxInEdition) {
			setGfxInEdition((prevState) => ({
				...prevState,
				properties: {
					...prevState.properties,
					...data,
				},
			}));
		} else {
			changePiPPosition({
				pipLayer: pip.layer,
				...data,
			});
		}
	}, [
		changePiPPosition,
		isGfxInEdition,
		setGfxInEdition,
	]);

	return (
		<div
			className="PlayerLivePiPHandler"
			style={{
				position: 'absolute',
				left: `${size.left}px`,
				right: `${size.left || 0}px`,
				zIndex: 4,
			}}
		>
			{channelMixerStatus && (gfxInEdition ? [gfxInEdition] : pips).map((pip) => (
				<PlayerLivePiPOverlay
					isGfxInEdition={!!gfxInEdition}
					key={pip.layer || pip.asset?._id}
					onMovingPiP={setIsMovingPiP}
					onUpdatePiP={(data) => handleUpdatePiP(data, pip)}
					orientation={channelMixerStatus.orientation}
					pip={pip}
					size={size}
				/>
			))}
			{isMovingPiP && SafeZone && <SafeZone playerSize={size} />}
		</div>
	);
};

PlayerLivePiPHandler.propTypes = {
	safeZoneComponent: PropTypes.elementType,
	size: PropTypes.shape({
		width: PropTypes.number,
		height: PropTypes.number,
		left: PropTypes.number,
		right: PropTypes.number,
	}),
};

PlayerLivePiPHandler.defaultProps = {
	safeZoneComponent: null,
	size: {},
};
