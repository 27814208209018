import { useMutation, useQuery, useQueryClient } from 'react-query';
import * as preferencesApi from '../../api/channel/preference';
import { axiosMutationWrapper } from '../utils/axios-wrapper';
import { useProfile } from '../../components/Profile/ProfileContext';
import { GRAPH_GLOBALS_QUERY_KEYS } from '../graph/globals';

const NOTIFICATION_PREFERENCES = {
	fetchNotificationsPreferences: () => ['api', 'notification', 'preferences'],
};

export const useUpdateChannelPreferences = () => {
	const queryClient = useQueryClient();
	const { profile } = useProfile();

	return useMutation(
		axiosMutationWrapper(preferencesApi.createOrUpdateChannelPreferences), {
			onSuccess: () => {
				queryClient.invalidateQueries(GRAPH_GLOBALS_QUERY_KEYS.fetchGraphGlobals(profile?._id));
			},
		},
	);
};

export const useFetchNotificationPreferences = () => useQuery(
	NOTIFICATION_PREFERENCES.fetchNotificationsPreferences(),
	axiosMutationWrapper(preferencesApi.fetchNotificationsPreferenecs),
);

export const useUpdateNotificationPreferences = () => {
	const queryClient = useQueryClient();

	return useMutation(
		axiosMutationWrapper(preferencesApi.createOrUpdateNotificationsPreferenecs), {
			onSuccess: () => {
				queryClient.invalidateQueries(NOTIFICATION_PREFERENCES.fetchNotificationsPreferences());
			},
		},
	);
};

export const useUpdateChannelNewsletterPreferences = () => useMutation(
	axiosMutationWrapper(preferencesApi.updateChannelNewsletterPreferences),
);
