import { useQuery } from 'react-query';
import * as homeApi from '../api/home';
import { axiosQueryWrapper } from './utils/axios-wrapper';

export const HOME_QUERY_KEYS = {
	fetchHomePageVod: () => ['api', 'home', 'vod'],
};

export const useFetchHomePageVod = () => useQuery(
	HOME_QUERY_KEYS.fetchHomePageVod(),
	axiosQueryWrapper(homeApi.fetchHomePageVod),
);
