import { Outlet } from 'react-router-dom';

export const BlankPage = () => (
	<div className="app-wrapper bg-dark content-dark">
		<div className="app-main">
			<div className="app-content">
				<div className="app-content--inner">
					<div className="app-content--inner__wrapper">
						<Outlet />
					</div>
				</div>
			</div>
		</div>
	</div>
);
