import { Gender } from '../../../../Profile/Details/profileDetails.schema';

export const AdSharedProps = {
	ANY: 'ANY',
};

export const AdTimeSlot = {
	...AdSharedProps,
	MORNING: 'MORNING',
	AFTERNOON: 'AFTERNOON',
	EVENING: 'EVENING',
};

export const AdGender = {
	...AdSharedProps,
	...Gender,
};

export const AdAgeGroup = {
	...AdSharedProps,
	AGE_13_17: 'AGE_13_17',
	AGE_18_24: 'AGE_18_24',
	AGE_25_34: 'AGE_25_34',
	AGE_35_44: 'AGE_35_44',
	AGE_45_54: 'AGE_45_54',
	AGE_55_64: 'AGE_55_64',
	AGE_65_PLUS: 'AGE_65_PLUS',
};

export const AdAudienceInterest = {
	...AdSharedProps,
	TECHNOLOGY: 'TECHNOLOGY',
	EDUCATION: 'EDUCATION',
	GAMING: 'GAMING',
	SPORT: 'SPORT',
};

export const AdLanguageLabel = {
	ENGLISH: 'English',
	SPANISH: 'Spanish',
	FRENCH: 'French',
	ITALIAN: 'Italian',
};

export const AdLanguage = {
	ENGLISH: 'en',
	SPANISH: 'es',
	FRENCH: 'fr',
	ITALIAN: 'it',
};

export const AdCycleDuration = {
	DAILY: 'DAILY',
	WEEKLY: 'WEEKLY',
	MONTHLY: 'MONTHLY',
};

export const properties = {
	name: { type: 'string' },
	viewTarget: { type: 'string' },
	dailyViewRestriction: { type: 'string' },
	spendingLimit: { type: 'string' },
	maxViewsPerCycle: { type: 'string' },
	startDate: {
		type: 'string',
		format: 'date-time',
	},
	endDate: {
		type: 'string',
		format: 'date-time',
	},
	timeSlots: {
		type: 'array',
		items: {
			type: 'string',
			enum: Object.values(AdTimeSlot),
		},
	},
	genders: {
		type: 'array',
		items: {
			type: 'string',
			enum: Object.values(AdGender),
		},
	},
	ageGroups: {
		type: 'array',
		items: {
			type: 'string',
			enum: Object.values(AdAgeGroup),
		},
	},
	interests: {
		type: 'array',
		items: {
			type: 'string',
			enum: Object.values(AdAudienceInterest),
		},
	},
	countries: {
		type: 'array',
		items: {
			type: 'string',
		},
	},
	ads: {
		type: 'array',
		items: {
			minItems: 1,
			name: { type: 'string' },
			video: {
				filename: { type: 'string' },
				duration: {
					min: 6,
					max: 31,
					type: 'number',
				},
				video: { type: 'string' },
			},
			playPercentage: { type: 'number' },
		},
	},
	cycleDuration: {
		type: 'string',
		enum: Object.values(AdCycleDuration),
	},
	language: {
		type: 'string',
		enum: Object.values(AdLanguage),
	},
};

export const schema = {
	type: 'object',
	additionalProperties: true,
	properties,
	required: [
		'name',
		'viewTarget',
		'dailyViewRestriction',
		'startDate',
		'endDate',
		'timeSlots',
		'genders',
		'ageGroups',
		'countries',
		'interests',
		'ads',
		'spendingLimit',
		'maxViewsPerCycle',
		'cycleDuration',
		'language',
	],
};
