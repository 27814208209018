/* eslint-disable react/prop-types */
// @ts-check
import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
	getSimulcastEncodings,
} from '@technomiam/soup-client';

import {
	connect,
	join as joinSoup,
	disconnect,
	soupSession,
} from '../../api/soup';
import { useDispatch, useReactVideo } from './Provider';
import { addSoupListenersAction, publishTrackAction, unpublishTrackAction, updateTrackAction } from '../../store/actions/channelMixing';
import { useMediaShareScreen } from '../Media/Share/Screen';
import { useMediaShareAudio } from '../Media/Share/Audio';
import { useMediaShareVideo } from '../Media/Share/Video';
import { useMediaShareImage } from '../Media/Share/Image';
import { useMediaKey } from '../Media/MediaKey/Provider';
import { usePrevious } from '../../lib/hooks';
import { KeyReplacementMode } from '../Media/MediaKey/KeyConfig';
import { useMediaTalkback } from '../Media/Talkback/Talkback';

//TODO CHECK
const { rollbar } = window;

/**
 * @typedef {{
 * 	connectingState: string,
 * 	getIsConnected: () => boolean,
 * 	hashtag?: string,
 * 	isConnected: boolean,
 * 	join: (hashtag: string) => void,
 * 	leave: () => void,
 * 	soupId?: string,
 * }} ISoupContext
 */

export const SoupContext = createContext(/** @type {ISoupContext} */({}));

export const useSoup = () => useContext(SoupContext);

export const SOUP_CONNECTING_STATE_CONNECTED = 'SOUP_CONNECTING_STATE_CONNECTED';
export const SOUP_CONNECTING_STATE_DISCONNECTED = 'SOUP_CONNECTING_STATE_DISCONNECTED';
export const SOUP_CONNECTING_STATE_PENDING = 'SOUP_CONNECTING_STATE_PENDING';

const TRACK_TYPE_CAM = 'TRACK_TYPE_CAM';
const TRACK_TYPE_MIC = 'TRACK_TYPE_MIC';
const TRACK_TYPE_TALKBACK = 'TRACK_TYPE_TALKBACK';
const TRACK_TYPE_SCREENSHARE = 'TRACK_TYPE_SCREENSHARE';
const TRACK_TYPE_VIDEOSHARE = 'TRACK_TYPE_VIDEOSHARE';
const TRACK_TYPE_IMAGESHARE = 'TRACK_TYPE_IMAGESHARE';
const TRACK_TYPE_AUDIOSHARE = 'TRACK_TYPE_AUDIOSHARE';

const STREAM_ID_CAM = 'cam';
const STREAM_ID_MIC = 'mic';
const STREAM_ID_TALKBACK = 'talkback';
const STREAM_ID_SCREENSHARE = 'screen';
const STREAM_ID_VIDEOSHARE = 'video';
const STREAM_ID_IMAGESHARE = 'image';
const STREAM_ID_AUDIOSHARE = 'audio';

const MODE_CONTROL = 'MODE_CONTROL';
const MODE_GUEST = 'MODE_GUEST';

/** @type {Record<string, Record<string, string>>} */
const trackTypeToStreamIdByModeMap = {
	[MODE_CONTROL]: {
		[TRACK_TYPE_CAM]: STREAM_ID_CAM,
		[TRACK_TYPE_MIC]: STREAM_ID_MIC,
		[TRACK_TYPE_TALKBACK]: STREAM_ID_TALKBACK,
		[TRACK_TYPE_SCREENSHARE]: STREAM_ID_SCREENSHARE,
		[TRACK_TYPE_VIDEOSHARE]: STREAM_ID_VIDEOSHARE,
		[TRACK_TYPE_IMAGESHARE]: STREAM_ID_IMAGESHARE,
		[TRACK_TYPE_AUDIOSHARE]: STREAM_ID_AUDIOSHARE,
	},
	[MODE_GUEST]: {
		[TRACK_TYPE_CAM]: STREAM_ID_CAM,
		[TRACK_TYPE_MIC]: STREAM_ID_MIC,
		[TRACK_TYPE_TALKBACK]: STREAM_ID_TALKBACK,
		[TRACK_TYPE_SCREENSHARE]: STREAM_ID_SCREENSHARE,
		[TRACK_TYPE_VIDEOSHARE]: STREAM_ID_VIDEOSHARE,
		[TRACK_TYPE_IMAGESHARE]: STREAM_ID_IMAGESHARE,
		[TRACK_TYPE_AUDIOSHARE]: STREAM_ID_AUDIOSHARE,
	},
};

/**
 * @typedef {{
 * 		children: React.ReactNode,
 * 		isController?: boolean,
 * }} SoupProviderProps
 */

export const SoupProvider = (
	/** @type {SoupProviderProps} */
	{
		children,
		isController = false,
	},
) => {
	const dispatch = useDispatch();
	const { getConnectionConfig, user } = useReactVideo();
	const [hashtag, setHashtag] = useState(/** @type {string | undefined} */(undefined));
	const [isConnected, setIsConnected] = useState(false);
	const [connectingState, setConnectingState] = useState(SOUP_CONNECTING_STATE_DISCONNECTED);
	const cancelled = useRef(false);
	const [soupId, setSoupId] = useState(/** @type {string | undefined} */(undefined));

	const getIsConnected = useCallback(() => (
		isConnected && !!soupSession()?.connected
	), [isConnected]);

	const leave = useCallback(async () => {
		setConnectingState(SOUP_CONNECTING_STATE_DISCONNECTED);
		cancelled.current = true;
		console.log('[leaveSoup]');
		disconnect();
	}, []);

	const join = useCallback(async (
		/** @type {string} */h,
	) => {
		try {
			setHashtag(h);
			cancelled.current = false;
			console.log('[joinSoup]');
			setConnectingState(SOUP_CONNECTING_STATE_PENDING);
			const connectionConfig = await getConnectionConfig();
			if (cancelled.current) return;
			await connect(connectionConfig, h);
			if (cancelled.current) return;
			await joinSoup();
			if (cancelled.current) return;
			setConnectingState(SOUP_CONNECTING_STATE_CONNECTED);
		} catch {
			setConnectingState(SOUP_CONNECTING_STATE_DISCONNECTED);
		}
	}, [getConnectionConfig]);

	const { screenshareActiveTracks } = useMediaShareScreen();
	const {
		audioshareActiveTracks,
		audiosharePreventLarsens,
	} = useMediaShareAudio();

	const { videoshareActive, videoshareActiveTracks } = useMediaShareVideo();
	const { imageshareActive, imageshareActiveTracks } = useMediaShareImage();
	const {
		keyOrUserActiveTracks,
		config: keyConfig,
		configOverride: keyConfigOverride,
	} = useMediaKey();
	const { recipientUsers, talkbackActiveTrack } = useMediaTalkback();

	const previousKeyConfig = usePrevious(keyConfig);
	const previousKeyConfigOverride = usePrevious(keyConfigOverride);
	const previousAudiosharePreventLarsens = usePrevious(audiosharePreventLarsens);

	const localTracks = useMemo(() => {
		const selectedTracks = keyOrUserActiveTracks
			.filter((track) => !!track) // useful ?
			// Remove tracks that are used in talkback
			.filter((track) => talkbackActiveTrack?.originalTrackId !== track.id)
			.map((mediaStreamTrack) => {
				const selectedTrack = { id: mediaStreamTrack.id, mediaStreamTrack };
				if (mediaStreamTrack.isKey && mediaStreamTrack.configId === 0) {
					selectedTrack.isKeyTrack = true;
					selectedTrack.keyConfig = keyConfig;
					selectedTrack.keyConfigOverride = keyConfigOverride;
				}
				return selectedTrack;
			});

		const selectedAudioshareTracks = audioshareActiveTracks
			.map((mediaStreamTrack) => {
				const preventLarsens = mediaStreamTrack.deviceId && audiosharePreventLarsens
					.includes(mediaStreamTrack.deviceId) ? true : undefined;
				const previousPreventLarsens = mediaStreamTrack.deviceId && previousAudiosharePreventLarsens
					.includes(mediaStreamTrack.deviceId) ? true : undefined;
				const selectedTrack = {
					id: mediaStreamTrack.id,
					mediaStreamTrack,
					preventLarsens,
					previousPreventLarsens,
				};
				return selectedTrack;
			});

		const formattedTalkbackTrack = talkbackActiveTrack ? {
			id: talkbackActiveTrack.id,
			mediaStreamTrack: talkbackActiveTrack,
			talkback: {
				senderUserId: user.sub,
				receiverUserIds: recipientUsers.map((u) => u._id),
			},
		} : null;

		const formatTrack = (
			/** @type {MediaStreamTrack} */mediaStreamTrack,
		) => ({ id: mediaStreamTrack.id, mediaStreamTrack });

		return [
			...selectedTracks,
			...selectedAudioshareTracks,
			...(formattedTalkbackTrack ? [formattedTalkbackTrack] : []),
			...videoshareActiveTracks.map(formatTrack),
			...imageshareActiveTracks.map(formatTrack),
			...screenshareActiveTracks.map(formatTrack),
		];
	}, [
		keyConfig,
		keyConfigOverride,
		screenshareActiveTracks,
		keyOrUserActiveTracks,
		audioshareActiveTracks,
		audiosharePreventLarsens,
		previousAudiosharePreventLarsens,
		videoshareActiveTracks,
		imageshareActiveTracks,
		recipientUsers,
		talkbackActiveTrack,
		user,
	]);

	console.log({
		keyOrUserActiveTracks,
		videoshareActive,
		videoshareActiveTracks,
		imageshareActive,
		imageshareActiveTracks,
		audioshareActiveTracks,
		localTracks,
	});

	useEffect(() => {
		if (connectingState === SOUP_CONNECTING_STATE_CONNECTED) {
			const soup = soupSession();
			if (!soup) {
				setIsConnected(false);
				return undefined;
			}

			const onConnected = () => {
				setIsConnected(true);
				setSoupId(soup.signaling.id);
			};
			const onDisconnected = () => { setIsConnected(false); };
			const onTransportFailed = async ({ rtcPeerConnection, transport }) => {
				// eslint-disable-next-line no-underscore-dangle
				const stats = Object.fromEntries(await transport.getStats());
				if (rollbar) {
					rollbar.error(
						'Transport connection failed.',
						{
							// eslint-disable-next-line no-underscore-dangle
							remoteSdpIceCandidates: transport._remoteSdp?._iceCandidates,
							stats,
							transport: {
								id: transport.id,
								direction: transport.direction,
							},
						},
					);
				}
				console.error('Soup transport failed', { rtcPeerConnection, transport, stats });
			};
			soup.on('connected', onConnected);
			soup.on('disconnected', onDisconnected);
			soup.on('transport:failed', onTransportFailed);
			const offSoupListeners = dispatch(addSoupListenersAction(user));

			setIsConnected(!!soup.connected);

			return () => {
				setIsConnected(false);
				offSoupListeners();
				soup.off('connected', onConnected);
				soup.off('disconnected', onDisconnected);
				soup.off('transport:failed', onTransportFailed);
			};
		}
		return undefined;
	}, [dispatch, connectingState, user]);

	useEffect(() => {
		if (isConnected && soupSession()?.connected) {
			console.log({ localTracks });
			const currentPublishedLocalTracksIds = Array.from(soupSession().productions.keys());
			const localTracksIds = localTracks.map(({ id }) => id);
			const screenSharingTracksIds = screenshareActiveTracks.map(({ id }) => id);
			const videoSharingTracksIds = videoshareActiveTracks.map(({ id }) => id);
			const imageSharingTracksIds = imageshareActiveTracks.map(({ id }) => id);
			const audioSharingTracksIds = audioshareActiveTracks.map(({ id }) => id);
			const tracksToUpdate = localTracks.filter((localTrack) => (
				currentPublishedLocalTracksIds.includes(localTrack.id)
				&& (
					(
						localTrack.isKeyTrack
						&& (
							localTrack.keyConfig !== previousKeyConfig
							|| localTrack.keyConfigOverride !== previousKeyConfigOverride
						)
					)
					|| (
						localTrack.preventLarsens !== localTrack.previousPreventLarsens
					)
				)
			));

			console.log({
				currentPublishedLocalTracksIds,
				localTracksIds,
				tracksToUpdate,
				keyConfig,
				previousKeyConfig,
			});

			// publish new ones
			const idsTracksToPublish = localTracksIds.filter(
				(lms) => !currentPublishedLocalTracksIds.includes(lms),
			);

			/**
			 * @param {string} trackId
			 * @param {MediaStreamTrack['kind']} kind
			 * @returns {string}
			 */
			const getTrackType = (trackId, kind) => {
				if (talkbackActiveTrack?.id === trackId) return TRACK_TYPE_TALKBACK;
				if (screenSharingTracksIds.includes(trackId)) return TRACK_TYPE_SCREENSHARE;
				if (videoSharingTracksIds.includes(trackId)) return TRACK_TYPE_VIDEOSHARE;
				if (imageSharingTracksIds.includes(trackId)) return TRACK_TYPE_IMAGESHARE;
				if (audioSharingTracksIds.includes(trackId)) return TRACK_TYPE_AUDIOSHARE;
				if (kind === 'audio') return TRACK_TYPE_MIC;
				return TRACK_TYPE_CAM;
			};

			/**
			 * @param {string} trackType
			 * @param {MediaStreamTrack} mediaStreamTrack
			 * @returns {string}
			 */
			const getTrackStreamId = (trackType, mediaStreamTrack) => {
				const mode = isController ? MODE_CONTROL : MODE_GUEST;
				const trackTypeId = trackTypeToStreamIdByModeMap[mode][trackType];
				if ([TRACK_TYPE_MIC, TRACK_TYPE_CAM].includes(trackType)) {
					return `${trackTypeId}:${mediaStreamTrack.configId}`;
				}
				if (TRACK_TYPE_AUDIOSHARE === trackType) {
					return `${trackTypeId}:${mediaStreamTrack.deviceId}`;
				}
				/* Force screenshare, videoshare and imageshare to have the same
				configId as the default microphone. So the microphone is played in
				live when local share is active */
				return `${trackTypeId}:0`;
			};

			/**
			 * @param {string} trackType
			 * @param {MediaStreamTrack['kind']} kind
			 * @returns {boolean}
			 */
			const getTrackPreventLarsens = (trackType, kind) => {
				if (kind === 'video') return false;
				if (trackType === TRACK_TYPE_VIDEOSHARE) return false;
				if (trackType === TRACK_TYPE_IMAGESHARE) return false;
				if (trackType === TRACK_TYPE_AUDIOSHARE) return false;
				return true;
			};

			const getTrackEncodings = (trackType, track) => {
				if (track.kind !== 'video') return undefined;
				if (track.kind !== 'audio') return undefined;

				// TODO: test with max bitrate (see below)
				if (trackType === TRACK_TYPE_SCREENSHARE) return undefined;

				// Firefox MediaStreamTrack.getSettings returns an empty object in case of captureStream
				// See Videoshare component for the fix
				const { height } = track.firefoxFixCaptureSettings || track.getSettings();
				const trackEncodings = getSimulcastEncodings(height);
				// TODO: test with max bitrate
				// if (trackType === TRACK_TYPE_SCREENSHARE) {
				// 	// Simulcast disabled for screensharing return only highest encoding
				// 	return trackEncodings.slice(-1);
				// }
				return trackEncodings;
			};
			console.log({ idsTracksToPublish });

			const getTrackAppDataKeyConfig = (kc) => {
				if (
					!kc
					|| kc.replacement.mode !== KeyReplacementMode.TRANSPARENT
				) return null;
				return {
					color: kc.replacement.transparentColor,
					sensitivity: kc.replacement.transparentColorSensitivity,
				};
			};

			const getPreventLarsensOverride = (
				trackType,
				mediaStreamTrack,
				preventLarsens,
			) => {
				const { kind } = mediaStreamTrack;
				if (preventLarsens === true || preventLarsens === false) {
					return preventLarsens;
				}
				return getTrackPreventLarsens(trackType, kind);
			};

			idsTracksToPublish.forEach((trackId) => {
				const {
					keyConfig: trackKeyConfig,
					keyConfigOverride: trackKeyConfigOverride,
					mediaStreamTrack,
					preventLarsens,
					talkback,
				} = localTracks.find(({ id }) => id === trackId);

				const trackType = getTrackType(trackId, mediaStreamTrack.kind);
				const trackStreamId = getTrackStreamId(trackType, mediaStreamTrack);
				const trackPreventLarsens = getPreventLarsensOverride(
					trackType,
					mediaStreamTrack,
					preventLarsens,
				);
				const trackEncodings = getTrackEncodings(trackType, mediaStreamTrack);
				const alphaColor = getTrackAppDataKeyConfig(trackKeyConfigOverride || trackKeyConfig);
				dispatch(publishTrackAction(
					mediaStreamTrack,
					trackStreamId,
					user,
					trackPreventLarsens,
					alphaColor,
					trackEncodings,
					talkback,
				));
			});

			tracksToUpdate.forEach((track) => {
				const {
					keyConfig: trackKeyConfig,
					keyConfigOverride: trackKeyConfigOverride,
					mediaStreamTrack,
					preventLarsens,
				} = track;
				const trackType = getTrackType(track.id, mediaStreamTrack.kind);
				const trackStreamId = getTrackStreamId(trackType, mediaStreamTrack);
				const trackPreventLarsens = getPreventLarsensOverride(
					trackType,
					mediaStreamTrack,
					preventLarsens,
				);
				const alphaColor = getTrackAppDataKeyConfig(trackKeyConfigOverride || trackKeyConfig);
				dispatch(updateTrackAction(mediaStreamTrack, {
					trackStreamId,
					preventLarsens: trackPreventLarsens,
					alphaColor,
				}, user));
			});

			// unpublish old ones
			const idsTracksToUnpublish = currentPublishedLocalTracksIds.filter(
				(cplt) => !localTracksIds.includes(cplt),
			);
			console.log({ idsTracksToUnpublish });
			idsTracksToUnpublish.forEach((trackId) => {
				dispatch(unpublishTrackAction({ id: trackId }));
			});
		} else if (soupSession()?.productions) {
			const currentPublishedLocalTracksIds = Array.from(soupSession()?.productions.keys());
			console.log({ currentPublishedLocalTracksIds });
			currentPublishedLocalTracksIds.forEach((id) => {
				dispatch(unpublishTrackAction({ id }));
			});
		}
	}, [
		isController,
		keyConfig,
		previousKeyConfig,
		previousKeyConfigOverride,
		dispatch,
		isConnected,
		localTracks,
		screenshareActiveTracks,
		user,
		videoshareActiveTracks,
		imageshareActiveTracks,
		audioshareActiveTracks,
		talkbackActiveTrack,
	]);

	const contextValue = useMemo(() => ({
		connectingState,
		getIsConnected,
		hashtag,
		isConnected,
		join,
		leave,
		soupId,
	}), [
		connectingState,
		getIsConnected,
		hashtag,
		isConnected,
		join,
		leave,
		soupId,
	]);

	return (
		<SoupContext.Provider value={contextValue}>
			{children}
		</SoupContext.Provider>
	);
};
