import { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';

import { NewUserBannerBlue } from './BannerBlue';
import { NewUserBannerGreen } from './BannerGreen';
import { NewUserBannerTeal } from './BannerTeal';
import { NewUserBannerYellow } from './BannerYellow';
import './NewUser.scss';

const settings = {
	dots: true,
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
	initialSlide: 0,
};

export const NewUserBanner = () => {
	const [isMouseOver, setIsMouseOver] = useState(false);
	const sliderRef = useRef(null);

	useEffect(() => {
		const interval = setInterval(() => {
			if (!isMouseOver && sliderRef.current) {
				sliderRef.current.slickNext();
			}
		}, 6000);

		return () => clearInterval(interval);
	}, [isMouseOver]);

	const handleMouseEnter = () => {
		setIsMouseOver(true);
	};
	const handleMouseLeave = () => {
		setIsMouseOver(false);
	};

	const banners = [
		<NewUserBannerBlue />,
		<NewUserBannerGreen />,
		<NewUserBannerTeal />,
		<NewUserBannerYellow />,
	];

	return (
		<div
			className="NewUserBanner_MobileTaller"
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			<Slider
				ref={sliderRef}
				className="NewUserBanner_Hundo"
				{...settings}
				style={{ adaptiveHeight: true }}
			>
				{banners.map((banner, index) => (
					// eslint-disable-next-line react/no-array-index-key
					<div key={index}>{banner}</div>
				))}
			</Slider>
		</div>
	);
};
