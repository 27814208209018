import PropTypes from 'prop-types';
import { SurveyTemplateProvider } from './SurveyTemplateProvider';
import { SurveyProvider } from './SurveyProvider';
import { SurveyModals } from '../Modals';
import { ShareModalProvider } from '../../Share/Modal/Provider';

export const SurveyProviderWrapper = ({ children }) => (
	<SurveyTemplateProvider>
		<SurveyProvider>
			<ShareModalProvider>
				{children}
				<SurveyModals />
			</ShareModalProvider>
		</SurveyProvider>
	</SurveyTemplateProvider>
);

SurveyProviderWrapper.propTypes = {
	children: PropTypes.node,
};

SurveyProviderWrapper.defaultProps = {
	children: undefined,
};
