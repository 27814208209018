import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

const StatusOverridesContext = createContext({});

export const useStatusOverrides = () => useContext(StatusOverridesContext);

export const StatusOverridesProvider = ({ children }) => {
	const [statusOverrides, setStatusOverrides] = useState({});

	const handleEventConnectionStatus = useCallback((connectionStatus) => {
		setStatusOverrides((prev) => ({
			...prev,
			[connectionStatus._id]: connectionStatus.isConnected,
		}));
	}, []);

	const getStatus = useCallback(
		(userId, status) => statusOverrides[userId] ?? status,
		[statusOverrides],
	);

	const value = useMemo(() => ({
		getStatus,
		handleEventConnectionStatus,
	}), [getStatus, handleEventConnectionStatus]);

	return (
		<StatusOverridesContext.Provider value={value}>
			{children}
		</StatusOverridesContext.Provider>
	);
};

StatusOverridesProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
