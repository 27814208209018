/* eslint-disable react/prop-types */
// @ts-check

import { PaginationItemLine } from './ItemLine';
import { PaginationPageLine } from './PageLine';

/**
 * @typedef {{
 * 	breakpoint?: string;
 * 	className?: string;
 * 	isDisabled?: boolean;
 * 	minimal?: boolean;
 * }} PaginationProps
 */

export const Pagination = (
	/** @type {PaginationProps} */
	{
		breakpoint = 'lg',
		className = '',
		isDisabled = false,
		minimal = false,
	},
) => (
	<div className={`d-flex align-items-center justify-content-center justify-content-${breakpoint}-between flex-wrap ${minimal ? 'text-dark' : ''} ${className}`}>
		<div className="d-flex align-items-center">
			<PaginationItemLine isDisabled={isDisabled} />
		</div>
		<div className="d-flex align-items-center ms-lg-4 ms-0">
			<PaginationPageLine isDisabled={isDisabled} />
		</div>
	</div>
);
