import PropTypes from 'prop-types';
import { useEffect, useMemo, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDisplaySettings } from '../Profile/Preferences/Settings/DisplayProvider';
import { useScreenTheme } from '../Studio/ScreenTheme/Provider';
import { UserPreferencesContext } from './Context';
import { useProfile } from '../Profile/ProfileContext';

export const LOCAL_STORAGE_KEY = 'beeyou_userPrefences';

const localStorageValue = localStorage.getItem(LOCAL_STORAGE_KEY);
const storedUserPreferences = localStorageValue ? JSON.parse(localStorageValue) : undefined;

export const UserPreferencesProvider = ({ children }) => {
	const [userPreferences, setUserPreferences] = useState(storedUserPreferences || {});
	const { i18n } = useTranslation();
	const { handleChangeCurrentScreenTheme } = useScreenTheme();
	const { handleFontSize } = useDisplaySettings();
	const { profile } = useProfile();
	const fontSizeRef = useRef(false);

	useEffect(() => {
		if (!userPreferences) {
			localStorage.removeItem(LOCAL_STORAGE_KEY);
		} else {
			localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(userPreferences));
		}
	}, [userPreferences]);

	useEffect(() => {
		if (profile?.preferences) {
			if (profile?.preferences?.locale) {
				i18n.changeLanguage(profile?.preferences.locale);
			}
			if (profile?.preferences?.theme) {
				handleChangeCurrentScreenTheme(profile?.preferences.theme);
			}
			if (profile?.preferences?.fontSize && !fontSizeRef.current) {
				// eslint-disable-next-line radix
				handleFontSize(parseInt(profile?.preferences?.fontSize));
				fontSizeRef.current = true;
			}
		}
	}, [handleChangeCurrentScreenTheme, i18n, profile, handleFontSize]);

	const value = useMemo(() => ({
		getUserPreference: (key) => userPreferences[key],
		userPreferences,
		setUserPreference: (data) => setUserPreferences((s) => ({ ...s, ...data })),
		setUserPreferences,
		...profile?.preferences,
	}), [
		userPreferences, profile,
	]);

	return (
		<UserPreferencesContext.Provider value={value}>
			{children}
		</UserPreferencesContext.Provider>
	);
};

UserPreferencesProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
