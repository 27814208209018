import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';

export const OverviewTab = ({ questions }) => (
	<div>
		{questions.map((question, index) => (
			<div className="w-100 py-2" key={`overview-question-${question._id}`}>
				<div className="pb-2">
					<Row className="p-0 m-0">
						<Col className="p-0 m-0 d-flex justify-content-center" xs={1}>{index + 1}.</Col>
						<Col className="p-0 m-0" xs={11}>{question.text}</Col>
					</Row>
					<Row className="py-2 m-0">
						<Col className="p-0 m-0" xs={1} />
						<Col className="p-0 m-0 font-weight-bold" xs={11}>{
							question.type === 'MULTICHOICE' ? (
								<div> { question.answer ? question.options[question.answer.optionIndex] : '/'}</div>
							) : (
								<div> { question.answer && !!question.answer.text ? question.answer.text : '/'}</div>
							)
						}
						</Col>
					</Row>
				</div>
				<div className="divider" />
			</div>
		))}
	</div>
);

OverviewTab.propTypes = {
	questions: PropTypes.arrayOf(PropTypes.shape({
		_id: PropTypes.string.isRequired,
		text: PropTypes.string.isRequired,
		type: PropTypes.string.isRequired,
		numeration: PropTypes.string,
	})).isRequired,
};
