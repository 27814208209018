import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { usePlayerMode, PlayerModes } from './PlayerModeProvider';

export const CropContext = createContext({});
export const useCrop = () => useContext(CropContext);

export const PlayerLiveCropProvider = ({
	children,
}) => {
	const [isCoefficientEnabled, setIsCoefficientEnabled] = useState(true);
	const { mode, setMode } = usePlayerMode();

	const setIsCropEnabled = useCallback((value) => {
		setMode(value ? PlayerModes.CROP : undefined);
	}, [setMode]);

	const toggleCoefficient = useCallback(() => {
		setIsCoefficientEnabled((prevState) => !prevState);
	}, []);

	const isCropEnabled = mode === PlayerModes.CROP;

	const contextValue = useMemo(() => ({
		isCropEnabled,
		isCoefficientEnabled,
		toggleCoefficient,
		setIsCropEnabled,
	}), [
		isCropEnabled,
		isCoefficientEnabled,
		toggleCoefficient,
		setIsCropEnabled,
	]);

	return (
		<CropContext.Provider value={contextValue}>
			{children}
		</CropContext.Provider>
	);
};

PlayerLiveCropProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
