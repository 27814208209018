// @ts-check

import { api } from '../api';

export const API_PUBLIC_CHAT_PATH = '/public/chat';

export const fetchPublicChatMessages = (studioId) => api.get(
	`${API_PUBLIC_CHAT_PATH}/messages/${studioId}`,
);

/**
 * @param {string} studioId
 * @param {string} role
 * @param {string} text
 * @returns {Promise<import('axios').AxiosResponse<void>>}
 */
export const sendPublicMessage = (studioId, role, text) => api.post(
	`${API_PUBLIC_CHAT_PATH}/messages/byStudioId/${studioId}`,
	{ role, text },
);

/**
 * @param {string} id
 * @param {string} text
 * @returns {Promise<import('axios').AxiosResponse<void>>}
 */
export const updatePublicChatMessage = (id, text) => api.put(
	`${API_PUBLIC_CHAT_PATH}/messages/${id}`,
	{ text },
);

/**
 * @param {string} id
 * @returns {Promise<import('axios').AxiosResponse<void>>}
 */
export const deleteGuestMessage = (id) => api.delete(
	`${API_PUBLIC_CHAT_PATH}/messages/${id}`,
);
