import { useMutation } from 'react-query';
import * as profileSignupApi from '../../api/profile/signup';
import { axiosMutationWrapper } from '../utils/axios-wrapper';

export const useSignup = () => useMutation(
	axiosMutationWrapper(profileSignupApi.signup),
);

export const useGoogleSignup = () => useMutation(
	axiosMutationWrapper(profileSignupApi.googleSignup),
);
