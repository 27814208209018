import { useMutation, useQuery, useQueryClient } from 'react-query';
import { axiosMutationWrapper, axiosQueryWrapper } from '../utils/axios-wrapper';
import * as profileApi from '../../api/profile/me';
import { useAuthentication } from '../../components/Authentication/Authentication';

export const PROFILE_QUERY_KEYS = {
	fetch: () => ['api', 'channel', 'profile', 'me'],
};

export const useFetchProfile = () => {
	const { isLoggedIn } = useAuthentication();

	return useQuery(
		PROFILE_QUERY_KEYS.fetch(),
		axiosQueryWrapper(profileApi.fetch),
		{ enabled: !!isLoggedIn },
	);
};

export const useUpdateProfile = () => {
	const queryClient = useQueryClient();

	return useMutation(
		axiosMutationWrapper(profileApi.update),
		{
			onSuccess: (data) => {
				queryClient.setQueryData(PROFILE_QUERY_KEYS.fetch(), data);
			},
		},
	);
};
