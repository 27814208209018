import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FieldError } from 'react-jsonschema-form-validation';
import { Label } from 'reactstrap';
import { FaTimes } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { CampaignCreateTooltip } from './Tooltip';
import FormCountry, { countryList } from '../../../../../../Form/Country';

export const getCountryLabelByCode = (code) => countryList.find(
	(country) => country[0] === code,
)?.[1];

export const AdvertisingCampaignsCreateCountries = ({ adCampaign, setAdCampaign, isViewOnly }) => {
	const { t } = useTranslation();

	const handleAddCountry = useCallback((e) => {
		const { value: country } = e.target;

		setAdCampaign((prevAdCampaign) => ({
			...prevAdCampaign,
			countries: !prevAdCampaign.countries.includes(country)
				? [...prevAdCampaign.countries, country]
				: prevAdCampaign.countries,
		}));
	}, [setAdCampaign]);

	const handleRemoveCountry = useCallback((code) => {
		setAdCampaign((prevAdCampaign) => ({
			...prevAdCampaign,
			countries: prevAdCampaign.countries.filter(
				(country) => country !== code,
			),
		}));
	}, [setAdCampaign]);
	return (
		<>
			<Label id="AudienceCountriesOptions" className="mt-3" for="currency">
				{isViewOnly ? t('AdCampaings.Ad.audienceCountries') : t('AdCampaings.Ad.chooseAudienceCountries')}
			</Label>
			<CampaignCreateTooltip
				target="AudienceCountriesTooltip"
				container="AudienceCountriesOptions"
				text={
					!adCampaign.countries.length
						? t('AdCampaings.Ad.noAdditionChargeCountry')
						: `You will be charged ${(10 / adCampaign.countries.length).toFixed(2)}% more, for selecting ${adCampaign.countries.length} countries`
				}
			/>
			{!isViewOnly && (
				<>
					<FormCountry disabled={isViewOnly} onChange={handleAddCountry} />
					<FieldError name="countries" />
				</>
			)}
			<div className="d-flex flex-wrap mt-3 mb-3">
				{adCampaign.countries.map((country) => (
					<div key={`country-select-${country}`} className="bg-secondary text-white p-1 px-3 mr-1 rounded d-flex align-items-center justify-content-center mb-2">
						{getCountryLabelByCode(country)}
						{!isViewOnly && (
							<FaTimes
								className="ml-2 cursor-pointer"
								size={10}
								onClick={() => handleRemoveCountry(country)}
							/>
						)}
					</div>
				))}
			</div>
		</>
	);
};

AdvertisingCampaignsCreateCountries.propTypes = {
	adCampaign: PropTypes.shape({
		countries: PropTypes.arrayOf(PropTypes.string),
	}).isRequired,
	setAdCampaign: PropTypes.func.isRequired,
	isViewOnly: PropTypes.bool,
};

AdvertisingCampaignsCreateCountries.defaultProps = {
	isViewOnly: false,
};
