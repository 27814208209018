// @ts-check

import { api } from '../api';

export const API_CHANNEL_VIDEOS_PATH = '/channel/videos';

export const createVideo = async ({ channelId, video }) => api.post(
	`${API_CHANNEL_VIDEOS_PATH}/toChannelById/${channelId}`,
	video,
);

export const deleteVideo = (videoId) => api.delete(
	`${API_CHANNEL_VIDEOS_PATH}/${videoId}`,
);

export const fetchVideos = (
	channelId,
	mode,
	itemsPerPage,
	currentPage,
) => api.get(
	`${API_CHANNEL_VIDEOS_PATH}`,
	{
		params: {
			channelId,
			mode,
			itemsPerPage,
			currentPage,
		},
	},
);

export const fetchVodByHashtag = (hashtag) => api.get(
	`${API_CHANNEL_VIDEOS_PATH}/vod/byhashtag/${hashtag}`,
);

export const fetchVodById = (id) => api.get(`${API_CHANNEL_VIDEOS_PATH}/${id}`);

export const updateVideo = (video) => api.put(
	API_CHANNEL_VIDEOS_PATH,
	video,
);

export const cloneVideo = ({ cloneFromVideoId, videoData }) => api.post(
	`${API_CHANNEL_VIDEOS_PATH}/clone/${cloneFromVideoId}`,
	videoData,
);

export const buyVodAccess = (id) => api.post(`${API_CHANNEL_VIDEOS_PATH}/${id}/access`);

/**
 * @param {string} videoId
 * @returns {Promise<import('axios').AxiosResponse<string>>}
 */
export const createShareableLink = (videoId) => api.post(`${API_CHANNEL_VIDEOS_PATH}/privateShareId/${videoId}`);

export const getDownloadLink = (videoId) => api.get(`${API_CHANNEL_VIDEOS_PATH}/download/${videoId}`);

export const viewVideo = (id, watchId) => api.put(
	`${API_CHANNEL_VIDEOS_PATH}/${id}/viewed`,
	{
		watchId,
	},
);

export const sendVideoWatchTime = ({
	videoId,
	adBlockInfo,
	playEndTime,
	playStartTime,
	watchedFromResourceId,
	watchedFromResourceType,
	watchId,
}) => api.post(
	`${API_CHANNEL_VIDEOS_PATH}/${videoId}/watchTime`,
	{
		adBlockInfo,
		playEndTime,
		playStartTime,
		watchedFromResourceId,
		watchedFromResourceType,
		watchId,
	},
);

/**
 * @param {string} videoId
 * @returns {Promise<import('axios').AxiosResponse<void>>}
 */
export const deleteShareableLink = (videoId) => api.delete(`${API_CHANNEL_VIDEOS_PATH}/privateShareId/${videoId}`);
