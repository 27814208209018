import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMediaCropPerformer } from './useMediaCropPerformer';
import { MediaCropProvider, useMediaCrop } from './Provider';
import { useMediaUser } from '../User';

export const MediaCropConfigPerformer = ({
	configId,
}) => {
	const {
		addCropVideoTrack,
		personalCrop,
		removeCropVideoTrackByTrack,
	} = useMediaCrop();
	const { userVideoActiveTracks } = useMediaUser();

	const videoTrack = userVideoActiveTracks.find((track) => track.configId === configId);

	const { cropVideoTrack } = useMediaCropPerformer(
		videoTrack,
		personalCrop,
	);

	useEffect(() => {
		if (cropVideoTrack && videoTrack) {
			cropVideoTrack.configId = videoTrack.configId;
			addCropVideoTrack(cropVideoTrack);
			return () => {
				removeCropVideoTrackByTrack(cropVideoTrack);
			};
		}
		return undefined;
	}, [
		addCropVideoTrack,
		cropVideoTrack,
		removeCropVideoTrackByTrack,
		videoTrack,
	]);

	return null;
};

MediaCropConfigPerformer.propTypes = {
	configId: PropTypes.number,
};

MediaCropConfigPerformer.defaultProps = {
	configId: 0,
};

export const MediaCrop = ({ children, isHost }) => (
	<MediaCropProvider
		isHost={isHost}
	>
		<MediaCropConfigPerformer configId={0} />
		{children}
	</MediaCropProvider>
);

MediaCrop.propTypes = {
	children: PropTypes.node,
	isHost: PropTypes.bool,
};

MediaCrop.defaultProps = {
	children: undefined,
	isHost: false,
};
