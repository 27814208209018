// @ts-check

import { api } from '../api';

const API_CHANNEL_GOALS_PATH = '/channel/goals';

/** @typedef {import('../../components/Channel/Goals/goal.dto').IGoalDto} IGoalDto */
/** @typedef {import('../../components/Channel/Goals/CreateModal/createGoal.schema').Goal} Goal */

/**
 * @param {string} channelId
 * @returns {Promise<import('axios').AxiosResponse<IGoalDto[]>>}
 */
export const fetchGoalsByChannelId = (channelId) => api.get(`${API_CHANNEL_GOALS_PATH}/byChannelId/${channelId}`);

/**
 * @param {string} goalId
 * @returns {Promise<import('axios').AxiosResponse<IGoalDto>>}
 */
export const fetchOneGoalById = (goalId) => api.get(`${API_CHANNEL_GOALS_PATH}/byId/${goalId}`);

/**
 * @param {Goal} goal
 * @returns {Promise<import('axios').AxiosResponse<IGoalDto>>}
 */
export const createGoal = (goal) => api.post(
	API_CHANNEL_GOALS_PATH, goal,
);

/**
 * @param {string} goalId
 * @returns {Promise<import('axios').AxiosResponse<IGoalDto>>}
 */
export const deleteOneGoalById = (goalId) => api.delete(`${API_CHANNEL_GOALS_PATH}/${goalId}`);
