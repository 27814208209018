/* eslint-disable react/prop-types */
// @ts-check

import clsx from 'clsx';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Select, { components } from 'react-select';
import { FaSearch } from 'react-icons/fa';

import { useFetchSearchSuggestions } from '../../api-hooks/search';
import { Path } from '../../RoutePath';

/**
 * @typedef {{
* 	label: string;
* 	value: string;
* }} QueryObject
*/

/** @type {import('react-select').StylesConfig<QueryObject, false>} */
const customSearchInputStyles = ({
	dropdownIndicator: () => ({
		display: 'none',
	}),
	indicatorSeparator: () => ({
		display: 'none',
	}),
	valueContainer: (base) => ({
		...base,
		paddingLeft: 24,
	}),
});

const ValueContainer = (
	/** @type {import('react-select').ValueContainerProps} */
	{ children, ...props },
) => (
	components.ValueContainer && (
		<components.ValueContainer {...props}>
			{!!children && (
				<FaSearch
					className="text-secondary"
					style={{ position: 'absolute', left: 6 }}
				/>
			)}
			{children}
		</components.ValueContainer>
	)
);

// Workaround for input being hidden after select (https://github.com/JedWatson/react-select/discussions/4302)
const Input = (
	/** @type {import('react-select').InputProps<QueryObject, false>} */
	props,
) => <components.Input {...props} isHidden={false} />;

/**
 * @param {string?} [query]
 * @returns {QueryObject?}
 */
const getQueryObject = (query) => (query
	? {
		label: query,
		value: query,
	}
	: null);

/**
 * @typedef {{
 * 	isSearchOpen?: boolean;
 * 	setSearchOpen: (isOpen: boolean) => void;
 * }} SearchInputProps
 */

export const SearchInput = (
	/** @type {SearchInputProps} */
	{
		isSearchOpen = false,
		setSearchOpen,
	},
) => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const [searchParams] = useSearchParams();
	const query = searchParams.get('q');

	const [queryValue, setQueryValue] = useState(getQueryObject(query));

	const searchRef = useRef(
		/** @type {import('react-select').SelectInstance<QueryObject> | null} */(null),
	);
	useEffect(() => {
		if (searchRef.current) {
			if (isSearchOpen) searchRef.current.focus();
			else searchRef.current.blur();
		}
	}, [isSearchOpen]);

	useEffect(() => {
		if (query) setQueryValue(getQueryObject(query));
	}, [query]);

	const {
		data: suggestions = [],
		isLoading: isSuggesting,
	} = useFetchSearchSuggestions(queryValue?.value);

	const formattedSuggestions = useMemo(
		() => [
			...(queryValue ? [queryValue] : []),
			...suggestions
				.filter((/** @type {string} */suggestion) => suggestion !== queryValue?.value?.trim())
				.map((
				/** @type {string} */suggestion,
				) => ({
					label: suggestion,
					value: suggestion,
				})),
		],
		[queryValue, suggestions],
	);

	const getSearchLink = useCallback((
		/** @type {string} */searchQuery,
	) => {
		searchParams.set('q', searchQuery);
		return `${Path.SEARCH}?${searchParams.toString()}`;
	}, [searchParams]);

	const handleSearch = useCallback((
		/** @type {import('react-select').SingleValue<QueryObject>} */value,
	) => {
		if (value) {
			setSearchOpen(false);
			navigate(getSearchLink(value.value));
		} else {
			setQueryValue(null);
		}
	}, [navigate, setSearchOpen, getSearchLink]);

	const handleInputChange = useCallback((
		/** @type {string} */value,
		/** @type {import('react-select').InputActionMeta} */{ action },
	) => {
		if (action === 'input-change') setQueryValue(getQueryObject(value));
	}, []);

	return (
		<Select
			className={clsx('flex-fill min-w-0', {
				'expand-search-menu': isSearchOpen,
			})}
			classNamePrefix="react-select-light"
			components={{ Input, ValueContainer }}
			inputValue={queryValue?.value}
			isClearable={!isSearchOpen}
			isLoading={isSuggesting}
			noOptionsMessage={() => null}
			onBlur={() => setSearchOpen(false)}
			onChange={handleSearch}
			onFocus={() => setSearchOpen(true)}
			onInputChange={handleInputChange}
			openMenuOnFocus
			options={formattedSuggestions}
			placeholder={t('SearchInput.search')}
			ref={searchRef}
			styles={customSearchInputStyles}
			value={queryValue}
		/>
	);
};
