import PropTypes from 'prop-types';
import { Alert, Spinner } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useFetchAdCountryAnalytics } from '../../../../../../api-hooks/analytics/ads';
import { AnalyticsBarGraph } from '../../../Analytics/BarGraph';

export const AdvertisingDashboardDemographicsCountry = ({ adCampaignId }) => {
	const { data: adCountryAnalytics, isError, isLoading } = useFetchAdCountryAnalytics(adCampaignId);
	const { t } = useTranslation();

	if (isError) {
		return <Alert color="danger">{t('Global.error')}</Alert>;
	}

	if (isLoading) {
		return <Spinner />;
	}

	return adCountryAnalytics ? (
		<div>
			<div className="GrahpHeading m-0 p-3">
				{t('AdCampaings.Ad.audienceCountries')}
			</div>
			<AnalyticsBarGraph
				data={adCountryAnalytics}
				label="country"
				bars={['viewers']}
			/>
		</div>
	) : <Spinner />;
};

AdvertisingDashboardDemographicsCountry.propTypes = {
	adCampaignId: PropTypes.string,
};

AdvertisingDashboardDemographicsCountry.defaultProps = {
	adCampaignId: undefined,
};
