import PropTypes from 'prop-types';

import { ModalScreenHeader } from '../Modal/Screen/Header';
import { ModalScreen } from '../Modal/Screen/Screen';
import { LoginStepOrganizationChallenge } from './StepOrganizationChallenge';

export const LoginOrganizationChallengeModal = ({
	isOpen,
	modalOptions,
	onSubmit,
	onClose,
}) => (
	<ModalScreen
		bodyClassName="py-0 pb-5 bg-light"
		contentClassName="border-0 shadow-lg rounded overflow-scroll m-auto"
		isOpen={isOpen}
		onClose={onClose}
		size="lg"
		header={(
			<ModalScreenHeader
				onClose={onClose}
				title="Choose organization"
				emphasisOnTitle
				noBorder
			/>
		)}
	>
		<LoginStepOrganizationChallenge
			availableOptions={modalOptions.availableOptions}
			onSubmit={onSubmit}
			onBack={onClose}
		/>
	</ModalScreen>
);

LoginOrganizationChallengeModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	modalOptions: PropTypes.shape({
		availableOptions: PropTypes.arrayOf({}),
	}),
	onSubmit: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
};

LoginOrganizationChallengeModal.defaultProps = {
	modalOptions: { availableOptions: [] },
};
