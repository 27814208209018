import { api } from '../api';

export const API_CHAT_PATH = '/channel/chat';

export const fetchChatMessages = (studioId) => api.get(
	`${API_CHAT_PATH}/messages/${studioId}`,
);

export const sendMessage = (studioId, role, text, images, videos) => api.post(
	`${API_CHAT_PATH}/messages/byStudioId/${studioId}`,
	{ role, text, images, videos },
);

export const updateChatMessage = (id, text, images, videos) => api.put(
	`${API_CHAT_PATH}/messages/${id}`,
	{ text, images, videos },
);

export const deleteMessage = (id) => api.delete(
	`${API_CHAT_PATH}/messages/${id}`,
);

export const clearChat = (studioId) => api.delete(
	`${API_CHAT_PATH}/messages/byStudioId/${studioId}`,
);
