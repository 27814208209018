import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { usePoints } from '../../../../../Membership/Points';
import { calculateAdCreditCostMultiplier, caluclateAdCreditCost } from '../../../../../../lib/ads';
import { useFetchAdSettings } from '../../../../../../api-hooks/ads/ads';
import { formatDate } from '../../../../../../lib/time';

export const AdvertisingDashboardCampaign = ({ adCampaign }) => {
	const { data: adSettings } = useFetchAdSettings();
	const { adCreditBalance } = usePoints();
	const { t } = useTranslation();

	return adCampaign ? (
		<div className="mt-4 mb-5">
			<Table className="TransactionList_table mt-3">
				<thead>
					<tr className="text-secondary">
						<th className="text-secondary">Campaign Name</th>
						<th className="text-secondary">Start Date</th>
						<th className="text-secondary">Current Total Cost</th>
						<th className="text-secondary">Ongoing Campaign Ad Credit Left</th>
						<th className="text-secondary">End Date</th>
						<th />
					</tr>
				</thead>
				<tbody>
					<tr>
						<td className="text-secondary">{adCampaign.name}</td>
						<td className="text-secondary">{formatDate(adCampaign.startDate, t)}</td>
						<td className="text-secondary">{`${adSettings ? caluclateAdCreditCost(
							adCampaign.ads,
							adCampaign.viewTarget,
							calculateAdCreditCostMultiplier(adCampaign, adSettings.maxPriceIncrease),
							adSettings.adCreditToChips,
						) : '-'} Ad Credits`}
						</td>
						<td className="text-secondary">{adCreditBalance}</td>
						<td className="text-secondary">{formatDate(adCampaign.endDate, t)}</td>
						<td className="text-secondary" />
					</tr>
				</tbody>
			</Table>
		</div>
	) : null;
};

AdvertisingDashboardCampaign.propTypes = {
	adCampaign: PropTypes.shape({
		name: PropTypes.string,
		startDate: PropTypes.string,
		endDate: PropTypes.string,
		viewTarget: PropTypes.number,
		ads: PropTypes.arrayOf(PropTypes.shape()),
	}),
};

AdvertisingDashboardCampaign.defaultProps = {
	adCampaign: undefined,
};
