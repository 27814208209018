// @ts-check
/* eslint-disable react/prop-types */

import { format } from 'fecha';
import { useTranslation } from 'react-i18next';
import { FaPlayCircle, FaUsers } from 'react-icons/fa';
import { Badge, ListGroup, ListGroupItem } from 'reactstrap';
import { Link } from 'react-router-dom';

import { ButtonPill, ButtonPillOutline } from '../../Button';
import { StudioStatus } from '../helper';
import { getLink, Path } from '../../../RoutePath';

/**
 * @typedef {{
 *  _id: string,
 *  subject: string,
 *  isPublic: boolean,
 *  sharesCount: number,
 *  startAt: string,
 *  owner: {
 *  	hashtag: string,
 *  },
 *  code: string,
 *  status: StudioStatus,
 *  }} StudioDto
 */

/**
 * @param {StudioDto} param0
 * @returns {boolean}
 */
const isRunningStudio = ({ status }) => [
	StudioStatus.PENDING,
	StudioStatus.RUNNING,
].includes(status.toString());

/**
 * @typedef {{
 *  closeModal: () => void,
 *  onForceContinue: () => void,
 *  onSelectStudio: (studioId: string) => void,
 * 	studios: StudioDto[],
 * }} StudioScheduleManagerActiveStudiosProps
 */

export const StudioScheduleManagerActiveStudios = (
	/** @type {StudioScheduleManagerActiveStudiosProps} */
	{
		closeModal,
		onForceContinue,
		onSelectStudio,
		studios,
	},
) => {
	const { t } = useTranslation();
	return (
		<div className="d-flex h-100 flex-column align-items-center justify-content-center">
			<h1 className="h4">{t('StudioSchedule.Manager.ActiveStudios.title')}</h1>
			<ListGroup className="bg-list-group content-list-group w-100">
				{studios.map((studio) => (
					<ListGroupItem className="d-flex align-items-center bg-light content-light" key={studio._id}>
						<div className="d-flex flex-column mr-2">
							<p className="d-flex align-items-center mb-1">
								<strong className="pt-1">{studio.subject || t('StudioSchedule.Manager.ActiveStudios.untitled')}</strong>
								{studio.isPublic && (
									<Badge className="ml-2" pill color="danger">{t('StudioSchedule.Manager.ActiveStudios.public')}</Badge>
								)}
							</p>
							<p className="d-flex align-items-start mb-1">
								<FaUsers
									className="mr-2"
								/>
								{studio.sharesCount || 0}
							</p>
							<p className="font-size-sm mb-0">
								{format(new Date(studio.startAt), t('Global.DateFormat.dateAndTime'))}
								{isRunningStudio(studio) ? (
									<span className="text-success ml-3 text-uppercase">{t('StudioSchedule.Manager.ActiveStudios.running')}</span>
								) : null}
							</p>
						</div>
						{isRunningStudio(studio) ? (
							<ButtonPill
								className="ml-auto shadow-none"
								color="danger"
								onClick={closeModal}
								size="sm"
								tag={Link}
								to={getLink(
									Path.STUDIO_HOST,
									{ hashtag: studio.owner.hashtag, code: studio.code },
								)}
							>
								{t('StudioSchedule.Manager.ActiveStudios.join')}
							</ButtonPill>
						) : (
							<ButtonPill
								className="ml-auto shadow-none"
								color="primary"
								onClick={() => onSelectStudio(studio._id)}
								size="sm"
							>
								{t('StudioSchedule.Manager.ActiveStudios.select')}
							</ButtonPill>
						)}
					</ListGroupItem>
				))}
				<ListGroupItem className="d-flex align-items-center bg-dark content-dark">
					<ButtonPillOutline
						className="ml-auto mr-auto my-4 shadow-none"
						color="primary"
						onClick={onForceContinue}
						size="sm"
					>
						<FaPlayCircle
							className="mr-2"
						/>
						<strong>{t('StudioSchedule.Manager.ActiveStudios.createNewStudio')}</strong>
					</ButtonPillOutline>
				</ListGroupItem>
			</ListGroup>
		</div>
	);
};
