// @ts-check

import { useCallback } from 'react';
import noimage from '../images/no-image.jpg';

/**
 * @param {string} [defaultAvatar]
 * @returns {React.ReactEventHandler<HTMLImageElement>}
 */
export const useValidateImage = (defaultAvatar) => useCallback((e) => {
	e.currentTarget.src = defaultAvatar || noimage;
}, [defaultAvatar]);
