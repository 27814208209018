import { api } from '../api';

export const API_PREFERENCE_PATH = '/channel/preferences';

export const createOrUpdateChannelPreferences = (payload) => api
	.post(API_PREFERENCE_PATH, {
		payload,
	});

export const createOrUpdateNotificationsPreferenecs = (payload) => api.post(`${API_PREFERENCE_PATH}/notifications`, {
	payload,
});

export const fetchNotificationsPreferenecs = () => api.get(`${API_PREFERENCE_PATH}/notifications`);

export const updateChannelNewsletterPreferences = (payload) => api.post(`${API_PREFERENCE_PATH}/notifications/newsletter`, {
	payload,
});
