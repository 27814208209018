import { api } from '../api';

export const API_PUBLIC_STUDIO_PATH = '/public/studio';

// TODO: Replace any with studiodto
/**
 * @param {string} code
 * @param {string} requiredAccess
 * @param {{
 *  forceJoin?: boolean
 *  password?: string
 * }} [params]
 * @returns {Promise<import('axios').AxiosResponse<any>>}
 */
export const requestPublicStudioByCode = async (code, requiredAccess, params) => api.post(
	`${API_PUBLIC_STUDIO_PATH}/request/bycode/${code}`,
	{ ...params, requiredAccess },
);
