import PropTypes from 'prop-types';
import { useCallback, useId, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaSmile, FaVideo } from 'react-icons/fa';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

import clsx from 'clsx';
import { useUploadVideo } from '../../api-hooks/channel/videos';
import { useProfile } from '../Profile/ProfileContext';
import { TooltipTimeout } from '../Tooltip/Timeout';
import { UserSettingsModal, useUserSettings } from '../UserSettings/Context';
import { ImageInput } from './ImageInput';

export const properties = {
	filename: { type: 'string' },
};

export const schema = {
	type: 'object',
	additionalProperties: false,
	properties,
	required: [],
};

export const PostAssetType = {
	IMAGE: 'IMAGE',
	VIDEO: 'VIDEO',
};

export const AssetUploadMode = {
	LIBRARY: 'MediaInput.chooseFromLibrary',
	UPLOAD: 'MediaInput.upload',
};

export const isFileAcceptable = (file, accept) => {
	const acceptedTypes = accept.replaceAll(' ', '').split(',');
	return acceptedTypes.includes(file.type);
};

export const MediaInput = ({
	uploadedAssets,
	setUploadedAssets,
	setImageUploadProgress,
	setVideoUploadProgress,
	toggleEmojiPanel,
	isDisabled,
}) => {
	const { t } = useTranslation();
	const [videoDropdownOpen, setVideoDropdownOpen] = useState(false);

	const [videoIncorrectTypeError, setVideoIncorrectTypeError] = useState(false);

	const { mutate: uploadVideo } = useUploadVideo();

	const { profile } = useProfile();
	const { openModal, closeModal } = useUserSettings();

	const videoInput = useRef(null);

	const id = useId();
	const videoId = `MediaInput-video${id.replaceAll(':', '__')}`;

	const onVideoUploadProgress = useCallback((progress) => {
		setVideoUploadProgress(progress);
	}, [setVideoUploadProgress]);

	const handleVideoUploaded = useCallback((video) => {
		if (video) {
			setUploadedAssets((prevUploadedAssets) => [
				...prevUploadedAssets,
				{ video: video._id, assetType: PostAssetType.VIDEO, filename: video.filename },
			]);
		}
	}, [setUploadedAssets]);

	const handleBrowseVideos = useCallback(() => {
		videoInput.current.click();
	}, [videoInput]);

	const handleUploadVideo = useCallback((videoData) => {
		uploadVideo({
			channelId: profile._id,
			videoFile: videoData,
			onUploadProgress: onVideoUploadProgress,
		}, {
			onSuccess: (data) => {
				handleVideoUploaded(data);
				setVideoUploadProgress(undefined);
			},
		});
	}, [handleVideoUploaded, onVideoUploadProgress, profile, setVideoUploadProgress, uploadVideo]);

	const handleVideoChange = useCallback(async (event) => {
		setVideoIncorrectTypeError(false);
		const files = Array.from(event.target.files);

		if (!files?.length) {
			return;
		}

		const file = files[0];

		if (!isFileAcceptable(file, event.target.accept)) {
			setVideoIncorrectTypeError(true);
			return;
		}

		const label = file.name.replace(/\.[^/.]+$/, '');
		const videoData = {
			blob: file,
			label,
			filename: URL.createObjectURL(file),
		};

		handleUploadVideo(videoData);
	}, [handleUploadVideo]);

	const handleOpenSelectAssetModal = useCallback((callback, modalType) => {
		const call = (asset) => {
			closeModal();
			return callback(asset);
		};
		openModal(modalType, undefined, undefined, call);
	}, [closeModal, openModal]);

	return (
		<>
			<div className="d-flex justify-content-start mt-2 align-items-center">
				<span
					className={clsx(
						'd-none d-sm-block cursor-pointer text-secondary mr-2',
						{ disabled: isDisabled },
					)}
					onClick={toggleEmojiPanel}
				>
					<FaSmile />
				</span>
				<ImageInput
					setImageUploadProgress={setImageUploadProgress}
					setUploadedAssets={setUploadedAssets}
					uploadedAssets={uploadedAssets}
				/>

				<Dropdown
					isOpen={videoDropdownOpen}
					toggle={() => setVideoDropdownOpen(!videoDropdownOpen)}
				>
					<DropdownToggle
						className="font-size-lg p-0 d-inline-block shadow-none border-0 text-center d-30 btn-transition-none bg-transparent text-secondary"
						title={t('MediaInput.uploadVideo')}
						type="button"
						id={videoId}
					>
						<FaVideo />
					</DropdownToggle>
					{videoDropdownOpen && (
						<DropdownMenu>
							<DropdownItem onClick={() => handleOpenSelectAssetModal(
								handleVideoUploaded,
								UserSettingsModal.SELECT_VIDEOS,
							)}
							>
								{t(AssetUploadMode.LIBRARY)}
							</DropdownItem>
							<DropdownItem divider />
							<DropdownItem onClick={handleBrowseVideos}>
								{t(AssetUploadMode.UPLOAD)}
							</DropdownItem>
						</DropdownMenu>
					)}
				</Dropdown>
			</div>

			<input
				className="d-none"
				accept="video/mp4, .mp4, video/mpeg, .mpeg, video/quicktime, .mov, video/webm, .webm"
				type="file"
				onChange={handleVideoChange}
				ref={videoInput}
			/>
			<TooltipTimeout
				onTimeout={() => setVideoIncorrectTypeError(false)}
				isOpen={videoIncorrectTypeError}
				placement="top"
				popperClassName="tooltip-danger"
				target={videoId}
			>
				{t('MediaInput.videoFormatAllowed')}
			</TooltipTimeout>
		</>
	);
};

MediaInput.propTypes = {
	uploadedAssets: PropTypes.arrayOf(PropTypes.shape({})),
	setUploadedAssets: PropTypes.func,
	setImageUploadProgress: PropTypes.func,
	setVideoUploadProgress: PropTypes.func,
	toggleEmojiPanel: PropTypes.func,
	isDisabled: PropTypes.bool,
};

MediaInput.defaultProps = {
	uploadedAssets: [],
	setUploadedAssets: undefined,
	setImageUploadProgress: undefined,
	setVideoUploadProgress: undefined,
	toggleEmojiPanel: undefined,
	isDisabled: false,
};
