import requiredCookies from './Required';
import functionalCookies from './Functional';
// import advertisingCookies from './Advertising';

export const list = [
	requiredCookies,
	functionalCookies,
	// advertisingCookies,
];

export const defaultCookies = {
	functionalGa: undefined,
	functionalRollbar: undefined,
};

export const COOKIES_NAME = 'beeyou.tv/cookies';
export const COOKIES_EXPIRATION_HOURS = 8760; //1 year
