export const supportedBrowsers = [
	{
		name: 'Chrome',
		minBrowserVersion: {
			major: 73,
			minor: 0,
		},
		os: ['android', 'desktop', 'macOS'],
		fullname: 'Google Chrome',
		linkInstall: 'https://www.google.com/chrome/',
		linkUpdate: 'https://www.google.com/chrome/update/',
	},
	{
		name: 'Safari',
		minBrowserVersion: {
			major: 12,
			minor: 0,
		},
		os: ['macOS'],
		fullname: 'Safari',
		linkInstall: 'https://support.apple.com/safari',
		linkUpdate: 'https://support.apple.com/safari',
	},
	{
		name: 'Safari',
		minBrowserVersion: {
			major: 13,
			minor: 0,
		},
		os: ['iOS'],
		fullname: 'Safari',
		linkInstall: 'https://support.apple.com/safari',
		linkUpdate: 'https://support.apple.com/safari',
	},
	{
		name: 'Microsoft Edge',
		minBrowserVersion: {
			major: 44,
			minor: 0,
		},
		os: ['android', 'desktop', 'macOS'],
		fullname: 'Microsoft Edge',
		linkInstall: 'https://www.microsoft.com/en-us/edge',
		linkUpdate: 'https://www.microsoft.com/en-us/edge',
	},
	{
		name: 'Firefox',
		minBrowserVersion: {
			major: 64,
			minor: 0,
		},
		os: ['android', 'desktop', 'macOS'],
		fullname: 'Firefox',
		linkInstall: 'https://www.mozilla.org/en-US/firefox/',
		linkUpdate: 'https://www.mozilla.org/en-US/firefox/browsers/update-your-browser/',
	},
	{
		name: 'Opera',
		minBrowserVersion: {
			major: 58,
			minor: 0,
		},
		os: ['android', 'desktop', 'macOS'],
		fullname: 'Opera',
		linkInstall: 'https://www.opera.com/',
		linkUpdate: 'https://www.opera.com/',
	},
];
