import PropTypes from 'prop-types';
import { isEmail, ReactMultiEmail } from 'react-multi-email';
import clsx from 'clsx';

import './Emails.scss';

export const FormEmails = ({
	className,
	tagClassName,
	...props
}) => (
	<ReactMultiEmail
		className={clsx('FormEmails', className)}
		validateEmail={isEmail}
		// eslint-disable-next-line react/no-unstable-nested-components
		getLabel={(
			email,
			index,
			removeEmail,
		) => (
			<div className={tagClassName} data-tag key={index}>
				{email}
				<span data-tag-handle onClick={() => removeEmail(index)}>
					×
				</span>
			</div>
		)}
		{...props}
	/>
);

FormEmails.propTypes = {
	className: PropTypes.string,
	tagClassName: PropTypes.string,
};

FormEmails.defaultProps = {
	className: '',
	tagClassName: PropTypes.string,
};
