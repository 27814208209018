import { useMutation, useQueryClient } from 'react-query';
import { axiosMutationWrapper } from '../utils/axios-wrapper';
import * as paypalApi from '../../api/membership/paypal';
import { GRAPH_GLOBALS_QUERY_KEYS } from '../graph/globals';
import { useProfile } from '../../components/Profile/ProfileContext';

export const useCreatePaypalOrder = () => useMutation(
	axiosMutationWrapper(paypalApi.createOrder),
);

export const useCreateCashOutPaypalOrder = () => useMutation(
	axiosMutationWrapper(paypalApi.createCashOutOrder),
);

export const useCapturePaypalOrder = () => useMutation(
	axiosMutationWrapper(paypalApi.captureOrder),
);

export const useCreatePaypalSubscription = () => useMutation(
	axiosMutationWrapper(paypalApi.createSubscription),
);

export const useCapturePaypalSubscription = () => {
	const queryClient = useQueryClient();
	const { profile } = useProfile();

	return useMutation(
		axiosMutationWrapper(paypalApi.captureSubscription),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(GRAPH_GLOBALS_QUERY_KEYS.fetchGraphGlobals(profile._id));
			},
		},
	);
};
