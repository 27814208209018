import { api } from '../api';

export const API_CHANNEL_GROUPS_PATH = '/channel/groups';

export const fetchGroups = () => api.get(`${API_CHANNEL_GROUPS_PATH}`);

export const fetchGroup = (id) => api.get(`${API_CHANNEL_GROUPS_PATH}/${id}`);

export const createGroup = (group) => api.post(
	`${API_CHANNEL_GROUPS_PATH}/`,
	group,
);

export const updateGroup = (group) => api.put(
	`${API_CHANNEL_GROUPS_PATH}/${group._id}`,
	group,
);

export const deleteGroup = (group) => api.delete(
	`${API_CHANNEL_GROUPS_PATH}/${group._id}`,
);

export const leaveGroup = (groupId) => api.post(
	`${API_CHANNEL_GROUPS_PATH}/${groupId}/leave`,
);
