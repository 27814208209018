export default {
	category: 'Cookies.Required.category',
	description: 'Cookies.Required.category.description',
	cookiesList: [
		{
			name: 'Cookies.Required.session',
			description: 'Cookies.Required.session.description',
			id: 'requiredSession',
		},
		{
			name: 'Cookies.Required.stripe',
			description: 'Cookies.Required.stripe.description',
			id: 'requiredStripe',
		},
	],
	canBeDeactivated: false,
};
