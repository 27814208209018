// @ts-check

import { useCallback, useEffect, useRef, useState } from 'react';

/**
 *
 * @param {{
 *  code?: string,
 *  callback: () => void,
 * }} param0
 * @param {any[]} resetConditions
 * @returns {{
 *  handleWsError: (err: { code?: string }) => void,
 *  reset: () => void,
 *  wsError: object|undefined,
 * }}
 */
export const useWsConnectorError = (
	{
		code = 'invalid_token',
		callback,
	},
	resetConditions,
) => {
	const callbackRef = useRef(callback);
	callbackRef.current = callback;
	const lastWsErrorTime = useRef(/** @type {number|undefined} */(undefined));

	const [wsError, setWsError] = useState(/** @type {object|undefined} */(undefined));

	const handleWsError = useCallback((/** @type {{ code?: string }} */err) => {
		if (
			err.code === code
		) {
			if (
				!lastWsErrorTime.current
				|| (Date.now() - lastWsErrorTime.current > 1000 * 60 * 3)
			) {
				lastWsErrorTime.current = Date.now();
				callbackRef.current();
			} else {
				setWsError(err);
			}
		} else {
			setWsError(err);
		}
	}, [code]);

	const reset = useCallback(() => {
		setWsError(undefined);
		lastWsErrorTime.current = undefined;
	}, []);

	useEffect(() => {
		reset();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, resetConditions);

	return {
		handleWsError,
		reset,
		wsError,
	};
};
