import { api } from '../api';

export const API_POSTS_PATH = '/channel/posts';

export const fetchPostsByChannelId = (channelId, mode, lastTimestamp) => api.get(
	`${API_POSTS_PATH}/bychannel/${channelId}`, {
		params: {
			mode,
			lastTimestamp,
		},
	},
);

export const fetchPost = (slug) => api.get(
	`${API_POSTS_PATH}/byid/${slug}`,
);

export const fetchPinnedPostByChannelId = (channelId) => api.get(
	`${API_POSTS_PATH}/pinned/bychannel/${channelId}`,
);

export const createPost = (post) => api.post(
	`${API_POSTS_PATH}`,
	post,
);

export const updatePost = ({ id, text, images, videos, surveyTemplate }) => api.put(
	`${API_POSTS_PATH}/${id}`,
	{ text, images, videos, surveyTemplate },
);

export const deletePost = (id) => api.delete(
	`${API_POSTS_PATH}/${id}`,
);

export const sharePostInChat = (id) => api.post(
	`${API_POSTS_PATH}/${id}/share_in_chat`,
);

export const sharePost = (id) => api.post(
	`${API_POSTS_PATH}/share/${id}`,
);

export const pinPost = (id) => api.post(
	`${API_POSTS_PATH}/pin/${id}`,
);

export const unpinPost = (id) => api.post(
	`${API_POSTS_PATH}/unpin/${id}`,
);
