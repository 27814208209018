// @ts-check

import { ResourceAccessRole } from './ResourceAccessRole';

/**
 * @enum {number}
 */
export const ResourceAccessLevel = {
	[ResourceAccessRole.PUBLIC]: 0,
	[ResourceAccessRole.VIEWER]: 10,
	[ResourceAccessRole.PARTICIPANT]: 20,
	[ResourceAccessRole.MODERATOR]: 30,
	[ResourceAccessRole.OPERATOR]: 40,
	[ResourceAccessRole.OWNER]: 60,
};
