import { useInfiniteQuery, useQuery } from 'react-query';
import * as gfxApi from '../../api/channel/gfx';
import { axiosQueryWrapper } from '../utils/axios-wrapper';

const ITEMS_PER_PAGE = 20;

export const GFX_QUERY_KEYS = {
	fetchGfx: (channelId, mode, itemsPerPage, currentPage) => ['api', 'channel', 'gfx', 'fetch', channelId, mode, itemsPerPage, currentPage].filter((el) => !!el),
};

export const useFetchGfx = (
	channelId,
	mode,
	itemsPerPage,
	currentPage,
) => useQuery(GFX_QUERY_KEYS.fetchGfx(channelId, mode, itemsPerPage, currentPage),
	axiosQueryWrapper(gfxApi.fetchGfx, channelId, mode, itemsPerPage, currentPage));

export const useInfiniteFetchGxf = (
	channelId,
	mode,
	itemsPerPage = ITEMS_PER_PAGE,
) => useInfiniteQuery(GFX_QUERY_KEYS.fetchGfx(channelId, mode, itemsPerPage),
	({ pageParam = 1 }) => axiosQueryWrapper(
		gfxApi.fetchGfx,
		channelId,
		mode,
		itemsPerPage,
		pageParam,
	)(),
	{
		getNextPageParam: (lastPage, allPages) => {
			const currentItemsLenght = allPages.reduce((acc, curr) => acc + curr.data.length, 0);
			if (currentItemsLenght < lastPage.totalItemsCount) {
				return allPages.length + 1;
			}
			return undefined;
		},
	});
