// @ts-check
import { api } from '../api';

/** @typedef {import('../public/channel.dto').IChannelPublicDto} IChannelPublicDto */

export const API_CHANNEL_PATH = '/channel';

/**
 * TODO: complete
 * @typedef {{
 * 	birthdate: Date,
 * } & IChannelPublicDto} IChannelOwn
 */

/**
 * @returns {Promise<import('axios').AxiosResponse<IChannelOwn>>}
 */
export const fetchOwnChannel = () => api.get(`${API_CHANNEL_PATH}/me`);

/**
 * @param {string} hashtag
 * @returns {Promise<import('axios').AxiosResponse<IChannelPublicDto>>}
 */
export const fetchOneChannel = (hashtag) => api.get(`${API_CHANNEL_PATH}/byhashtag/${hashtag}`);

/**
 * @param {string} id
 * @returns {Promise<import('axios').AxiosResponse<IChannelPublicDto>>}
 */
export const fetchOneChannelById = (id) => api.get(`${API_CHANNEL_PATH}/byid/${id}`);
