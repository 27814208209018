// @ts-check

/**
 * @readonly
 * @enum {string}
 */
export const LibraryModalTab = {
	GFX_VIDEO: 'gfx_videos',
	GFX: 'gfx',
	IMAGES: 'images',
	PLAYLISTS: 'playlists',
	SCENES: 'scenes',
	SIMULCASTS: 'simulcasts',
	UPLOAD: 'upload',
	VIDEOS: 'videos',
	AUDIOS: 'audios',
};
