import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import PlayerCanvas from './PlayerCanvas';
import { PlayerCustom } from './PlayerCustom';
import { PlayerLivePiPHandler } from './PiPHandler';
import { PlayerLiveCropOverlay } from './CropOverlay';
import { usePlayerMode, PlayerModes } from './PlayerModeProvider';

import './PlayerLive.scss';

const getRenderedSize = (contains, cWidth, cHeight, width, height, pos) => {
	const oRatio = (width / height) || (16 / 9);
	const cRatio = (cWidth / cHeight) || (16 / 9);
	const size = {
		width: 0,
		height: 0,
		left: 0,
		right: 0,
	};
	if (contains ? (oRatio > cRatio) : (oRatio < cRatio)) {
		size.width = cWidth;
		size.height = cWidth / oRatio;
	} else {
		size.width = cHeight * oRatio;
		size.height = cHeight;
	}
	size.left = (cWidth - size.width) * (pos / 100);
	size.right = size.width + size.left;
	return size;
};

export const usePlayerSize = (playerRef) => {
	const [size, setSize] = useState({
		width: 0,
		height: 0,
		left: 0,
	});

	useEffect(() => {
		const videoEl = playerRef.current;
		const onResize = () => {
			const pos = window.getComputedStyle(videoEl).getPropertyValue('object-position').split(' ');
			setSize(
				getRenderedSize(true,
					videoEl.clientWidth,
					videoEl.clientHeight,
					videoEl.videoWidth,
					videoEl.videoHeight,
					parseInt(pos[0], 10)),
			);
		};

		let videoResizeObserver;
		let videoResizeInterval;

		try {
			videoResizeObserver = new ResizeObserver(onResize);
		} catch (err) {
			console.warn('ResizeObserver not supported, using setInterval instead.');
			videoResizeInterval = setInterval(() => onResize(), 1500);
		}

		if (videoEl) {
			videoEl.addEventListener('resize', onResize);
			if (videoResizeObserver) videoResizeObserver.observe(videoEl);
		}

		return () => {
			if (videoEl) {
				videoEl.removeEventListener('resize', onResize);
				if (videoResizeObserver) videoResizeObserver.unobserve(videoEl);
			}
			if (videoResizeInterval) clearInterval(videoResizeInterval);
		};
	}, [playerRef]);

	return size;
};

const PlayerMode = (props) => {
	const { mode } = usePlayerMode();
	return (
		<>
			{mode === PlayerModes.DRAW && <PlayerCanvas className="PlayerLive" {...props} />}
			{mode === PlayerModes.CROP && <PlayerLiveCropOverlay {...props} />}
			{mode === PlayerModes.FLOATING_PIP && (
				<PlayerLivePiPHandler {...props} />
			)}
		</>
	);
};
export const PlayerLive = ({
	children,
	className,
	controls,
	handleClickFullScreen,
	isCameraOnMainLayer,
	isFullscreen,
	safeZoneComponent,
	src,
}) => {
	const playerRef = useRef();
	const size = usePlayerSize(playerRef);

	return (
		<>
			<PlayerCustom
				className={clsx('PlayerLive',
					{ 'camera-on-main-layer': isCameraOnMainLayer },
					className)}
				src={src}
				controls={controls}
				handleClickFullScreen={handleClickFullScreen}
				isFullscreen={isFullscreen}
				ref={playerRef}
			/>
			<PlayerMode size={size} safeZoneComponent={safeZoneComponent} />
			<div className="position-absolute" style={{ height: size.height, width: size.width }}>
				{children}
			</div>
		</>
	);
};

PlayerLive.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	controls: PropTypes.bool,
	handleClickFullScreen: PropTypes.func.isRequired,
	isCameraOnMainLayer: PropTypes.bool,
	isFullscreen: PropTypes.bool,
	safeZoneComponent: PropTypes.elementType,
	src: PropTypes.instanceOf(MediaStream),
};

PlayerLive.defaultProps = {
	children: null,
	className: '',
	controls: true,
	isCameraOnMainLayer: false,
	isFullscreen: false,
	safeZoneComponent: null,
	src: null,
};
