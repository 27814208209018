import { Suspense, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { StudioSummaryContext, StudioSummaryModalType } from './Context';
import { StudioSummaryModal } from './StudioSummaryModal';

export const StudioSummaryProvider = ({ children }) => {
	const [modalOpen, setModalOpen] = useState();
	const [activeStudioId, setActiveStudioId] = useState();

	const closeModal = useCallback(() => {
		setModalOpen(undefined);
		setActiveStudioId(undefined);
	}, []);

	const openModal = useCallback((studioId) => {
		setActiveStudioId(studioId);
		setModalOpen(StudioSummaryModalType.SUMMARY);
	}, []);

	const context = useMemo(() => ({
		activeStudioId,
		modalOpen,
		closeModal,
		openModal,
		setModalOpen,
	}), [activeStudioId, closeModal, modalOpen, openModal]);

	return (
		<StudioSummaryContext.Provider value={context}>
			{children}
			<Suspense fallback={null}>
				{modalOpen === StudioSummaryModalType.SUMMARY && (
					<StudioSummaryModal isOpen />
				)}
			</Suspense>
		</StudioSummaryContext.Provider>
	);
};

StudioSummaryProvider.propTypes = {
	children: PropTypes.node,
};

StudioSummaryProvider.defaultProps = {
	children: undefined,
};
