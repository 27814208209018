import { AdSharedProps } from '../components/UserSettings/AnalyticsAndAdvertising/Advertising/Ads/createAds.schema';

export const calculateBillableAdDuration = (ads) => {
	const totalDuration = ads.reduce(
		(acc, cur) => acc + (cur?.video?.duration || 0), 0,
	);

	return totalDuration / ads.length;
};

export const calculateAdCreditCostMultiplier = (adCampaign, maxPriceIncrease) => {
	const timeSlotsPercentage = adCampaign.timeSlots.includes(AdSharedProps.ANY)
		? 0
		: maxPriceIncrease / adCampaign.timeSlots.length;

	const ageGroupsPercentage = adCampaign.ageGroups.includes(AdSharedProps.ANY)
		? 0
		: maxPriceIncrease / adCampaign.ageGroups.length;

	const gendersPercentage = adCampaign.genders.includes(AdSharedProps.ANY)
		? 0
		: maxPriceIncrease / adCampaign.genders.length;

	const interestsPercentage = adCampaign.interests.includes(AdSharedProps.ANY)
		? 0
		: maxPriceIncrease / adCampaign.interests.length;

	const countriesPercentage = !adCampaign.countries.length
		? 0
		: maxPriceIncrease / adCampaign.interests.length;

	return 1 + (
		timeSlotsPercentage + ageGroupsPercentage + gendersPercentage
			+ interestsPercentage + countriesPercentage
	) / 100;
};

export const caluclateAdCreditCost = (ads, targetViews, costMultiplier, adCreditToChips) => ads
	.reduce((acc, cur) => (cur.video?.video ? acc + Math.ceil(
		(
			(4 * targetViews * (cur.playPercentage / 100) * cur.video.duration) / adCreditToChips
		) * costMultiplier,
	) : acc), 0);
