import { NavItem, NavLink } from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export const SidebarHeaderItem = ({ menuItem, toggleDropdown }) => (
	<NavItem>
		<NavLink
			tag={Link}
			to={menuItem.to}
			onClick={() => {
				if (menuItem.action) {
					menuItem.action();
				}
				toggleDropdown();
			}}
		>
			{!!menuItem.icon && (
				<span className="mr-2">
					{menuItem.icon}
				</span>
			)}
			{menuItem.label}
		</NavLink>
	</NavItem>
);

SidebarHeaderItem.propTypes = {
	menuItem: PropTypes.shape({
		to: PropTypes.string,
		label: PropTypes.string,
		action: PropTypes.func,
		icon: PropTypes.node,
	}).isRequired,
	toggleDropdown: PropTypes.func.isRequired,
};
