import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Field, FieldError } from 'react-jsonschema-form-validation';
import { Col, FormGroup, Row, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { FormLabel } from '../../../../../Form/Label';
import { AdCycleDuration } from '../createAds.schema';
import { AdCreditBuyButton } from '../../../../../AdCredit/BuyButton';
import { usePoints } from '../../../../../Membership/Points';
import { calculateAdCreditCostMultiplier, caluclateAdCreditCost, calculateBillableAdDuration } from '../../../../../../lib/ads';
import { useFetchAdSettings } from '../../../../../../api-hooks/ads/ads';

export const AdvertisingEstimation = ({ adCampaign, isViewOnly }) => {
	const { t } = useTranslation();
	const { data: adSettings } = useFetchAdSettings();
	const { adCreditBalance } = usePoints();

	const totalEstimatedAdCredit = useMemo(
		() => (adSettings ? caluclateAdCreditCost(
			adCampaign.ads,
			adCampaign.viewTarget,
			calculateAdCreditCostMultiplier(adCampaign, adSettings.maxPriceIncrease),
			adSettings.adCreditToChips,
		) : '-'),
		[adCampaign, adSettings],
	);

	const totalEstimatedCost = useMemo(() => totalEstimatedAdCredit / 400, [totalEstimatedAdCredit]);

	return (
		<>
			<h4 className="my-4">{t('AdCampaings.Ad.estimatedCost')}</h4>
			<Row className="">
				<Col>
					<FormGroup>
						<FormLabel>{t('AdCampaings.Ad.campaignSpendingLimit')}</FormLabel>
						<Field
							component={Input}
							type="select"
							name="spendingLimit"
							disabled={isViewOnly}
							value={adCampaign.spendingLimit}
						>
							<option value="1000">1000</option>
							<option value="5000">5000</option>
							<option value="10000">10000</option>
							<option value="50000">50000</option>
							<option value="100000">100000</option>
						</Field>
						<FieldError name="spendingLimit" />
					</FormGroup>
					<FormGroup>
						<FormLabel>{t('AdCampaings.Ad.maximumViewsPerCycle')}</FormLabel>
						<Field
							component={Input}
							type="select"
							name="maxViewsPerCycle"
							disabled={isViewOnly}
							value={adCampaign.maxViewsPerCycle}
						>
							<option value="100">100</option>
							<option value="500">500</option>
							<option value="1000">1000</option>
							<option value="5000">5000</option>
							<option value="10000">10000</option>
							<option value="50000">50000</option>
							<option value="100000">100000</option>
						</Field>
						<FieldError name="maxViewsPerCycle" />
					</FormGroup>
					<FormGroup>
						<FormLabel>{t('AdCampaings.Ad.cycleDuration')}</FormLabel>
						<Field
							component={Input}
							type="select"
							name="cycleDuration"
							disabled={isViewOnly}
							value={adCampaign.cycleDuration}
						>
							<option value={AdCycleDuration.DAILY}>{t('AdCampaings.Ad.daily')}</option>
							<option value={AdCycleDuration.WEEKLY}>{t('AdCampaings.Ad.weekly')}</option>
							<option value={AdCycleDuration.MONTHLY}>{t('AdCampaings.Ad.monthly')}</option>
						</Field>
						<FieldError name="cycleDuration" />
					</FormGroup>
				</Col>
				<Col>
					<FormGroup>
						<FormLabel>{t('AdCampaings.Ad.totalAdDuration')}</FormLabel>
						<Field
							component={Input}
							type="number"
							value={calculateBillableAdDuration(adCampaign.ads)}
							disabled
						/>
					</FormGroup>
					<FormGroup>
						<FormLabel>{t('AdCampaings.Ad.targetUniqueuViews')}</FormLabel>
						<Field
							component={Input}
							type="number"
							value={adCampaign.viewTarget}
							disabled
						/>
					</FormGroup>
					<FormGroup>
						<FormLabel>{t('AdCampaings.Ad.estimatedAdCredit')}</FormLabel>
						<Field
							component={Input}
							type="number"
							value={totalEstimatedAdCredit}
							disabled
						/>
					</FormGroup>
				</Col>
				<Col>
					<FormGroup>
						<FormLabel>{t('AdCampaings.Ad.currentAdCredit')}</FormLabel>
						<Field
							component={Input}
							type="number"
							value={Math.ceil(adCreditBalance || 0)}
							disabled
						/>
					</FormGroup>
					<FormGroup>
						<FormLabel>{t('AdCampaings.Ad.additionalAdCreditRequired')}</FormLabel>
						<Field
							component={Input}
							type="number"
							value={
								totalEstimatedAdCredit < adCreditBalance
									? 0
									: Math.ceil(totalEstimatedAdCredit - adCreditBalance)
							}
							disabled
						/>
					</FormGroup>
					<FormGroup>
						<FormLabel>{t('AdCampaings.Ad.totalEstimatedCost')}</FormLabel>
						<Field
							component={Input}
							type="string"
							value={`$${totalEstimatedCost.toFixed(2)}`}
							disabled
						/>
					</FormGroup>
				</Col>
				<Col className="pt-4">
					{!isViewOnly && (
						<AdCreditBuyButton>
							{t('AdCampaings.Ad.buyAdCredits')}
						</AdCreditBuyButton>
					)}
				</Col>
			</Row>
		</>
	);
};

AdvertisingEstimation.propTypes = {
	adCampaign: PropTypes.shape({
		ads: PropTypes.arrayOf(PropTypes.shape()),
		viewTarget: PropTypes.string,
		spendingLimit: PropTypes.string,
		maxViewsPerCycle: PropTypes.string,
		cycleDuration: PropTypes.string,
	}),
	isViewOnly: PropTypes.bool,
};

AdvertisingEstimation.defaultProps = {
	adCampaign: undefined,
	isViewOnly: false,
};
