// @ts-check

/**
 * @import { ChannelStream } from '../../lib/store/channelStream';
 * @import { Publication, SubscribedPublication } from '../../lib/store/publication';
 * @import { Action } from 'redux';
 * */

const ACTION_KEY = 'channelStreams';

/**
 * actions
 */
export const ADD_STREAM_TRACK = `${ACTION_KEY}/addStreamTrack`;
export const REMOVE_STREAM_TRACK = `${ACTION_KEY}/removeStreamTrack`;
export const REMOVE_CHANNEL_STREAMS = `${ACTION_KEY}/removeChannelStreams`;
export const UPDATE_STREAM = `${ACTION_KEY}/updateStream`;

/**
 * @typedef {Action<typeof ADD_STREAM_TRACK> & { payload: SubscribedPublication } |
 * 		Action<typeof REMOVE_STREAM_TRACK> & { payload: SubscribedPublication } |
 * 		Action<typeof REMOVE_CHANNEL_STREAMS> & { payload: Pick<ChannelStream, 'hashtag'> } |
* 		Action<typeof UPDATE_STREAM> & { payload: Publication }
* } ChannelStreamAction
* */

/**
 * @param {SubscribedPublication} subscription
 * @returns {ChannelStreamAction}
 */
export const addStreamTrackAction = (subscription) => ({
	type: ADD_STREAM_TRACK,
	payload: subscription,
});

/**
 * @param {SubscribedPublication} subscription
 * @returns {ChannelStreamAction}
 */
export const removeStreamTrackAction = (subscription) => ({
	type: REMOVE_STREAM_TRACK,
	payload: subscription,
});

/**
 * @param {Pick<ChannelStream, 'hashtag'>} channel
 * @returns {ChannelStreamAction}
 */
export const removeChannelStreamsAction = (channel) => ({
	type: REMOVE_CHANNEL_STREAMS,
	payload: channel,
});

/**
 * @param {Publication} publication
 * @returns {ChannelStreamAction}
 */
export const updateStreamAction = (publication) => ({
	type: UPDATE_STREAM,
	payload: publication,
});
