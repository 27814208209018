// @ts-check

/**
 * creates and returns a canvas
 * @param {{
 *   offscreen?: boolean,
 *   width?: number,
 *   height?: number,
 * }} param0
 * @returns {HTMLCanvasElement | OffscreenCanvas}
 */
export const createCanvas = ({
	offscreen = false,
	width = 0,
	height = 0,
}) => {
	if (offscreen && window.OffscreenCanvas) return new window.OffscreenCanvas(width, height);
	const canvas = document.createElement('canvas');
	canvas.width = width;
	canvas.height = height;
	return canvas;
};

/**
 * creates and returns a canvas and associated 2d context
 * @param {{
 *   offscreen?: boolean,
 *   width?: number,
 *   height?: number,
 *   contextOptions?: {},
 * }} param0
 * @returns {{
 *  canvas: HTMLCanvasElement | OffscreenCanvas,
 *  context: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D,
 * }}
 */
export const createCanvasAndContext = ({
	offscreen,
	width,
	height,
	contextOptions,
}) => {
	const canvas = createCanvas({
		offscreen,
		width,
		height,
	});

	const context = /** @type {CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D} */(
		canvas.getContext('2d', contextOptions));
	return { canvas, context };
};

/**
 *
 * @param {HTMLCanvasElement|OffscreenCanvas} canvas
 * @param {{
 *   type?: string,
 *   quality?: number,
 * }} options
 * @returns {Promise<Blob|null>}
 */
export const getBlob = (canvas, options) => {
	if (window.OffscreenCanvas && canvas instanceof window.OffscreenCanvas) {
		return canvas.convertToBlob(options);
	}
	return new Promise((resolve) => {
		/** @type {HTMLCanvasElement}*/(canvas).toBlob(
			(blob) => resolve(blob),
			options?.type,
			options?.quality,
		);
	});
};
