import PropTypes from 'prop-types';
import { Progress } from 'reactstrap';

export const MediaLoader = ({
	imageUploadProgress,
	videoUploadProgress,
}) => (
	<>
		{imageUploadProgress && (
			<Progress className="mt-3" value={imageUploadProgress.loaded} max={imageUploadProgress.total} />
		)}
		{videoUploadProgress && (
			<Progress className="mt-3" value={videoUploadProgress.loaded} max={videoUploadProgress.total} />
		)}
	</>
);

MediaLoader.propTypes = {
	imageUploadProgress: PropTypes.shape({
		loaded: PropTypes.number.isRequired,
		total: PropTypes.number.isRequired,
	}),
	videoUploadProgress: PropTypes.shape({
		loaded: PropTypes.number.isRequired,
		total: PropTypes.number.isRequired,
	}),
};

MediaLoader.defaultProps = {
	imageUploadProgress: undefined,
	videoUploadProgress: undefined,
};
