import { QueryCache, QueryClient } from 'react-query';

export default new QueryClient({
	defaultOptions: {
		queries: {
			retry: 0,
			staleTime: 30000, // 30 seconds
		},
		mutations: {
			retry: 0,
		},
	},
	// https://tkdodo.eu/blog/react-query-error-handling
	queryCache: new QueryCache({
		onError: (error, query) => {
			// only show error toasts if we already have data in the cache
			// which indicates a failed background update
			if (query.state.data !== undefined) {
				// toast.error(`Something went wrong: ${error.message}`);
			}
		},
	}),
});
