import { api } from '../api';
import { FiatCurrency } from '../../lib/currency';

const API_MEMBERSHIP_POINTS = '/membership/pi';

export const fetchPiTransactions = ({ startDate, endDate }) => api.get(
	`${API_MEMBERSHIP_POINTS}/transaction`,
	{ params: {
		startDate,
		endDate,
	} },
);

export const getPiBundles = () => api.get(`${API_MEMBERSHIP_POINTS}/bundle`);

export const getPiBundle = (bundleId) => api.get(`${API_MEMBERSHIP_POINTS}/bundle/${bundleId}`);

export const fetchPointsRatio = (orderBundleType, currency = FiatCurrency.USD) => api.get(
	`${API_MEMBERSHIP_POINTS}/ratio/${orderBundleType}/${currency}`,
);
