/* eslint-disable react/prop-types */
// @ts-check

import { useCallback, useState } from 'react';
import { Modal } from 'reactstrap';
import { useAsyncCallback } from 'react-async-hook';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-jsonschema-form-validation';

import { useAsyncErrorLog } from '../../../lib/hooks';
import { useStudio } from '../Context';
import { ButtonLoading, ButtonPill, ButtonPillOutline } from '../../Button';
import { useActiveStudio } from '../Active/Context';
import { StudioScheduleSettingsFormSocialNetworkSharing } from '../../StudioSchedule/SettingsForm/SocialNetworkSharing';
import { useUpdateStudioSchedule } from '../../../api-hooks/studio/schedule';
import { AlertTimeout } from '../../Alert/Timeout';
import { studioScheduleSimulCastsSchema } from '../../StudioSchedule/Manager/Edit.schema';

/** @typedef {import('../../../api/channel/simulCast.dto').ISimulCastDto} ISimulCastDto */

const LIVE_RECORDING_START_DELAY_SECONDS = 20;

/**
 * @param {ISimulCastDto[]} fromWebapp
 * @param {ISimulCastDto[]} fromDatabase
 * @returns {boolean}
 */
const areSimulCastsArraysEqual = (fromWebapp, fromDatabase) => {
	if (fromWebapp.length !== fromDatabase.length) return false;

	return fromWebapp.every((simul1) => fromDatabase.some((simul2) => simul1.name === simul2.name
		&& simul1.url === simul2.url
		&& simul1.streamKey === simul2.streamKey));
};

const formSimulCastsSchema = {
	type: 'object',
	additionalProperties: false,
	properties: {
		simulCast: studioScheduleSimulCastsSchema,
	},
	required: [
		'simulCast',
	],
};

/**
 * @typedef {{
* 	isOpen: boolean,
* 	toggle: () => void,
* }} StudioGoLiveModalProps
*/

export const StudioGoLiveModal = (
	/** @type {StudioGoLiveModalProps} */
	{
		isOpen,
		toggle,
	},
) => {
	const { t } = useTranslation();
	const { golive, studio, updateStudio } = useStudio();
	const { updateActiveStudio } = useActiveStudio();

	const {
		mutateAsync: updateStudioSchedule,
		error: updateStudioScheduleError,
		isLoading: isLoadingUpdateStudioSchedule,
		reset: resetUpdateStudioSchedule,
	} = useUpdateStudioSchedule();

	// Put simulcast array into an object for form validation
	const [simulCastsData, setSimulCastsData] = useState({ simulCast: studio.simulCast });
	const isEditingSimulCasts = !areSimulCastsArraysEqual(simulCastsData.simulCast, studio.simulCast);

	const asyncGoLive = useAsyncCallback(async () => {
		const updatedStudio = await golive(
			new Date(Date.now() + LIVE_RECORDING_START_DELAY_SECONDS * 1000),
		);
		updateActiveStudio(updatedStudio);
		toggle();
	});
	useAsyncErrorLog(asyncGoLive);

	const updateSimulCasts = useCallback(async () => {
		await updateStudioSchedule({ id: studio._id, studioSchedule: simulCastsData }, {
			onSuccess: (updatedStudio) => {
				updateActiveStudio(updatedStudio);
				updateStudio(updatedStudio);
				setSimulCastsData({ simulCast: updatedStudio.simulCast });
			},
		});
	}, [simulCastsData, studio._id, updateActiveStudio, updateStudio, updateStudioSchedule]);

	const resetSubmit = useCallback(() => {
		resetUpdateStudioSchedule();
		asyncGoLive.reset();
	}, [asyncGoLive, resetUpdateStudioSchedule]);

	const isError = updateStudioScheduleError || asyncGoLive.error;
	const errorMessage = /** @type {import('axios').AxiosError} */(updateStudioScheduleError)?.response?.data?.message || t('Global.error');

	const isSimulCastEditable = studio.isPublic && !studio.hasLiveAlreadyStarted;

	return (
		<Modal
			centered
			contentClassName="border-0 rounded overflow-hidden bg-transparent align-self-center"
			isOpen={isOpen}
			toggle={toggle}
			zIndex={2000}
		>
			<div className="bg-dark shadow-lg" id="Session_SocialNetworkSharing">
				<div className="mt-4 w-100 text-center">
					<h4>{t('GoLive.Modal.startNow')}</h4>
					{!studio.isPublic && (
						<p>{t('GoLive.Modal.studioUpdgradeToPublic')}</p>
					)}
				</div>
				{isError && (
					<AlertTimeout className="mx-3" color="danger" onTimeout={resetSubmit}>
						{errorMessage}
					</AlertTimeout>
				)}
				<div className="mb-4 d-flex justify-content-evenly">
					<ButtonPillOutline
						onClick={toggle}
						color="secondary"
					>
						{t('GoLive.Modal.cancel')}
					</ButtonPillOutline>
					<ButtonLoading
						color={!isEditingSimulCasts ? 'primary' : 'light'}
						component={ButtonPill}
						loading={asyncGoLive.loading}
						onClick={asyncGoLive.execute}
						disabled={isEditingSimulCasts}
					>
						{t('GoLive.Modal.goLive')}
					</ButtonLoading>
				</div>
				<Form
					data={simulCastsData}
					id="GoLiveModalFormSimulCasts"
					onSubmit={updateSimulCasts}
					schema={formSimulCastsSchema}
				>
					<div className="mb-4 mx-4 form-light">
						<div className="divider bg-secondary" />
						<div className="d-flex mt-3 align-items-center justify-content-between">
							<h4>{t('GoLive.Modal.socialNetworks')}</h4>
							<ButtonLoading
								color={isEditingSimulCasts ? 'primary' : 'light'}
								component={ButtonPill}
								disabled={!isEditingSimulCasts}
								form="GoLiveModalFormSimulCasts"
								loading={isLoadingUpdateStudioSchedule}
								size="sm"
							>
								{t('GoLive.Modal.save')}
							</ButtonLoading>
						</div>
						<div>
							<p className="font-size-sm">{t('GoLive.Modal.streamKeys')}</p>
							<StudioScheduleSettingsFormSocialNetworkSharing
								isEditable={isSimulCastEditable}
								onChange={(simulCast) => setSimulCastsData({ simulCast })}
								selectedSimulCasts={simulCastsData.simulCast}
								studioOwnerId={studio.owner._id}
							/>
						</div>
					</div>
				</Form>
			</div>
		</Modal>
	);
};
