/* eslint-disable react/prop-types */
// @ts-check

import { useCallback } from 'react';
import 'react-multi-email/style.css';
import { Button, Col, Modal, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useAsyncCallback } from 'react-async-hook';
import { ButtonLoading } from '../Button';
import { useCurrentStudio } from './useCurrentStudio';

/**
 * @typedef {{
 * 	isOpen: boolean,
 * 	stopActiveStudio: () => void,
 * 	toggle: () => void,
 * 	studioLeaveHandlers: {
* 		onCancel?: () => void,
 * 		onConfirm?: () => void,
 * 	},
 * }} StudioModalLeaveConfirmationProps
 */

export const StudioModalLeaveConfirmation = (
	/** @type {StudioModalLeaveConfirmationProps} */
	{
		isOpen,
		stopActiveStudio,
		toggle,
		studioLeaveHandlers,
	},
) => {
	const { t } = useTranslation();

	const { isCurrentStudioMine, isCurrentStudioOperatedByMe } = useCurrentStudio();

	const isController = isCurrentStudioMine || isCurrentStudioOperatedByMe;

	const handleConfirmAndLeaveStudioRunning = useCallback(() => {
		if (studioLeaveHandlers.onConfirm) studioLeaveHandlers.onConfirm();
		toggle();
	}, [studioLeaveHandlers, toggle]);

	const handleConfirmAndEndStudio = useAsyncCallback(async () => {
		await stopActiveStudio();
		if (studioLeaveHandlers.onConfirm) studioLeaveHandlers.onConfirm();
		toggle();
	});

	const handleCancelLeave = useCallback(() => {
		if (studioLeaveHandlers.onCancel) studioLeaveHandlers.onCancel();
		toggle();
	}, [studioLeaveHandlers, toggle]);

	return (
		<Modal
			centered
			contentClassName="border-0 rounded overflow-hidden bg-transparent align-self-center"
			isOpen={isOpen}
			toggle={handleCancelLeave}
			zIndex={2000}
			size="lg"
		>
			<div className="bg-dark shadow-lg">
				<Row className="g-0">
					<Col className="d-flex mx-auto rounded overflow-hidden p-relative">
						<div className="py-5 mx-auto">
							<h4 className="mb-4 text-center">{t('Studio.ModalLeaveConfirmation.areYouSureYouWantToLeave')}</h4>
							<div className="d-flex w-100 justify-content-center">
								{isController ? (
									<>
										<ButtonLoading
											className="mt-2"
											color="danger"
											onClick={handleConfirmAndEndStudio.execute}
											loading={handleConfirmAndEndStudio.loading}
										>
											{t('Studio.ModalLeaveConfirmation.yesEndStudio')}
										</ButtonLoading>
										<Button
											className="mt-2 mx-3"
											color="danger"
											onClick={handleConfirmAndLeaveStudioRunning}
											disabled={handleConfirmAndEndStudio.loading}
										>
											{t('Studio.ModalLeaveConfirmation.yesLeaveStudioRunning')}
										</Button>
									</>
								) : (
									<ButtonLoading
										className="mt-2 mr-3"
										color="danger"
										onClick={handleConfirmAndLeaveStudioRunning}
									>
										{t('Studio.ModalLeaveConfirmation.yes')}
									</ButtonLoading>
								)}
								<Button
									className="mt-2"
									color="primary"
									disabled={handleConfirmAndEndStudio.loading}
									onClick={handleCancelLeave}
								>
									{t('Studio.ModalLeaveConfirmation.no')}
								</Button>
							</div>
						</div>
					</Col>
				</Row>
			</div>
		</Modal>
	);
};
