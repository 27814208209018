import { api } from '../api';

const API_IDENTITY_AUTHENTICATION_PATH = '/identity/authentication';

export const fetchOrganizationMemberInviteById = async (inviteId) => api.get(
	`${API_IDENTITY_AUTHENTICATION_PATH}/organization/invites/${inviteId}`,
);

export const switchToOrganizationPublicChannel = async () => api.post(
	`${API_IDENTITY_AUTHENTICATION_PATH}/organization/channel/switch`,
);
