import { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { isMobile } from '../../lib/userAgent';
import { useMixing } from '../ReactVideo/Mixing';
import { useDrawer } from './PlayerCanvasDrawerProvider';

const CANVAS_SIZE = { width: 1280, height: 720 };
const DRAWING_FREQ = 150;
const instructions = [];
let mouseClickPos = { x: 0, y: 0 };

const get2DDistance = (pointA, pointB) => (
	Math.sqrt(
		(pointB.x - pointA.x) ** 2 + (pointB.y - pointA.y) ** 2,
	)
);

const Canvas = ({ size }) => {
	const canvasRef = useRef(null);
	const [clearCanvasTimeout, setClearCanvasTimeout] = useState(undefined);
	const { notifyNewDrawing } = useMixing();
	const { config, drawMode } = useDrawer();
	const [isDrawingMode, setIsDrawingMode] = useState(false);
	const [rect, setRect] = useState(undefined);
	const [circle, setCircle] = useState(undefined);

	useEffect(() => {
		const canvas = canvasRef.current;
		const context = canvas?.getContext('2d');
		let canvasRect;
		if (isMobile) {
			canvasRect = canvas?.getBoundingClientRect();
		}

		const xFactor = CANVAS_SIZE.width / size.width;
		const yFactor = CANVAS_SIZE.height / size.height;

		const sendInterval = setInterval(() => {
			if (instructions.length > 0) {
				notifyNewDrawing({ instructions, config, size: CANVAS_SIZE });
				instructions.splice(0, instructions.length);
			}
			if (isDrawingMode) return;
			if (rect) {
				notifyNewDrawing({
					instructions: [{
						type: 'rect',
						data: rect,
					}],
					config,
					size: CANVAS_SIZE,
				});
				setRect(undefined);
			}
			if (circle) {
				notifyNewDrawing({
					instructions: [{
						type: 'circle',
						data: circle,
					}],
					config,
					size: CANVAS_SIZE,
				});
				setCircle(undefined);
			}
		}, DRAWING_FREQ);

		const handleDrawRect = (mousePosition) => {
			context.clearRect(
				0,
				0,
				canvas.width,
				canvas.height,
			);
			context.strokeRect(
				mouseClickPos.x,
				mouseClickPos.y,
				mousePosition.x - mouseClickPos.x,
				mousePosition.y - mouseClickPos.y,
			);
			context.fillRect(
				mouseClickPos.x,
				mouseClickPos.y,
				mousePosition.x - mouseClickPos.x,
				mousePosition.y - mouseClickPos.y,
			);
			setRect({
				x: mouseClickPos.x,
				y: mouseClickPos.y,
				width: mousePosition.x - mouseClickPos.x,
				height: mousePosition.y - mouseClickPos.y,
			});
		};

		const handleDrawPen = (mousePosition) => {
			context.lineTo(mousePosition.x, mousePosition.y);
			context.stroke();
			instructions.push({
				type: 'lineTo',
				data: {
					x: mousePosition.x,
					y: mousePosition.y,
				},
			});
		};

		const handleDrawCircle = (mousePosition) => {
			const radius = get2DDistance(mouseClickPos, mousePosition) / 2;

			const circleCenterX = mouseClickPos.x + (mousePosition.x - mouseClickPos.x) / 2;
			const circleCenterY = mouseClickPos.y + (mousePosition.y - mouseClickPos.y) / 2;

			context.beginPath();
			context.clearRect(0, 0, canvas.width, canvas.height);
			context.arc(
				circleCenterX,
				circleCenterY,
				radius + 1,
				0,
				2 * Math.PI,
			);
			context.closePath();
			context.stroke();
			context.beginPath();
			context.arc(
				circleCenterX,
				circleCenterY,
				radius,
				0,
				2 * Math.PI,
			);
			context.closePath();
			context.fill();
			setCircle({
				x: circleCenterX,
				y: circleCenterY,
				radius,
			});
		};

		const handleEraser = (mousePosition) => {
			instructions.push({
				type: 'eraser',
				data: {
					x: mousePosition.x,
					y: mousePosition.y,
				},
			});
		};

		const mouseUp = () => {
			setIsDrawingMode(false);
			setClearCanvasTimeout(setTimeout(() => {
				context.clearRect(0, 0, 1280, 720);
			}, 2000));
		};

		const mouseDown = (e) => {
			if (isMobile) {
				e.offsetX = e.touches[0].clientX - canvasRect.left;
				e.offsetY = e.touches[0].clientY - canvasRect.top;
			}
			const mousePosition = {
				x: e.offsetX * xFactor,
				y: e.offsetY * yFactor,
			};
			if (clearTimeout) {
				clearInterval(clearCanvasTimeout);
				setClearCanvasTimeout(undefined);
			}
			setIsDrawingMode(true);
			context.lineWidth = config.penWidth;
			context.strokeStyle = config.colorPrimary;
			context.fillStyle = config.colorSecondary;

			switch (drawMode) {
			case 'pen':
				context.beginPath();
				context.moveTo(mousePosition.x, mousePosition.y);
				instructions.push({
					type: 'moveTo',
					data: {
						x: mousePosition.x,
						y: mousePosition.y,
					},
				});
				break;
			case 'shape.rect':
				mouseClickPos = mousePosition;
				break;
			case 'shape.circle':
				mouseClickPos = mousePosition;
				break;
			case 'eraser':
				context.beginPath();
				context.moveTo(mousePosition.x, mousePosition.y);
				instructions.push({
					type: 'eraser.moveTo',
					data: {
						x: mousePosition.x,
						y: mousePosition.y,
					},
				});
				break;
			default: break;
			}
		};

		const mouseMove = (e) => {
			if (isMobile) {
				e.preventDefault();
				e.offsetX = e.touches[0].clientX - canvasRect.left;
				e.offsetY = e.touches[0].clientY - canvasRect.top;
			}
			const mousePosition = {
				x: e.offsetX * xFactor,
				y: e.offsetY * yFactor,
			};
			if (!isDrawingMode) return;

			switch (drawMode) {
			case 'pen':
				handleDrawPen(mousePosition);
				break;
			case 'shape.rect':
				handleDrawRect(mousePosition);
				break;
			case 'shape.circle':
				handleDrawCircle(mousePosition);
				break;
			case 'eraser':
				handleEraser(mousePosition);
				break;
			default: break;
			}
		};

		if (context) {
			if (isMobile) {
				canvas.addEventListener('touchmove', mouseMove);
				document.addEventListener('touchend', mouseUp);
				canvas.addEventListener('touchstart', mouseDown);
			} else {
				canvas.addEventListener('mousemove', mouseMove);
				document.addEventListener('mouseup', mouseUp);
				canvas.addEventListener('mousedown', mouseDown);
			}
		}

		// eslint-disable-next-line consistent-return
		return () => {
			clearInterval(sendInterval);
			if (canvas) {
				if (isMobile) {
					canvas.removeEventListener('touchmove', mouseMove);
					document.removeEventListener('touchend', mouseUp);
					canvas.removeEventListener('mousedown', mouseDown);
				} else {
					canvas.removeEventListener('mousemove', mouseMove);
					document.removeEventListener('mouseup', mouseUp);
					canvas.removeEventListener('mousedown', mouseDown);
				}
			}
		};
	}, [
		clearCanvasTimeout,
		isDrawingMode,
		notifyNewDrawing,
		config,
		size,
		drawMode,
		rect,
		circle,
	]);

	return (
		<canvas
			style={{
				cursor: 'crosshair',
				height: size.height,
				width: size.width,
				left: size.left,
				zIndex: 11,
			}}
			width={CANVAS_SIZE.width}
			height={CANVAS_SIZE.height}
			className="PlayerCanvas position-absolute"
			ref={canvasRef}
		/>
	);
};

Canvas.propTypes = {
	size: PropTypes.shape({
		width: PropTypes.number,
		height: PropTypes.number,
		top: PropTypes.number,
		left: PropTypes.number,
	}),
};

Canvas.defaultProps = {
	size: { width: 0, height: 0 },
};

export default Canvas;
