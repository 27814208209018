import PropTypes from 'prop-types';

export const LogoComplement = ({
	hidden,
	text,
}) => (!hidden
	? (
		<div className="d-none d-md-block position-relative font-size-md w-100">
			<span className="content-darker">
				{text}
			</span>
		</div>
	)
	: null);
LogoComplement.propTypes = {
	hidden: PropTypes.bool,
	text: PropTypes.string,
};

LogoComplement.defaultProps = {
	hidden: false,
	text: '',
};
