// @ts-check

const ROOT = '/a/admin';
/**
 * @param {string} path
 * @returns {string}
 */
export const getAdminPath = (path) => `${ROOT}${path}`;

const ANALYTICS_ROOT = getAdminPath('/analytics');
/**
 * @param {string} path
 * @returns {string}
 */
export const getAnalyticsPath = (path) => `${ANALYTICS_ROOT}${path}`;

/** @enum {string} */
export const AdminPath = {
	ROOT,
	CATEGORIES: getAdminPath('/categories'),
	TAGS: getAdminPath('/tags'),
	TRANSACTIONS: getAdminPath('/transactions'),
	USERS: getAdminPath('/users'),
	PAGEVIEWS: getAdminPath('/pageviews'),
	MEMBERSHIPS: getAdminPath('/memberships'),
	VODS: getAdminPath('/vods'),
	STUDIO_SETTINGS: getAdminPath('/studios/settings'),
	STUDIO_SUMMARY: getAdminPath('/studios/summary/:id'),
	STUDIO: getAdminPath('/studios/:id'),
	STUDIOS: getAdminPath('/studios'),
	CHANNEL: getAdminPath('/channels/:id'),
	CHANNELS: getAdminPath('/channels'),
	USER_REPORTS: getAdminPath('/user-reports'),
	VOD_REPORTS: getAdminPath('/vod-reports'),
	GUEST_REPORTS: getAdminPath('/guest-reports'),
	CASHOUTS: getAdminPath('/cashouts'),
	ORGANIZATION_PRICING: getAdminPath('/organization/pricing'),
	ADS_CAMPAIGNS: getAdminPath('/ads'),
	ADS_SETTINGS: getAdminPath('/ads/settings'),
	NEWSLETTER: getAdminPath('/newsletter'),
	ANALYTICS_ROOT,
	ANALYTICS_ADS: getAnalyticsPath('/ads'),
	ANALYTICS_FINANCE: getAnalyticsPath('/finance'),
	ANALYTICS_MARKETING: getAnalyticsPath('/marketing'),
	ANALYTICS_MODERATION: getAnalyticsPath('/moderation'),
	ANALYTICS_RESEARCH: getAnalyticsPath('/research'),
};
