import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// TODO: eslint path config ?
// eslint-disable-next-line import/no-unresolved
import { ReactComponent as BeeyouLogo } from '../../../images/logo.@@PROJECT.svg';
// eslint-disable-next-line import/no-unresolved
import { ReactComponent as BeeyouRedLogo } from '../../../images/@@PROJECT-red.svg';
import { getFileUrl } from '../../../lib/file';
import { useCurrentStudio } from '../../../components/Studio/useCurrentStudio';
import Hexagon from '../../../components/Hexagon/Hexagon';
import { LogoComplement } from './LogoComplement';
import { useProfile } from '../../../components/Profile/ProfileContext';

const StudioInactiveLogo = () => {
	const { profile } = useProfile();
	const { t } = useTranslation();

	const organization = profile?.organization;

	return (!organization?.default && !!organization?.logo
		? (
			<Hexagon className="avatar-icon small shadow-none">
				<img
					alt={t('Profile.Avatar.avatar')}
					src={getFileUrl({ name: organization?.logo ?? '' })}
				/>
			</Hexagon>
		)
		: <BeeyouLogo className="mr-2 content-dark" height="45px" width="45px" />
	);
};

export const SidebarHeaderLogo = ({ isStudioRoute }) => {
	const { profile } = useProfile();

	const organization = profile?.organization;
	const isDefaultOrganization = !!organization?.default;

	const {
		isCurrentStudioStarted,
		isCurrentStudioMine,
	} = useCurrentStudio();

	const logoComplementText = useMemo(() => (
		// eslint-disable-next-line no-nested-ternary
		import.meta.env.VITE_PROJECT === 'beeyou'
			? !!organization && !isDefaultOrganization ? `${organization?.name ?? ''} - ORG` : 'beeyou.tv'
			: 'COMMANDO VISION'
	), [isDefaultOrganization, organization]);

	return (
		<div className="nav-logo">
			<a className="w-100 justify-content-start">
				{isCurrentStudioStarted && isCurrentStudioMine
					? <BeeyouRedLogo className="mr-2" height="45px" width="45px" />
					: <StudioInactiveLogo />}
				<LogoComplement
					text={logoComplementText}
					hidden={isStudioRoute}
				/>
			</a>
		</div>
	);
};

SidebarHeaderLogo.propTypes = {
	isStudioRoute: PropTypes.bool,
};

SidebarHeaderLogo.defaultProps = {
	isStudioRoute: false,
};
