// @ts-check

/**
 *
 *
 *
 * KEEP IN SYNC WITH WEBRTC-ROUTER
 *
 *
 */

/**
 * @typedef {{
 * 	width: number,
 * 	height: number
 * }} OrientationBaseSize
 */

/**
 * @type {{
 * 	PORTRAIT: OrientationBaseSize,
 * 	LANDSCAPE: OrientationBaseSize
 * }}
 */
export const OrientationBaseSizes = {
	PORTRAIT: {
		width: 1080,
		height: 1920,
	},
	LANDSCAPE: {
		width: 1920,
		height: 1080,
	},
};

/**
 * @param {number} index
 * @returns {string}
 */
const indexToPipName = (index) => {
	if (index === 0) return 'main';
	return `pip${index}`;
};

/**
 * @param {OrientationBaseSize} videoSize
 * @param {number} rows
 * @param {number} cols
 * @param {number} [padding=10]
 * @returns {Array<{
 * 	x: number,
 * 	y: number,
 * 	width: number,
 * 	height: number,
 * 	hotkeySwitch: {
 * 		up?: string,
 * 		down?: string,
 * 		left?: string,
 * 		right?: string,
 * 	},
 * }>}
 */
const calculateMosaic = (
	videoSize,
	rows,
	cols,
	padding = 10,
) => {
	const mosaic = [];
	const divider = rows > cols ? rows : cols;
	for (let row = 0; row < rows; row += 1) {
		for (let col = 0; col < cols; col += 1) {
			let x = col * (videoSize.width / divider);
			let y = row * (videoSize.height / divider);
			const width = videoSize.width / divider;
			const height = videoSize.height / divider;
			if (cols * width < videoSize.width) {
				x += (videoSize.width - cols * width) / 2;
			}
			if (rows * height < videoSize.height) {
				y += (videoSize.height - rows * height) / 2;
			}
			mosaic.push({
				x,
				y,
				width: width - padding,
				height: height - padding,
				hotkeySwitch: {
					up: row !== 0 ? indexToPipName(((row - 1) * cols) + col) : undefined,
					down: row !== (rows - 1) ? indexToPipName(((row + 1) * cols) + col) : undefined,
					left: col !== 0 ? indexToPipName((row * cols) + col - 1) : undefined,
					right: col !== (cols - 1) ? indexToPipName((row * cols) + col + 1) : undefined,
				},
			});
		}
	}
	return mosaic;
};

/**
 * @param {number} mergerWidth
 * @param {number} mergerHeight
 * @returns {{ width: number, height: number }}
 */
const getPipDefaultDimensions = (mergerWidth, mergerHeight) => ({
	width: Math.floor(mergerWidth * (1 / 4)),
	height: Math.floor(mergerHeight * (1 / 4)),
});

/**
 * @param {number} mergerWidth
 * @param {number} mergerHeight
 * @returns {{ horizontal: number, vertical: number }}
 * @see https://www.itu.int/dms_pubrec/itu-r/rec/bt/R-REC-BT.1848-1-201510-I!!PDF-E.pdf
 */
export const getSafeZoneMargin = (mergerWidth, mergerHeight) => ({
	horizontal: Math.floor(mergerWidth * (5 / 100)), // 5% of the width (approx 96px on 1920px)
	vertical: Math.floor(mergerHeight * (5 / 100)), // 5% of the height (approx 54px on 1080px)
});

/**
 * @param {number} pipWidth
 * @param {number} pipHeight
 * @param {number} mergerWidth
 * @param {number} mergerHeight
 * @returns {{ x: number, y: number }}
 */
const getDefaultPip1Position = (
	pipWidth,
	pipHeight,
	mergerWidth,
	mergerHeight,
) => ({
	x: getSafeZoneMargin(mergerWidth, mergerHeight).horizontal,
	y: mergerHeight - pipHeight - getSafeZoneMargin(mergerWidth, mergerHeight).vertical,
});

/**
 * @param {number} pipWidth
 * @param {number} pipHeight
 * @param {number} mergerWidth
 * @param {number} mergerHeight
 * @returns {{ x: number, y: number }}
 */
const getDefaultPip2Position = (
	pipWidth,
	pipHeight,
	mergerWidth,
	mergerHeight,
) => ({
	x: mergerWidth - pipWidth - getSafeZoneMargin(mergerWidth, mergerHeight).horizontal,
	y: mergerHeight - pipHeight - getSafeZoneMargin(mergerWidth, mergerHeight).vertical,
});

/**
 * @returns {Object.<string, {
 *  autoFill: boolean,
 * 	automaticSwitchAllowed: boolean,
 * 	layers: Array<{
 * 		x: number,
 * 		y: number,
 * 		width: number,
 * 		height: number
 * 		hotkeySwitch?: {
 * 			up?: string,
 * 			down?: string,
 * 			left?: string,
 * 			right?: string,
 * 		},
 * }>,
 * }>}
 */
export const getLayoutsDefaults = (
	/** @type {{
	 * 	width: number,
	* 	height: number,
	 * }}
	 */
	videoSize,
) => {
	const { width, height } = videoSize;

	const defaultPipDimension = getPipDefaultDimensions(width, height);
	const defaultPip1Position = getDefaultPip1Position(
		defaultPipDimension.width,
		defaultPipDimension.height,
		width,
		height,
	);
	const defaultPip2Position = getDefaultPip2Position(
		defaultPipDimension.width,
		defaultPipDimension.height,
		width,
		height,
	);

	const centerAllRightPadding = (height - 5 * (height * 0.2)) / 5;

	return {
		DEFAULT_MODE: {
			autoFill: false,
			automaticSwitchAllowed: true,
			layers: [
				{
					x: 0,
					y: 0,
					width,
					height,
					hotkeySwitch: { down: 'pip1', left: 'pip1', right: 'pip2' },
				},
				{
					...defaultPip1Position,
					...defaultPipDimension,
					hotkeySwitch: { up: 'main', right: 'pip2' },
				},
				{
					...defaultPip2Position,
					...defaultPipDimension,
					hotkeySwitch: { up: 'main', left: 'pip1' },
				},
			],
		},
		HALF_RATIO: {
			autoFill: false,
			automaticSwitchAllowed: true,
			layers: [
				{
					x: 0,
					y: (height - (height / 2 - 10)) / 2,
					width: width / 2 - 10,
					height: (height / 2 - 10),
					hotkeySwitch: { right: 'pip1' },
				},
				{
					x: width - (width / 2 - 10),
					y: (height - (height / 2 - 10)) / 2,
					width: width / 2 - 10,
					height: height / 2 - 10,
					hotkeySwitch: { left: 'main' },
				},
			],
		},
		HALF_THIRD_THIRD: {
			autoFill: false,
			automaticSwitchAllowed: true,
			layers: [
				{
					x: 0,
					y: 0,
					width: width / 2 - 10,
					height,
					hotkeySwitch: { right: 'pip1', down: 'pip2', up: 'pip1' },
				},
				{
					x: width - (width / 2 - 10),
					y: 0,
					width: width / 2 - 10,
					height: height / 2 - 10,
					hotkeySwitch: { down: 'pip2', left: 'main' },
				},
				{
					x: width - (width / 2 - 10),
					y: height - (height / 2 - 10),
					width: width / 2 - 10,
					height: height / 2 - 10,
					hotkeySwitch: { left: 'main', up: 'pip1' },
				},
			],
		},
		CENTER_ALL_BOTTOM: {
			autoFill: false,
			automaticSwitchAllowed: true,
			layers: [
				{
					x: (width - (width * 0.75)) / 2,
					y: 0,
					width: width * 0.75,
					height: height * 0.75,
					hotkeySwitch: { down: 'pip3', left: 'pip1', right: 'pip5' },
				},
				{
					x: 0,
					y: height * 0.75 + 10,
					width: width / 5 - 10,
					height: height / 5 - 10,
					hotkeySwitch: { right: 'pip2', up: 'main' },
				},
				{
					x: (width - 4 * (width * 0.2)),
					y: height * 0.75 + 10,
					width: width / 5 - 10,
					height: height / 5 - 10,
					hotkeySwitch: { left: 'pip1', right: 'pip3', up: 'main' },
				},
				{
					x: (width - 3 * (width * 0.2)),
					y: height * 0.75 + 10,
					width: width / 5 - 10,
					height: height / 5 - 10,
					hotkeySwitch: { left: 'pip2', right: 'pip4', up: 'main' },
				},
				{
					x: (width - 2 * (width * 0.2)),
					y: height * 0.75 + 10,
					width: width / 5 - 10,
					height: height / 5 - 10,
					hotkeySwitch: { left: 'pip3', right: 'pip5', up: 'main' },
				},
				{
					x: (width - 1 * (width * 0.2)),
					y: height * 0.75 + 10,
					width: width / 5 - 10,
					height: height / 5 - 10,
					hotkeySwitch: { left: 'pip4', up: 'main' },
				},
			],
		},
		CENTER_ALL_RIGHT: {
			autoFill: false,
			automaticSwitchAllowed: true,
			layers: [
				{
					x: 0,
					y: (height - (height * 0.75 - 10)) / 2,
					width: width * 0.75 - 10,
					height: height * 0.75 - 10,
					hotkeySwitch: { down: 'pip4', right: 'pip1', up: 'pip1' },
				},
				{
					x: width * 0.75,
					y: centerAllRightPadding,
					width: width * 0.2,
					height: (height * 0.2),
					hotkeySwitch: { down: 'pip2', left: 'main' },
				},
				{
					x: width * 0.75,
					y: height - 4 * (height * 0.2) - 4 * centerAllRightPadding,
					width: width * 0.2,
					height: height * 0.2,
					hotkeySwitch: { down: 'pip3', left: 'main', up: 'pip1' },
				},
				{
					x: width * 0.75,
					y: height - 3 * (height * 0.2) - 3 * centerAllRightPadding,
					width: width * 0.2,
					height: height * 0.2,
					hotkeySwitch: { down: 'pip4', left: 'main', up: 'pip2' },
				},
				{
					x: width * 0.75,
					y: height - 2 * (height * 0.2) - 2 * centerAllRightPadding,
					width: width * 0.2,
					height: height * 0.2,
					hotkeySwitch: { down: 'pip5', left: 'main', up: 'pip3' },
				},
				{
					x: width * 0.75,
					y: height - 1 * (height * 0.2) - centerAllRightPadding,
					width: width * 0.2,
					height: height * 0.2,
					hotkeySwitch: { left: 'main', up: 'pip4' },
				},
			],
		},
		MOSAIC_QUAD: {
			autoFill: true,
			automaticSwitchAllowed: true,
			layers: calculateMosaic(videoSize, 2, 2),
		},
		MOSAIC_SIX: {
			autoFill: true,
			automaticSwitchAllowed: false,
			layers: calculateMosaic(videoSize, 2, 3),
		},
		MOSAIC_TWELVE: {
			autoFill: true,
			automaticSwitchAllowed: false,
			layers: calculateMosaic(videoSize, 3, 4),
		},
		MOSAIC_SIXTEEN: {
			autoFill: true,
			automaticSwitchAllowed: false,
			layers: calculateMosaic(videoSize, 4, 4),
		},
		FULL_CANVAS: {
			autoFill: false,
			automaticSwitchAllowed: true,
			layers: [
				{
					x: 0,
					y: 0,
					width,
					height,
				},
			],
		},
	};
};

export const DEFAULT_MODE = 'DEFAULT_MODE';
