import { createContext, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

export const GfxContext = createContext({});
export const useGfx = () => useContext(GfxContext);

export const GfxProvider = ({
	children,
}) => {
	const [gfxInEdition, setGfxInEdition] = useState(undefined);

	const contextValue = useMemo(() => ({
		gfxInEdition,
		setGfxInEdition,
	}), [
		gfxInEdition,
		setGfxInEdition,
	]);

	return (
		<GfxContext.Provider value={contextValue}>
			{children}
		</GfxContext.Provider>
	);
};

GfxProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
