import PropTypes from 'prop-types';
import {
	Dropdown,
	DropdownMenu,
	DropdownToggle,
	ListGroup,
	ListGroupItem,
	Nav,
	NavItem,
	NavLink,
} from 'reactstrap';
import { Link, useMatch } from 'react-router-dom';
import { FaEllipsisV, FaHome } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

import { useCurrentStudio } from '../../components/Studio/useCurrentStudio';
import { useStudio } from '../../components/Studio/Context';
import { StudioStatusManager } from '../../components/Studio/StatusManager';
import { useAuthentication } from '../../components/Authentication/Authentication';
import { SidebarHeaderCollapse } from './Collapse';
import { SidebarHeaderLogo } from './Logo/Logo';
import { SidebarHeaderItem } from './Item/Item';
import { useSidebar } from '../Sidebar/Provider';
import { useSidebarItems } from '../Sidebar/useSidebarItems';
import { StudioHeaderStudioItems } from './StudioItems';
import './SidebarHeader.scss';

const isBeeyou = import.meta.env.VITE_PROJECT === 'beeyou';

const SidebarHeader = ({ isStudioRoute, sideBar }) => {
	const { openSidebarMenu, toggleSidebarMenu } = useSidebar();
	const { t } = useTranslation();

	const isHomeRoute = useMatch({ path: '/', end: true });

	const {
		secondarySidebarItems,
		helpCenterItems,
		helpCenterParentItem,
		aboutParentItem,
		aboutSidebarItems,
	} = useSidebarItems();

	const { user } = useAuthentication();

	const {
		currentStudio,
		isCurrentStudioStarted,
	} = useCurrentStudio();

	const { controlroomActive } = useStudio();

	return (
		<div className="app-sidebar--header px-0 w-100">
			{!isCurrentStudioStarted && sideBar}
			<div className="d-flex align-items-center flex-fill mw-100">
				{!isStudioRoute || !isCurrentStudioStarted ? (
					<Dropdown
						className="HeaderUserbox user-box position-relative ml-2"
						isOpen={openSidebarMenu}
						toggle={toggleSidebarMenu}
					>
						<DropdownToggle
							color="link"
							className="p-0 text-left d-flex align-items-center"
						>
							<span className="pr-1">
								<FaEllipsisV className="text-second" />
							</span>
							<SidebarHeaderLogo isStudioRoute={isStudioRoute} />
						</DropdownToggle>
						<DropdownMenu end className="dropdown-menu-xl mt-1 overflow-hidden shadow-none border border-secondary py-0 bg-transparent">
							<ListGroup flush className="text-left bg-transparent">
								<ListGroupItem className="rounded-top bg-light border-white-light p-2">
									<Nav pills className="nav-neutral-secondary flex-column">
										{!isHomeRoute && (
											<NavItem>
												<NavLink
													onClick={() => { toggleSidebarMenu(); }}
													tag={Link}
													to="/"
												>
													<span className="mr-2">
														<FaHome className="icon" />
													</span>
													{t('SidebarHeader.Dropdown.home')}
												</NavLink>
											</NavItem>
										)}
										{!!user && (
											<StudioHeaderStudioItems />
										)}
										{secondarySidebarItems.map((menuItem) => menuItem && (
											<SidebarHeaderItem
												key={menuItem.label}
												menuItem={menuItem}
												toggleDropdown={toggleSidebarMenu}
											/>
										))}
										<SidebarHeaderCollapse
											parentItem={helpCenterParentItem}
											childrenItems={helpCenterItems}
											toggleMenu={toggleSidebarMenu}
										/>
										{!user && isBeeyou && (
											<SidebarHeaderCollapse
												parentItem={aboutParentItem}
												childrenItems={aboutSidebarItems}
												toggleMenu={toggleSidebarMenu}
											/>
										)}
									</Nav>
								</ListGroupItem>
							</ListGroup>
						</DropdownMenu>
					</Dropdown>
				) : null}
				{isStudioRoute && (
					<StudioStatusManager
						className="mr-3"
						controlroomActive={controlroomActive}
					/>
				)}
				{isStudioRoute && isCurrentStudioStarted && (
					<div className="SubjectContainer mw-100 d-flex align-items-center">
						<span className="d-none d-md-block text-truncate">
							{currentStudio.subject}
						</span>
					</div>
				)}
			</div>
		</div>
	);
};

SidebarHeader.propTypes = {
	isStudioRoute: PropTypes.bool,
	sideBar: PropTypes.node,
};

SidebarHeader.defaultProps = {
	isStudioRoute: false,
	sideBar: null,
};

export default SidebarHeader;
