import { useQuery } from 'react-query';
import * as analyticsAdsApi from '../../api/analytics/ads';
import { axiosQueryWrapper } from '../utils/axios-wrapper';

export const ANALYTICS_AD_QUERY_KEYS = {
	fetchAdGenderAnalyticsById: (adCampaignId) => ['analytics', 'ads', adCampaignId, 'gender'],
	fetchAdAgeAnalyticsById: (adCampaignId) => ['analytics', 'ads', adCampaignId, 'age'],
	fetchAdCountryAnalyticsById: (adCampaignId) => ['analytics', 'ads', adCampaignId, 'country'],
	fetchAdViewsAnalyticsById: (adCampaignId) => ['analytics', 'ads', adCampaignId, 'views'],
	fetchIssuedAdCreditsAnalyticsById: (adCampaignId) => ['analytics', 'ads', adCampaignId, 'adCredits', 'issued'],
};

export const useFetchAdGenderAnalytics = (adCampaignId) => useQuery(
	ANALYTICS_AD_QUERY_KEYS.fetchAdGenderAnalyticsById(adCampaignId),
	axiosQueryWrapper(analyticsAdsApi.fetchAdGenderAnalytics, adCampaignId),
	{ enabled: !!adCampaignId },
);

export const useFetchAdAgeAnalytics = (adCampaignId) => useQuery(
	ANALYTICS_AD_QUERY_KEYS.fetchAdAgeAnalyticsById(adCampaignId),
	axiosQueryWrapper(analyticsAdsApi.fetchAdAgeAnalytics, adCampaignId),
	{ enabled: !!adCampaignId },
);

export const useFetchAdCountryAnalytics = (adCampaignId) => useQuery(
	ANALYTICS_AD_QUERY_KEYS.fetchAdCountryAnalyticsById(adCampaignId),
	axiosQueryWrapper(analyticsAdsApi.fetchAdCountryAnalytics, adCampaignId),
	{ enabled: !!adCampaignId },
);

export const useFetchAdViewsAnalytics = (adCampaignId) => useQuery(
	ANALYTICS_AD_QUERY_KEYS.fetchAdViewsAnalyticsById(adCampaignId),
	axiosQueryWrapper(analyticsAdsApi.fetchAdViewsAnalytics, adCampaignId),
	{ enabled: !!adCampaignId },
);

export const useFetchAdCreditAnalytics = (adCampaignId) => useQuery(
	ANALYTICS_AD_QUERY_KEYS.fetchIssuedAdCreditsAnalyticsById(adCampaignId),
	axiosQueryWrapper(analyticsAdsApi.fetchIssuedAdCredits, adCampaignId),
	{ enabled: !!adCampaignId },
);
