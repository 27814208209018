import { useState } from 'react';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { CompleteProfileModal } from '../Profile/CompleteModal';

export const CompleteProfile = () => {
	const { t } = useTranslation();
	const [showCompleteProfileModal, setShowCompleteProfileModal] = useState(false);

	return (
		<>
			<div className="d-flex align-items-center justify-content-center h-100">
				<Button
					onClick={() => setShowCompleteProfileModal(true)}
					color="primary"
					className="flex-shrink-0 mx-3"
				>
					<span className="btn-wrapper--label d-md-inline">
						{t('CompleteProfile.getChips')}
					</span>
				</Button>
			</div>
			<CompleteProfileModal
				isOpen={showCompleteProfileModal}
				close={() => setShowCompleteProfileModal(false)}
			/>
		</>
	);
};
