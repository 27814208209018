// @ts-check

/**
 * @import { Publication } from '../../lib/store/publication';
 */

const regCamOrMicStreamId = /(?:cam|mic):(\d+)$/;
const regCamStreamId = /(?:cam):(\d+)$/;

/**
 * @param {string} sourceId
 * @returns {boolean}
 */
export const isCamStreamId = (sourceId) => regCamStreamId.test(sourceId);

/**
 * @param {string} sourceId
 * @returns {string}
 */
export const replaceCamOrMicByParticipant = (sourceId) => sourceId.replace(regCamOrMicStreamId, 'participant:$1');

/**
 * @param {Publication} publication
 * @returns {string}
 */
export const getIdFromPublication = (publication) => {
	const {
		appData,
		hashtag,
		peerId,
	} = publication;
	const {
		streamId,
	} = appData;

	const mergedParticipantStreamId = replaceCamOrMicByParticipant(streamId);

	return `${hashtag}/${peerId}/${mergedParticipantStreamId}`;
};

const regParticipantSourceId = /participant:(\d+)$/;

/**
 * @param {string} sourceId
 * @returns {string}
 * */
export const replaceParticipantSourceIdByMic = (sourceId) => sourceId.replace(regParticipantSourceId, 'mic:$1');
/**
 * @param {string} sourceId
 * @returns {string}
 * */
export const replaceParticipantSourceIdByCam = (sourceId) => sourceId.replace(regParticipantSourceId, 'cam:$1');

/**
 * @param {string} sourceId
 * @returns {boolean}
 * */
export const isParticipantSourceId = (sourceId) => regParticipantSourceId.test(sourceId);

const regParticipantDefaultSourceId = /participant:0$/;
/**
 * @param {string} sourceId
 * @returns {boolean}
 * */
export const isParticipantDefaultSourceId = (sourceId) => (
	regParticipantDefaultSourceId.test(sourceId)
);

const regTalkbackSourceId = /talkback:0$/;
/**
 * @param {string} sourceId
 * @returns {boolean}
 * */
export const isTalkbackSourceId = (sourceId) => regTalkbackSourceId.test(sourceId);

const regVideoshareSourceId = /video:0$/;
/**
 * @param {string} sourceId
 * @returns {boolean}
 * */
export const isVideoshareSourceId = (sourceId) => regVideoshareSourceId.test(sourceId);

const regImageshareSourceId = /image:0$/;
/**
 * @param {string} sourceId
 * @returns {boolean}
 * */
export const isImageshareSourceId = (sourceId) => regImageshareSourceId.test(sourceId);

const regAudioshareSourceId = /audio:0$/;
/**
 * @param {string} sourceId
 * @returns {boolean}
 * */
export const isAudioshareSourceId = (sourceId) => regAudioshareSourceId.test(sourceId);

const regScreenshareSourceId = /screen:0$/;
/**
 * @param {string} sourceId
 * @returns {boolean}
 * */
export const isScreenshareSourceId = (sourceId) => regScreenshareSourceId.test(sourceId);

const layerIndexRegex = /(?:pip)(\d+)$/;
/**
 * @param {string} layer
 * @returns {number?}
 * */
export const getLayerIndex = (layer) => {
	if (layer === 'main') return 0;
	if (layer === 'audioShare') return 0;
	const regLayer = layerIndexRegex.exec(layer);
	return regLayer?.length === 2 ? parseInt(regLayer[1], 10) : null;
};
