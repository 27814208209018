// @ts-check

import { api } from '../api';

const API_ACCESS_PATH = '/access';

/**
 * @param {string} videoId
 * @param {string} amounts
 * @returns {Promise<import('axios').AxiosResponse>}
 */
export const buyVideo = (
	videoId,
	amounts,
) => api.post(
	`${API_ACCESS_PATH}/buy/video`,
	{ videoId, amounts },
);
