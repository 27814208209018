import { useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useAsync, useAsyncCallback } from 'react-async-hook';

import { FaRegUser, FaUsers } from 'react-icons/fa';
import Select from 'react-select';
import { FormGroup, Input } from 'reactstrap';
import { Field, FieldError, Form } from 'react-jsonschema-form-validation';
import { FormLabel } from '../../Form/Label';
import * as channelGroupsApi from '../../../api/channel/groups';
import { ShareItem } from '../Item';
import { ResourceAccessRole } from '../../../lib/ResourceAccessRole';
import { ShareRecipientType, ShareTabType } from '../Share.helper';
import { ShareRoleInput } from '../RoleInput';
import { ButtonPillOutline } from '../../Button';
import FormSubmit from '../../Form/Submit';
import { AlertTimeout } from '../../Alert/Timeout';

export const ShareTabItemGroup = ({
	group,
	isAdded,
	message,
	onToggle,
}) => {
	const { t } = useTranslation();

	return (
		<ShareItem
			isAdded={isAdded}
			label={t('Share.Tab.ItemGroup.group')}
			onToggle={onToggle}
		>
			<div className="mr-3 d-flex align-items-center justify-content-center d-60 flex-shrink-0">
				<FaUsers size="2em" />
			</div>
			<div className="d-flex flex-column align-items-start overflow-hidden">
				<strong className="text-truncate">{group.label}</strong>
				<div className="font-size-sm">
					<FaRegUser className="mr-1" />
					<b className="pr-1">{group.users.length}</b>
					{group.users.length === 1 ? t('Share.Tab.ItemGroup.member') : t('Share.Tab.ItemGroup.members')}
				</div>
				{message && (
					<small className="text-secondary text-truncate text-left">{t('Share.ItemFriend.message')} : {message}</small>
				)}
			</div>
		</ShareItem>
	);
};

ShareTabItemGroup.propTypes = {
	group: PropTypes.shape({
		label: PropTypes.string,
		users: PropTypes.arrayOf(PropTypes.shape({
			_id: PropTypes.string.isRequired,
		})),
	}).isRequired,
	isAdded: PropTypes.bool,
	message: PropTypes.string,
	onToggle: PropTypes.func.isRequired,
};

ShareTabItemGroup.defaultProps = {
	isAdded: false,
	message: '',
};

const formSchema = {
	type: 'object',
	additionalProperties: false,
	properties: {
		message: {
			type: 'string',
			maxLength: 100,
		},
		groups: {
			type: 'array',
			minItems: 1,
		},
	},
};

const getInitialData = (defaultMessage) => ({
	groups: [],
	message: defaultMessage || '',
});

const CustomOption = ({ data, innerProps, innerRef }) => {
	const { t } = useTranslation();
	return (
		<div ref={innerRef} {...innerProps} className={`d-flex py-1 ${innerRef ? 'selected' : ''}`}>
			<div className="ms-3 d-flex align-items-center justify-content-center d-40 flex-shrink-0">
				<FaUsers size="2em" />
			</div>
			<div className="d-flex justify-content-between w-100 align-items-center mx-3 overflow-hidden">
				<strong className="text-truncate">{data.label}</strong>
				<div className="d-flex align-items-center font-size-sm ms-3">
					<FaRegUser className="mr-1" />
					<b className="pr-1">{data.group.users.length}</b>
					{data.group.users.length === 1 ? t('Share.Tab.ItemGroup.member') : t('Share.Tab.ItemGroup.members')}
				</div>
			</div>
		</div>
	);
};

CustomOption.propTypes = {
	data: PropTypes.shape({
		group: PropTypes.shape({
			users: PropTypes.arrayOf(PropTypes.shape({})),
		}),
		label: PropTypes.string,
	}).isRequired,
	innerProps: PropTypes.shape({}).isRequired,
	innerRef: PropTypes.func,
};

CustomOption.defaultProps = {
	innerRef: undefined,
};

export const ShareTabGroups = ({
	alreadySharedUsers,
	defaultMessage,
	inputColor,
	onShare,
	roles,
	selectedRole,
	setSelectedRole,
	shareButtonTranslationKey,
	showSuccessMessage,
}) => {
	const { t } = useTranslation();

	const [formData, setFormData] = useState(getInitialData(defaultMessage));

	const { result: groupsSuggestions = [] } = useAsync(async () => {
		const { data } = await channelGroupsApi.fetchGroups();
		return data;
	}, []);

	const filteredGroupSuggestions = useMemo(() => (
		groupsSuggestions
			.filter((group) => !alreadySharedUsers.some(
				(share) => share.type === ShareRecipientType.GROUP && share.group._id === group._id,
			)).map((group) => ({
				group,
				label: group.label,
				value: group._id,
			}))
	), [groupsSuggestions, alreadySharedUsers]);

	const clear = () => setFormData((s) => ({ ...s, groups: [] }));

	const handleSubmitAsync = useAsyncCallback(async () => {
		const participants = formData.groups.map((group) => ({
			group: group.group,
			message: formData.message,
			type: ShareRecipientType.GROUP,
		}));
		await onShare(participants, selectedRole);
		clear();
	});

	const handleClear = () => { clear(); };

	const isEmpty = !(formData.groups?.length > 0);
	const isActionDisabled = isEmpty || handleSubmitAsync.loading;
	const areAllGroupsSelected = useMemo(() => formData.groups.length
		=== filteredGroupSuggestions.length, [formData.groups, filteredGroupSuggestions]);

	const customDropdownStyles = useMemo(() => ({
		dropdownIndicator: (baseStyles) => ({
			...baseStyles,
			display: areAllGroupsSelected ? 'none' : 'flex',
		}),
		indicatorSeparator: (baseStyles) => ({
			...baseStyles,
			display: areAllGroupsSelected ? 'none' : 'flex',
		}),
	}), [areAllGroupsSelected]);

	if (filteredGroupSuggestions.length === 0) return <div>{t('Share.Tab.Groups.dontHaveGroups')}</div>;

	return (
		<Form
			schema={formSchema}
			data={formData}
			onSubmit={handleSubmitAsync.execute}
		>
			{showSuccessMessage && handleSubmitAsync.status === 'success' && (
				<AlertTimeout color="success" onTimeout={handleSubmitAsync.reset} timeoutTime={3000}>
					{t('Share.shareSuccessfullySent')}
				</AlertTimeout>
			)}
			{handleSubmitAsync.error && (
				<AlertTimeout color="danger" onTimeout={handleSubmitAsync.reset}>
					{t('Global.error')}
				</AlertTimeout>
			)}
			<ShareRoleInput
				value={selectedRole}
				onChange={(role) => setSelectedRole(role)}
				roles={roles}
				shareTabType={ShareTabType.GROUPS}
			/>
			<FormGroup>
				<FormLabel>{t('Share.sendTo')}</FormLabel>
				<Select
					captureMenuScroll={false}
					classNamePrefix={`react-select-${inputColor}`}
					closeMenuOnSelect={false}
					components={{ Option: CustomOption }}
					hideSelectedOptions
					isMulti
					isSearchable
					name="groups"
					noOptionsMessage={() => null}
					onChange={(groups) => setFormData({ ...formData, groups })}
					options={filteredGroupSuggestions}
					placeholder={t('Share.Tab.Groups.searchForAnyOfYourGroups')}
					styles={customDropdownStyles}
					value={formData.groups}
				/>
			</FormGroup>
			<div className="d-flex flex-column flex-sm-row justify-content-start justify-content-sm-between align-items-start align-items-sm-center pl-1">
				<div className="d-flex mb-3">
					<ButtonPillOutline
						className="mr-2"
						color={inputColor}
						disabled={isActionDisabled}
						onClick={handleClear}
					>
						{t('Share.Buttons.clear')}
					</ButtonPillOutline>
					<FormSubmit
						className="btn-pill shadow-none"
						disabled={isActionDisabled}
						loading={handleSubmitAsync.loading}
						title={t('Share.Tab.Groups.sendInvitationToGroups')}
					>
						{t(shareButtonTranslationKey)}
					</FormSubmit>
				</div>
			</div>
			<FormGroup>
				<FormLabel>{t('Share.Message.message')}</FormLabel>
				<Field
					className={`Share_InviteMessage bg-${inputColor} content-${inputColor} rounded-1 border-${inputColor}`}
					component={Input}
					name="message"
					onChange={(e) => setFormData({ ...formData, message: e.target.value })}
					placeholder={t('Share.Message.addCustomMessage')}
					rows="3"
					type="textarea"
					value={formData.message}
				/>
				<FieldError
					errorMessages={{
						maxLength: () => t('Share.Message.messageIsTooLong'),
					}}
					name="message"
				/>
			</FormGroup>
		</Form>
	);
};

ShareTabGroups.propTypes = {
	alreadySharedUsers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	defaultMessage: PropTypes.string,
	inputColor: PropTypes.oneOf(['dark', 'light']),
	onShare: PropTypes.func.isRequired,
	roles: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(ResourceAccessRole))).isRequired,
	selectedRole: PropTypes.oneOf(Object.keys(ResourceAccessRole)).isRequired,
	setSelectedRole: PropTypes.func.isRequired,
	shareButtonTranslationKey: PropTypes.string.isRequired,
	showSuccessMessage: PropTypes.bool,
};

ShareTabGroups.defaultProps = {
	defaultMessage: '',
	inputColor: 'dark',
	showSuccessMessage: true,
};
