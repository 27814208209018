export const OrganizationVisibility = {
	PRIVATE: 'PRIVATE',
	PUBLIC: 'PUBLIC',
};

export const OrganizationVisibilityLabel = {
	PUBLIC: 'MyOrganizationModal.OrganizationDetails.visibility.public',
	PRIVATE: 'MyOrganizationModal.OrganizationDetails.visibility.private',
};

export const properties = {
	allowAutoJoin: { type: 'boolean' },
};

export const schema = {
	type: 'object',
	additionalProperties: false,
	properties,
	required: [
		'allowAutoJoin',
	],
};
