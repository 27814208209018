import { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Input, InputGroup, InputGroupText } from 'reactstrap';
import { Field } from 'react-jsonschema-form-validation';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';

const FormPassword = ({
	defaultShowPassword,
	...props
}) => {
	const [showPassword, setShowPassword] = useState(defaultShowPassword);

	useEffect(() => {
		setShowPassword(defaultShowPassword);
	}, [defaultShowPassword]);

	const timeout = useRef();

	const hideAfterTimeout = useCallback(() => {
		clearTimeout(timeout.current);
		timeout.current = setTimeout(() => {
			setShowPassword(false);
		}, 5000);
	}, []);

	useEffect(() => {
		if (showPassword) {
			hideAfterTimeout();
			return () => { clearTimeout(timeout.current); };
		}

		return undefined;
	}, [hideAfterTimeout, showPassword]);

	const handleClickShowPassword = () => {
		setShowPassword((prevState) => !prevState);
	};

	return (
		<InputGroup>
			<Field
				component={Input}
				placeholder="****"
				type={showPassword ? 'text' : 'password'}
				{...props}
			/>
			<InputGroupText onClick={handleClickShowPassword}>
				{
					showPassword
						? <FaRegEyeSlash style={{ width: '20px' }} />
						: <FaRegEye style={{ width: '20px' }} />
				}
			</InputGroupText>
		</InputGroup>
	);
};

FormPassword.propTypes = {
	defaultShowPassword: PropTypes.bool,
};

FormPassword.defaultProps = {
	defaultShowPassword: false,
};

export default FormPassword;
