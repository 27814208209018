import { useCallback } from 'react';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';

import * as postsApi from '../../api/channel/posts';
import { ChannelPostsMode } from '../../components/Channel/Posts/ChannelPostsMode';
import { axiosMutationWrapper, axiosQueryWrapper } from '../utils/axios-wrapper';
import { useAuthentication } from '../../components/Authentication/Authentication';
import { CHANNEL_FEED_QUERY_KEYS, FEED_ITEMS_PER_PAGE } from './feed';
import { useProfile } from '../../components/Profile/ProfileContext';

const POST_QUERY_KEYS = {
	fetchPostsByChannelId: (channelId, mode = ChannelPostsMode.MINE) => ['api', 'channel', 'posts', 'fetch', channelId, mode],
	fetchPostBySlug: (postSlug) => ['api', 'channel', 'posts', 'fetch', postSlug],
	fetchPinnedPostByChannelId: (channelId) => ['api', 'channel', 'posts', 'fetch', 'pinned', channelId],
};

const POSTS_PER_PAGE_LIMIT = 20;

export const useFetchPosts = (channelId, mode) => useInfiniteQuery(
	POST_QUERY_KEYS.fetchPostsByChannelId(channelId, mode),
	({ pageParam }) => axiosQueryWrapper(
		postsApi.fetchPostsByChannelId, channelId, mode, pageParam,
	)(),
	{
		getNextPageParam: (lastPage) => {
			if (lastPage?.length && lastPage[0]?.createdAt && lastPage.length >= POSTS_PER_PAGE_LIMIT) {
				return lastPage[lastPage.length - 1].createdAt;
			}

			return undefined;
		},
		select: (data) => ({
			pages: [...data.pages],
			pageParams: [...data.pageParams],
		}),
		enabled: !!channelId,
		refetchOnWindowFocus: false,
	},
);

export const useFetchPost = (slug) => useQuery(
	POST_QUERY_KEYS.fetchPostBySlug(slug),
	axiosQueryWrapper(postsApi.fetchPost, slug),
	{ enabled: !!slug },
);

export const useFetchPinnedPost = (channelId, isLoggedIn) => useQuery(
	POST_QUERY_KEYS.fetchPinnedPostByChannelId(channelId),
	axiosQueryWrapper(postsApi.fetchPinnedPostByChannelId, channelId),
	{ enabled: !!isLoggedIn && !!channelId },
);

export const useHandleNewPost = (channelId) => {
	const queryClient = useQueryClient();

	return useCallback((newPost) => {
		queryClient.invalidateQueries(POST_QUERY_KEYS.fetchPostsByChannelId(newPost.channel.channelId));

		queryClient.invalidateQueries(
			POST_QUERY_KEYS.fetchPinnedPostByChannelId(channelId),
		);
	}, [channelId, queryClient]);
};

export const useHandleUpdatedPost = (channelId) => {
	const queryClient = useQueryClient();

	return useCallback((updatedPost) => {
		queryClient.invalidateQueries(
			POST_QUERY_KEYS.fetchPostsByChannelId(updatedPost.channel.channelId),
		);

		queryClient.setQueryData(
			POST_QUERY_KEYS.fetchPostBySlug(updatedPost.slug),
			(prevPost) => ({
				...prevPost,
				...updatedPost,
			}),
		);

		queryClient.setQueryData(
			POST_QUERY_KEYS.fetchPinnedPostByChannelId(channelId),
			(prevPosts) => prevPosts?.map(
				(prevPost) => (prevPost._id === updatedPost._id
					? {
						...prevPost,
						...updatedPost,
					}
					: prevPost),
			),
		);
	}, [channelId, queryClient]);
};

export const useHandleDeletedPost = (channelId) => {
	const queryClient = useQueryClient();

	return useCallback((deletedPost) => {
		queryClient.invalidateQueries(
			POST_QUERY_KEYS.fetchPostsByChannelId(deletedPost.channel._id),
		);

		queryClient.setQueryData(
			POST_QUERY_KEYS.fetchPinnedPostByChannelId(channelId),
			(posts) => posts.pages
				?.filter((post) => (post._id !== deletedPost._id)),
		);
	}, [channelId, queryClient]);
};

export const useHandlePostLikesChange = () => {
	const queryClient = useQueryClient();
	const { user } = useAuthentication();

	return useCallback(({ post: likedPost, like }) => {
		queryClient.invalidateQueries(
			POST_QUERY_KEYS.fetchPostsByChannelId(likedPost.channel.channelId),
		);

		queryClient.setQueryData(
			POST_QUERY_KEYS.fetchPostBySlug(likedPost.slug),
			(prevPost) => ({
				...prevPost,
				numberOfLikes: likedPost.numberOfLikes,
				userLikeReactionType: like.user === user?.sub
					? like.reactionType
					: prevPost?.userLikeReactionType,
			}),
		);
	}, [queryClient, user]);
};

export const useHandlePostPinnedChange = (channelId) => {
	const queryClient = useQueryClient();
	const handlePostUpdated = useHandleUpdatedPost(channelId);

	return useCallback((post) => {
		if (post.pinned) {
			queryClient.setQueryData(
				POST_QUERY_KEYS.fetchPinnedPostByChannelId(
					channelId,
				),
				(prevPosts) => [
					post,
					...prevPosts,
				],
			);
		} else {
			queryClient.setQueryData(
				POST_QUERY_KEYS.fetchPinnedPostByChannelId(
					channelId,
				),
				(prevPosts) => prevPosts?.filter((prevPost) => prevPost._id !== post._id),
			);
		}
		handlePostUpdated({
			_id: post._id,
			channel: post.channel,
			pinned: post.pinned,
		});
	}, [handlePostUpdated, channelId, queryClient]);
};

export const useCreatePost = () => {
	const queryClient = useQueryClient();

	return useMutation(
		axiosMutationWrapper(postsApi.createPost),
		{
			onSuccess: (data) => {
				queryClient.invalidateQueries(POST_QUERY_KEYS.fetchPostsByChannelId(data.channel));
				queryClient.invalidateQueries(CHANNEL_FEED_QUERY_KEYS.infinityFetchChannelFeed(
					data.channel, FEED_ITEMS_PER_PAGE,
				));
			},
		},

	);
};

export const useUpdatePost = () => {
	const queryClient = useQueryClient();

	return useMutation(
		async ({ id, text, images, videos, surveyTemplate }) => axiosMutationWrapper(
			postsApi.updatePost,
		)({ id, text, images, videos, surveyTemplate }), {
			onSuccess: (data) => {
				queryClient.invalidateQueries(POST_QUERY_KEYS.fetchPostsByChannelId(data.channel));
			},
		},
	);
};

export const useDeletePost = () => {
	const queryClient = useQueryClient();
	const { profile } = useProfile();

	return useMutation(
		axiosMutationWrapper(postsApi.deletePost),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(POST_QUERY_KEYS.fetchPostsByChannelId(profile._id));
				queryClient.invalidateQueries(CHANNEL_FEED_QUERY_KEYS.infinityFetchChannelFeed(
					profile._id, FEED_ITEMS_PER_PAGE,
				));
			},
		},
	);
};

export const useSharePostInChat = () => useMutation(
	axiosMutationWrapper(postsApi.sharePostInChat),
);

export const usePinPost = () => useMutation(
	axiosMutationWrapper(postsApi.pinPost),
);

export const useUnpinPost = () => useMutation(
	axiosMutationWrapper(postsApi.unpinPost),
);
