/* eslint-disable react/jsx-no-useless-fragment */
import { useGoogleLogin } from '@react-oauth/google';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FaGoogle, FaMailBulk, FaPhoneAlt } from 'react-icons/fa';
import { Field, FieldError } from 'react-jsonschema-form-validation';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { Col, FormGroup, Input, Row } from 'reactstrap';
import { ButtonPillOutline } from '../../Button';
import { FormLabel } from '../../Form/Label';
import FormPassword from '../../Form/Password';
import { InputVerificationOrientation } from '../../Input/Verification';
import { MIN_PASSWORD_LENGTH, getPasswordValidationMessage } from '../../Login/password.schema';
import { PhoneInputInput } from '../../Phone/PhoneInputInput';
import { VerifiedEmailInput } from '../../Profile/Settings/VerifiedEmailInput';
import { VerifiedPhoneInput } from '../../Profile/Settings/VerifiedPhoneInput';

import {
	AccountType,
	CredentialsStrategy,
	CredentialsStrategyLabel,
	IdentificationField,
	IdentificationFieldLabel,
	PasswordValidationCriteria,
} from '../signup.schema';

const isBeeyou = import.meta.env.VITE_PROJECT === 'beeyou';

export const SignupFormCreateAccount = ({
	signupData,
	onChange,
	nameError,
	passwordErrors,
	handleGoogleSubmit,
}) => {
	const { t } = useTranslation();

	const handlePhoneNumberChange = useCallback((phoneNumber) => {
		onChange({ phoneNumber });
	}, [onChange]);

	const handleGoogleLogin = useGoogleLogin({
		onSuccess: (codeResponse) => handleGoogleSubmit({ code: codeResponse.code }),
		flow: 'auth-code',
	});

	const handleButtonClick = useCallback((identificationFieldValue) => {
		if (identificationFieldValue === IdentificationField.GOOGLE) {
			handleGoogleLogin();
			return;
		}
		onChange({ identificationField: identificationFieldValue });
	}, [handleGoogleLogin, onChange]);

	const prepareIdentificationFields = useMemo(() => Object.keys(IdentificationField).map((key) => ({
		key,
		// eslint-disable-next-line no-nested-ternary
		icon: key === IdentificationField.EMAIL
			? <FaMailBulk /> : key === IdentificationField.GOOGLE
				? <FaGoogle /> : <FaPhoneAlt />,
		onClick: handleButtonClick,

	})), [handleButtonClick]);

	return (
		<>
			<Row>
				<Col>
					<FormGroup>
						<FormLabel className={clsx({ 'text-danger': nameError })}>
							{t('Onboarding.Signup.publicName')}
						</FormLabel>
						<Field
							component={Input}
							name="nickname"
							type="text"
							placeholder={t('Onboarding.Signup.publicName.placeholder')}
							value={signupData.nickname || ''}
						/>
						<FieldError name="nickname" />
					</FormGroup>
				</Col>
			</Row>
			<Row className="mt-1">

				{!signupData.identificationField
					&& signupData.identificationField !== IdentificationField.GOOGLE
					&& isBeeyou && signupData.accountType === AccountType.NEW_ORGANIZATION && (
					<Col lg="6">
						<FormGroup>
							<FormLabel>
								{t('Onboarding.Signup.credentials')}
							</FormLabel>
							<Field
								component={Input}
								name="credentialsStrategy"
								type="select"
								value={signupData.credentialsStrategy || CredentialsStrategy.NEW}
								disabled={signupData.accountType === AccountType.JOIN_ORGANIZATION}
							>
								{Object.keys(CredentialsStrategy).map((key) => (
									<option
										key={`identification-field-${CredentialsStrategy[key]}`}
										value={CredentialsStrategy[key]}
									>
										{t(CredentialsStrategyLabel[key])}
									</option>
								))}
							</Field>
							<FieldError name="credentialsStrategy" />
						</FormGroup>
					</Col>
				)}

				{signupData.accountType !== AccountType.JOIN_ORGANIZATION
					&& !signupData.identificationField && (
					<Col lg={12}>
						<FormGroup>
							<FormLabel>
								{t('Onboarding.Signup.registerWith')}
							</FormLabel>
							<div className="d-xl-flex gap-3 d-md-block">
								{prepareIdentificationFields.map((ifield) => (
									<ButtonPillOutline
										className="d-flex align-center gap-2 mb-1 w-100"
										color={`primary ${IdentificationField[ifield.key] === signupData.identificationField ? 'active' : ''}`}
										key={`identification-field-${IdentificationField[ifield.key]}`}
										disabled={signupData.accountType === AccountType.JOIN_ORGANIZATION
												|| !signupData.nickname}
										onClick={() => ifield.onClick(ifield.key)}
									>
										{ifield.icon}
										{t(IdentificationFieldLabel[ifield.key])}
									</ButtonPillOutline>
								))}
							</div>
							<FieldError name="identificationField" />
						</FormGroup>
					</Col>

				)}
			</Row>
			{signupData.identificationField !== IdentificationField.GOOGLE
				&& (
					<>
						<Row>
							{signupData.identificationField === IdentificationField.EMAIL && (
								<>
									{signupData.credentialsStrategy === CredentialsStrategy.NEW
										&& signupData.accountType !== AccountType.JOIN_ORGANIZATION
										? (
											<Col lg="12">
												<VerifiedEmailInput
													email={signupData.email || ''}
													orientation={InputVerificationOrientation.HORIZONTAL}
												/>
											</Col>
										) : (
											<Col lg="6">
												<FormGroup>
													<FormLabel>
														{t('Onboarding.Signup.email')}
													</FormLabel>
													<Field
														component={Input}
														name="email"
														type="email"
														placeholder={t('Onboarding.Signup.email.placeholder')}
														value={signupData.email || ''}
														disabled={signupData.accountType === AccountType.JOIN_ORGANIZATION}
													/>
													<FieldError name="email" />
												</FormGroup>
											</Col>
										)}
								</>
							)}

							{signupData.identificationField === IdentificationField.PHONE && (
								<>
									{
										signupData.credentialsStrategy === CredentialsStrategy.NEW
											&& signupData.accountType !== AccountType.JOIN_ORGANIZATION
											? (
												<Col lg="12">
													<VerifiedPhoneInput
														phoneNumber={signupData.phoneNumber || ''}
														country="US"
														orientation={InputVerificationOrientation.HORIZONTAL}
														onChange={handlePhoneNumberChange}
													/>
												</Col>
											) : (
												<Col lg="6">
													<FormGroup>
														<FormLabel>
															{t('Onboarding.Signup.phoneNumber')}
														</FormLabel>
														<Field
															component={PhoneInput}
															inputComponent={PhoneInputInput}
															defaultCountry="US"
															international
															name="phoneNumber"
															placeholder={t('Input.Verified.phone')}
															onChange={handlePhoneNumberChange}
															value={signupData.phoneNumber}
															disabled={signupData.accountType === AccountType.JOIN_ORGANIZATION}
														/>
														<FieldError name="phoneNumber" />
													</FormGroup>
												</Col>
											)
									}
								</>
							)}

						</Row>

						{signupData.identificationField === IdentificationField.EMAIL
							|| signupData.identificationField === IdentificationField.PHONE ? (
								<Row className="align-items-center mb-5">
									<Col lg="6">
										<FormGroup>
											<FormLabel>
												{t('Signup.Form.password')}
											</FormLabel>
											<small className="text-secondary mb-2 d-inline-block">
												{t('Signup.Form.passwordMustBe')}{' '}
												<span className={!passwordErrors.includes(PasswordValidationCriteria.LENGHT) ? 'text-success' : 'text-danger'}>{t('Signup.Form.passwordMinLength', { minLength: MIN_PASSWORD_LENGTH })}</span> {t('Signup.Form.passwordAndContainAtLeast')} {' '}
												<span className={!passwordErrors.includes(PasswordValidationCriteria.LOWECASE_LETTER) ? 'text-success' : 'text-danger'}>{t('Signup.Form.passwordMinLowercase')}</span>, {' '}
												<span className={!passwordErrors.includes(PasswordValidationCriteria.UPPERCASE_LETTER) ? 'text-success' : 'text-danger'}>{t('Signup.Form.passwordMinUppercase')}</span>, {' '}
												<span className={!passwordErrors.includes(PasswordValidationCriteria.SPECIAL_CHARACTER) ? 'text-success' : 'text-danger'}>{t('Signup.Form.passwordMinSpecialChar')}</span> {t('Signup.Form.passwordAnd')}{' '}
												<span className={!passwordErrors.includes(PasswordValidationCriteria.NUMBER) ? 'text-success' : 'text-danger'}>{t('Signup.Form.passwordMinNumber')}</span>
											</small>
											<FormPassword
												name="password"
												value={signupData.password || ''}
											/>
											<FieldError
												name="password"
												errorMessages={{
													pattern: (data) => getPasswordValidationMessage(data.params.pattern)
													|| data.message,
													minLength: () => t('Signup.Form.passwordLengthErrorMessage', { minLength: MIN_PASSWORD_LENGTH }),
												}}
											/>
										</FormGroup>
									</Col>
								</Row>
							) : null}
					</>
				)}
		</>
	);
};

SignupFormCreateAccount.propTypes = {
	signupData: PropTypes.shape({
		accountType: PropTypes.string,
		identificationField: PropTypes.string,
		credentialsStrategy: PropTypes.string,
		organizationName: PropTypes.string,
		email: PropTypes.string,
		phoneNumber: PropTypes.string,
		password: PropTypes.string,
		nickname: PropTypes.string,
	}),
	onChange: PropTypes.func,
	nameError: PropTypes.string,
	passwordErrors: PropTypes.arrayOf(PropTypes.string),
	handleGoogleSubmit: PropTypes.func.isRequired,
};

SignupFormCreateAccount.defaultProps = {
	signupData: {
		accountType: '',
		identificationField: '',
		credentialsStrategy: '',
		organizationName: '',
		email: '',
		phoneNumber: '',
		password: '',
		nickname: '',
	},
	onChange: undefined,
	nameError: '',
	passwordErrors: [],
};
