// @ts-check

import { api } from '../api';

const API_ADS_PATH = '/ads';

export const fetchBeeyouAd = async () => api.get(API_ADS_PATH);

export const launchAd = async ({ duration, studioId }) => api.post(`${API_ADS_PATH}/toStudioById/${studioId}`, { duration });

export const fetchPersonalizedAds = async (duration, studioId) => api.get(`${API_ADS_PATH}/personalized/${duration}`,
	{
		params: { studioId },
	});

export const fetchStudioAd = async () => api.get(`${API_ADS_PATH}/studio`);

export const fetchAdSettings = async () => api.get(
	`${API_ADS_PATH}/settings`,
);

export const fetchVodAds = async (videoId) => api.get(
	`${API_ADS_PATH}/video/${videoId}`,
);

/**
 * @returns {Promise<import('axios').AxiosResponse<ISponsorAdDto[]>>}
 */
export const fetchSponsoredAds = async () => api.get(`${API_ADS_PATH}/sponsoredAds`);
