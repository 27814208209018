import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, TabPane, Input, Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { VideoThumbnail } from '../../../Video/Thumbnail';
import { getEmbedShareLink } from '../../Share.helper';
import { FormLabel } from '../../../Form/Label';
import { AlertTimeout } from '../../../Alert/Timeout';

const EMBED_TYPE = {
	IFRAME: 'IFRAME',
	LINK: 'LINK',
};

const IFRAME_HEIGHT = 560;
const IFRAME_WIDTH = 315;

export const ShareTabEmbed = ({
	channel,
	inputColor,
	video,
	studio,
}) => {
	const { t } = useTranslation();
	const [success, setSuccess] = useState(false);

	const link = getEmbedShareLink({ channel, studio, video });
	const embedTitle = video?.label || studio?.subject || '';
	const iframeLink = `<iframe width="${IFRAME_HEIGHT}" height="${IFRAME_WIDTH}" src="${link}" title="${embedTitle}" frameBorder="0"></iframe>`;

	const handleCopyLink = useCallback((type) => {
		const text = type === EMBED_TYPE.IFRAME
			? iframeLink
			: link;

		navigator.clipboard.writeText(text)
			.then(() => setSuccess(true));
	}, [iframeLink, link]);

	return (
		<TabPane tabId="Post">
			<Row className={`ShareTabEmbed_PostInput_${inputColor} g-0 h-100 w-100 d-flex justify-content-center align-items-center`}>
				{!!video && (
					<VideoThumbnail
						video={video}
						className="mt-3 mx-auto h-100 w-100 mb-4"
					/>
				)}
				<FormLabel>{t('Share.Tab.Embed.copyIframeCode')}</FormLabel>
				<Input
					className={`mt-0 border-${inputColor} bg-${inputColor} text-secondary mb-2`}
					type="textarea"
					name="iframe"
					id="iframe"
					value={iframeLink}
					readOnly
					onClick={() => handleCopyLink(EMBED_TYPE.IFRAME)}
				/>
				<div className="mt-2 d-flex justify-content-between">
					<Button
						size="sm"
						color="primary"
						className="btn-pill shadow-none font-weight-bold"
						onClick={() => handleCopyLink(EMBED_TYPE.IFRAME)}
					>
						{t('Share.Tab.Embed.copy')}
					</Button>
				</div>
				<FormLabel className="mt-4">{t('Share.Tab.Embed.copyLink')}</FormLabel>
				<Input
					className={`border-${inputColor} bg-${inputColor} text-secondary`}
					type="text"
					name="link"
					id="link"
					value={link}
					readOnly
					onClick={() => handleCopyLink(EMBED_TYPE.LINK)}
				/>
				<div className="mt-3 d-flex justify-content-between">
					<Button
						size="sm"
						color="primary"
						className="btn-pill shadow-none font-weight-bold"
						onClick={() => handleCopyLink(EMBED_TYPE.LINK)}
					>
						{t('Share.Tab.Embed.copy')}
					</Button>
				</div>
				{success && (
					<AlertTimeout color="success mt-4" onTimeout={() => setSuccess(false)} timeoutTime={3000}>
						{t('Share.Tab.Embed.copied')}
					</AlertTimeout>
				)}
			</Row>
		</TabPane>
	);
};

ShareTabEmbed.propTypes = {
	channel: PropTypes.shape({
		hashtag: PropTypes.string.isRequired,
	}),
	inputColor: PropTypes.oneOf(['dark', 'light']),
	post: PropTypes.shape({
		_id: PropTypes.string.isRequired,
	}),
	video: PropTypes.shape({
		_id: PropTypes.string.isRequired,
		label: PropTypes.string.isRequired,
		watchLinkId: PropTypes.string.isRequired,
	}),
	studio: PropTypes.shape({
		_id: PropTypes.string.isRequired,
		subject: PropTypes.string.isRequired,
		watchLinkId: PropTypes.string.isRequired,
	}),
};

ShareTabEmbed.defaultProps = {
	channel: undefined,
	inputColor: 'dark',
	post: undefined,
	video: undefined,
	studio: undefined,
};
