import { createRoot } from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import './lib/shims';
import './lib/session';
import './lib/fixViewportHeight';
import './i18n';
import { routes } from './Routes';

const router = createBrowserRouter(routes);

const container = document.getElementById('root');
const root = createRoot(container);
//Wait reacstrap fix findDOMNode to enable StrictMode
root.render(
	<RouterProvider router={router} />,
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

const {
	VITE_BUILD_ID,
	VITE_VERSION,
	DEV,
	MODE,
} = import.meta.env;

// eslint-disable-next-line no-console
console.info(`ℹ️ v${VITE_VERSION}/${VITE_BUILD_ID}`);
if (DEV) {
	// eslint-disable-next-line no-console
	console.info(`ℹ️ MODE: ${MODE}`);
}
