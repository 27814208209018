import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'reactstrap';
import { MentionsInput, Mention } from 'react-mentions';
import { useTranslation } from 'react-i18next';
import { FaNewspaper, FaTimes } from 'react-icons/fa';
import clsx from 'clsx';
import { useAsyncCallback } from 'react-async-hook';

import { useRestrictedLengthInput } from '../../../lib/message';
import mentionStyle from './mentionStyle';
import { getFriendsChannels } from '../../../api/channel/friends';
import { MediaInput } from '../../Media/Input';
import { MediaUploadList } from '../../Media/UploadList';
import { MediaLoader } from '../../Media/Loader';
import { ChannelPostSurveyModal } from './SurveyModal';
import { ButtonLoading } from '../../Button';
import { AlertTimeout } from '../../Alert/Timeout';

const POST_LENGTH_LIMIT = 1400;

export const properties = {
	filename: { type: 'string' },
};

export const schema = {
	type: 'object',
	additionalProperties: false,
	properties,
	required: [],
};

export const PostAssetType = {
	IMAGE: 'IMAGE',
	VIDEO: 'VIDEO',
};

export const AssetUploadMode = {
	LIBRARY: 'Choose from library',
	UPLOAD: 'Upload',
};

export const PostInput = ({
	onSubmit,
	onCancel,
	initialMessage,
	isDisabled,
	images,
	videos,
	surveyTemplate,
	buttonText,
	share,
	isPostPage,
}) => {
	const [imageUploadProgress, setImageUploadProgress] = useState();
	const [videoUploadProgress, setVideoUploadProgress] = useState();
	const { t } = useTranslation();
	const [postText, setPostText] = useState(initialMessage);
	const [friends, setFriends] = useState([]);
	const [uploadedAssets, setUploadedAssets] = useState([]);
	const [openSurveyModal, setOpenSurveyModal] = useState(false);
	const [selectedSurvey, setSelectedSurvey] = useState();

	const [warning, onCommentChange] = useRestrictedLengthInput(
		postText,
		setPostText,
		POST_LENGTH_LIMIT,
	);

	useEffect(() => {
		setPostText(initialMessage);
	}, [initialMessage, setPostText]);

	const isInputEmpty = useMemo(() => !postText.trim().length
		&& !uploadedAssets?.length,
	[uploadedAssets, postText]);

	const isAssetUploading = useMemo(() => !!imageUploadProgress || !!videoUploadProgress,
		[imageUploadProgress, videoUploadProgress]);

	const setImagesToUploadedAssets = useCallback((postImages) => {
		const formattedImages = postImages.map((image) => (
			{ image: image._id, assetType: PostAssetType.IMAGE, filename: image.filename }
		));

		setUploadedAssets((prevUploadedAssets) => [
			...prevUploadedAssets,
			...formattedImages,
		]);
	}, []);

	const setVideosToUploadedAssets = useCallback((postVideos) => {
		const formattedVideos = postVideos.map((video) => ((
			{
				video: video.video?._id,
				_id: video._id,
				assetType: PostAssetType.VIDEO,
				filename: video.filename }
		)));

		setUploadedAssets((prevUploadedAssets) => [
			...prevUploadedAssets,
			...formattedVideos,
		]);
	}, []);

	useEffect(() => {
		if (images) {
			setImagesToUploadedAssets(images);
		}
	}, [images, setImagesToUploadedAssets]);

	useEffect(() => {
		if (videos) {
			setVideosToUploadedAssets(videos);
		}
	}, [videos, setVideosToUploadedAssets]);

	useEffect(() => {
		if (surveyTemplate) {
			setSelectedSurvey(surveyTemplate);
		}
	}, [surveyTemplate, setVideosToUploadedAssets]);

	const handleSendMessageAsync = useAsyncCallback(async () => {
		const uploadedImages = uploadedAssets.filter(
			(asset) => asset.assetType === PostAssetType.IMAGE,
		).map((image) => ({ filename: image.filename, image: image.image }));

		const uploadedVideos = uploadedAssets.filter(
			(asset) => asset.assetType === PostAssetType.VIDEO,
		).map((video) => ({ filename: video.filename, video: video.video }));

		await onSubmit(
			postText.trim(),
			uploadedImages,
			uploadedVideos,
			selectedSurvey?._id,
			share,
		);
		setPostText('');
		setUploadedAssets([]);
		setSelectedSurvey(null);
	});

	const handleFetchFriends = useCallback(async (query, callback) => {
		const { data } = await getFriendsChannels();
		const formattedFriends = data.map((friend) => ({
			id: friend._id,
			display: friend.hashtag,
		}));
		setFriends(formattedFriends);
		return callback(formattedFriends);
	}, []);

	const handleMessageKeyDown = useCallback((event) => {
		if (event.ctrlKey && event.key === 'Enter') {
			handleSendMessageAsync.execute();
		}
	}, [handleSendMessageAsync]);

	const handleCancelClick = useCallback(() => {
		onCancel();
		setPostText('');
	}, [onCancel, setPostText]);

	const handleRemoveImage = useCallback(async (imageId) => {
		setUploadedAssets((prevUploadedAssets) => prevUploadedAssets
			.filter((asset) => asset.image !== imageId));
	}, []);

	const handleRemoveVideo = useCallback(async (videoId) => {
		setUploadedAssets((prevUploadedAssets) => prevUploadedAssets
			.filter((asset) => asset.video !== videoId));
	}, []);

	const handleSelectSurvey = useCallback((survey) => {
		setSelectedSurvey(survey);
		setOpenSurveyModal(false);
	}, []);

	return (
		<>
			<div className="mt-2">
				<MentionsInput
					style={mentionStyle}
					value={postText}
					placeholder={isPostPage ? t('Comments.Input.addComment') : t('Channel.Posts.eg')}
					disabled={isDisabled}
					onChange={onCommentChange}
					onKeyDown={handleMessageKeyDown}
				>
					<Mention
						ignoreAccents
						trigger="@"
						data={friends?.length ? friends : handleFetchFriends}
						appendSpaceOnAdd
						className="text-primary text-decoration-underline"
						style={{
							textDecoration: 'underline',
							color: '#FFCA00',
						}}
					/>
				</MentionsInput>
				{warning && (
					<p className="text-danger text-small text-center m-0">{warning}</p>
				)}
				{handleSendMessageAsync.error && (
					<AlertTimeout className="m-0 mt-2" color="danger" onTimeout={handleSendMessageAsync.reset}>
						{handleSendMessageAsync.error.response?.data?.message || t('Global.error')}
					</AlertTimeout>
				)}
			</div>
			<MediaLoader
				imageUploadProgress={imageUploadProgress}
				videoUploadProgress={videoUploadProgress}
			/>
			<div className="d-flex justify-content-between align-items-center my-2">
				<div className="d-flex align-items-center">
					<MediaInput
						uploadedAssets={uploadedAssets}
						setUploadedAssets={setUploadedAssets}
						setImageUploadProgress={setImageUploadProgress}
						setVideoUploadProgress={setVideoUploadProgress}
					/>
					<Button
						className="font-size-lg p-0 d-inline-block shadow-none border-0 text-center d-30 btn-transition-none mt-2 ml-1"
						color="neutral-info"
						title="Choose a survey to post"
						type="button"
						onClick={() => setOpenSurveyModal(true)}
					>
						<FaNewspaper />
					</Button>
					{!!selectedSurvey && (
						<small className="m-0 mt-2 ml-2 d-flex align-items-center bg-dark p-2 rounded text-secondary">
							<p className="m-0 mr-2"><span className="font-weight-bold">Survey: </span>{selectedSurvey?.title}</p>
							<FaTimes
								className="cursor-pointer"
								onClick={() => setSelectedSurvey(null)}
							/>
						</small>
					)}
				</div>
				<div
					className={clsx('d-flex', { 'justify-content-end mt-2 align-items-center': isPostPage })}
				>
					<ButtonLoading
						className="flex-shrink-0 btn-pill"
						color={isPostPage ? 'info' : 'primary'}
						component={Button}
						disabled={isInputEmpty || isDisabled || isAssetUploading}
						loading={handleSendMessageAsync.loading}
						onClick={handleSendMessageAsync.execute}
						size={isPostPage ? 'sm' : 'md'}
					>
						{onCancel ? t('Channel.Posts.update') : buttonText || t('Channel.Posts.post')}
					</ButtonLoading>
					{onCancel && (
						<Button
							className="flex-shrink-0 ml-2 btn-pill"
							color="neutral-secondary"
							disabled={isDisabled}
							onClick={handleCancelClick}
							size={isPostPage ? 'sm' : 'md'}
						>
							{t('Channel.Posts.cancel')}
						</Button>
					)}
				</div>
			</div>
			<MediaUploadList
				uploadedAssets={uploadedAssets}
				onRemoveImage={handleRemoveImage}
				onRemoveVideo={handleRemoveVideo}
			/>
			<ChannelPostSurveyModal
				isOpen={openSurveyModal}
				closeModal={() => setOpenSurveyModal(false)}
				onSelectSurvey={handleSelectSurvey}
			/>
		</>
	);
};

PostInput.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	onCancel: PropTypes.func,
	initialMessage: PropTypes.string,
	images: PropTypes.arrayOf(PropTypes.shape({})),
	videos: PropTypes.arrayOf(PropTypes.shape({})),
	isDisabled: PropTypes.bool,
	buttonText: PropTypes.string,
	share: PropTypes.string,
	surveyTemplate: PropTypes.string,
	isPostPage: PropTypes.bool,
};

PostInput.defaultProps = {
	onCancel: undefined,
	initialMessage: '',
	isDisabled: false,
	images: undefined,
	videos: undefined,
	buttonText: undefined,
	share: undefined,
	surveyTemplate: undefined,
	isPostPage: false,
};
