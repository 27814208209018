import { useMutation, useQueryClient } from 'react-query';
import * as notifyApi from '../../api/notify/notify';
import { PROFILE_QUERY_KEYS } from '../profile/me';
import { axiosMutationWrapper } from '../utils/axios-wrapper';

export const useCreateSubscription = () => {
	const queryClient = useQueryClient();

	return useMutation(
		axiosMutationWrapper(notifyApi.createSubscription), {
			onSuccess: () => {
				queryClient.invalidateQueries(PROFILE_QUERY_KEYS.fetch());
			},
		},
	);
};

export const useDeleteSubscription = () => useMutation(
	axiosMutationWrapper(notifyApi.deleteSubscription),
);

export const useDisableNotifications = () => {
	const queryClient = useQueryClient();

	return useMutation(
		axiosMutationWrapper(notifyApi.disableNotifications), {
			onSuccess: () => {
				queryClient.invalidateQueries(PROFILE_QUERY_KEYS.fetch());
			},
		},
	);
};

export const useEnableNotifications = () => {
	const queryClient = useQueryClient();

	return useMutation(
		axiosMutationWrapper(notifyApi.enableNotifications), {
			onSuccess: () => {
				queryClient.invalidateQueries(PROFILE_QUERY_KEYS.fetch());
			},
		},
	);
};
