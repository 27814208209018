import { useQuery } from 'react-query';
import { axiosQueryWrapper } from '../utils/axios-wrapper';
import * as publicOrganizationApi from '../../api/public/organization';

export const PUBLIC_ORGANIZATION_QUERY_KEYS = {
	fetchDefaultOrganization: () => ['api', 'public', 'organization', 'default'],
	fetchOrganizationRoles: (organizationId) => ['api', 'public', 'organization', 'byId', { id: organizationId }, 'roles'],
};

export const useFetchPublicOrganizationRoles = (organizationId) => useQuery(
	PUBLIC_ORGANIZATION_QUERY_KEYS.fetchOrganizationRoles(organizationId),
	axiosQueryWrapper(publicOrganizationApi.fetchOrganizationRoles, organizationId),
	{ enabled: !!organizationId },
);

export const useFetchDefaultOrganization = () => useQuery(
	PUBLIC_ORGANIZATION_QUERY_KEYS.fetchDefaultOrganization(),
	axiosQueryWrapper(publicOrganizationApi.fetchDefaultOrganization),
);
