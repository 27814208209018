import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';

import { useLogin } from '../Login/Provider';
import { ButtonPill } from '../Button';
import { useAuthentication } from '../Authentication/Authentication';

export const NewUserSignUpOrHomeButton = ({
	className,
	Component,
	free,
}) => {
	const { isLoggedIn } = useAuthentication();
	const { openSignup } = useLogin();
	const { t } = useTranslation();

	const handleClickSignUp = () => {
		ReactGA.event({ category: 'signup', action: 'click', label: 'User clicked on Sign Up from New User Info Banner' });
		openSignup({
			showRoutingModalAfterLogin: true,
			showIntroModalAfterLogin: true,
		});
	};

	if (isLoggedIn) {
		return (
			<Component
				className={className}
				tag={Link}
				to="/"
			>
				{t('NewUser.Button.Home')}
			</Component>
		);
	}

	return (
		<Component
			className={className}
			onClick={handleClickSignUp}
		>
			{free
				? t('NewUser.Button.SignUpFree')
				: t('NewUser.Button.SignUp')}
		</Component>
	);
};

NewUserSignUpOrHomeButton.propTypes = {
	className: PropTypes.string,
	Component: PropTypes.elementType,
	free: PropTypes.bool,
};

NewUserSignUpOrHomeButton.defaultProps = {
	className: undefined,
	Component: ButtonPill,
	free: false,
};
