import { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Nav, Navbar } from 'reactstrap';

import clsx from 'clsx';

import './Header.scss';
import { FaChevronLeft, FaTimes } from 'react-icons/fa';

export const ModalScreenHeaderNav = ({
	className,
	children,
	childClassName,
}) => (
	<Navbar
		className={clsx('ModalScreenHeaderNav w-100 p-0 nav-line-alt', className)}
	>
		<Nav className={clsx('nav-line w-100 justify-content-start text-nowrap text-uppercase', childClassName)}>
			{children}
		</Nav>
	</Navbar>
);

ModalScreenHeaderNav.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node.isRequired,
	childClassName: PropTypes.string,
};

ModalScreenHeaderNav.defaultProps = {
	className: '',
	childClassName: '',
};

export const ModalScreenHeaderCloseButton = ({
	className,
	onClose,
}) => (
	<Button
		color="link"
		className={clsx('d-30 p-0 text-inherit', className)}
		onClick={onClose}
	>
		<span className="btn-wrapper--icon">
			<FaTimes />
		</span>
	</Button>
);

ModalScreenHeaderCloseButton.propTypes = {
	className: PropTypes.string,
	onClose: PropTypes.func.isRequired,
};

ModalScreenHeaderCloseButton.defaultProps = {
	className: '',
};

// Notice close state implementation

function DisplayNotice({ notice }) {
	const [showNotice, setShowNotice] = useState(true);

	const closeNotice = () => {
		setShowNotice(false);
	};

	if (showNotice) {
		return (
			<div className="position-absolute fixed-top max-width mr-2 bg-primary rounded-bottom shadow-sm p-2">
				{notice}
				<ModalScreenHeaderCloseButton
					className="ml-auto align-self-start float-right d-inline-block flex-shrink-0"
					onClose={closeNotice}
				/>
			</div>
		);
	}
}

DisplayNotice.propTypes = {
	notice: PropTypes.node,
};

DisplayNotice.defaultProps = {
	notice: undefined,
};

export const ModalScreenHeader = ({
	buttons,
	children,
	onClose,
	onBack,
	noBorder,
	title,
	subTitle,
	emphasisOnTitle,
	notice,
}) => (
	<header className={clsx('d-flex align-items-center p-3', { 'border-bottom': !noBorder }, { 'bg-light content-light': !!title })}>
		{notice && (
			<DisplayNotice notice={notice} />
		)}
		{!!onBack && (
			<span className="btn-wrapper--icon mr-2 cursor-pointer text-secondary font-size-lg" onClick={onBack}>
				<FaChevronLeft />
			</span>
		)}
		<div className={`d-flex align-items-center w-100 mr-4 ${onBack ? 'ml-2' : 'ml-3'}`}>
			<div className="d-flex flex-column w-100">
				<div className={
					clsx(
						'd-flex',
						{
							'flex-column': emphasisOnTitle,
							'align-items-start': emphasisOnTitle,
							'align-items-center': !emphasisOnTitle,
							'pl-md-5': emphasisOnTitle,
							'pt-5': emphasisOnTitle,
						},
					)
				}
				>
					<div className="d-flex w-100">
						{!!title && (
							<h1 className={clsx('mb-0 mr-3', { h4: !emphasisOnTitle, h2: emphasisOnTitle, 'font-weight-bold': emphasisOnTitle })}>{title}</h1>
						)}
						{!!onClose && (
							<ModalScreenHeaderCloseButton
								className="ml-auto align-self-start float-right flex-shrink-0"
								onClose={onClose}
							/>
						)}
					</div>
					{!!subTitle && (
						<p className="text-secondary m-0">{subTitle}</p>
					)}
				</div>
				{children}
			</div>
			{!!buttons && (
				<div className="d-flex align-items-center">
					{buttons}
				</div>
			)}
		</div>
	</header>
);

ModalScreenHeader.propTypes = {
	buttons: PropTypes.node,
	children: PropTypes.node,
	noBorder: PropTypes.bool,
	onClose: PropTypes.func,
	onBack: PropTypes.func,
	title: PropTypes.node,
	subTitle: PropTypes.string,
	emphasisOnTitle: PropTypes.bool,
	notice: PropTypes.node,
};

ModalScreenHeader.defaultProps = {
	buttons: undefined,
	children: undefined,
	noBorder: false,
	onClose: undefined,
	onBack: undefined,
	title: undefined,
	subTitle: undefined,
	emphasisOnTitle: false,
	notice: undefined,
};
