import { useCallback } from 'react';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';
import * as conversationsApi from '../api/conversation';
import { useAuthentication } from '../components/Authentication/Authentication';
import { MessageCategoryTabs } from '../components/FriendsMenu/Messages/categories.schema';
import { Sound, useSound } from '../components/Sound/Provider';
import { axiosMutationWrapper, axiosQueryWrapper } from './utils/axios-wrapper';

const CONVERSATIONS_PER_PAGE_LIMIT = 15;
const MESSAGES_PER_PAGE_LIMIT = 20;

export const CONVERSATION_QUERY_KEYS = {
	fetchConversations: (tab) => ['api', 'conversations', { tab }],
	fetchConversationMessages: (conversationId) => ['api', 'conversations', conversationId, 'messages'],
	fetchTotalUnreadCount: () => ['api', 'conversations', 'all', 'unreadCount'],
	fetchConversationUnreadCount: (conversationId) => ['api', 'conversations', conversationId, 'unreadCount'],
};

export const useFetchConversations = (tab = 'all') => useInfiniteQuery(
	CONVERSATION_QUERY_KEYS.fetchConversations(tab),
	({ pageParam }) => axiosQueryWrapper(conversationsApi.fetchConversations, pageParam, tab)(),
	{
		getNextPageParam: (lastPage) => {
			if (
				lastPage?.length
				&& lastPage[lastPage.length - 1]?.lastMessage?.createdAt
				&& lastPage.length >= CONVERSATIONS_PER_PAGE_LIMIT
			) {
				return lastPage[lastPage.length - 1].lastMessage.createdAt;
			}

			return undefined;
		},
	},
);

export const useCreateConversation = () => useMutation(
	axiosMutationWrapper(conversationsApi.createConversation),
);

export const useDeleteConversation = () => useMutation(
	axiosMutationWrapper(conversationsApi.deleteConversation),
);

export const useHideConversation = () => useMutation(
	axiosMutationWrapper(conversationsApi.hideConversation),
);

export const useFetchMessages = (conversationId) => useInfiniteQuery(
	CONVERSATION_QUERY_KEYS.fetchConversationMessages(conversationId),
	({ pageParam }) => axiosQueryWrapper(
		conversationsApi.fetchConversationMessages, conversationId, pageParam,
	)(),
	{
		getNextPageParam: (lastPage) => {
			if (
				lastPage?.length && lastPage[0]?.createdAt && lastPage.length >= MESSAGES_PER_PAGE_LIMIT
			) {
				return lastPage[0].createdAt;
			}

			return undefined;
		},
		select: (data) => ({
			pages: [...data.pages].reverse(),
			pageParams: [...data.pageParams].reverse(),
		}),
	},
);

export const useSendMessage = () => useMutation(
	axiosMutationWrapper(conversationsApi.sendMessage),
);

export const useSendMessageToUsers = () => useMutation(
	async ({ userIds, name, groupId, text, images, videos }) => {
		const result = await conversationsApi
			.createConversation({ users: userIds, name, groupId });

		if (result?.data && typeof result.data === 'object') {
			await conversationsApi.sendMessage(
				{ conversationId: result.data._id, text, images, videos },
			);

			return result.data;
		}

		return null;
	},
);

export const useSendMessageToManyUsers = () => useMutation(
	axiosMutationWrapper(conversationsApi.sendMessageToManyUsers),
);

export const useUpdateMessage = () => useMutation(
	axiosMutationWrapper(conversationsApi.updateMessage),
);

export const useDeleteMessage = () => useMutation(
	axiosMutationWrapper(conversationsApi.deleteMessage),
);

export const useFetchTotalUnreadCount = () => useQuery(
	CONVERSATION_QUERY_KEYS.fetchTotalUnreadCount(),
	async () => ({
		total: 0,
		private: 0,
		group: 0,
	}),
	// never re-fetch will be controlled from sockets
	{
		enabled: false,
		initialData: {
			total: 0,
			private: 0,
			group: 0,
		},
	},
);

export const useFetchConversationUnreadCount = (conversationId) => useQuery(
	CONVERSATION_QUERY_KEYS.fetchConversationUnreadCount(conversationId),
	async () => ({ unreadCount: 0, isGroup: false }),
	// never re-fetch will be controlled from sockets
	{
		enabled: false,
		initialData: {
			unreadCount: 0,
			isGroup: false,
		},
	},
);

export const useHandleEventConversationNew = () => {
	const queryClient = useQueryClient();

	return useCallback((conversation) => {
		const tabsToUpdate = [
			MessageCategoryTabs.ALL,
			conversation.isGroup ? MessageCategoryTabs.GROUP : MessageCategoryTabs.PRIVATE,
		];

		tabsToUpdate.forEach((tab) => {
			if (!queryClient.getQueryData(CONVERSATION_QUERY_KEYS.fetchConversations(tab))) {
				return;
			}

			queryClient.setQueryData(
				CONVERSATION_QUERY_KEYS.fetchConversations(tab),
				(data) => ({
					pages: [[conversation, ...data.pages[0]], ...data.pages.slice(1)],
					pageParams: data.pageParams,
				}),
			);
		});
	}, [queryClient]);
};

export const useHandleEventConversationUpdate = () => {
	const queryClient = useQueryClient();

	return useCallback(({ conversation, isLastMessageSkipped }) => {
		const tabsToUpdate = [
			MessageCategoryTabs.ALL,
			conversation.isGroup ? MessageCategoryTabs.GROUP : MessageCategoryTabs.PRIVATE,
		];

		tabsToUpdate.forEach((tab) => {
			if (!queryClient.getQueryData(CONVERSATION_QUERY_KEYS.fetchConversations(tab))) {
				return;
			}

			queryClient.setQueryData(
				CONVERSATION_QUERY_KEYS.fetchConversations(tab),
				(data) => {
					const retVal = {
						pages: [],
						pageParams: data.pageParams,
					};

					// in case last message was the cause of the conversation update
					// that means that conversation should be moved to the top
					// (added as first conversation, if not found)
					if (!isLastMessageSkipped) {
						retVal.pages = [
							[
								conversation,
								...data.pages[0].filter((c) => c._id !== conversation._id),
							],
							...data.pages.slice(1).map((page) => page.filter((c) => c._id !== conversation._id)),
						];
					} else {
						retVal.pages = data.pages
							.map((page) => page.map((c) => (c._id === conversation._id ? conversation : c)));
					}

					return retVal;
				},
			);
		});
	}, [queryClient]);
};

export const useHandleEventConversationDelete = () => {
	const queryClient = useQueryClient();

	return useCallback((conversationId) => {
		const tabsToUpdate = [
			MessageCategoryTabs.ALL,
			MessageCategoryTabs.GROUP,
			MessageCategoryTabs.PRIVATE,
		];

		tabsToUpdate.forEach((tab) => {
			if (!queryClient.getQueryData(CONVERSATION_QUERY_KEYS.fetchConversations(tab))) {
				return;
			}

			queryClient.setQueryData(
				CONVERSATION_QUERY_KEYS.fetchConversations(tab),
				(data) => ({
					pages: data.pages?.map(
						(conversations) => conversations?.filter((c) => c._id !== conversationId),
					),
					pageParams: data.pageParams,
				}),
			);

			queryClient.invalidateQueries(
				CONVERSATION_QUERY_KEYS.fetchConversationMessages(conversationId),
			);
		});
	}, [queryClient]);
};

export const useHandleEventConversationMessageNew = () => {
	const queryClient = useQueryClient();
	const { user } = useAuthentication();
	const { playSound } = useSound();

	return useCallback((message) => {
		if (!queryClient.getQueryData(
			CONVERSATION_QUERY_KEYS.fetchConversationMessages(message.conversation),
		)) {
			return;
		}

		queryClient.setQueryData(
			CONVERSATION_QUERY_KEYS.fetchConversationMessages(message.conversation),
			(data) => ({
				pages: [[...data.pages[0], message], ...data.pages.slice(1)],
				pageParams: data.pageParams,
			}),
		);

		if (message.from?._id !== user.sub) { playSound(Sound.NEW_MESSAGE); }
	}, [playSound, queryClient, user]);
};

export const useHandleEventConversationMessageUpdate = () => {
	const queryClient = useQueryClient();

	return useCallback((message) => {
		if (!queryClient.getQueryData(
			CONVERSATION_QUERY_KEYS.fetchConversationMessages(message.conversation),
		)) {
			return;
		}

		queryClient.setQueryData(
			CONVERSATION_QUERY_KEYS.fetchConversationMessages(message.conversation),
			(data) => ({
				pages: data.pages
					?.map((messages) => messages.map((m) => (m._id === message._id ? message : m))),
				pageParams: data.pageParams,
			}),
		);
	}, [queryClient]);
};

export const useHandleEventConversationMessageDelete = () => {
	const queryClient = useQueryClient();

	return useCallback((message) => {
		if (!queryClient.getQueryData(
			CONVERSATION_QUERY_KEYS.fetchConversationMessages(message.conversation),
		)) {
			return;
		}

		queryClient.setQueryData(
			CONVERSATION_QUERY_KEYS.fetchConversationMessages(message.conversation),
			(data) => ({
				pages: data.pages
					?.map((messages) => messages.map((m) => (m._id === message._id ? message : m))),
				pageParams: data.pageParams,
			}),
		);
	}, [queryClient]);
};

export const useHandleEventConversationMessageUpdateAll = () => {
	const queryClient = useQueryClient();

	return useCallback((messageData) => {
		queryClient.setQueryData(
			CONVERSATION_QUERY_KEYS.fetchConversationMessages(messageData.conversationId),
			{ pages: [messageData.messages], pageParams: [undefined] },
		);
	}, [queryClient]);
};

export const useHandleEventConversationUnreadCounts = () => {
	const queryClient = useQueryClient();

	return useCallback((unreadCounts) => {
		const unread = {
			total: 0,
			private: 0,
			group: 0,
		};

		unreadCounts.forEach(({ conversation, isGroup, unreadCount }) => {
			unread.total += unreadCount;
			unread[isGroup ? 'group' : 'private'] += unreadCount;

			queryClient.setQueryData(
				CONVERSATION_QUERY_KEYS.fetchConversationUnreadCount(conversation),
				{ isGroup, unreadCount },
			);
		});

		queryClient.setQueryData(
			CONVERSATION_QUERY_KEYS.fetchTotalUnreadCount(),
			unread,
		);
	}, [queryClient]);
};

export const useHandleEventConversationUnreadCountsNew = () => {
	const queryClient = useQueryClient();

	return useCallback((conversationId) => {
		const unreadData = queryClient.getQueryData(
			CONVERSATION_QUERY_KEYS.fetchConversationUnreadCount(conversationId),
		);

		queryClient.setQueryData(
			CONVERSATION_QUERY_KEYS.fetchConversationUnreadCount(conversationId),
			(prevUnreadCount) => (
				{ ...prevUnreadCount, unreadCount: (prevUnreadCount?.unreadCount || 0) + 1 }
			),
		);

		queryClient.setQueryData(
			CONVERSATION_QUERY_KEYS.fetchTotalUnreadCount(),
			(prevUnreadCount) => ({
				total: prevUnreadCount.total + 1,
				private: prevUnreadCount.private + (unreadData?.isGroup ? 0 : 1),
				group: prevUnreadCount.group + (unreadData?.isGroup ? 1 : 0),
			}),
		);
	}, [queryClient]);
};

export const useMarkConversationAsRead = () => {
	const queryClient = useQueryClient();

	return useMutation(
		axiosMutationWrapper(conversationsApi.markConversationAsRead),
		{
			onSuccess: (_, conversationId) => {
				const unreadData = queryClient.getQueryData(
					CONVERSATION_QUERY_KEYS.fetchConversationUnreadCount(conversationId),
				);

				queryClient.setQueryData(
					CONVERSATION_QUERY_KEYS.fetchConversationUnreadCount(conversationId),
					(prevUnreadCount) => ({ ...prevUnreadCount, unreadCount: 0 }),
				);

				queryClient.setQueryData(
					CONVERSATION_QUERY_KEYS.fetchTotalUnreadCount(),
					(prevUnreadCount) => ({
						total: prevUnreadCount.total - unreadData.unreadCount || 0,
						private:
							prevUnreadCount.private - (unreadData?.isGroup ? 0 : unreadData.unreadCount || 0),
						group: prevUnreadCount.group - (unreadData?.isGroup ? unreadData.unreadCount : 0),
					}),
				);
			},
		},
	);
};
