const convertRole = (role) => {
	switch (role) {
	case 'VIEWER':
		return 'viewer';
	case 'PARTICIPANT':
		return 'participant';
	default:
		return '';
	}
};

export const hasAvailablePresale = (studio, role) => {
	// base case, presale not defined for this studio / role
	if (!studio?.presale?.[convertRole(role)]?.active) {
		return false;
	}
	const presaleConfig = studio.presale[convertRole(role)];
	// date check
	const dateMatch = presaleConfig.limitedDates
		? Date.now()
			>= Date.parse(presaleConfig.startDate)
			&& Date.now()
			<= Date.parse(presaleConfig.endDate)
		: Date.now() < Date.parse(studio.startAt);

	if (!dateMatch) {
		return false;
	}
	// sold-out check
	const ticketsMatch = presaleConfig.limitedTickets
		? presaleConfig.ticketsSold < presaleConfig.ticketLimit
		: true;
	if (!ticketsMatch) {
		return false;
	}
	return true;
};

export const getStudioPriceWithPresale = (studio, role) => {
	// Check if there is an available presale for the given role
	if (hasAvailablePresale(studio, role) && studio.presale[convertRole(role)].discount) {
		return studio.presale[convertRole(role)].price; // Return presale price if available
	}
	// Return the regular price if there are no presale tickets to buy or no discount is available
	return studio.price[convertRole(role)];
};

export const formatDate = (inputDate) => {
	const date = new Date(inputDate);
	return date.toLocaleString('en-US', { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', timeZoneName: 'short' });
};
