import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

import { Path, getLink } from '../../RoutePath';
import { ScreenThemeLayout, useScreenTheme } from '../../components/Studio/ScreenTheme/Provider';
// TODO: eslint path config ?
// eslint-disable-next-line import/no-unresolved
import { ReactComponent as Logo } from '../../images/logo.@@PROJECT.svg';

export const Footer = () => {
	const { t } = useTranslation();

	const { currentScreenTheme, handleChangeCurrentScreenTheme } = useScreenTheme();

	const helpCenterMenuItems = useMemo(() => [
		{ label: t('SidebarMenu.Index.gettingStarted'), to: getLink(Path.HELP_ANCHOR, { anchor: 'gettingStarted' }) },
		{ label: t('SidebarMenu.Index.userGuide'), to: getLink(Path.HELP_ANCHOR, { anchor: 'userGuide' }) },
		{ label: t('SidebarMenu.Index.faqs'), to: getLink(Path.HELP_ANCHOR, { anchor: 'faq' }) },
		{ label: t('SidebarMenu.Index.troubleshooting'), to: getLink(Path.HELP_ANCHOR, { anchor: 'troubleshooting' }) },
	], [t]);

	const legalMenuItems = useMemo(() => [
		{ label: t('SidebarMenu.Index.termsAndPrivacy'), to: getLink(Path.LEGAL_TERMS) },
		{ label: t('SidebarMenu.Index.cookiesPolicy'), to: getLink(Path.LEGAL_COOKIES) },
	], [t]);

	return (
		<Container fluid className="w-100 px-5 mt-5 pt-5 mb-5">
			<div className="divider bg-secondary opacity-25" />
			<Row className="w-100 py-4 mt-4">
				<Col md={3}>
					<div className="nav-logo d-flex align-items-center flex-fill mw-100">
						<Link
							to="/"
							title={t('SidebarHeader.Index.homepage')}
						>
							<Logo className="mr-2 content-dark" height="40px" width="auto" color="#fff" />
							{import.meta.env.VITE_PROJECT === 'beeyou' && (
								<div className="d-none d-md-block position-relative">
									<span className="content-darker">
										beeyou.tv
									</span>
								</div>
							)}
						</Link>
					</div>
				</Col>
				{import.meta.env.VITE_PROJECT === 'beeyou' && (
					<Col md={3} className="pt-2">
						<h6 className="mb-4 font-weight-bold">{t('SidebarMenu.Index.helpCenter')}</h6>
						<ul className="list-unstyled">
							{helpCenterMenuItems.map((helpCentarMenuItem) => (
								<li key={helpCentarMenuItem.label} className="mb-2">
									<Link
										to={helpCentarMenuItem.to}
										className="text-secondary"
									>
										{helpCentarMenuItem.label}
									</Link>
								</li>
							))}
						</ul>
					</Col>
				)}
				<Col md={3} className="pt-2">
					<h6 className="mb-4 font-weight-bold">{t('SidebarMenu.Index.legalNotice')}</h6>
					<ul className="list-unstyled">
						{legalMenuItems.map((helpCentarMenuItem) => (
							<li key={helpCentarMenuItem.label} className="mb-2">
								<Link
									to={helpCentarMenuItem.to}
									className="text-secondary"
								>
									{helpCentarMenuItem.label}
								</Link>
							</li>
						))}
					</ul>
				</Col>
				<Col md={3} className="pt-2">
					<h6 className="mb-4 font-weight-bold">{t('SidebarMenu.Index.settings')}</h6>
					<ul className="list-unstyled">
						<li
							className="mb-2"
							onClick={() => handleChangeCurrentScreenTheme(
								currentScreenTheme === ScreenThemeLayout.DARK
									? ScreenThemeLayout.LIGHT
									: ScreenThemeLayout.DARK,
							)}
						>
							<span className="cursor-pointer text-secondary">
								{t('SidebarMenu.Index.toggleTheme')} {currentScreenTheme === ScreenThemeLayout.DARK ? t('SidebarMenu.Index.lightTheme') : t('SidebarMenu.Index.darkTheme')}
							</span>
						</li>
					</ul>
				</Col>
			</Row>
		</Container>
	);
};
