import { api } from '../api';

const API_NOTIFY_PATH = '/notify';

export const createSubscription = async (data) => api.post(
	`${API_NOTIFY_PATH}/subscribe`, data,
);
export const deleteSubscription = async (endpoint) => api.post(`${API_NOTIFY_PATH}/delete`, { endpoint });

export const disableNotifications = async (endpoint) => api.post(`${API_NOTIFY_PATH}/disable`, { endpoint });

export const enableNotifications = async () => api.post(`${API_NOTIFY_PATH}/enable`);
