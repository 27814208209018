/* eslint-disable react/prop-types */
// @ts-check

import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { useMediaUser } from '../User';

/** @import { UserMediaStreamTrack, UserMediaStream } from '../User'; */

/**
 * @typedef {{
 * addCropVideoTrack: (track: UserMediaStreamTrack) => void,
 * cropOrUserVideoActiveTracks: UserMediaStreamTrack[],
 * cropOrUserActiveTracks: UserMediaStreamTrack[],
 * cropOrUserMediastreams: UserMediaStream[],
 * cropVideoActiveTracks: UserMediaStreamTrack[],
 * isCropEnabled: boolean,
 * removeCropVideoTrackByTrack: (track: UserMediaStreamTrack) => void,
 * personalCrop: any,
 * setPersonalCrop: React.Dispatch<React.SetStateAction<any>>,
 * }} IMediaCropContext
 */

export const MediaCropContext = createContext(/** @type {IMediaCropContext}*/({}));
export const useMediaCrop = () => useContext(MediaCropContext);

/**
 * @typedef {{
 * 	children: React.ReactNode,
 * }} MediaCropProviderProps
 */

export const MediaCropProvider = (
	/** @type {MediaCropProviderProps} */
	{
		children,
	},
) => {
	// TODO : type personalCrop
	const [personalCrop, setPersonalCrop] = useState(undefined);
	const [cropVideoActiveTracks, setCropVideoActiveTracks] = useState(
		/** @type {UserMediaStreamTrack[]} */([]),
	);
	const { userVideoActiveTracks, userMediastreams, userAudioActiveTracks } = useMediaUser();

	const addCropVideoTrack = useCallback((
		/** @type {UserMediaStreamTrack} */track,
	) => {
		setCropVideoActiveTracks((prev) => [...prev, track]);
	}, []);

	const removeCropVideoTrackByTrack = useCallback((
		/** @type {UserMediaStreamTrack} */track,
	) => {
		setCropVideoActiveTracks((prev) => prev.filter(({ configId }) => configId !== track.configId));
	}, []);

	const isCropEnabled = !!personalCrop;

	const cropOrUserVideoActiveTracks = useMemo(() => {
		// ensure user track is removed even if key track is not yet available
		// in order to avoid publish two tracks in a row
		if (isCropEnabled) {
			return [
				...cropVideoActiveTracks,
				...userVideoActiveTracks.filter(({ configId }) => configId !== 0),
			];
		}
		return userVideoActiveTracks;
	}, [
		cropVideoActiveTracks,
		isCropEnabled,
		userVideoActiveTracks,
	]);

	const cropOrUserActiveTracks = useMemo(
		() => [
			...cropOrUserVideoActiveTracks,
			...userAudioActiveTracks,
		], [cropOrUserVideoActiveTracks, userAudioActiveTracks],
	);

	const cropOrUserMediastreams = useMemo(() => {
		if (cropVideoActiveTracks?.length > 0) {
			/** @type {UserMediaStream} */
			const cropMediaStream = new MediaStream();
			cropMediaStream.configId = 0;
			cropMediaStream.addTrack(cropVideoActiveTracks[0]);
			const userMediaStream = userMediastreams.find(({ configId }) => configId === 0);
			if (userMediaStream) {
				const track = userMediaStream.getAudioTracks()[0];
				if (track) cropMediaStream.addTrack(track);
			}

			// Refresh cropMediaStream when tracks change to avoid player image stuck
			return [
				...userMediastreams.filter(({ configId }) => configId !== 0),
				cropMediaStream,
			];
		}
		return userMediastreams;
	}, [cropVideoActiveTracks, userMediastreams]);

	const contextValue = useMemo(() => ({
		addCropVideoTrack,
		cropOrUserVideoActiveTracks,
		cropOrUserActiveTracks,
		cropOrUserMediastreams,
		cropVideoActiveTracks,
		isCropEnabled,
		removeCropVideoTrackByTrack,
		personalCrop,
		setPersonalCrop,
	}), [
		addCropVideoTrack,
		cropOrUserVideoActiveTracks,
		cropOrUserActiveTracks,
		cropOrUserMediastreams,
		cropVideoActiveTracks,
		isCropEnabled,
		removeCropVideoTrackByTrack,
		personalCrop,
		setPersonalCrop,
	]);

	return (
		<MediaCropContext.Provider value={contextValue}>
			{children}
		</MediaCropContext.Provider>
	);
};
