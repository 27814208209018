/* eslint-disable react/prop-types */
// @ts-check

import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

import { usePagination } from './Context';

/**
 * @typedef {{
 * 	isDisabled?: boolean;
 * }} PaginationPageLineProps
 */

export const PaginationPageLine = (
	/** @type {PaginationPageLineProps} */
	{
		isDisabled = false,
	},
) => {
	const {
		currentPage,
		pageCount,
		canNextPage,
		canPrevPage,
		goNextPage,
		goPrevPage,
		goFirstPage,
		goLastPage,
	} = usePagination();

	return pageCount ? (
		<Pagination>
			<PaginationItem disabled={isDisabled || !canPrevPage}>
				<PaginationLink first onClick={goFirstPage} className="bg-light content-light border-0" />
			</PaginationItem>
			<PaginationItem disabled={isDisabled || !canPrevPage}>
				<PaginationLink previous onClick={goPrevPage} className="bg-light content-light border-0 me-0" />
			</PaginationItem>
			<PaginationItem className="d-flex align-items-center text-secondary px-2">
				{currentPage} / {pageCount}
			</PaginationItem>
			<PaginationItem disabled={isDisabled || !canNextPage}>
				<PaginationLink next onClick={goNextPage} className="bg-light content-light border-0" />
			</PaginationItem>
			<PaginationItem disabled={isDisabled || !canNextPage}>
				<PaginationLink last onClick={goLastPage} className="bg-light content-light border-0" />
			</PaginationItem>
		</Pagination>
	) : null;
};
