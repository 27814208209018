import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { getFileUrl, AVATAR_SIZE } from '../../lib/file';
import Hexagon from '../Hexagon/Hexagon';
import defaultAvatar from '../../images/default-avatar.png';
import { getChannelLink } from '../../RoutePath';

export const ProfileAvatar = ({ hashtag, avatarUrl, small }) => {
	const { t } = useTranslation();
	const userAvatar = avatarUrl
		? getFileUrl({ name: avatarUrl }, AVATAR_SIZE.width, AVATAR_SIZE.height)
		: defaultAvatar;

	return (
		<Link to={hashtag ? getChannelLink(hashtag) : '#'}>
			<div className="avatar-icon-wrapper avatar-icon-md mr-3 mt-1">
				<Hexagon className={`avatar-icon ${small ? '-mini' : ''} shadow-none`}>
					<img alt={t('Profile.Avatar.avatar')} src={userAvatar} />
				</Hexagon>
			</div>
		</Link>
	);
};

ProfileAvatar.propTypes = {
	avatarUrl: PropTypes.string,
	hashtag: PropTypes.string,
	small: PropTypes.bool,
};

ProfileAvatar.defaultProps = {
	avatarUrl: undefined,
	hashtag: undefined,
	small: false,
};
