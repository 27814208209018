import { createContext, useContext } from 'react';

export const ReportType = {
	CHANNEL: 'CHANNEL',
	STUDIO: 'STUDIO',
	VIDEO: 'VIDEO',
};

export const ModerationContext = createContext();

export const useModeration = () => useContext(ModerationContext);
