import { api } from '../api';

export const API_CHANNEL_IMAGES_PATH = '/channel/images';

export const createImage = async ({ channelId, image }) => api.post(
	`${API_CHANNEL_IMAGES_PATH}/toChannelById/${channelId}`,
	image,
);

export const fetchImages = (
	channelId,
	mode,
	itemsPerPage,
	currentPage,
) => api.get(
	`${API_CHANNEL_IMAGES_PATH}`,
	{
		params: {
			channelId,
			mode,
			itemsPerPage,
			currentPage,
		},
	},
);

export const updateImage = async (image) => api.put(
	API_CHANNEL_IMAGES_PATH,
	image,
);

export const deleteImage = async (imageId) => api.delete(
	`${API_CHANNEL_IMAGES_PATH}/${imageId}`,
);
