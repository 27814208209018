import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { AdvertisingDashboardDemographicsGender } from './Gender';
import { AdvertisingDashboardDemographicsAge } from './Age';
import { AdvertisingDashboardDemographicsCountry } from './Country';

export const AdvertisingDashboardDemographics = ({ adCampaignId }) => (
	<Row className="mb-5">
		<Col md={4}>
			<AdvertisingDashboardDemographicsGender
				adCampaignId={adCampaignId}
			/>
		</Col>
		<Col md={4}>
			<AdvertisingDashboardDemographicsAge
				adCampaignId={adCampaignId}
			/>
		</Col>
		<Col md={4}>
			<AdvertisingDashboardDemographicsCountry
				adCampaignId={adCampaignId}
			/>
		</Col>
	</Row>
);

AdvertisingDashboardDemographics.propTypes = {
	adCampaignId: PropTypes.string,
};

AdvertisingDashboardDemographics.defaultProps = {
	adCampaignId: undefined,
};
