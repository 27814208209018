import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useCallback } from 'react';

import { axiosQueryWrapper, axiosMutationWrapper } from '../utils/axios-wrapper';
import * as commentsApi from '../../api/channel/comments';
import { useHandleUpdatedPost } from './posts';
import { useAuthentication } from '../../components/Authentication/Authentication';

const COMMENT_QUERY_KEYS = {
	fetchCommentsByPost: (postId) => ['api', 'channel', 'posts', 'comments', postId],
};

export const useFetchPostComments = (postId) => useQuery(
	COMMENT_QUERY_KEYS.fetchCommentsByPost(postId),
	axiosQueryWrapper(commentsApi.fetchPostComments, postId),
);

export const useHandleNewPostComment = (channelId) => {
	const queryClient = useQueryClient();
	const handleUpdatedPost = useHandleUpdatedPost(channelId);

	return useCallback((comment) => {
		queryClient.setQueryData(
			COMMENT_QUERY_KEYS.fetchCommentsByPost(comment.post._id),
			(comments) => (comments?.length ? [comment, ...comments] : [comment]),
		);

		handleUpdatedPost(comment.post);
	}, [handleUpdatedPost, queryClient]);
};

export const useHandleUpdatePostComment = () => {
	const queryClient = useQueryClient();

	return useCallback((updatedComment) => {
		queryClient.setQueryData(
			COMMENT_QUERY_KEYS.fetchCommentsByPost(updatedComment.post._id),
			(comments) => comments
				?.map((comment) => (
					comment._id === updatedComment._id
						? {
							...updatedComment,
							liked: comment.liked,
						}
						: comment
				)),
		);
	}, [queryClient]);
};

export const useHandleDeletePostComment = (channelId) => {
	const queryClient = useQueryClient();
	const handleUpdatedPost = useHandleUpdatedPost(channelId);

	return useCallback((deletedComment) => {
		queryClient.invalidateQueries(COMMENT_QUERY_KEYS.fetchCommentsByPost(deletedComment.post._id));

		handleUpdatedPost(deletedComment.post);
	}, [handleUpdatedPost, queryClient]);
};

export const useHandlePostCommentLikesChange = () => {
	const queryClient = useQueryClient();
	const { user } = useAuthentication();

	return useCallback(({ comment: likedComment, like }) => {
		queryClient.setQueryData(
			COMMENT_QUERY_KEYS.fetchCommentsByPost(likedComment.post),
			(comments) => comments
				?.map((comment) => (comment._id === likedComment._id
					? {
						...comment,
						numberOfLikes: likedComment.numberOfLikes,
						userLikeReactionType: like.user === user?.sub
							? like.reactionType
							: comment?.userLikeReactionType,
					}
					: comment
				)),
		);
	}, [queryClient, user]);
};

export const useCreatePostComment = () => useMutation(
	axiosMutationWrapper(commentsApi.createPostComment),
);

export const useUpdatePostComment = () => useMutation(
	async ({ id, text, images, videos }) => axiosMutationWrapper(
		commentsApi.updatePostComment,
	)({ id, text, images, videos }),
);

export const useDeletePostComment = () => useMutation(
	axiosMutationWrapper(commentsApi.deletePostComment),
);
