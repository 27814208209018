// @ts-check

import { createSelector } from 'reselect';
import { STREAM_TYPE_LIVE } from '../../lib/stream';

export const STATE_KEY = 'tracks';

/** @import { RootState } from '..'; */

/**
 * selectors
 * @param {RootState} state
 */
export const selectTracks = (state) => state[STATE_KEY];

export const selectAudioTracks = createSelector(
	selectTracks,
	(tracks = []) => tracks.filter((t) => t.mediaStreamTrack instanceof MediaStreamTrack && t.mediaStreamTrack.kind === 'audio'),
);

export const selectLiveTrack = createSelector(
	selectTracks,
	(tracks = []) => {
		const liveTracks = tracks.filter(({ appData }) => appData?.streamType === STREAM_TYPE_LIVE);
		const videoTracks = liveTracks.filter((t) => t.mediaStreamTrack instanceof MediaStreamTrack && t.mediaStreamTrack.kind === 'video');
		// Track dedicated to a peer receiver
		// (contains all audio sources of live except the one from current user to avoid larsens)
		const audioTracks = liveTracks.filter((t) => t.appData?.peerReceiver);

		if (audioTracks.length < 1) {
			// If there's no dedicated audio track, we'll use the general audio track
			const track = liveTracks.find((t) => t.mediaStreamTrack instanceof MediaStreamTrack && t.mediaStreamTrack.kind === 'audio');
			if (track) {
				audioTracks.push(track);
			}
		}

		return [
			...videoTracks,
			...audioTracks,
		];
	},
);

export const selectLiveAudioGeneralTrack = createSelector(
	selectTracks,
	(tracks = []) => {
		const liveTracks = tracks.filter(({ appData }) => appData?.streamType === STREAM_TYPE_LIVE);
		// General audio track (which contains all audio sources of live)
		return liveTracks.find((t) => !t.appData?.peerReceiver && t.mediaStreamTrack instanceof MediaStreamTrack && t.mediaStreamTrack.kind === 'audio');
	},
);

export const selectVideoTracks = createSelector(
	selectTracks,
	(tracks = []) => tracks.filter((t) => t.mediaStreamTrack instanceof MediaStreamTrack && t.mediaStreamTrack.kind === 'video'),
);
