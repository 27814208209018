/* eslint-disable react/prop-types */
// @ts-check

import { Outlet } from 'react-router-dom';

import { ViewForbidden } from '../../views/Forbidden/Forbidden';
import { useAuthentication } from './Authentication';

/**
 * @typedef {{
 * 	condition: (variables: { isLoggedIn: boolean }) => boolean
 * 	element?: React.ReactNode
 * 	fallback?: React.ReactNode
 * }} RouteIfConditionProps
 */

const RouteIfCondition = (
	/** @type {RouteIfConditionProps} */
	{
		condition,
		element,
		fallback,
	},
) => {
	const authentication = useAuthentication();

	if (authentication.isPending) return null; // wait for first getUser
	if (!condition({ isLoggedIn: authentication.isLoggedIn })) {
		return (
			fallback || <ViewForbidden />
		);
	}

	return element || <Outlet />;
};

export default RouteIfCondition;
